import { Route, Switch } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import ExactSubscription from './ExactSubscription';
import NewSubscription from './NewSubscription';
import SubscriptionList from './SubscriptionList';

const Subscriptions = () => {
  const roles = useUserRoles();
  return (
    <Switch>
      {roles.includes(Permission.CREATE_SUBSCRIPTION) && [
        <Route key={Routes.newSubscription} exact path={Routes.newSubscription} component={NewSubscription} />
      ]}
      {roles.includes(Permission.VIEW_SUBSCRIPTION_LIST) && [
        <Route key={Routes.subscriptions} exact path={Routes.subscriptions} component={SubscriptionList} />
      ]}
      {(roles.includes(Permission.VIEW_SUBSCRIPTION) || roles.includes(Permission.VIEW_DELIVERY_SUBSCRIPTION)) && [
        <Route key={Routes.subscription} path={Routes.subscription} component={ExactSubscription} />
      ]}
    </Switch>
  );
};

export default Subscriptions;
