import { LogType, Subscription } from '@calo/dashboard-types';
import { Dictionary } from '@calo/types';
import { Button } from 'components';
import { format, parseISO } from 'date-fns/fp';
import client from 'lib/client';
import { Log } from 'lib/interfaces';
import { flatten } from 'lodash-es';
import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import MessageRow from './MessageRow';
import { Res } from './types';
interface LogPanelProps {
  subscription: Subscription;
}

const LogPanel = ({ subscription }: LogPanelProps) => {
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery<{ data: Log[]; meta: { cursor?: string } }>(
    `subscriptions/${subscription.id}/logs`,
    async ({ pageParam, queryKey }) => {
      const { data } = await client.get(queryKey.join('/'), {
        params: {
          ...(pageParam && {
            cursor: pageParam
          })
        }
      });
      return data;
    },
    {
      getNextPageParam: (data) => {
        console.log(data, 'DATAASDAS', data.meta.cursor);
        return data.meta.cursor;
        // !!data.meta.cursor,
      }
    }
  );

  const list = useMemo(
    () =>
      (data?.pages || []).reduce<Log[]>((res, r) => {
        res = [...res, ...(r.data || [])];
        return res;
      }, []),
    [data]
  );

  const LogDataCollapsed = list.reduce((res: Dictionary<Res[]>, row) => {
    const key = `${format('yyy-MM-dd')(parseISO(row.createdAt))}-${row.type}`;

    if (!res[key]) {
      res[key] = [
        {
          log: row,
          childrens: []
        }
      ];
    } else if (row.type === LogType.DELIVERY_FOOD_REPLACED) {
      const index = res[key].find((r) => r.log.message.includes('replaced'));
      index?.childrens.push(row);
    } else {
      res[key].push({
        log: row,
        childrens: []
      });
    }
    return res;
  }, {});
  const changed = flatten(Object.values(LogDataCollapsed));

  return (
    <div className="card has-table has-table-container-upper-radius">
      <header className="card-header bg-black">
        <p className="card-header-title text-white">Logs</p>
      </header>
      <div className="card-content">
        <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
          <thead>
            <tr>
              <th>Action</th>
              <th>Actor</th>
              <th>Time</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>Action</th>
              <th>Actor</th>
              <th>Time</th>
            </tr>
          </tfoot>
          <tbody>
            {changed.map((row) => (
              <MessageRow key={row.log.id} row={row} />
            ))}
          </tbody>
        </table>
        {hasNextPage && <Button onClick={() => fetchNextPage()} content="Load More" primary />}
      </div>
    </div>
  );
};
export default LogPanel;
