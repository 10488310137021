import ExcelJS from 'exceljs';
import { calculatePurchasingCost, handleMealSellingPrice } from 'lib/helpers';
import { Food, FoodComponent, FoodComponentWithQuantity } from 'lib/interfaces';
import { isEmpty, range, round } from 'lodash';
import {
  addCellBorderToFilledCells,
  addCellBorders,
  addEmptyRow,
  addLabelValueRow,
  addTableHeaderRow,
  clearRows,
  leftBorderStylesForCell,
  removeRowBorders,
  topBorderStylesForCell
} from './excelUtils';
import { MealSizeWithCost, calculatePercentageCost, findPopulatedComponents } from './helpers';

const createFoodWorksheet = (
  workbook: ExcelJS.Workbook,
  selectedFoodComponents: FoodComponent[],
  childComponents: FoodComponent[],
  foodWithAllSizes: Food[]
) => {
  if (isEmpty(foodWithAllSizes)) {
    return;
  }

  const worksheet = workbook.addWorksheet('meals');

  worksheet.getColumn(2).width = 30;
  worksheet.getColumn(3).width = 10;
  worksheet.getColumn(4).width = 10;
  worksheet.getColumn(5).width = 10;
  worksheet.getColumn(6).width = 15;
  worksheet.getColumn(7).width = 15;
  worksheet.getColumn(8).width = 17;

  const colCount = 9;
  let rowIndex = 1;
  let i = 0;

  const sizes = ['S', 'M', 'L'];

  const foodSellingPrices: { [size: string]: number } = {};

  for (const food of foodWithAllSizes) {
    foodSellingPrices[food.size] = sizes.includes(food.size)
      ? (handleMealSellingPrice(food.kitchen, food.type, food.tags, food.type?.[0]) ?? 0)
      : food.price;
  }

  const mealCostsAllSizes: MealSizeWithCost[] = [];
  const costPercentageRowIndexes: number[] = [];

  for (const food of foodWithAllSizes) {
    rowIndex = clearRows(worksheet, rowIndex, 2, colCount);
    if (i !== 0) {
      worksheet.getRow(rowIndex - 2).getCell(colCount - 1).border = topBorderStylesForCell;
    }

    rowIndex = addMealTableHeaders(worksheet, rowIndex, colCount, food);

    addMealComponentTableHeader(worksheet, rowIndex);
    rowIndex++;

    const componentsWithQuantity = findPopulatedComponents(food.components ?? [], selectedFoodComponents);
    const result = addComponentRowsForMeal(worksheet, rowIndex, colCount, componentsWithQuantity, childComponents);

    const totalMealCost = result.totalMealCost;
    rowIndex = result.rowIndex;

    addMealCostPerPortionRow(worksheet, rowIndex, colCount, totalMealCost);
    rowIndex++;

    if (sizes.includes(food.size)) {
      mealCostsAllSizes.push({ size: food.size, value: totalMealCost });
    }

    addSellingPriceRow(worksheet, rowIndex, colCount, foodSellingPrices[food.size]);
    rowIndex++;

    const costPercentageRow = worksheet.getRow(rowIndex);
    costPercentageRow.height = 20;
    costPercentageRow.values = ['', 'ESTIMATED COST %', '', '', '', '', '', '', ''];
    removeRowBorders(costPercentageRow);

    const costPercentageValueCell = costPercentageRow.getCell(colCount - 1);
    costPercentageValueCell.alignment = { horizontal: 'right' };
    addCellBorders(costPercentageValueCell);
    costPercentageRow.getCell(colCount).border = leftBorderStylesForCell;
    costPercentageRowIndexes.push(rowIndex);
    rowIndex++;
    i++;
  }

  const acceptedSellingPrice = foodSellingPrices[Object.keys(foodSellingPrices).find((size) => sizes.includes(size))!] ?? 0;
  const costPercentage = calculatePercentageCost(mealCostsAllSizes, foodWithAllSizes[0].kitchen, acceptedSellingPrice);
  for (const index of costPercentageRowIndexes) {
    worksheet.getRow(index).getCell(colCount - 1).value = costPercentage + '%';
  }
};

const addMealTableHeaders = (worksheet: ExcelJS.Worksheet, rowIndex: number, colCount: number, food: Food) => {
  addLabelValueRow(worksheet, rowIndex, colCount, 'KITCHEN', food.kitchen);
  rowIndex++;
  addLabelValueRow(worksheet, rowIndex, colCount, 'MEAL NAME', food.name.en);
  rowIndex++;
  addLabelValueRow(worksheet, rowIndex, colCount, 'MEAL TYPE', food.type.join('/'), 13);
  rowIndex++;
  addLabelValueRow(worksheet, rowIndex, colCount, 'MEAL DIET', food.tags.join('/'), 13);
  rowIndex++;
  addLabelValueRow(worksheet, rowIndex, colCount, 'MEAL SIZE', food.size, 13);
  rowIndex++;
  addLabelValueRow(worksheet, rowIndex, colCount, 'PORTION', '1');
  rowIndex++;
  addEmptyRow(worksheet, rowIndex, colCount);
  rowIndex++;

  return rowIndex;
};

const addMealComponentTableHeader = (worksheet: ExcelJS.Worksheet, rowIndex: number) => {
  const values = ['', 'COMPONENTS', 'COOKED WEIGHT', 'Shrinkage Factor', 'RAW WEIGHT', 'UNIT', 'UNIT COST', 'TOTAL COST', ''];
  addTableHeaderRow(worksheet, rowIndex, values);
  const componentsLabelCell = worksheet.getCell(rowIndex, 2);
  addCellBorders(componentsLabelCell);
};

const addComponentRowsForMeal = (
  worksheet: ExcelJS.Worksheet,
  rowIndex: number,
  colCount: number,
  components: FoodComponentWithQuantity[],
  childComponents: FoodComponent[]
) => {
  let totalMealCost = 0;

  for (const component of components) {
    const componentRow = worksheet.getRow(rowIndex);
    componentRow.height = 15;
    componentRow.alignment = { horizontal: 'center' };

    const populatedChildComps = findPopulatedComponents(component.childComponents ?? [], childComponents);

    const purchasingCostPerGram = calculatePurchasingCost(
      component.ingredients,
      populatedChildComps,
      component.cookedRawFactor ?? 1
    );
    const purchasingCostPerUnit = purchasingCostPerGram * (component.weight ?? 1);
    const costPerQuantity = purchasingCostPerUnit * component.quantity;
    componentRow.values = [
      '',
      component.name.en,
      component.weight,
      component.cookedRawFactor,
      (component.weight ?? 1) * (component.cookedRawFactor ?? 1),
      component.measurementUnit,
      round(purchasingCostPerUnit, 5),
      round(costPerQuantity, 5),
      ''
    ];

    removeRowBorders(componentRow);
    addCellBorderToFilledCells(componentRow, colCount);

    const componentNameCell = componentRow.getCell(2);
    addCellBorders(componentNameCell);

    totalMealCost += costPerQuantity;
    rowIndex++;
  }

  return { rowIndex, totalMealCost };
};

const addMealCostPerPortionRow = (worksheet: ExcelJS.Worksheet, rowIndex: number, colCount: number, cost: number) => {
  const costPerPortionRow = worksheet.getRow(rowIndex);
  costPerPortionRow.height = 20;
  costPerPortionRow.values = ['', 'COST PER PORTION', '', '', '', '', '', round(cost, 3), ''];
  removeRowBorders(costPerPortionRow);
  costPerPortionRow.eachCell((cell, colNumber) => {
    if (range(2, colCount).includes(colNumber)) {
      cell.border = topBorderStylesForCell;
    }

    if (colNumber === colCount - 1) {
      addCellBorders(cell);
    }

    if (colNumber === colCount) {
      cell.border = leftBorderStylesForCell;
    }
  });
};

const addSellingPriceRow = (worksheet: ExcelJS.Worksheet, rowIndex: number, colCount: number, sellingPrice: number) => {
  const sellingPriceRow = worksheet.getRow(rowIndex);
  sellingPriceRow.height = 20;
  sellingPriceRow.values = ['', 'PROPOSED SELLING PRICE (NET.)', '', '', '', '', '', sellingPrice, ''];
  removeRowBorders(sellingPriceRow);

  const propsedPriceValueCell = sellingPriceRow.getCell(colCount - 1);
  addCellBorders(propsedPriceValueCell);
  sellingPriceRow.getCell(colCount).border = leftBorderStylesForCell;
};

export default createFoodWorksheet;
