import { PermissionService } from '@calo/services';
import { getCaloFoodTypePrice } from '@calo/services/dist/constants';
import { CustomMealCategory, Delivery, DietType, FoodType, Kitchen, Localized, SubscriptionTier } from '@calo/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Card, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import { createCustomFood, getListWithParams, replaceFood, updateCustomFood } from 'actions';
import mutation from 'actions/mutation';
import { caloTheme } from 'assets/images/theme/calo';
import { Select2 } from 'components';
import CustomFoodPopup from 'components/CustomFoodPopup';
import { macrosSortOptions } from 'lib/constants';
import { checkCountryCurrency, renderIngredients, resolveCountryFromKitchen, sortFoodByMacros } from 'lib/helpers';
import { CustomComponentsByCategory, FoodWithPosition, MenuFood } from 'lib/interfaces';
import { compact, flatten, groupBy, startCase, uniqBy } from 'lodash-es';
import { useMemo, useRef, useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation, useQuery } from 'react-query';
import MealTotalInformationSection from '../../../../../components/MealTotalInformationSection';
import MenuFoodDietTags from '../../../../../components/MenuFoodDietTags';
import { ModalRef } from '../../../../../components/Modal';
import Popup from '../../../../../components/Popup';
import CustomMealListItem from './CustomMealListItem';

interface ReplaceMealPopupProps {
  selectedMeal: FoodWithPosition;
  menuFood: MenuFood[];
  selectedDelivery: Delivery;
  allFoodComponentsData: any[];
  customFoodComps: CustomComponentsByCategory;
  isCustomCompsLoading: boolean;
  refetchDel: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  mealSwapRef: React.MutableRefObject<ModalRef | undefined>;
}
const ReplaceMealPopup = ({
  allFoodComponentsData,
  mealSwapRef,
  refetchDel,
  selectedMeal,
  menuFood,
  selectedDelivery,
  customFoodComps,
  isCustomCompsLoading
}: ReplaceMealPopupProps) => {
  const [isCustomCardOpen, setIsCustomCardOpen] = useState<boolean>(false);
  const { mutateAsync: replaceMutation, isLoading: replaceMealLoading } = useMutation(replaceFood);
  const { mutateAsync: updateCustomFoodMutation } = useMutation(updateCustomFood);
  const { mutateAsync: createCustomFoodMutation } = useMutation(createCustomFood);
  const [customMealAction, setCustomMealAction] = useState<string>('');
  const [customFood, setCustomFood] = useState<any | null>();
  const customMealRef = useRef<ModalRef>();
  const [selectedSize, setSelectedSize] = useState<any>({
    size: selectedMeal?.size,
    foodId: selectedMeal?.id,
    foodName: selectedMeal?.name?.en,
    meal: selectedMeal
  });

  const [sortFilter, setSortFilter] = useState<string>('');

  const { data: customMeals, refetch } = useQuery<any, Error, { data: any[] }>(
    ['food/custom', { subscriptionId: selectedDelivery?.userId, deliveryId: selectedDelivery?.id }],
    getListWithParams,
    {
      enabled: !!selectedDelivery
    }
  );
  const userCustomMeals = useMemo(() => customMeals?.data.map((customFood) => customFood), [customMeals?.data]);

  const menuMeals = useMemo(() => {
    if (!menuFood) {
      return [];
    }

    return sortFoodByMacros(sortFilter, menuFood);
  }, [
    menuFood,
    selectedDelivery,
    sortFilter,
    macrosSortOptions.highestToLowestProtein,
    macrosSortOptions.highestToLowestCarb,
    macrosSortOptions.lowestToHighestFat
  ]);

  const handleSizeChange = (size: string, allSizes: MenuFood[]) => {
    const selectedMealData = uniqBy(flatten(compact(allSizes.filter((r) => r.size === size))), 'id');
    setSelectedSize({
      size: selectedMealData[0].size,
      foodId: selectedMealData[0].id,
      foodName: selectedMealData[0].name?.en,
      meal: selectedMealData[0]
    });
  };

  const handleCustomMeal = (
    componentData: any,
    customName: Localized,
    mealDescription: Localized,
    customMealCategory: CustomMealCategory
  ) => {
    if (customMealAction === 'update') {
      handleUpdateCustomFood(componentData, customName, mealDescription);
    } else if (customMealAction === 'create') {
      handleCreateCustomFood(componentData, customName, customMealCategory, mealDescription);
    }
  };

  const handleCreateCustomFood = async (
    components: any,
    customName: Localized,
    customMealCategory: CustomMealCategory,
    description?: Localized
  ) => {
    const customComponents = flatten(Object.values(components)).map((r: any) => ({ id: r.value }));
    await createCustomFoodMutation(
      {
        subscriptionId: selectedDelivery.userId,
        deliveryId: selectedDelivery.id,
        name: customName,
        foodComponentIds: customComponents.map((r) => r.id),
        category: customMealCategory,
        description
      },
      {
        onSuccess: (data) => {
          customMealRef.current?.close();
          handleReplace(data, selectedMeal);
          mutation(['food/custom', { subscriptionId: selectedDelivery.userId, deliveryId: selectedDelivery.id }], data);
          refetch();
        }
      }
    );
  };

  const handleUpdateCustomFood = async (x: any, customName: Localized, description?: Localized) => {
    const customComponents = flatten(Object.values(x)).map((r: any) => ({ id: r.value }));
    await updateCustomFoodMutation(
      {
        deliveryId: selectedDelivery.id,
        foodId: customFood.id === 'food' ? customFood.sk : customFood.id,
        name: customName,
        components: customComponents.map((r) => r.id),
        description
      },
      {
        onSuccess: () => {
          customMealRef.current?.close();
          setCustomFood(null);
          refetch();
        }
      }
    );
  };

  const handleReplace = async (newMeal: any, oldMeal: any) => {
    await replaceMutation({
      id: selectedDelivery?.id || '',
      sourceId: oldMeal.id,
      targetId: newMeal.size === 'C' ? (newMeal.id === 'food' ? newMeal.sk : newMeal.id) : newMeal.id || newMeal.foodId,
      positionIndex: oldMeal?.positionIndex
    });
    refetchDel();
    mealSwapRef.current?.close();
  };

  const handleFoodSize = (foodName: MenuFood) => {
    const sameMealSize = flatten(Object.values(groupBy(menuFood, 'name.en')));
    const allSizes = uniqBy(compact(sameMealSize.map((r) => r.name?.en === foodName.name?.en && r)), 'size');
    return (
      <Tooltip
        title={`${allSizes.length === 0 ? 'No Sizes' : allSizes.length <= 1 ? 'The only size for this meal is already Selected' : selectedDelivery && !PermissionService.deliveryCanBeEdited(selectedDelivery) ? 'Can not change size during lockup time' : ''}`}
        placement="top"
        arrow
      >
        <Select
          value={selectedSize?.foodName === foodName.name?.en ? selectedSize.size : foodName.size}
          name="size"
          style={{
            width: '100%',
            minWidth: '35%',
            height: '45px',
            marginRight: '12px'
          }}
          disabled={!(allSizes.length > 1) || (selectedDelivery && !PermissionService.deliveryCanBeEdited(selectedDelivery))}
          onChange={(data: any) => handleSizeChange(data.target.value, allSizes)}
        >
          {(allSizes.length === 0 ? [{ size: foodName.size }] : Object.values(allSizes)).map((type) => (
            <MenuItem key={type.size} value={type.size}>
              {startCase(type.size)}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    );
  };

  const handleReplaceMeal = (replacedMeal: MenuFood) => {
    if (selectedSize.meal.name.en === replacedMeal.name.en && selectedSize.size !== replacedMeal.size) {
      handleReplace(selectedSize.meal, selectedMeal);
    } else {
      handleReplace(replacedMeal, selectedMeal);
    }
  };

  const renderMealCard = (meal: MenuFood, selectMeal: boolean) => (
    <>
      <Box
        sx={{
          width: '100%',
          borderBottom: 1,
          borderBottomColor: caloTheme.palette.neutral100,
          flexDirection: 'row',
          display: 'flex'
        }}
      >
        <Stack flexDirection={'column'} width={'100%'} sx={{ marginY: 1 }}>
          <Stack flexDirection={'row'} sx={{ marginRight: 1 }}>
            <img
              className="object-cover"
              width={40}
              style={{ maxHeight: '40px', maxWidth: '40px', borderRadius: '8px' }}
              height={40}
              alt={meal.name.en}
              onError={(e: any) => (e.target.src = 'https://via.placeholder.com/120')}
              src={`${process.env.REACT_APP_BUCKET_URL}/food/${meal.id}/square@1x.jpg`}
            />
            <Stack justifyContent={'space-between'} flexDirection={'row'} sx={{ my: 'auto', width: '100%' }}>
              <Stack justifyContent={'start'} flexDirection={'row'} width={'70%'}>
                <Typography sx={{ ml: 1, fontSize: '16px', fontWeight: 600, lineHeight: '20px' }}>{meal?.name?.en}</Typography>
                <Stack sx={{ ml: 1, display: 'flex', flexDirection: 'row' }}>
                  {meal.tags?.map((r) => <MenuFoodDietTags key={r} tag={r} />)}
                </Stack>
              </Stack>
              <Stack
                sx={{
                  mr: 1,
                  display: selectMeal ? 'flex' : 'none',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'flex-end'
                }}
              >
                <Stack sx={{ mr: 2 }}>{handleFoodSize(meal)}</Stack>
                <Stack>
                  <Button
                    variant="outlined"
                    sx={{
                      height: '45px',
                      lineHeight: '17px',
                      fontWeight: 600,
                      fontSize: '14px',
                      boxShadow: 'none',
                      borderRadius: '8px',
                      padding: '14px 20px 14px 20px',
                      borderColor: caloTheme.palette.neutral900,
                      color: caloTheme.palette.neutral900,
                      '&:hover': {
                        backgroundColor: caloTheme.palette.neutral50,
                        boxShadow: 'none',
                        color: caloTheme.palette.neutral900,
                        borderColor: caloTheme.palette.neutral900
                      },
                      [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                        justifyItems: 'center',
                        margin: 'auto',
                        width: 'auto'
                      }
                    }}
                    onClick={() => handleReplaceMeal(meal)}
                  >
                    Select Meal
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Stack display={'flex'} flexDirection={'row'} width={'94%'} flexWrap={'wrap'} sx={{ my: 'auto' }}>
              {renderIngredients(meal, selectedDelivery, allFoodComponentsData).map((i: any, index: number, array: any[]) => (
                <Tooltip title={`${i.weightedIng}`} placement="top" arrow>
                  <Typography
                    key={index}
                    sx={{
                      fontSize: '12px',
                      fontWeight: 400,
                      lineHeight: '16px',
                      color: i.isAlergen ? 'red' : caloTheme.palette.neutral900,
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {`${i.name}${index === array.length - 1 ? '' : ',\u00A0'}`}
                  </Typography>
                </Tooltip>
              ))}
            </Stack>
            <Stack width={'100%'} sx={{ mx: 'auto', mt: '-12px', mb: 1 }}>
              <MealTotalInformationSection meals={[meal]} />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );

  const closeCustomFoodPopupHandler = () => {
    customMealRef.current?.close();
  };

  const handleMealSellingPrice = (meal: MenuFood) => {
    try {
      const sellingPrice = getCaloFoodTypePrice({
        tier: selectedDelivery.tier || SubscriptionTier.personalized,
        currency: checkCountryCurrency(resolveCountryFromKitchen(selectedDelivery.kitchen || Kitchen.BH1)),
        dietType: meal.tags[0] as unknown as DietType,
        foodType: meal.type[0]
      });
      return sellingPrice || 0;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  return (
    <Box>
      <Stack sx={{ marginBottom: 1 }}>{renderMealCard(selectedMeal, false)}</Stack>
      <Box sx={{ mb: 2 }}>
        <Select2
          label="Sort by"
          value={sortFilter}
          name="sort"
          onChange={(data: any) => setSortFilter(data.target.value)}
          options={Object.values(macrosSortOptions).map((option) => ({
            value: option,
            label: option
          }))}
        />
      </Box>
      <Box>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '24px',
            mb: 2
          }}
        >
          Choose Meal
        </Typography>
        <Stack sx={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '38vh' }}>
          {menuMeals?.map(
            (meal) =>
              meal.type.some((type) => selectedMeal.type.includes(type)) &&
              handleMealSellingPrice(selectedMeal) === handleMealSellingPrice(meal) &&
              renderMealCard(meal, true)
          )}
        </Stack>
      </Box>

      <Box display={selectedMeal.type.includes(FoodType.lunch) || selectedMeal.type.includes(FoodType.dinner) ? 'flex' : 'none'}>
        <Card
          variant="outlined"
          sx={{
            paddingX: 1,
            paddingY: '12px',
            borderRadius: '8px',
            width: '100%',
            overflow: 'visible',
            marginY: 2
          }}
        >
          <Box
            sx={{ marginBottom: 1 }}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            onClick={() => setIsCustomCardOpen(!isCustomCardOpen)}
          >
            <Stack>
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '19px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '23px',
                  letterSpacing: '-0.38px'
                }}
              >
                Custom Meals
              </Typography>
            </Stack>
            {isCustomCardOpen ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
          </Box>
          <Box sx={{ display: isCustomCardOpen ? 'flex' : 'none', width: '100%' }}>
            <Stack sx={{ width: '100%' }}>
              {!userCustomMeals || userCustomMeals.length === 0 ? (
                <Typography>No Custom Meal Created for this user before</Typography>
              ) : (
                <Stack>
                  {userCustomMeals.map((customMeal: any, index) => (
                    <CustomMealListItem
                      key={customMeal.id}
                      isLastItem={userCustomMeals!.length - 1 === index}
                      customMeal={customMeal}
                      selectedMeal={selectedMeal}
                      isLoading={replaceMealLoading}
                      handleReplace={handleReplace}
                    />
                  ))}
                </Stack>
              )}
            </Stack>
          </Box>
          <Stack sx={{ textAlign: 'center', display: isCustomCardOpen ? 'flex' : 'none' }}>
            <Button
              variant="outlined"
              sx={{
                mx: 'auto',
                height: '45px',
                lineHeight: '17px',
                fontWeight: 600,
                fontSize: '14px',
                boxShadow: 'none',
                borderRadius: '8px',
                padding: '14px 20px 14px 20px',
                borderColor: caloTheme.palette.primary500,
                color: caloTheme.palette.primary500,
                '&:hover': {
                  backgroundColor: caloTheme.palette.neutral50,
                  boxShadow: 'none',
                  color: caloTheme.palette.primary500,
                  borderColor: caloTheme.palette.primary500
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  justifyItems: 'center',
                  margin: 'auto',
                  width: 'auto'
                }
              }}
              onClick={() => {
                setCustomMealAction('create');
                setCustomFood(null);
                customMealRef.current?.open();
              }}
            >
              Create Custom Meal
            </Button>
          </Stack>
        </Card>
      </Box>
      <Popup maxWidth="xl" ref={customMealRef} onClose={closeCustomFoodPopupHandler}>
        <CustomFoodPopup
          isLoading={isCustomCompsLoading}
          customMealAction={'create'}
          customFood={customFood}
          closeHandler={closeCustomFoodPopupHandler}
          handleUpdateCustomFood={handleCustomMeal}
          customFoodComps={customFoodComps}
        />
      </Popup>
    </Box>
  );
};
export default ReplaceMealPopup;
