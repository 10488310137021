import { useMemo, useState } from 'react';

import cx from 'classnames';
import { addDays } from 'date-fns';
import { format } from 'date-fns/fp';
import { flatten, groupBy, intersection, map, sortBy } from 'lodash-es';
import { toast } from 'react-toastify';

import { Permission } from '@calo/dashboard-types';
import { PricingService } from '@calo/services';
import { Brand, Delivery, DeliveryTime, DietType, FoodDietType, FoodType, Kitchen, SubscriptionTier } from '@calo/types';
import { Button, Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { quickSearch } from 'actions';
import { Autocomplete, Icon, Input, Select } from 'components';
import { customStyles2, selectStyles } from 'lib/componentStyles';
import { OperationType } from 'lib/enums';
import { isDeliverable, makeFoodSlug } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { AddressService } from 'services';
import styles from './styles';

interface CreateLogisticRequestProps {
  today: Date;
  selectedDate: string;
  extraMeals: any[];
  addedComment: string;
  coolerBagSelected: boolean;
  cutlerySelected: boolean;
  selectedDelivery?: Delivery;
  selectedKitchen: Kitchen;
  selectedCustomer: any;
  deliveryExist: boolean;
  deliverCostManual: number;
  selectedManualMeals?: any[];
  selectedUserBalance?: number;
  selectedOperation: OperationType;
  selectedDeliverySlot: DeliveryTime;
  setAddedComment: (value: string) => void;
  setSelectedDate: (value: string) => void;
  setSelectedCustomer: (value: any) => void;
  setDeliveryExist: (value: boolean) => void;
  setCoolerBagSelected: (value: boolean) => void;
  setCutlerySelected: (value: boolean) => void;
  setSelectedManualMeals: (value: any[]) => void;
  setSelectedDeliverySlot: (value: DeliveryTime) => void;
  selectedManualAddress: any;
  setSelectedManualAddress: (value: any) => void;
  selectedMealSwaps: any[];
  setSelectedMealSwaps: (value: any[]) => void;
}

const CreateLogisticRequestInput = ({
  today,
  selectedMealSwaps,
  setSelectedMealSwaps,
  cutlerySelected,
  coolerBagSelected,
  deliverCostManual,
  deliveryExist,
  selectedDelivery,
  extraMeals,
  setSelectedCustomer,
  selectedManualMeals,
  selectedDeliverySlot,
  selectedDate,
  selectedCustomer,
  selectedUserBalance,
  setSelectedDate,
  setDeliveryExist,
  addedComment,
  setSelectedDeliverySlot,
  selectedOperation,
  setAddedComment,
  setCoolerBagSelected,
  setCutlerySelected,
  setSelectedManualMeals,
  setSelectedManualAddress,
  selectedManualAddress
}: CreateLogisticRequestProps) => {
  const roles = useUserRoles();
  const [availableMealSelects, setAvailableMealSelects] = useState([0]);

  const handleSelectOldMeal = (data: any, index: number) => {
    const oldFoodSlug =
      data.slug ||
      makeFoodSlug(data.name, selectedCustomer.data.brand || Brand.CALO, selectedCustomer.data.kitchen, data.isCustom, data.id);
    const checkSlug = extraMeals?.filter(
      (extra) => extra.slug === oldFoodSlug && extra.sizes.find((sizes: any) => sizes.size === data.size)
    );

    if (checkSlug && checkSlug.length > 0) {
      const mealsList = [...selectedMealSwaps];
      mealsList[index] = {
        newFoodOptions: flatten(applyRestrictionToMealSearch(data)),
        oldFood: data
      };
      setSelectedMealSwaps(mealsList);
    } else {
      toast(`The Meal (${data.name.en}) doesn't exist on the KDS`, { type: 'error', autoClose: 2000 });
    }
  };

  const applyRestrictionToMealSearch = (oldMeal?: any) => {
    // if subscription tier is standard to exclude L meals - allways applied
    // if it's lunch everyone can pick it regardless if it's non-premium or premium
    // if plan is non-premium balance/vegetarian they can only chose those food tags
    // high-protein and low-carb can pick all food tags
    let filteredMeals = sortBy(extraMeals, (f) => `${f.name.en}`);
    if (selectedCustomer) {
      if (selectedCustomer.data.tier === SubscriptionTier.standard) {
        filteredMeals = filteredMeals.filter((f) => f.size !== 'L');
      }

      if (
        selectedCustomer.data.plan.dietType === DietType.balanced ||
        selectedCustomer.data.plan.dietType === DietType.vegetarian
      ) {
        filteredMeals = filteredMeals.filter(
          (f) =>
            f.type.includes(FoodType.lunch) ||
            f.tags.includes(FoodDietType.balanced) ||
            f.tags.includes(FoodDietType.vegetarian) ||
            f.tags.includes(FoodDietType.gourmet) ||
            (selectedCustomer.data.tier === SubscriptionTier.personalized && f.tags.includes(FoodDietType.gourmet))
        );
      }

      if (oldMeal) {
        filteredMeals = filteredMeals.filter((f) => intersection(oldMeal.type, f.type).length > 0);
      }
    }
    return filteredMeals.map((food, index) =>
      food.sizes
        .map((foodSize: any) => ({
          name: food.name,
          value: Math.random(),
          type: food.type,
          size: foodSize.size,
          extraMeals: foodSize.extraMeals,
          id: foodSize.id,
          data: food,
          innerValue: food.id,
          slug:
            food.slug ||
            makeFoodSlug(
              food.name,
              selectedCustomer.data.brand || Brand.CALO,
              selectedCustomer.data.kitchen,
              food.isCustom,
              foodSize.id
            ),
          positionIndex: index,
          label: `${food.name.en} (${foodSize.size})`
        }))
        .filter((food: any) => food.extraMeals > 0 && food.size !== 'C')
    );
  };

  const handleSelectNewmeal = (data, index: number) => {
    const mealsList = [...selectedMealSwaps];
    mealsList[index] = {
      ...mealsList[index],
      newFood: data
    };
    setSelectedMealSwaps(mealsList);
  };

  const optionsExtraMeals = useMemo(() => applyRestrictionToMealSearch(), [extraMeals, selectedCustomer, selectedDelivery]);

  const optionsOldFood = useMemo(
    () =>
      (selectedDelivery?.food || []).map((food: any, index) => ({
        name: food.name,
        type: food.type,
        size: food.size,
        innerValue: food.id,
        value: Math.random(),
        slug: makeFoodSlug(
          food.name,
          selectedCustomer.data.brand || Brand.CALO,
          selectedCustomer.data.kitchen,
          food.isCustom,
          food.id
        ),
        positionIndex: index,
        label: `${food.name.en} (${food.size}) - ${food.type.map((r: any) => r)} ${food.actions && food.actions.length > 0 ? ' - Customized meal' : ''}`
      })),
    [selectedDelivery, selectedCustomer]
  );

  const optionsAddresses = useMemo(
    () =>
      (selectedCustomer?.data.deliveryAddresses || [])
        .filter((address: any) => !address.deletedAt)
        .map((address: any) => ({
          value: address.id,
          label: `${AddressService.display(address)})}`
        })),
    [selectedDelivery, selectedCustomer]
  );

  const handleDisabled = () => {
    return selectedDelivery ? deliveryExist && isDeliverable(selectedDelivery) : !deliveryExist;
  };
  return (
    <>
      <div className="flex flex-row mb-4">
        <div className="w-1/2 mx-4">
          <Select
            label="Date"
            customStyles={customStyles2}
            value={selectedDate}
            onChange={(data: any) => setSelectedDate(data.value)}
            options={[
              {
                value: format('yyyy-MM-dd')(today),
                label: format('yyyy-MM-dd')(today)
              },
              {
                value: format('yyyy-MM-dd')(addDays(today, 1)),
                label: format('yyyy-MM-dd')(addDays(today, 1))
              }
            ]}
          />
        </div>
        <div className="w-1/2">
          {roles.includes(Permission.VIEW_SEARCH_BAR) && (
            <>
              <p className="label">Customer</p>
              <Autocomplete
                onSearch={(text) =>
                  quickSearch({
                    limit: 10,
                    query: text,
                    page: 1
                  })
                }
                transformer={(data: any[]) =>
                  map(groupBy(data, 'type'), (group, key) => ({
                    label: key,
                    options: group.map(
                      (row: any) =>
                        row.type === 'subscription' && {
                          value: row.id,
                          type: row.type,
                          data: row,
                          kitchen: row.kitchen,
                          phoneNumber: row.phoneNumber,
                          name: row.name,
                          label: `${row.name}, ${row.phoneNumber}, ${row.email}, ${row.brand ? row.brand : Brand.CALO}`
                        }
                    )
                  }))
                }
                customStyles={customStyles2}
                placeHolder={true}
                placeHolderValue={selectedCustomer ? selectedCustomer.label : 'Search'}
                onPick={(data) =>
                  data === undefined
                    ? (setSelectedCustomer(undefined), setDeliveryExist(false))
                    : (setSelectedCustomer(data), setDeliveryExist(true))
                }
              />
            </>
          )}
          {selectedCustomer && selectedUserBalance !== 0 && selectedOperation === OperationType.manualDelivery && (
            <p className="flex flex-row w-full text-cGreen text-xs -mt-1 ml-2">{` User has ${selectedCustomer.data.balance[selectedCustomer.data.currency]} ${selectedCustomer.data.currency} in their balance`}</p>
          )}
          {selectedCustomer && selectedUserBalance === 0 && selectedOperation === OperationType.manualDelivery && (
            <p className="flex flex-row w-full text-cRed text-xs -mt-1 ml-2">{` User has ${selectedUserBalance} ${selectedCustomer.data.currency} in their balance`}</p>
          )}
          <p></p>
        </div>
      </div>
      {selectedOperation === OperationType.mealSwap && (
        <div>
          {availableMealSelects.map((i) => (
            <div
              key={`${i}-${selectedMealSwaps[i]?.oldFood?.innerValue}-${selectedMealSwaps[i]?.newFood?.innerValue}`}
              className="flex flex-row mb-4"
            >
              <div className="w-1/2 mx-4 ">
                <Select
                  label="Change from"
                  onChange={(data: any) => handleSelectOldMeal(data, i)}
                  placeholder={'Change from...'}
                  options={optionsOldFood.filter(
                    (option) =>
                      option.innerValue === selectedMealSwaps[i]?.oldFood?.innerValue ||
                      !selectedMealSwaps.some(
                        (item) =>
                          item.oldFood.innerValue === option.innerValue && item.oldFood.positionIndex === option.positionIndex
                      )
                  )}
                  value={selectedMealSwaps[i]?.oldFood.value}
                  customStyles={customStyles2}
                  isDisabled={!selectedCustomer || !selectedDate || !deliveryExist || extraMeals.length === 0}
                />
              </div>
              <div className="w-1/2">
                <Select
                  label="Change to"
                  customStyles={customStyles2}
                  onChange={(data: any) => handleSelectNewmeal(data, i)}
                  placeholder={'Change to..'}
                  options={selectedMealSwaps[i]?.newFoodOptions || []}
                  isDisabled={!selectedMealSwaps[i]?.oldFood || !selectedCustomer || !selectedDate || !deliveryExist}
                  value={selectedMealSwaps[i]?.newFood?.value}
                />
              </div>
              {i > 0 && (
                <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                  <Button
                    sx={styles.trashBtn}
                    onClick={() => {
                      setAvailableMealSelects(availableMealSelects.filter((item) => item !== i).map((_, index) => index));
                      setSelectedMealSwaps(selectedMealSwaps.filter((_, index) => i !== index));
                    }}
                  >
                    <Icon name={'removeTrash'} size={7} className="mt-1 ml-1" />
                  </Button>
                </div>
              )}
            </div>
          ))}
          {selectedCustomer && (
            <div className="level-right">
              <div className="level-item">
                <p
                  className="flex flex-row w-full capitalize text-sm mr-12"
                  style={{ whiteSpace: 'nowrap' }}
                >{`${selectedCustomer.data.deliveryTime} Delivery`}</p>
                <p
                  className="flex flex-row w-full capitalize text-sm mr-4"
                  style={{ whiteSpace: 'nowrap' }}
                >{`${selectedCustomer.data.plan.id}-${selectedCustomer.data.plan.dietType}`}</p>
              </div>
            </div>
          )}
          {availableMealSelects.length !== selectedDelivery?.food.length && (
            <div className="flex justify-center mt-5">
              <Button
                variant="outlined"
                aria-label="create-subscription-list"
                sx={styles.addNewSectionBtn}
                onClick={() =>
                  setAvailableMealSelects([...availableMealSelects, availableMealSelects[availableMealSelects.length - 1] + 1])
                }
                startIcon={'+'}
              >
                Swap another meal
              </Button>
            </div>
          )}
        </div>
      )}

      {selectedOperation === OperationType.manualDelivery && (
        <div className="w-full mx-4">
          {roles.includes(Permission.SET_DELIVERY_FOOD_MENU) && (
            <Stack width={'100%'} flexDirection={'column'} justifyContent={'space-between'}>
              <Stack width={'100%'} flexDirection={'row'} justifyContent={'space-between'}>
                <Stack style={{ width: '50%' }}>
                  <Select
                    label="Meal Select"
                    isMulti
                    onChange={(data: any) => {
                      setSelectedManualMeals(data.map((d: any) => d));
                    }}
                    placeholder={'Search...'}
                    className="mr-4"
                    options={flatten(optionsExtraMeals)}
                    value={selectedManualMeals?.map((r) => r.value)}
                    isDisabled={!selectedCustomer || !selectedDate || flatten(optionsExtraMeals).length === 0 || handleDisabled()}
                  />
                  {selectedCustomer && (
                    <div className="level">
                      <div className="level-left">
                        <div className="level-item">
                          <p
                            className={cx('flex flex-row w-full capitalize text-sm', {
                              'text-cRed':
                                PricingService.roundAmount(
                                  selectedCustomer.data.plan.pricePerDay - deliverCostManual,
                                  selectedCustomer.data.currency
                                ) <= 0
                            })}
                          >{`${PricingService.roundAmount(selectedCustomer.data.plan.pricePerDay, selectedCustomer.data.currency)} ${selectedCustomer.data.currency} Daily Rate: ${PricingService.roundAmount(selectedCustomer.data.plan.pricePerDay - deliverCostManual, selectedCustomer.data.currency)} left`}</p>
                        </div>
                      </div>
                      <div className="level-left">
                        <div className="level-item">
                          <p className="flex flex-row w-full capitalize text-sm mr-4">{`${selectedCustomer.data.plan.id}-${selectedCustomer.data.plan.dietType}`}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </Stack>

                <Stack style={{ width: '50%' }}>
                  <Select
                    label="Address"
                    placeholder="Select Address"
                    className="mr-4"
                    options={optionsAddresses}
                    value={selectedManualAddress || ''}
                    onChange={(data: any) => setSelectedManualAddress(data.value)}
                    isDisabled={
                      !selectedCustomer ||
                      !selectedDate ||
                      selectedCustomer?.data.deliveryAddresses.length === 0 ||
                      handleDisabled()
                    }
                  />
                </Stack>
              </Stack>
              <p className="text-md font-semibold mb-2 mt-6">Add</p>
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  control={<Checkbox checked={cutlerySelected} onChange={(e) => setCutlerySelected(e.target.checked)} />}
                  label="Cutlery"
                />
                <FormControlLabel
                  control={<Checkbox checked={coolerBagSelected} onChange={(e) => setCoolerBagSelected(e.target.checked)} />}
                  label="Cooler Bag"
                  style={{ marginLeft: '100px' }}
                />
              </Stack>
            </Stack>
          )}
        </div>
      )}

      {selectedOperation === OperationType.other && (
        <div className="w-full mx-4">
          <p className="text-lg font-semibold mb-2">Add</p>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={<Checkbox checked={cutlerySelected} onChange={(e) => setCutlerySelected(e.target.checked)} />}
              label="Cutlery"
            />
            <FormControlLabel
              control={<Checkbox checked={coolerBagSelected} onChange={(e) => setCoolerBagSelected(e.target.checked)} />}
              label="Cooler Bag"
              style={{ marginLeft: '100px' }}
            />
          </Stack>
        </div>
      )}

      <div className="mt-4 flex flex-row w-full  mx-4">
        <span className={selectedOperation === OperationType.manualDelivery ? 'w-1/2 ' : 'w-full mr-4'}>
          <Input
            label="Comment"
            value={addedComment}
            placeholder={'Add comment'}
            onChange={(data: any) => setAddedComment(data.target.value)}
          />
        </span>

        {selectedOperation === OperationType.manualDelivery && (
          <span className="w-1/2 mx-4">
            <Select
              label="Delivery Slot"
              value={selectedDeliverySlot}
              onChange={(data: any) => setSelectedDeliverySlot(data.value as any)}
              options={[
                { value: DeliveryTime.morning, label: 'Morning' },
                { value: DeliveryTime.evening, label: 'Evening' }
              ]}
              customStyles={selectStyles}
              isDisabled={!selectedCustomer || !selectedDate}
            />
          </span>
        )}
      </div>
    </>
  );
};
export default CreateLogisticRequestInput;
