import { CreateDeliveryReq, Permission, Subscription } from '@calo/dashboard-types';
import { useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { createSubscriptionDelivery, getListWithParams } from 'actions';
import { Button, Card, Modal, ModalRef, Pagination } from 'components';
import { useUserRoles } from 'lib/hooks';
import { Delivery } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import { orderBy } from 'lodash';
import { useEffect } from 'react';
import DeliveryRow from './DeliveryRow';
import NewDeliveryForm from './NewDeliveryForm';

interface PaginatedDeliveries {
  data: Delivery[];
  meta: {
    limit: number;
    total: number;
  };
}

interface DeliveriesPanelProps {
  subscription: Subscription;
}

const DeliveriesCard = ({ subscription }: DeliveriesPanelProps) => {
  const [page, setPage] = useState(0);
  const roles = useUserRoles();

  const newDeliveryModalRef = useRef<ModalRef>();
  const { mutateAsync: createMutation } = useMutation(createSubscriptionDelivery);

  const handleNewDelivery = async (values: CreateDeliveryReq) => {
    await createMutation(
      {
        ...values,
        id: subscription.id
      },
      {
        onSuccess: (newData) => {
          if (data) {
            const totalPages = Math.ceil(data.meta.total / data.meta.limit);
            const isBetween = data.data[0]?.day >= newData.day && data.data[data.data.length - 1]?.day <= newData.day;
            const isBigger = data.data[0]?.day < newData.day;
            const isSmaller = data.data[data.data.length - 1]?.day > newData.day;
            const isLastPage = totalPages === page;
            const isFirstPage = page === 0;
            if (totalPages === (1 || 0) || (isFirstPage && isBigger) || (isLastPage && isSmaller) || isBetween) {
              data.data = orderBy([...data.data, newData], ['day'], ['desc']);
            }
          }
        }
      }
    );
    newDeliveryModalRef.current?.close();
  };

  const { data } = useQuery<any, Error, PaginatedDeliveries>(
    [`subscriptions/${subscription.id}/deliveries`, { page, limit: 10 }],
    getListWithParams,
    {
      onSuccess: (data) => {
        for (const row of data?.data || []) {
          queryClient.setQueryData(['deliveries', row.id], row);
        }
        queryClient.setQueryData(
          [`subscriptions/${subscription.id}/deliveriesId`],
          data.data.map((row: Delivery) => row.id)
        );
      }
    }
  );

  useEffect(() => {
    setPage(0);
  }, [subscription.id]);

  return (
    <Card
      title="Deliveries"
      actions={[
        roles.includes(Permission.CREATE_SUBSCRIPTION_DELIVERY) && (
          <Button key="new-delivery" icon="fas fa-plus" className="mb-2" onClick={() => newDeliveryModalRef.current?.open()} />
        )
      ]}
    >
      <div className="card has-table has-table-container-upper-radius">
        <div className="card-content">
          <div className="table-container">
            <table className="table w-full">
              <thead>
                <tr className="bg-black">
                  <th style={{ color: 'white' }}>Day</th>
                  <th style={{ color: 'white' }}>Cost</th>
                  <th style={{ color: 'white' }}>Paid Amount</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.data.map((delivery) => <DeliveryRow key={delivery.id} delivery={delivery} subscription={subscription} />)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {data && (
        <Pagination
          isHidden={data.data.length === 0}
          limit={data.meta.limit}
          total={data.meta.total}
          page={page}
          onChange={setPage}
        />
      )}

      <Modal ref={newDeliveryModalRef}>
        <NewDeliveryForm subscription={subscription} onSubmit={handleNewDelivery} />
      </Modal>
    </Card>
  );
};

export default DeliveriesCard;
