import { Permission, Subscription } from '@calo/dashboard-types';
import { updateSubscriptionPhoneNumber } from 'actions';
import { Button, Input } from 'components';
import { useUserRoles } from 'lib/hooks';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

interface PhoneNumberProps {
  subscription: Subscription;
}

const PhoneNumber = ({ subscription }: PhoneNumberProps) => {
  const [editing, setEditing] = useState(false);
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState<string>(subscription.phoneNumber);

  const { mutateAsync: updatePhoneNumberMutation } = useMutation(updateSubscriptionPhoneNumber);

  const onSubmit = async () => {
    try {
      if (updatedPhoneNumber !== subscription.phoneNumber) {
        const phoneNumber = parsePhoneNumberFromString(updatedPhoneNumber, subscription.country);
        if (phoneNumber && phoneNumber.isValid()) {
          await updatePhoneNumberMutation({
            id: subscription.id,
            phoneNumber: updatedPhoneNumber
          });
        } else {
          toast('Phone number should be in a valid form', { type: 'error', autoClose: 2000 });
          setUpdatedPhoneNumber(subscription.phoneNumber);
        }
      }
    } catch (error: any) {
      if (error.message.includes('409')) {
        toast('Phone number already exists', { type: 'error', autoClose: 2000 });
        setUpdatedPhoneNumber(subscription.phoneNumber);
      } else if (error.response.status === 400) {
        toast(error.response.data, { type: 'error', autoClose: 2000 });
        setUpdatedPhoneNumber(subscription.phoneNumber);
      }
    }
    setEditing(false);
  };
  const roles = useUserRoles();

  const handlePhoneInputChange = (data: any) => {
    const input = data.target.value;
    setUpdatedPhoneNumber(input.slice(0, 2) === '00' ? `+${input.slice(2)}` : input);
  };

  return (
    <div className="card">
      <header className="card-header bg-black">
        <p className="card-header-title text-white"> Phone </p>
      </header>
      <div className="card-content">
        <div className="flex -my-4 mt-1">
          <span className="mb-3">
            <Input
              disabled={!editing}
              value={updatedPhoneNumber}
              name="phoneNumber"
              onChange={handlePhoneInputChange}
              placeholder="+9733XXXXXXXX"
            />
          </span>
          {roles.includes(Permission.UPDATE_SUBSCRIPTION) && (
            <a href="#" className="card-header-icon -mt-2" onClick={() => setEditing(!editing)}>
              <span className="icon">
                <i className="fas fa-edit"></i>
              </span>
            </a>
          )}
        </div>
        {editing && (
          <div className="w-16 mt-3">
            <Button type="button" primary fluid onClick={onSubmit} content="Save" />
          </div>
        )}
      </div>
    </div>
  );
};

export default PhoneNumber;
