import { useState } from 'react';

import { exportFoodComponent, getListWithParams } from 'actions';
import { Button, CaloLoader, Pagination } from 'components';
import { Routes } from 'lib/enums';
import { resolveCountryFromKitchen } from 'lib/helpers';
import history from 'lib/history';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { FoodComponentQuery } from 'lib/interfaces';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { FoodComponentFilters, Permission } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';

import FoodComponentRow from './FoodComponentRow';
import Settings from './Settings';

const FoodComponentList = () => {
  const roles = useUserRoles();
  const [page, setPage] = useState<number>(0);
  const userKitchen = useUserKitchens();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState<FoodComponentFilters>({
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    brand: Brand.CALO,
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });
  const isDisabled = false;

  const { data, isLoading } = useQuery<any, Error, FoodComponentQuery>(
    [
      'food-components',
      {
        page,
        filters: {
          ...filters,
          name: filters.name ? filters.name : undefined,
          ingredientId: filters.ingredientId ? filters.ingredientId : undefined
        },
        limit: 50
      }
    ],
    getListWithParams,
    {
      suspense: false,
      onSuccess: () => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      }
    }
  );

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  Components / {filters.country} / {filters.kitchen}
                </li>
              </ul>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              {roles.includes(Permission.CREATE_FOOD_COMPONENTS) && (
                <Button
                  data-test="createFoodComponentButton"
                  disabled={isDisabled}
                  icon="fas fa-plus"
                  onClick={() => history.push(Routes.newFoodComponent)}
                  className="mr-4"
                />
              )}
              {roles.includes(Permission.EXPORT_FOOD_COMPONENTS_LIST) && (
                <Button onClick={() => exportFoodComponent(filters)} icon="fas fa-file-export" />
              )}
            </div>
          </div>
        </div>
      </section>
      <section>
        {isLoading ? (
          <CaloLoader />
        ) : (
          <div>
            <div className="card has-table has-table-container-upper-radius">
              <div className="card-content">
                <div className="table-container">
                  <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                    <thead>
                      <tr className="bg-black">
                        <th style={{ color: 'white' }}>Name</th>
                        <th style={{ color: 'white' }}>Raw Weight</th>
                        <th style={{ color: 'white' }}>Cal</th>
                        <th style={{ color: 'white' }}>Protein</th>
                        <th style={{ color: 'white' }}>Carbs</th>
                        <th style={{ color: 'white' }}>Fat</th>
                        <th style={{ color: 'white' }}>Cost</th>
                        <th style={{ color: 'white' }}>
                          {filters.brand === Brand.MEALO ? (
                            <p className="text-bold text-yellow-500">mealo</p>
                          ) : (
                            <p className=" text-bold text-green-500">CALO</p>
                          )}
                        </th>
                      </tr>
                    </thead>
                    {data && data.data.length === 0 ? (
                      <span className="absolute w-full text-4xl mt-4 text-center font-bold text-gray-400">
                        NO FOOD COMPONENTS
                      </span>
                    ) : (
                      <tbody>
                        {data?.data.map((foodComponent, index) => (
                          <FoodComponentRow key={foodComponent.id} foodComponent={foodComponent} index={index} />
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
            {data?.data && (
              <Pagination
                isHidden={data.data.length === 0 || data.meta.total < 50}
                limit={50}
                total={data?.meta.total}
                page={page}
                onChange={setPage}
              />
            )}
          </div>
        )}
      </section>
      <Settings onFilter={setFilters} filters={filters} />
    </>
  );
};

export default FoodComponentList;
