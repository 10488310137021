import { getImageUploadLink } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { ImageUploaderMUI } from 'components';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import { FormikErrors } from 'formik';
import { ImageType } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { BaseOmit, FoodComponent } from 'lib/interfaces';
import { startCase, uniq, uniqBy } from 'lodash-es';

import { KitchenLogType, Permission } from '@calo/dashboard-types';
import { IngredientCategory } from '@calo/types';
import { Card, Chip, Stack, TextField, Tooltip, Typography } from '@mui/material';

interface ComponentNameAndImageProps {
  foodComponent: FoodComponent;
  values: Omit<FoodComponent, BaseOmit>;
  errors: FormikErrors<Omit<FoodComponent, BaseOmit>>;
  isLoading: boolean;
  isDisabled?: boolean;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1
    ): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
}

const ComponentNameAndImageCard = ({ foodComponent, values, errors, handleChange, isDisabled }: ComponentNameAndImageProps) => {
  const userRoles = useUserRoles();

  const uniqIngredients = uniqBy(foodComponent?.ingredients, 'id') || [];
  const ingredientsWithAllergens = uniqIngredients.filter(
    (ing) => ing.category.length > 0 && !ing.category.includes(IngredientCategory.other)
  );

  const sortedIngredientsByCategory = ingredientsWithAllergens.sort((a, b) => a.name.en.localeCompare(b.name.en));
  const uniqAllergens = uniq(sortedIngredientsByCategory.flatMap((i) => i.category));

  return (
    <Card
      variant="outlined"
      sx={{
        width: 'full',
        border: 'none',
        px: '12px',
        py: '18px',
        borderRadius: '16px',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          flexDirection: 'column'
        }
      }}
    >
      <Typography sx={{ fontSize: '19px', fontWeight: 600 }}>Component Information</Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack display={'flex'} flexDirection={'column'} justifyContent={'center'}>
          <ImageUploaderMUI
            key={foodComponent.id}
            values={{ name: foodComponent.name.en, id: foodComponent.id, type: KitchenLogType.foodComponent }}
            uploadLink={() => getImageUploadLink(`food-component/${foodComponent.id}`)}
            image={`${process.env.REACT_APP_BUCKET_URL}/food-component/${foodComponent.id}/square@1x.jpg`}
            disabled={userRoles.includes(Permission.UPLOAD_IMAGE)}
            id={foodComponent.id}
            imageType={ImageType.COMPONENT}
          />
        </Stack>
        <Stack sx={{ width: '80%', mx: 2 }}>
          <InputThemeProvider>
            <Stack
              sx={{
                mt: 3,
                mb: 2,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <TextField
                data-test="foodComponentEnNameTextField"
                label="Name EN*"
                name="name.en"
                id="name.en"
                placeholder="Enter EN-Name"
                value={values.name.en}
                sx={{ width: '50%', mr: 2 }}
                disabled={isDisabled}
                onChange={handleChange}
                error={!!errors.name?.en}
              />
              <TextField
                data-test="foodComponentArNameTextField"
                label="Name AR*"
                name="name.ar"
                id="name.ar"
                placeholder="Enter AR-Name"
                value={values.name.ar}
                sx={{ width: '50%' }}
                disabled={isDisabled}
                onChange={handleChange}
                error={!!errors.name?.ar}
                inputProps={{ inputProps: { style: { borderRadius: '16px' } }, style: { borderRadius: '16px' } }}
              />
            </Stack>
          </InputThemeProvider>
          {uniqAllergens.length > 0 && <Typography>Allergens</Typography>}
          <Stack
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: '12px' }}
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Stack direction="row" flexWrap="wrap" gap={2} sx={{ fontWeight: 600 }}>
              {sortedIngredientsByCategory?.map((ing) => (
                <Tooltip title={ing.category.sort().join(', ')} placement="top" arrow>
                  <Chip
                    key={ing.id}
                    label={ing.internalName ?? ing.name.en}
                    sx={{
                      color: caloTheme.palette.red,
                      backgroundColor: caloTheme.palette.secondaryPink100,
                      fontSize: '14px',
                      mb: '15px'
                    }}
                  />
                </Tooltip>
              ))}
            </Stack>
            {uniqAllergens.length > 0 && (
              <Typography>Warning: Includes {uniqAllergens.map(startCase).sort().join(', ')}.</Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ComponentNameAndImageCard;
