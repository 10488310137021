import { FormControl, Select, MenuItem, Typography, Grid } from '@mui/material';
import { DeliveryTime } from '@calo/types';

type DeliveryTimePickerProps = {
  isEditing: boolean;
  isApplyForAllDays: boolean;
  deliveryTime: DeliveryTime;
  deliveryDay?: string;
  changeDeliveryTime: (applyForAllDays: boolean, deliveryTime: DeliveryTime, day?: string) => void;
};

const deliveryTimeValues = {
  [DeliveryTime.morning]: '☀️ Early Morning (7AM to 11AM)',
  [DeliveryTime.evening]: '🌙 Night Before (6PM to 10PM)'
};

export const DeliveryTimePicker = ({
  isEditing,
  deliveryDay,
  deliveryTime,
  changeDeliveryTime,
  isApplyForAllDays = false
}: DeliveryTimePickerProps) => {
  return (
    <Grid item xs={4}>
      {isEditing ? (
        <FormControl sx={{ width: '100%' }}>
          <Select
            value={deliveryTime}
            onChange={(e) => changeDeliveryTime(isApplyForAllDays, e.target.value as DeliveryTime, deliveryDay)}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {Object.values(DeliveryTime).map((deliveryTime) => (
              <MenuItem value={deliveryTime}>{deliveryTimeValues[deliveryTime]}</MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Typography sx={{ padding: '15px 16.5px 16px 14px' }}>{deliveryTimeValues[deliveryTime]}</Typography>
      )}
    </Grid>
  );
};
