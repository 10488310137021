import { useRef, useState } from 'react';

import { format } from 'date-fns/fp';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { Permission } from '@calo/dashboard-types';
import { PermissionService, PricingService } from '@calo/services';
import { Brand, Delivery, DeliveryStatus, SubscriptionStatus } from '@calo/types';
import { Box, Button, Stack, Switch, ThemeProvider, Typography } from '@mui/material';

import { clearSubscriptionPause, pauseSubscription, removeSuspension, resumeSubscription, updateSubscription } from 'actions';
import mutation from 'actions/mutation';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon, ModalRef } from 'components';
import Popup from 'components/Popup';
import { theme } from 'lib/componentStyles';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';
import { SubscriptionPUValues } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import EditPendingForm from '../EditPendingForm';
import PauseSubscriptionForm from '../PlanCard/PauseSubscriptionForm';

interface SubscriptionCardProps {
  subscription: any;
  loyaltyPoints: { amount: number };
  refetchSubscription: () => void;
}
const between = (x: string, pausedAt: string, unpauseAt: string) => x >= pausedAt && x <= unpauseAt;

const copyToClipBoard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    toast('Phone number copied!', { type: 'success', autoClose: 2000 });
  } catch {
    toast('Failed to copy!', { type: 'error', autoClose: 2000 });
  }
};

const SubscriptionCard = ({ subscription, loyaltyPoints }: SubscriptionCardProps) => {
  const editModalRef = useRef<ModalRef>();
  const { mutateAsync: updateMutation } = useMutation(updateSubscription);
  const [exactOldView, setExactOldView] = useState<boolean>(false);
  const roles = useUserRoles();

  const { mutateAsync: pauseMutation } = useMutation(pauseSubscription);
  const { mutateAsync: resumeMutation } = useMutation(resumeSubscription);
  const { mutateAsync: clearPauseMutation } = useMutation(clearSubscriptionPause);
  const { mutateAsync: removeSuspensionMutation } = useMutation(removeSuspension);
  const pauseSubRef = useRef<ModalRef>();

  const ChangeDeliveryData = (name: string, data?: any) => {
    const allDeliveries = queryClient
      .getQueryData<any>([`subscriptions/${subscription.id}/deliveriesId`])
      .map((d: string) => queryClient.getQueryData(['deliveries', d]));

    if (name.includes(DeliveryStatus.paused)) {
      const pausedDelivery = allDeliveries.filter((delivery: Delivery) => between(delivery.day, data.pausedAt, data.unpauseAt));
      for (const deliveryData of pausedDelivery) {
        mutation(['deliveries', deliveryData.id], { status: DeliveryStatus.paused });
      }
    } else if (name.includes(DeliveryStatus.upcoming)) {
      const resumedDelivery = allDeliveries.filter(
        (delivery: Delivery) =>
          delivery.status.includes(DeliveryStatus.paused) &&
          delivery.day >= format('yyyy-MM-dd')(PermissionService.getMinActionDate(subscription.deliveryDays, Date.now()))
      );
      for (const deliveryData of resumedDelivery) {
        mutation(['deliveries', deliveryData.id], { status: DeliveryStatus.paymentRequired });
      }
    } else if (name.includes(DeliveryStatus.suspended)) {
      const suspensedDelivery = allDeliveries.filter(
        (delivery: Delivery) =>
          delivery.status.includes(DeliveryStatus.suspended) && delivery.day >= format('yyyy-MM-dd')(Date.now())
      );
      for (const deliveryData of suspensedDelivery) {
        mutation(['deliveries', deliveryData.id], { status: DeliveryStatus.paymentRequired });
      }
    }
  };

  const handleEditPending = async (amount: number) => {
    const amountWithVat = {
      ...subscription.pendingAmount,
      [subscription.currency]: PricingService.getGrossAmount(amount, subscription.currency)
    };
    await updateMutation({
      id: subscription.id,
      pendingAmount: amountWithVat
    });
    editModalRef.current?.close();
  };

  const handleSubscriptionStatus = (status: SubscriptionStatus) => {
    switch (status) {
      case SubscriptionStatus.ongoing: {
        return 'Active';
      }
      case SubscriptionStatus.paused: {
        return 'Paused';
      }
      case SubscriptionStatus.suspended: {
        return 'Suspended';
      }
      default: {
        return 'Cancelled';
      }
    }
  };

  const onPause = async ({ startDate, endDate }: SubscriptionPUValues) => {
    await pauseMutation(
      {
        id: subscription.id,
        startDate: format('yyyy-MM-dd')(startDate),
        ...(endDate && {
          endDate: format('yyyy-MM-dd')(endDate)
        })
      },
      {
        onSuccess: (data) => {
          pauseSubRef.current?.close();
          ChangeDeliveryData(DeliveryStatus.paused, data);
        }
      }
    );
  };

  const onResume = async () => {
    await resumeMutation(
      {
        id: subscription.id
      },
      {
        onSuccess: () => {
          ChangeDeliveryData(DeliveryStatus.upcoming);
        }
      }
    );
  };

  const onClearPause = async () => {
    await clearPauseMutation({
      id: subscription.id
    });
  };

  const onRemoveSuspension = async () => {
    await removeSuspensionMutation(subscription.id);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          padding: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Stack
          display="flex"
          width="100%"
          flexDirection={'row'}
          justifyContent={'space-between'}
          sx={{
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column',
              textAlign: 'center'
            }
          }}
        >
          <Stack
            display={'flex'}
            flexDirection={'row'}
            sx={{
              width: '60%',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                flexDirection: 'column',
                textAlign: 'center',
                width: '100%'
              }
            }}
          >
            <Typography
              variant="h3"
              sx={{
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  flexDirection: 'column',
                  textAlign: 'center'
                },
                textAlign: 'left',
                fontSize: '33px',
                lineHeight: '40px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600
              }}
            >
              Subscriptions
            </Typography>

            <Typography
              sx={{
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  flexDirection: 'column',
                  textAlign: 'center',
                  alignSelf: 'center'
                },
                lineHeight: '40px'
              }}
            >
              {subscription.brand === Brand.CALO ? (
                <Icon name="calo" size={6} className="w-24 mr-4 -ml-6 " />
              ) : (
                <Icon name="mealo" size={18} className="ml-8 mt-3 w-24 -mb-16" />
              )}
            </Typography>
            {subscription.magicPrice && (
              <Typography
                variant="h3"
                sx={{
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    flexDirection: 'column',
                    textAlign: 'center'
                  },
                  flexShrink: 1,
                  textAlign: 'left',
                  mt: '2px',
                  mx: 1,
                  width: 'auto',
                  fontSize: '19px',
                  lineHeight: '40px',
                  fontFamily: caloTheme.typography.fontFamily,
                  fontWeight: 600
                }}
              >
                BAH Magic Price Experiment
              </Typography>
            )}
          </Stack>

          <Stack display={'flex'} flexDirection={'row'}>
            <ThemeProvider theme={theme}>
              <Box sx={{ marginX: 2 }}>
                <Switch
                  onChange={() => {
                    setExactOldView(!exactOldView);
                    history.push(
                      exactOldView
                        ? Routes.subscription2.replace(':id', subscription.id)
                        : Routes.subscription.replace(':id', subscription.id)
                    );
                  }}
                  sx={{ color: 'white' }}
                  checked={exactOldView}
                />
              </Box>
            </ThemeProvider>

            <Typography
              data-test="subscriptionStatusTypography"
              sx={{
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  flexDirection: 'column',
                  textAlign: 'center',
                  width: '100%',
                  ml: '8px'
                },
                mr: 2,
                textAlign: 'right',
                fontSize: '19px',
                lineHeight: '40px',
                fontWeight: 600,
                color:
                  subscription.status === SubscriptionStatus.ongoing
                    ? caloTheme.palette.primary500
                    : subscription.status === SubscriptionStatus.paused
                      ? caloTheme.palette.red
                      : subscription.status === SubscriptionStatus.suspended
                        ? caloTheme.palette.secondaryYellow500
                        : caloTheme.palette.neutral600
              }}
            >
              {handleSubscriptionStatus(subscription.status)}
            </Typography>
            <Stack sx={{ ml: 2 }}>
              {subscription.status === SubscriptionStatus.suspended &&
                roles.includes(Permission.REMOVE_SUBSCRIPTION_SUSPENSION) && (
                  <Button
                    variant="contained"
                    aria-label="Remove Suspension"
                    sx={{
                      ml: 'auto',
                      height: '45px',
                      lineHeight: '17px',
                      fontWeight: 600,
                      fontSize: '14px',
                      boxShadow: 'none',
                      borderRadius: '8px',
                      padding: '14px 20px 14px 20px',
                      backgroundColor: caloTheme.palette.primary500,
                      '&:hover': {
                        backgroundColor: caloTheme.palette.primary600,
                        boxShadow: 'none'
                      },
                      [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                        justifyItems: 'center',
                        margin: 'auto',
                        width: 'auto'
                      }
                    }}
                    onClick={onRemoveSuspension}
                    disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION)}
                  >
                    Remove Suspension
                  </Button>
                )}
              {subscription.status === SubscriptionStatus.paused && roles.includes(Permission.RESUME_SUBSCRIPTION) ? (
                <Button
                  data-test="resumeSubscriptionButton"
                  variant="contained"
                  aria-label="Resume Subscription"
                  sx={{
                    height: '45px',
                    ml: 'auto',
                    boxShadow: 'none',
                    lineHeight: '17px',
                    fontWeight: 600,
                    fontSize: '14px',
                    borderRadius: '8px',
                    padding: '14px 20px 14px 20px',
                    backgroundColor: caloTheme.palette.secondaryYellow500,
                    '&:hover': {
                      backgroundColor: caloTheme.palette.secondaryYellow600,
                      boxShadow: 'none'
                    },
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      justifyItems: 'center',
                      margin: 'auto',
                      width: 'auto'
                    }
                  }}
                  onClick={onResume}
                  disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION)}
                >
                  Resume
                </Button>
              ) : (
                subscription.status === SubscriptionStatus.ongoing &&
                roles.includes(Permission.PAUSE_SUBSCRIPTION) && (
                  <Button
                    data-test="pauseSubscriptionButton"
                    variant="contained"
                    aria-label="Pause Subscription"
                    sx={{
                      boxShadow: 'none',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '17px',
                      borderRadius: '8px',
                      padding: '14px 20px 14px 20px',
                      backgroundColor: caloTheme.palette.primary500,
                      '&:hover': {
                        backgroundColor: caloTheme.palette.primary600,
                        boxShadow: 'none'
                      },
                      [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                        justifyItems: 'center',
                        margin: 'auto',
                        width: 'auto'
                      }
                    }}
                    startIcon={<Icon name="pause" size={5} />}
                    onClick={() => pauseSubRef.current?.open()}
                    disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION)}
                  >
                    Pause Subscription
                  </Button>
                )
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
      {subscription.status === SubscriptionStatus.paused && subscription.unpauseAt && (
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse'
          }}
        >
          <Stack
            sx={{
              marginX: 2,
              borderRadius: '10px',
              gridColumn: 4,
              backgroundColor: caloTheme.palette.primary100,
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: '90%',
                mx: 4
              }
            }}
          >
            <Typography
              variant={'caption'}
              sx={{
                m: 1,
                mb: 1,
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '14px',
                fontFamily: caloTheme.typography.fontFamily,
                color: caloTheme.palette.primary800
              }}
            >
              Resume date : {subscription.unpauseAt}
            </Typography>
          </Stack>
        </Stack>
      )}
      {subscription.status === SubscriptionStatus.ongoing && subscription.pausedAt && (
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse'
          }}
        >
          <Stack
            sx={{
              marginX: 2,
              borderRadius: '10px',
              gridColumn: 4,
              backgroundColor: caloTheme.palette.secondaryYellow100,
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: '90%',
                mx: 4
              }
            }}
          >
            <Typography
              variant={'caption'}
              sx={{
                m: 1,
                mb: 1,
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '14px',
                fontFamily: caloTheme.typography.fontFamily,
                color: caloTheme.palette.secondaryYellow800
              }}
            >
              Scheduled for pausing from {subscription.pausedAt} - {subscription.unpauseAt}
              {roles.includes(Permission.RESUME_SUBSCRIPTION) && (
                <Button
                  variant="outlined"
                  aria-label="Sync Remaining Days"
                  sx={{
                    width: 'auto',
                    height: '25px',
                    lineHeight: '17px',
                    fontWeight: 600,
                    fontSize: '14px',
                    borderRadius: '8px',
                    padding: '14px 20px 14px 20px',
                    marginLeft: '10px',
                    color: caloTheme.palette.secondaryYellow900,
                    borderColor: caloTheme.palette.secondaryYellow400,
                    '&:hover': {
                      color: caloTheme.palette.secondaryYellow900,
                      borderColor: caloTheme.palette.secondaryYellow600
                    }
                  }}
                  onClick={onClearPause}
                >
                  Clear
                </Button>
              )}
            </Typography>
          </Stack>
        </Stack>
      )}
      <Box
        sx={{
          padding: 2,
          width: '100%',
          display: 'flex',
          marginBottom: '4px',
          flexDirection: 'row',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Stack
          sx={{
            width: '50%',
            display: 'flex',
            flexDirection: 'row',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto',
              flexDirection: 'column'
            }
          }}
        >
          <Stack
            sx={{
              width: 'auto',
              marginRight: 1,
              display: 'flex',
              borderRadius: '8px',
              flexDirection: 'row',
              justifyContent: 'space-between',
              fontFamily: caloTheme.typography.fontFamily,
              border: '1px solid' + caloTheme.palette.neutral100,
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: 'auto',
                marginRight: 0
              }
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '23px',
                lineHeight: '28px',
                letterSpacing: -0.02,
                padding: '16px 16px 10px 16px',
                color: caloTheme.palette.neutral900,
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              {subscription.name} {subscription.phoneNumber}
            </Typography>

            <Typography
              sx={{
                margin: '16px',
                display: 'flex',
                fontSize: '23px',
                cursor: 'pointer',
                lineHeight: '28px',
                justifyItems: 'end'
              }}
              onClick={() => copyToClipBoard(subscription.phoneNumber)}
            >
              <Icon name={'copyBlack'} size={7} />
            </Typography>
          </Stack>
          <Stack
            sx={{
              width: 'auto',
              display: 'flex',
              borderRadius: '8px',
              flexDirection: 'row',
              fontFamily: caloTheme.typography.fontFamily,
              border: '1px solid' + caloTheme.palette.neutral100,
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: 'auto'
              }
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '19px',
                marginLeft: '2px',
                lineHeight: '23px',
                letterSpacing: -0.02,
                padding: '16px 16px 10px 16px',
                color: caloTheme.palette.neutral900,
                fontFamily: caloTheme.typography.fontFamily,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  width: 'auto'
                }
              }}
            >
              {subscription.country} - {subscription.kitchen}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          sx={{
            width: '50%',
            display: 'flex',
            justifyContent: 'end',
            flexDirection: 'row',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto',
              flexDirection: 'column'
            }
          }}
        >
          <Stack
            sx={{
              width: '35%',
              marginRight: 1,
              display: 'flex',
              textAlign: 'left',
              cursor: 'pointer',
              borderRadius: '8px',
              flexDirection: 'row',
              padding: '16px 16px 10px 16px',
              justifyContent: 'space-between',
              color: caloTheme.palette.neutral900,
              border: '1px solid ' + caloTheme.palette.neutral100,
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: 'auto',
                marginRight: 0
              }
            }}
            onClick={() => editModalRef.current?.open()}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '19px',
                cursor: 'pointer',
                lineHeight: '23px',
                letterSpacing: '-0.02em',
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              Pending
            </Typography>

            <Typography
              sx={{
                my: '3px',
                ml: 'auto',
                fontSize: '23px',
                cursor: 'pointer',
                fontWeight: '600',
                lineHeight: '17px',
                marginRight: '6px',
                letterSpacing: '-0.02em',
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              {` ${subscription.pendingAmount[subscription.currency] || 0}`}
            </Typography>
            <Typography
              sx={{
                ml: 4,
                fontWeight: 600,
                cursor: 'pointer'
              }}
            >
              <Icon name={'edit'} size={6} />
            </Typography>
          </Stack>

          <Stack
            sx={{
              width: '35%',
              marginRight: 1,
              display: 'flex',
              minWidth: '64px',
              textAlign: 'left',
              borderRadius: '8px',
              flexDirection: 'row',
              padding: '16px 16px 10px 16px',
              justifyContent: 'space-between',
              color: caloTheme.palette.neutral900,
              border: '1px solid ' + caloTheme.palette.neutral100,
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: 'auto'
              }
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '19px',
                textAlign: 'left',
                marginRight: '4px',
                lineHeight: '23px',
                letterSpacing: '-0.02em',
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              Balance
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '23px',
                lineHeight: '24px',
                marginRight: '6px',
                letterSpacing: '-0.02em',
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              {`${subscription.balance[subscription.currency] || 0}`}
            </Typography>
          </Stack>

          {roles.includes(Permission.VIEW_LOYALTY_POINTS) && (
            <Stack
              sx={{
                width: '30%',
                display: 'flex',
                minWidth: '64px',
                textAlign: 'left',
                borderRadius: '8px',
                flexDirection: 'row',
                padding: '16px 16px 10px 16px',
                justifyContent: 'space-between',
                color: caloTheme.palette.neutral900,
                border: '1px solid ' + caloTheme.palette.neutral100,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  width: 'auto'
                }
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '19px',
                  textAlign: 'left',
                  marginRight: '4px',
                  lineHeight: '23px',
                  letterSpacing: '-0.02em',
                  fontFamily: caloTheme.typography.fontFamily
                }}
              >
                Points
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '23px',
                  lineHeight: '24px',
                  marginRight: '6px',
                  letterSpacing: '-0.02em',
                  fontFamily: caloTheme.typography.fontFamily
                }}
              >
                {loyaltyPoints.amount}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Box>

      <Popup title="Pending" ref={editModalRef} onClose={() => editModalRef.current?.close()}>
        <EditPendingForm
          onSubmit={handleEditPending}
          balance={subscription.balance[subscription.currency]}
          currency={subscription.currency}
        />
      </Popup>

      <Popup title={'Pause Subscription'} ref={pauseSubRef} onClose={() => pauseSubRef.current?.close()}>
        <PauseSubscriptionForm
          onSubmit={onPause}
          deliveryDays={subscription.deliveryDays}
          pausedAt={subscription.pausedAt}
          resumeAt={subscription.unpauseAt}
        />
      </Popup>
    </>
  );
};
export default SubscriptionCard;
