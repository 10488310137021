import { Permission } from '@calo/dashboard-types';
import { getListWithParams, selectedFoodData } from 'actions';
import { Pagination } from 'components';
import { format, parseISO } from 'date-fns/fp';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';
import { useState } from 'react';
import { useQuery } from 'react-query';

interface FeedBackPanelProps {
  subscriptionId: string;
}

interface PaginatedFeedback {
  data: any[];
  meta: {
    limit: number;
    total: number;
  };
}

const handleChangeURL = async (foodID: string) => {
  const selectedFeedBackFood = await selectedFoodData(foodID);
  return history.replace(Routes.foodSlug.replace(':slug', selectedFeedBackFood.slug));
};

const FeedBackPanel = ({ subscriptionId }: FeedBackPanelProps) => {
  const roles = useUserRoles();
  const [page, setPage] = useState(0);

  const { data: feedback } = useQuery<any, Error, PaginatedFeedback>(
    ['ratings', { page, limit: 10, filters: { userId: subscriptionId } }],
    getListWithParams,
    {
      suspense: true,
      keepPreviousData: true,
      enabled: !!roles.includes(Permission.VIEW_RATING_LIST)
    }
  );

  if (!feedback || feedback?.data.length === 0) {
    return <div></div>;
  } else {
    return (
      <div className="card has-table has-table-container-upper-radius">
        <header className="card-header bg-black">
          <p className="card-header-title text-white">Feedback</p>
        </header>
        <div className="card-content">
          <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
            <thead>
              <tr>
                <th>Meal Name</th>
                <th>Rating</th>
                <th>Review</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {feedback?.data?.map((fb: any) => (
                <tr key={fb.id}>
                  <td className="w-64">
                    <div className="flex">
                      <span className="ml-2 flex">
                        {roles.includes(Permission.VIEW_FOOD_LIST_BY_SLUG) ? (
                          <a onClick={() => handleChangeURL(fb.food.id)}>{fb.food.name.en}</a>
                        ) : (
                          `${fb.food.name.en}`
                        )}
                      </span>
                    </div>
                  </td>
                  <td className="w-10">{fb.rating}</td>
                  <td className="w-64 break-all">{fb.review}</td>
                  <td className="w-32">
                    <span className="w-full text-gray-400">{fb.createdAt && format('dd-MM-yyyy')(parseISO(fb.createdAt))}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {feedback.data && (
          <Pagination
            isHidden={feedback.meta.total === 0 || feedback.meta.total <= 10}
            limit={10}
            total={feedback.meta.total}
            page={page}
            onChange={setPage}
          />
        )}
      </div>
    );
  }
};

export default FeedBackPanel;
