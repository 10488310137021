import { useState } from 'react';

import { format } from 'date-fns/fp';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { isEqual, pick, startCase } from 'lodash-es';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { Permission, Subscription, UpdateSubscriptionReq } from '@calo/dashboard-types';
import { Gender } from '@calo/types';
import DateFnsAdapter from '@date-io/date-fns';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { updateSubscription2, updateSubscriptionPhoneNumber } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from 'components';
import { subscriberPaymentOptions } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import useUserInformationForm from './useUserInformationForm';

interface SubscriptionInfoProps {
  subscription: Subscription;
}

const specificPropsToCheck = [
  'name',
  'email',
  'dayAllowance',
  'autoRenew',
  'macrosType',
  'dietType',
  'deliveryTime',
  'paymentMethod',
  'macros',
  'macrosData',
  'macrosPreferences'
];

const SubscriptionInfo = ({ subscription }: SubscriptionInfoProps) => {
  const roles = useUserRoles();
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState<string>(subscription.phoneNumber);
  const { mutateAsync: updateMutation } = useMutation(updateSubscription2);
  const { mutateAsync: updatePhoneNumberMutation } = useMutation(updateSubscriptionPhoneNumber);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const onSubmit = async ({
    name,
    email,
    paymentMethod,
    macrosData,
    macrosType,
    macrosPreferences,
    macros
  }: UpdateSubscriptionReq): Promise<void> => {
    await updateMutation({
      subId: subscription.id,
      data: { name, email, paymentMethod, macrosData, macrosType, macrosPreferences, macros }
    });
  };
  const { values, handleChange, setFieldValue, initialValues, setValues } = useUserInformationForm(subscription, onSubmit);
  const [newDob, setNewDob] = useState<string | undefined>(values.macrosData?.dob!);

  const changedValues = () => {
    return isEqual(pick(values, specificPropsToCheck), pick(subscription, specificPropsToCheck));
  };

  const handleEditing = (edit: boolean) => {
    if (!edit) {
      if (subscription.phoneNumber !== updatedPhoneNumber) {
        const phoneNumber = parsePhoneNumberFromString(updatedPhoneNumber, subscription.country);
        if (phoneNumber) {
          updatePhoneNumberMutation({
            id: subscription.id,
            phoneNumber: updatedPhoneNumber
          });
        } else {
          toast('Phone number should be in a valid form', { type: 'error', autoClose: 2000 });
          setUpdatedPhoneNumber(subscription.phoneNumber);
        }
      }
      if (!changedValues()) {
        onSubmit({
          ...values
        });
      }
    }
    setIsEditing(edit);
  };

  const handleReset = () => {
    setValues(initialValues);
    setNewDob(subscription.macrosData?.dob);
    setUpdatedPhoneNumber(subscription.phoneNumber);
    setIsEditing(false);
  };
  return (
    <>
      <Box
        sx={{
          margin: 2,
          padding: 2,
          backgroundColor: caloTheme.palette.neutral50,
          borderRadius: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'auto',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column'
          }
        }}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '19px',
            lineHeight: '23px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Customer Information
        </Typography>
        {isEditing ? (
          <Stack sx={{ justifyContent: 'end', flexDirection: 'row' }}>
            <CheckIcon
              data-test="save-customer-information-icon"
              sx={{ marginRight: 2 }}
              style={{ cursor: 'pointer' }}
              onClick={() => handleEditing(!isEditing)}
            />
            <ClearIcon data-test="clear-customer-information-icon" style={{ cursor: 'pointer' }} onClick={handleReset} />
          </Stack>
        ) : (
          <Icon
            data-test="edit-customer-information-icon"
            onClick={() => handleEditing(!isEditing)}
            name="edit2"
            size={6}
            style={{ cursor: 'pointer', width: '26px', height: '26px' }}
          />
        )}
      </Box>
      <Box
        component="form"
        autoComplete="off"
        sx={{
          width: 'full',
          mx: 2,
          '& .MuiTextField-root': { my: 2, mx: 2, width: '29%', justifyContent: 'space-between' },
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'full',
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTextField-root': { my: 2, mx: 1, width: '100%', justifyContent: 'space-between' }
          }
        }}
      >
        <TextField
          data-test="nameTextField"
          label="Name"
          placeholder="Enter Name"
          value={values.name}
          name="name"
          disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
          onChange={(data) => setFieldValue('name', data.target.value)}
          id="exact-subscription-name"
          inputProps={{
            inputProps: { style: { borderRadius: 8 } },
            style: { borderRadius: 8 },
            'data-test': 'nameTextFieldInput'
          }}
        />

        <>
          <TextField
            data-test="phoneNumberTextField"
            name="phoneNumber"
            label="Phone Number"
            value={updatedPhoneNumber}
            placeholder="Enter Phone Number"
            onChange={(d) =>
              setUpdatedPhoneNumber(d.target.value.slice(0, 2) === '00' ? `+${d.target.value.slice(2)}` : d.target.value)
            }
            id="exact-subscription-phoneNumber"
            disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
            inputProps={{
              inputProps: { style: { borderRadius: 8 } },
              style: { borderRadius: 8 },
              'data-test': 'phoneNumberTextFieldInput'
            }}
          />
        </>

        <TextField
          data-test="genderSelect"
          select
          label="Gender"
          name="macrosData.gender"
          id="exact-subscription-gender"
          value={values.macrosData?.gender}
          disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
          onChange={(data: any) => {
            setFieldValue('macrosData.gender', data.target.value);
          }}
          InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          SelectProps={{ inputProps: { 'data-test': 'genderSelectInput' } }}
        >
          {Object.values(Gender).map((gender) => (
            <MenuItem key={gender} value={gender} data-test={`${gender}MenuItem`}>
              {startCase(gender)}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          data-test="paymentMethodSelect"
          label="Payment Method"
          name="paymentMethod"
          id="exact-subscription-paymentMethod"
          value={values.paymentMethod}
          disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
          onChange={(data: any) => {
            setFieldValue('paymentMethod', data.target.value);
          }}
          InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          SelectProps={{ inputProps: { 'data-test': 'paymentMethodSelectInput' } }}
        >
          {subscriberPaymentOptions(subscription.currency).map((pm) => (
            <MenuItem key={pm} value={pm} data-test={`${pm}MenuItem`}>
              {startCase(pm)}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          data-test="emailTextField"
          name="email"
          label="Email"
          value={values.email}
          placeholder="Enter Email"
          id="exact-subscription-email"
          onChange={(data) => {
            setFieldValue('email', data.target.value);
            handleChange(data.target.value);
          }}
          disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
          inputProps={{
            inputProps: { style: { borderRadius: 8 } },
            style: { borderRadius: 8 },
            'data-test': 'emailTextFieldInput'
          }}
        />

        <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ mb: '4px' }}>
          <DesktopDatePicker
            data-test="dateOfBirthDatePicker"
            label="Date of Birth"
            disableFuture
            inputFormat="dd/MM/yyyy"
            value={newDob}
            maxDate={format('yyyy-MM-dd')(Date.now())}
            renderInput={(params) => <TextField {...params} data-test="dateOfBirthDatePickerInput" />}
            disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
            onChange={(endDate) => {
              setNewDob(endDate!);
              setFieldValue('macrosData[dob]', endDate!);
            }}
          />
        </LocalizationProvider>
      </Box>
    </>
  );
};
export default SubscriptionInfo;
