import { useEffect, useMemo, useState } from 'react';

import { format } from 'date-fns/fp';
import { startCase } from 'lodash-es';
import { useQuery } from 'react-query';

import { AddressService } from '@calo/services';
import { DeliveryTime } from '@calo/types';
import DateFnsAdapter from '@date-io/date-fns';
import {
  Autocomplete,
  Box,
  Button,
  Container,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { DesktopDatePicker, LocalizationProvider, PickersDay, pickersDayClasses } from '@mui/x-date-pickers';

import { getList } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, Icon, InputMUI, ModalRef } from 'components';
import Popup from 'components/Popup';
import { BusinessAccount, Food, Options } from 'lib/interfaces';
import { SelectedMealData } from '../Accounts/ExactAccount/AccountDeliveriesCard/AccountDeliveriesCard';

interface DeliveryOrderModelProps {
  isEditing: boolean;
  isLoading: boolean;
  accountDelivery: any;
  isDuplicate: boolean;
  foodLoading?: boolean;
  existDeliveryDay: string[];
  accountData: BusinessAccount;
  mealOptions?: Options<Food>[];
  allSelectedMealOptions: Food[];
  searchMealName?: string | undefined;
  selectedMealsData: SelectedMealData[];
  handleUpdateOrder?: () => Promise<void>;
  handleDuplicateOrder?: () => Promise<void>;
  updatedData: { time: any; orders: any; date: any; addressId: string };
  setSearchMealName?: (data: string) => void;
  setIsDuplicate: (value: boolean) => void;
  setExistingFoodIdList: (data: string[]) => void;
  handleSelectedOption: (data: Options<Food>) => void;
  updateDeliveryRef: React.MutableRefObject<ModalRef | undefined>;
  setAllSelectedMealOptions: React.Dispatch<React.SetStateAction<Food[]>>;
  setSelectedMealsData: React.Dispatch<React.SetStateAction<SelectedMealData[]>>;
  setUpdatedData: React.Dispatch<React.SetStateAction<{ time: any; orders: any; date: any; addressId: string }>>;
  handleSelectedMealChanges: (selectedMeal: SelectedMealData, value: string, field: string) => void;
}

interface OpenState {
  [key: string]: boolean;
}

const DeliveryOrderModel = ({
  isEditing,
  updateDeliveryRef,
  setExistingFoodIdList,
  setSelectedMealsData,
  accountDelivery,
  accountData,
  updatedData,
  setUpdatedData,
  mealOptions,
  foodLoading,
  searchMealName,
  setSearchMealName,
  handleSelectedOption,
  isLoading,
  selectedMealsData,
  handleSelectedMealChanges,
  allSelectedMealOptions,
  handleUpdateOrder,
  setAllSelectedMealOptions,
  isDuplicate,
  handleDuplicateOrder,
  existDeliveryDay,
  setIsDuplicate
}: DeliveryOrderModelProps) => {
  const [selectedMealSlug, setSelectedMealSlug] = useState<string>('');
  const [slugOption, setSlugOption] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<OpenState>({});
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      color: caloTheme.palette.neutral900,
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none'
    }
  }));

  useEffect(() => {
    if (isDuplicate) {
      setUpdatedData!({ ...updatedData, date: null });
    }
  }, []);

  useQuery<string, Error, Food[]>([`food/slug/${selectedMealSlug}`], getList, {
    suspense: false,
    enabled: selectedMealSlug.length > 0,
    keepPreviousData: false,
    onSuccess: (data) => {
      for (const optionData of data) {
        if (!allSelectedMealOptions.find((meal) => meal.id === optionData.id)) {
          setAllSelectedMealOptions([...allSelectedMealOptions, optionData]);
          allSelectedMealOptions.push(optionData);
        }
      }
      setSlugOption(false);
      setSelectedMealSlug('');
    }
  });

  const handleRenderDay = (day: any, DayComponentProps: any) => {
    const calenderDay = format('yyyy-MM-dd')(day);
    if (existDeliveryDay.includes(calenderDay)) {
      return (
        <PickersDay
          sx={{
            pointerEvents: 'none',
            borderColor: DayComponentProps.tabIndex === 0 ? caloTheme.palette.primary900 : caloTheme.palette.primary500,
            backgroundColor: caloTheme.palette.neutral100,
            textDecoration: 'line-through',
            color: 'black',
            ':hover': { color: 'black', backgroundColor: caloTheme.palette.neutral100 },
            [`&&.${pickersDayClasses.selected}`]: {
              border: 1,
              color: 'black',
              backgroundColor: caloTheme.palette.neutral100,
              borderColor: caloTheme.palette.primary600
            }
          }}
          disabled={true}
          {...DayComponentProps}
        />
      );
    } else if (calenderDay <= format('yyyy-MM-dd')(Date.now())) {
      return (
        <PickersDay
          sx={{
            pointerEvents: 'none',
            borderColor: DayComponentProps.tabIndex === 0 ? caloTheme.palette.primary900 : caloTheme.palette.primary500,
            backgroundColor: caloTheme.palette.neutral100,
            color: 'black',
            ':hover': { color: 'black', backgroundColor: caloTheme.palette.neutral100 },
            [`&&.${pickersDayClasses.selected}`]: {
              border: 1,
              color: 'black',
              backgroundColor: caloTheme.palette.neutral100,
              borderColor: caloTheme.palette.primary600
            }
          }}
          disabled={true}
          {...DayComponentProps}
        />
      );
    } else {
      return <PickersDay sx={{ backgroundColor: 'none' }} {...DayComponentProps} />;
    }
  };

  const allFoodListOptions = useMemo(() => allSelectedMealOptions, [allSelectedMealOptions]);
  return (
    <Popup
      maxWidth="lg"
      fullWidth
      title={isDuplicate ? 'Duplicate Delivery' : isEditing ? 'Update Delivery' : 'Meals'}
      ref={updateDeliveryRef}
      onClose={() => {
        updateDeliveryRef.current?.close();
        setExistingFoodIdList([]);
        setSelectedMealsData([]);
        setIsDuplicate(false);
        setUpdatedData({
          time: accountDelivery.time,
          date: accountDelivery.date,
          orders: accountDelivery.orders,
          addressId: accountDelivery.deliveryAddress.id
        });
      }}
    >
      <Container>
        <Box sx={{ my: 2 }}>
          <Stack display="flex" flexDirection={'row'} justifyContent={'space-between'}>
            <TextField
              select={isDuplicate}
              label="Location"
              name="Location"
              disabled={!isDuplicate}
              placeholder="select a location"
              sx={{ minWidth: '31%', mr: 2 }}
              value={isDuplicate ? updatedData.addressId : AddressService.display(accountDelivery.deliveryAddress)}
              id="new-account-delivery-location"
              onChange={(data: any) => (isDuplicate ? setUpdatedData!({ ...updatedData, addressId: data.target.value }) : null)}
              InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            >
              {isDuplicate ? (
                accountData.deliveryAddresses.map((deliveryAddress) => (
                  <MenuItem value={deliveryAddress.id}>{AddressService.display(deliveryAddress)}</MenuItem>
                ))
              ) : (
                <MenuItem value={accountDelivery.deliveryAddress.id}>
                  {AddressService.display(accountDelivery.deliveryAddress)}
                </MenuItem>
              )}
            </TextField>

            <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ mb: '4px', mx: 2 }}>
              <DesktopDatePicker
                disabled={!isDuplicate}
                label="Delivery Date"
                inputFormat="dd-MM-yyyy"
                value={updatedData.date}
                renderDay={(day, _value, DayComponentProps) => handleRenderDay(day, DayComponentProps)}
                renderInput={(params) => <TextField {...params} sx={{ mx: 2, borderRadius: '8px' }} />}
                onChange={(data: any) =>
                  isDuplicate ? setUpdatedData && setUpdatedData({ ...updatedData, date: format('yyyy-MM-dd')(+data!) }) : null
                }
              />
            </LocalizationProvider>

            <TextField
              select
              name="time"
              label="Delivery Time"
              disabled={isDuplicate ? !isDuplicate : !isEditing}
              sx={{ minWidth: '31%' }}
              id="new-account-delivery-time"
              value={updatedData.time}
              InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
              onChange={(data: any) => setUpdatedData && setUpdatedData({ ...updatedData, time: data.target.value })}
            >
              {Object.values(DeliveryTime).map((time) => (
                <MenuItem value={time}>{startCase(time)}</MenuItem>
              ))}
            </TextField>
          </Stack>

          {(isDuplicate ? isDuplicate : isEditing) && (
            <Stack display="flex" flexDirection={'row'} justifyContent={'space-between'} sx={{ mt: 2 }}>
              <Autocomplete
                disabled={isDuplicate ? !isDuplicate : !isEditing}
                options={mealOptions || []}
                loading={foodLoading}
                loadingText={'Loading....'}
                inputValue={searchMealName || ''}
                getOptionLabel={(option) => option.label || ''}
                style={{ borderRadius: 8, width: '100%' }}
                value={updatedData.orders}
                onInputChange={(event, value) => setSearchMealName && setSearchMealName(value)}
                onChange={(x, value) => handleSelectedOption && handleSelectedOption(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Add Meals"
                    variant="outlined"
                    placeholder="Type to search..."
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'Search for a Meal to Add'
                    }}
                  />
                )}
              />
            </Stack>
          )}
        </Box>

        <Box maxHeight={'27rem'} sx={{ overflowY: 'auto', overflowX: 'hidden', my: 2 }}>
          <Table
            sx={{
              marginY: '4px',
              minHeight: '120px',
              overflow: 'auto',
              width: '100%',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                flexDirection: 'column'
              }
            }}
          >
            <TableHead
              style={{ borderRadius: '8px' }}
              sx={{
                backgroundColor: caloTheme.palette.neutral50,
                color: 'black',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                borderRadius: '8px'
              }}
            >
              <TableRow
                sx={{
                  backgroundColor: caloTheme.palette.neutral50,
                  color: 'black',
                  width: '100%',
                  flexWrap: 'nowrap',
                  justifyContent: 'space-between'
                }}
              >
                <StyledTableCell
                  style={{
                    width: '50%',
                    justifyContent: 'start',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px'
                  }}
                >
                  Meals
                </StyledTableCell>
                <StyledTableCell style={{ maxWidth: '100px', minWidth: '100px' }} />
                <StyledTableCell colSpan={2} style={{ maxWidth: '520px', minWidth: '520px' }}></StyledTableCell>
                {(isDuplicate ? isDuplicate : isEditing) && (
                  <StyledTableCell
                    style={{
                      width: '200px',
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px',
                      textAlign: 'end'
                    }}
                  ></StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow sx={{ borderBottom: 0, borderColor: caloTheme.palette.neutral300 }}>
                  <StyledTableCell colSpan={5} style={{ textAlign: 'center', width: '100%' }}>
                    <CaloLoader />
                  </StyledTableCell>
                </TableRow>
              ) : selectedMealsData?.length === 0 ? (
                <TableRow sx={{ borderBottom: 0, borderColor: caloTheme.palette.neutral300 }}>
                  <StyledTableCell colSpan={5} style={{ textAlign: 'center', width: '100%' }}>
                    <Typography>No Meal Found</Typography>
                  </StyledTableCell>
                </TableRow>
              ) : (
                selectedMealsData?.map((meal) => (
                  <TableRow key={meal.id} sx={{ borderBottom: 2, borderColor: caloTheme.palette.neutral300 }}>
                    <StyledTableCell style={{ width: '300px' }}>
                      <Typography>{startCase(meal.name)}</Typography>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '100px', display: 'table-cell', alignContent: 'end' }} />
                    <StyledTableCell sx={{ width: '200px', display: 'table-cell', position: 'relative' }}>
                      <Select
                        disabled={isDuplicate ? !isDuplicate : !isEditing}
                        open={isOpen[meal.id]}
                        onOpen={() => {
                          setSelectedMealSlug(() => meal.data.slug);
                          setSlugOption(true);
                          setIsOpen((prevState) => ({
                            ...prevState,
                            [meal.id]: true
                          }));
                        }}
                        onClose={() => {
                          setSlugOption(false);
                          setIsOpen((prevState) => ({
                            ...prevState,
                            [meal.id]: false
                          }));
                        }}
                        key={meal.id}
                        type="text"
                        name="size"
                        label="Meal Size"
                        value={meal.size}
                        placeholder={meal.size}
                        style={{
                          width: '100%',
                          alignContent: 'end'
                        }}
                        onClick={() => setSelectedMealSlug(() => meal.data.slug)}
                        onChange={(data: any) => handleSelectedMealChanges(meal, data.target.value, 'size')}
                      >
                        <MenuItem sx={{ display: slugOption ? 'inherit' : 'none' }} disabled value="laoding">
                          Loading...
                        </MenuItem>
                        {allFoodListOptions
                          .filter((mealList) => mealList.name.en === meal.name)
                          .map((mealOption) => (
                            <MenuItem
                              disabled={!!selectedMealsData.find((x) => x.id === mealOption.id)}
                              value={mealOption.size}
                              key={mealOption.id}
                            >
                              {mealOption.size}
                            </MenuItem>
                          ))}
                      </Select>
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: '200px', display: 'table-cell', position: 'relative' }}>
                      <InputMUI
                        debounce
                        name="Number of Meals"
                        label="Number of Meals"
                        value={meal.numberOfMeals}
                        style={{ width: '100%' }}
                        pattern={'[0-9]*'}
                        disabled={meal.size?.length === 0 || (isDuplicate ? !isDuplicate : !isEditing)}
                        onChange={(data: any) => {
                          handleSelectedMealChanges(meal, data.target.value, 'number');
                        }}
                        inputProps={{
                          inputProps: { style: { borderRadius: 8, width: '100%' } },
                          style: { borderRadius: 8, width: '100%' }
                        }}
                      />
                    </StyledTableCell>
                    {(isDuplicate ? isDuplicate : isEditing) && (
                      <StyledTableCell sx={{ width: '300px', height: '34px' }}>
                        <Button
                          sx={{
                            textAlign: 'center',
                            backgroundColor: caloTheme.palette.secondaryPink100,
                            width: '48px',
                            height: '48px',
                            borderRadius: '8px',
                            '&:hover': {
                              backgroundColor: caloTheme.palette.secondaryPink300
                            }
                          }}
                          onClick={() => setSelectedMealsData((meals) => meals?.filter((r) => r !== meal))}
                        >
                          <Icon name={'removeTrash'} size={7} className="mt-1 ml-1" />
                        </Button>
                      </StyledTableCell>
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Box>
      </Container>
      {(isDuplicate ? isDuplicate : isEditing) && (
        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
          <Button
            variant="contained"
            sx={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              textTransform: 'none',
              fontSize: '19px',
              fontWeight: 600,
              lineHeight: '23px',
              backgroundColor: caloTheme.palette.primary500,
              color: 'white',
              boxShadow: 'none',
              width: '185px',
              height: '54px',
              '&:hover': {
                backgroundColor: caloTheme.palette.primary600,
                boxShadow: 'none'
              }
            }}
            disabled={
              isDuplicate
                ? updatedData.date === null || selectedMealsData?.length === 0
                : false || selectedMealsData?.length === 0
            }
            onClick={() => (isDuplicate ? handleDuplicateOrder!() : handleUpdateOrder && handleUpdateOrder())}
          >
            {isDuplicate ? 'Duplicate Delivery' : 'Update Delivery'}
          </Button>
        </Box>
      )}
    </Popup>
  );
};
export default DeliveryOrderModel;
