import { forwardRef } from 'react';

import { startCase, uniq, uniqBy } from 'lodash-es';

import { Permission, UpdateFoodReq } from '@calo/dashboard-types';
import { IngredientCategory } from '@calo/types';
import { Chip, Stack, Tooltip, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import CaloLoader from '../CaloLoader';

interface IngredientsProps {
  roles: any;
  values: UpdateFoodReq;
  isLoading: boolean;
}

const Ingredients = forwardRef<HTMLDivElement, IngredientsProps>(({ values, roles, isLoading }: IngredientsProps, ref) => {
  const uniqIngredients = uniqBy(values.ingredients, 'id') || [];
  const ingredientsWithoutOtherCategory = uniqIngredients.map((i) => ({
    ...i,
    category: i.category?.filter((i) => i !== IngredientCategory.other)
  }));
  const sortedIngredientsByCategoryAndName = ingredientsWithoutOtherCategory.sort((a, b) => {
    if (a.category?.length === 0 && b.category?.length > 0) {
      return 1;
    } else if (a.category?.length > 0 && b.category?.length === 0) {
      return -1;
    } else {
      return a.name.en?.localeCompare(b.name.en);
    }
  });
  const uniqAllergens = uniq(ingredientsWithoutOtherCategory.flatMap((i) => i.category));

  return (
    <>
      {roles.includes(Permission.VIEW_INGREDIENT) && (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          ref={ref}
          sx={{ backgroundColor: caloTheme.palette.white, borderRadius: '16px' }}
        >
          <Typography
            sx={{
              m: 2,
              color: caloTheme.palette.neutral900,
              textTransform: 'capitalize',
              fontSize: '19px',
              fontWeight: 600,
              lineHeight: '23px'
            }}
          >
            Ingredients
          </Typography>
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
            <Stack direction="row" flexWrap="wrap" sx={{ m: 2, fontWeight: 600 }}>
              {isLoading ? (
                <CaloLoader />
              ) : (
                sortedIngredientsByCategoryAndName?.map((ing) =>
                  ing.category?.length > 0 ? (
                    <Tooltip key={ing.id} title={ing.category.sort().join(', ')} placement="top" arrow>
                      <Chip
                        label={ing.name.en}
                        sx={{
                          color: caloTheme.palette.red,
                          backgroundColor: caloTheme.palette.secondaryPink100,
                          fontSize: '14px',
                          my: '8px',
                          mx: '4px'
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Chip
                      key={ing.id}
                      label={ing.name.en}
                      sx={{
                        color: caloTheme.palette.neutral700,
                        backgroundColor: caloTheme.palette.neutral50,
                        fontSize: '14px',
                        my: '8px',
                        mx: '4px'
                      }}
                    />
                  )
                )
              )}
            </Stack>
            {uniqAllergens.length > 0 && (
              <Typography sx={{ fontSize: '16px', lineHeight: '20px', m: 2 }}>
                Warning: Includes {uniqAllergens.map(startCase).sort().join(', ')}.
              </Typography>
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
});

export default Ingredients;
