import { useEffect, useMemo, useState } from 'react';

import { compact, flatten, keyBy, round, sumBy } from 'lodash-es';
import { useQuery } from 'react-query';

import { CreateFoodReq, MenuFoodComponent, Permission } from '@calo/dashboard-types';
import { Dictionary, DietType, FoodDietType } from '@calo/types';
import { Stack } from '@mui/material';

import { ComponentService } from '@calo/services';
import { getListWithParams } from 'actions';
import Disclaimer from 'components/Disclaimer';
import { MealCreationInformation } from 'components/FoodShared';
import CostInformationCard from 'components/MealCostInformationCard/CostInformationCard';
import {
  Components,
  Information,
  Ingredients,
  NutritionalInformation,
  PackagingInformation,
  PortioningNotes
} from 'components/Sections';
import { checkCountryCurrency } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { FoodComponent } from 'lib/interfaces';
import MealCreationHeader from './MealCreationHeader/MealCreationHeader';
import useFoodForm from './useFoodForm';

interface FoodFormProps {
  onSubmit: (value: Omit<CreateFoodReq, 'id'>) => Promise<void>;
  isPreBuildCustom: boolean;
}

const FoodForm = ({ onSubmit, isPreBuildCustom }: FoodFormProps) => {
  const roles = useUserRoles();

  const isDisabled = !roles.includes(Permission.CREATE_PROTOTYPE_FOOD);

  const [name, setName] = useState('');
  const [packageCost, setPackageCost] = useState(0);
  const [childFoodCompIDS, setChildFoodCompIDS] = useState<string[]>([]);
  const [childComponent, setChildComponent] = useState<any>();
  const [foodCompIDS, setFoodCompIDS] = useState<string[]>([]);

  const { handleSubmit, values, handleChange, handleBlur, isSubmitting, isValid, dirty, setFieldValue, setValues, errors } =
    useFoodForm(onSubmit, isPreBuildCustom);

  const [microNutrientsAuto, setMicroNutrientsAuto] = useState<boolean>(
    values.withManualMacros ? values.withManualMacros : false
  );

  const { data: foodComponentListIDS } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components',
      {
        limit: foodCompIDS.length,
        filters: {
          withChildComponents: true,
          ids: foodCompIDS,
          country: values.country,
          brand: values.brand,
          kitchen: values.kitchen
        }
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: !!foodCompIDS.length
    }
  );

  const { data: foodComponentList, isLoading: isLoadingFoodComponent } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components',
      {
        filters: { withChildComponents: true, name, country: values.country, brand: values.brand, kitchen: values.kitchen },
        limit: 50
      }
    ],
    getListWithParams,
    {
      enabled: !!name
    }
  );

  const { data: prototypeComponentSearchResult } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components/prototype',
      {
        limit: 50,
        filters: {
          withChildComponents: true,
          name,
          country: values.country,
          brand: values.brand,
          kitchen: values.kitchen
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!name || roles.includes(Permission.VIEW_PROTOTYPE_COMPONENT_LIST),
      keepPreviousData: true
    }
  );

  const { data: childFoodComponentListIDS, isLoading } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components',
      {
        limit: childFoodCompIDS.length,
        filters: {
          withChildComponents: true,
          ids: childFoodCompIDS,
          country: values.country,
          brand: values.brand,
          kitchen: values.kitchen
        }
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: !!childFoodCompIDS,
      onSuccess: (data) => {
        setChildComponent(keyBy(data.data, 'id'));
      }
    }
  );

  const componentAPI = useMemo(
    () => keyBy([...(foodComponentList?.data || []), ...(prototypeComponentSearchResult?.data || [])], 'id'),
    [foodComponentList, prototypeComponentSearchResult]
  );
  const componentAPIIds = useMemo(() => keyBy(foodComponentListIDS?.data || [], 'id'), [foodComponentListIDS!]);
  const [allComponents, setAllComponents] = useState<Dictionary<FoodComponent>>({ ...componentAPI, ...componentAPIIds });

  useEffect(() => {
    setAllComponents((prev) => {
      const newComponents = {};
      for (const id in prev) {
        if (values.components?.find((comp) => comp.id === id)) {
          newComponents[id] = prev[id];
        }
      }
      return { ...newComponents, ...componentAPI, ...componentAPIIds };
    });
  }, [componentAPI, componentAPIIds]);

  useEffect(() => {
    setChildFoodCompIDS(compact(flatten(values.components?.map((r) => allComponents[r.id]?.childComponents?.map((r) => r.id)))));
  }, [componentAPIIds, componentAPI, childComponent, allComponents, componentAPI, componentAPIIds, childFoodComponentListIDS]);

  useEffect(() => {
    setValues({
      ...values,
      size: 'M',
      tags: isPreBuildCustom ? [FoodDietType.preBuiltCustom] : [DietType.balanced]
    });
  }, [values.brand]);

  useEffect(() => {
    setValues({
      ...values,
      components: [],
      ingredients: []
    });
  }, [values.brand, values.country]);

  useEffect(() => {
    if ((childFoodComponentListIDS && childFoodComponentListIDS?.data.length > 0) || childComponent || childFoodCompIDS) {
      const childCompIng = compact(
        flatten(
          values.components?.map((row) =>
            allComponents[row.id]?.childComponents?.map((r) =>
              childComponent[r.id]?.ingredients?.map((r: any) => ({ id: r.id, category: r.category, name: r.name, slug: r.slug }))
            )
          )
        )
      );
      const ingredComponent = compact(
        flatten(
          values.components?.map((row) =>
            allComponents[row.id]?.ingredients?.map((r: any) => ({ id: r.id, category: r.category, name: r.name, slug: r.slug }))
          )
        )
      );
      setValues({
        ...values,
        ingredients: compact([...flatten(childCompIng), ...ingredComponent])
      });
    }
  }, [childFoodComponentListIDS, childFoodCompIDS, childComponent]);

  const getFoodComponentAmount = (fc: MenuFoodComponent) => {
    const component = allComponents[fc.id];
    const componentWeight = ComponentService.calculateComponentWeight(
      component.cups,
      component.measurementUnit,
      component.weight ?? 0,
      fc.quantity
    );

    return componentWeight;
  };

  const handleComponentsChange = async (rows: MenuFoodComponent[]) => {
    rows.map((r, index) => isNaN(r.quantity) && rows.splice(index, 1, { id: r.id, quantity: 0 }));
    setFoodCompIDS(rows.map((r) => r.id));
    setChildFoodCompIDS(compact(flatten(rows.map((r) => allComponents[r.id]?.childComponents?.map((child: any) => child.id)))));
    const ingredComponent = compact(
      flatten(
        rows.map((row) =>
          allComponents[row.id]?.ingredients?.map((r: any) => ({ id: r.id, category: r.category, name: r.name, slug: r.slug }))
        )
      )
    );
    setValues({
      ...values,
      components: rows,
      macros: {
        cal: microNutrientsAuto
          ? values.macros.cal || 0
          : Math.round(sumBy(rows, (fc) => allComponents[fc.id]?.macros.cal * getFoodComponentAmount(fc))),
        protein: microNutrientsAuto
          ? values.macros.protein || 0
          : Math.round(sumBy(rows, (fc) => allComponents[fc.id]?.macros.protein * getFoodComponentAmount(fc))),
        carbs: microNutrientsAuto
          ? values.macros.carbs || 0
          : Math.round(sumBy(rows, (fc) => allComponents[fc.id]?.macros.carbs * getFoodComponentAmount(fc))),
        fat: microNutrientsAuto
          ? values.macros.fat || 0
          : Math.round(sumBy(rows, (fc) => allComponents[fc.id]?.macros.fat * getFoodComponentAmount(fc))),
        fiber: microNutrientsAuto
          ? values.macros.fiber || 0
          : Math.round(sumBy(rows, (fc) => (allComponents[fc.id]?.macros.fiber || 0) * getFoodComponentAmount(fc) || 0))
      },
      micronutrients: {
        addedSugar: microNutrientsAuto
          ? values.micronutrients?.addedSugar
          : Math.round(sumBy(rows, (fc) => (allComponents[fc.id]?.micronutrients?.addedSugar || 0) * getFoodComponentAmount(fc))),
        cholesterol: microNutrientsAuto
          ? values.micronutrients?.cholesterol
          : Math.round(
              sumBy(rows, (fc) => (allComponents[fc.id]?.micronutrients?.cholesterol || 0) * getFoodComponentAmount(fc))
            ),
        saturatedFats: microNutrientsAuto
          ? values.micronutrients?.saturatedFats
          : Math.round(
              sumBy(rows, (fc) => (allComponents[fc.id]?.micronutrients?.saturatedFats || 0) * getFoodComponentAmount(fc))
            ),
        sodium: microNutrientsAuto
          ? values.micronutrients?.sodium
          : Math.round(sumBy(rows, (fc) => (allComponents[fc.id]?.micronutrients?.sodium || 0) * getFoodComponentAmount(fc))),
        totalSugar: microNutrientsAuto
          ? values.micronutrients?.totalSugar
          : Math.round(sumBy(rows, (fc) => (allComponents[fc.id]?.micronutrients?.totalSugar || 0) * getFoodComponentAmount(fc))),
        transFats: microNutrientsAuto
          ? values.micronutrients?.transFats
          : Math.round(sumBy(rows, (fc) => (allComponents[fc.id]?.micronutrients?.transFats || 0) * getFoodComponentAmount(fc)))
      },
      ingredients: compact(flatten(ingredComponent))
    });
  };

  const getTotalCost = () => {
    const componentCost = values.components?.map(
      (r) =>
        ([...(foodComponentList?.data || []), ...(prototypeComponentSearchResult?.data || [])].find((k) => k.id === r.id)?.cost ||
          0) * r.quantity
    );
    const cC = round(
      sumBy(componentCost, (fk) => fk || 0),
      3
    );
    return (packageCost + cC).toFixed(3);
  };

  useEffect(() => {
    setFieldValue('currency', checkCountryCurrency(values.country));
  }, [values.country]);

  const handleAutoCalculate = (value: boolean) => {
    if (!value) {
      setValues({
        ...values,
        micronutrients: {
          addedSugar: Math.round(
            sumBy(values.components, (fc) => (allComponents[fc.id]?.micronutrients?.addedSugar || 0) * getFoodComponentAmount(fc))
          ),
          cholesterol: Math.round(
            sumBy(
              values.components,
              (fc) => (allComponents[fc.id]?.micronutrients?.cholesterol || 0) * getFoodComponentAmount(fc)
            )
          ),
          saturatedFats: Math.round(
            sumBy(
              values.components,
              (fc) => (allComponents[fc.id]?.micronutrients?.saturatedFats || 0) * getFoodComponentAmount(fc)
            )
          ),
          sodium: Math.round(
            sumBy(values.components, (fc) => (allComponents[fc.id]?.micronutrients?.sodium || 0) * getFoodComponentAmount(fc))
          ),
          totalSugar: Math.round(
            sumBy(values.components, (fc) => (allComponents[fc.id]?.micronutrients?.totalSugar || 0) * getFoodComponentAmount(fc))
          ),
          transFats: Math.round(
            sumBy(values.components, (fc) => (allComponents[fc.id]?.micronutrients?.transFats || 0) * getFoodComponentAmount(fc))
          )
        }
      });
    }
    setValues({
      ...values,
      withManualMacros: value
    });
    setMicroNutrientsAuto(value);
  };

  const isValidMeal = () => {
    if (
      !values.name?.ar ||
      (values.name?.ar && values.name?.ar.length < 3) ||
      (values.name?.ar && values.name?.ar.length > 50) ||
      values.type?.length === 0 ||
      !isValid
    ) {
      return false;
    }
    return true;
  };

  return (
    <Stack flexDirection={'column'} justifyContent={'space-between'} spacing={2}>
      <MealCreationHeader
        values={values}
        setValues={setValues}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        disableSubmit={!dirty || !isValid || isSubmitting || isDisabled || !isValidMeal()}
      />
      <Disclaimer type="meal" sx={{ top: '130px' }} />

      <MealCreationInformation values={values} setFieldValue={setFieldValue} />

      <Components
        ref={null}
        name={name}
        values={values}
        setName={setName}
        isDisabled={isDisabled}
        allComponents={allComponents}
        setFieldValue={setFieldValue}
        isLoading={isLoadingFoodComponent}
        handleComponentsChange={handleComponentsChange}
        foodComponents={[...(foodComponentList?.data || []), ...(prototypeComponentSearchResult?.data || [])]}
      />

      <Ingredients ref={null} roles={roles} values={values} isLoading={isLoading} />

      <PackagingInformation
        ref={null}
        roles={roles}
        values={values}
        isDisabled={isDisabled}
        food={values as any}
        setFieldValue={setFieldValue}
        setPackageCost={(r) => setPackageCost(r)}
      />

      <NutritionalInformation
        ref={null}
        values={values}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        handleAutoCalculate={handleAutoCalculate}
        displayMessageForMacrosLimits={undefined}
        microNutrientsAuto={values.withManualMacros || false}
      />
      <CostInformationCard
        values={values}
        setFieldValue={setFieldValue}
        getTotalCost={getTotalCost}
        packagingCost={packageCost}
        allComponents={allComponents}
        childComponent={childComponent}
      />

      <Information
        values={values}
        errors={errors}
        allSizesFood={[]}
        isDisabled={isDisabled}
        food={values as any}
        setFieldValue={setFieldValue}
      />

      <PortioningNotes ref={null} values={values} errors={errors} isDisabled={isDisabled} handleChange={handleChange} />
    </Stack>
  );
};

export default FoodForm;
