import { PaymentSource, Permission, Wallet, WalletAction } from '@calo/dashboard-types';
import { PaymentMethod } from '@calo/types';
import cx from 'classnames';
import { Button } from 'components';
import { format, parseISO } from 'date-fns/fp';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { sortBy } from 'lodash-es';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { WalletCollapse } from './types';

interface WalletRowProps {
  row: WalletCollapse;
  onRefund: (wallet: Wallet) => Promise<void> | void;
}

const WalletRow = ({ row, onRefund }: WalletRowProps) => {
  const [isOpen, setIsOpen] = useState<boolean>();
  const roles = useUserRoles();

  const sortChild = sortBy(row.childrens, (f) => [f.createdAt]);

  return (
    <>
      {row.childrens.length > 0 ? (
        <>
          <tr key={row.wallet.id} onClick={() => setIsOpen(!isOpen)}>
            <td colSpan={8}>
              <div className="py-2 px-1 flex-row">
                <i className={cx('fas pr-1 pt-1', { 'fa-angle-up': isOpen, 'fa-angle-down': !isOpen })}> </i>
                Remove
              </div>
            </td>
            <td colSpan={3}>{format('MM-yyyy')(parseISO(row.wallet.createdAt))}</td>
          </tr>
          <tr className={cx('w-full', { hidden: !isOpen, 'table-row': isOpen })}>
            <td colSpan={10}>
              <table className="w-full">
                <tbody>
                  {sortChild.map((childWallet) => (
                    <>
                      {row.childrens.indexOf(childWallet) === 0 && (
                        <tr>
                          <td>
                            <div className="ml-10">{row.wallet.amount}</div>
                          </td>
                          <td>{row.wallet.currency}</td>
                          <td> {row.wallet.action} </td>
                          <td> {row.wallet.source} </td>
                          <td> {row.wallet.actor?.name || ''} </td>
                          <td> {row.wallet.reason} </td>
                          <td> {row.wallet.notes} </td>
                          <td>
                            {row.wallet.deliveryId && (
                              <Link to={Routes.delivery.replace(':id', row.wallet.deliveryId)}>delivery</Link>
                            )}
                          </td>
                          <td>{format('Pp')(parseISO(row.wallet.createdAt))}</td>
                          <td>
                            {row.wallet.action === WalletAction.ADD &&
                              row.wallet.source === PaymentSource.online &&
                              roles.includes(Permission.CREATE_REFUND) && (
                                <Button content="Refund" onClick={() => onRefund(row.wallet)} />
                              )}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <div className="ml-10 ">{childWallet.amount}</div>
                        </td>
                        <td>{childWallet.currency}</td>
                        <td>{childWallet.action}</td>
                        <td>{childWallet.source}</td>
                        <td>{childWallet.actor?.name || ''} </td>
                        <td>{childWallet.reason}</td>
                        <td>{childWallet.notes}</td>
                        <td>
                          {childWallet.deliveryId && (
                            <Link to={Routes.delivery.replace(':id', childWallet.deliveryId)}>delivery</Link>
                          )}
                        </td>
                        {/* <td>{format('Pp')(parseISO(childWallet.createdAt))}</td> */}
                        <td>
                          {/* {Object.values(PaymentMethod).includes(childWallet.paymentMethod) && childWallet.action === WalletAction.ADD && childWallet.source === PaymentSource.online && (roles.includes(Permission.CREATE_REFUND)) && ( */}
                          <Button content="Refund" onClick={() => onRefund(childWallet)} />
                          {/* )} */}
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        </>
      ) : (
        <tr>
          <td>
            {row.wallet.amount}{' '}
            {row.wallet.vat && row.wallet.action === WalletAction.ADD && row.wallet.source !== PaymentSource.credits ? (
              <span className="text-xs text-gray-500">(-{row.wallet.vat} VAT)</span>
            ) : null}
          </td>
          <td>{row.wallet.currency}</td>
          <td>{row.wallet.action}</td>
          <td>{row.wallet.source} </td>
          <td>{row.wallet.actor?.name || ''} </td>
          <td>{row.wallet.reason} </td>
          <td style={{ whiteSpace: 'pre-line' }}>{row.wallet.notes}</td>
          <td>{row.wallet.deliveryId && <Link to={Routes.delivery.replace(':id', row.wallet.deliveryId)}>delivery</Link>}</td>
          <td>{format('Pp')(parseISO(row.wallet.createdAt))}</td>
          <td>
            {[PaymentMethod.cc, PaymentMethod.benefit, PaymentMethod.applePay, PaymentMethod.mada].includes(
              row.wallet.paymentMethod
            ) &&
              row.wallet.action === WalletAction.ADD &&
              row.wallet.source === PaymentSource.online &&
              roles.includes(Permission.CREATE_REFUND) && <Button content="Refund" onClick={() => onRefund(row.wallet)} />}
          </td>
        </tr>
      )}
    </>
  );
};
export default WalletRow;
