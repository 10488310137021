import { RefObject, useEffect, useMemo, useRef, useState } from 'react';

import { compact, flatten, keyBy, round, sumBy } from 'lodash-es';
import { useQuery } from 'react-query';

import { MenuFoodComponent, Permission, UpdateFoodReq } from '@calo/dashboard-types';
import { Dictionary } from '@calo/types';
import { Stack } from '@mui/material';

import { getListWithParams } from 'actions';
import Disclaimer from 'components/Disclaimer';
import CostInformationCard from 'components/MealCostInformationCard/CostInformationCard';
import { ModalRef } from 'components/Modal';
import {
  Components,
  Information,
  Ingredients,
  MealInformation,
  NutritionalInformation,
  PackagingInformation,
  PortioningNotes
} from 'components/Sections';
import { useUserRoles } from 'lib/hooks';
import { BaseOmit, Food, FoodComponent } from 'lib/interfaces';
import ActivityLogCard from 'views/ChefPlayground/Shared/ActivityLogCard';
import FoodCommentsPopup from 'views/ChefPlayground/Shared/FoodCommentsPopup';
import {
  displayMessageForMacrosLimits,
  findAutoCalculatedMacros,
  findAutoCalculatedMicros,
  findFoodMacros,
  findMicronutrients,
  selectCompIngredientsAttribtues
} from '../helpers';
import StickyHeader from './StickyHeader';
import useFoodForm from './useFoodForm';

interface FoodFormProps {
  refetch: any;
  food: Food;
  name: string;
  selectedFood: Food;
  allSizesFood: Food[];
  filteredFoodUsed: any;
  foodWithAllSizes: Food[];
  usedOnMenuButtonColor: string[];
  foodComponentsFromSearch: FoodComponent[];
  setName: (value: string) => void;
  selectedFoodComponents: FoodComponent[];
  setSelectedFood: React.Dispatch<React.SetStateAction<Food | undefined>>;
  setFoodWithAllSizes: (food: Food[]) => void;
  onSubmit: (value: Omit<UpdateFoodReq, BaseOmit>) => Promise<void>;
}

const FoodForm = ({
  onSubmit,
  food,
  refetch,
  foodComponentsFromSearch,
  setName,
  name,
  selectedFoodComponents,
  allSizesFood,
  filteredFoodUsed,
  selectedFood,
  foodWithAllSizes,
  usedOnMenuButtonColor,
  setSelectedFood,
  setFoodWithAllSizes
}: FoodFormProps) => {
  const roles = useUserRoles();
  const isDisabled = !roles.includes(Permission.UPDATE_PROTOTYPE_FOOD);
  const commentsPopupRef = useRef<ModalRef>();

  const [childFoodCompIDS, setChildFoodCompIDS] = useState<string[]>([]);

  const [packageCost, setPackageCost] = useState(0);
  const [keyedChildComponents, setKeyedChildComponents] = useState<Dictionary<FoodComponent>>({});
  const [allComponents, setAllComponents] = useState<Dictionary<FoodComponent>>({});

  const keyedSelectedComponents = useMemo(() => keyBy(selectedFoodComponents, 'id'), [selectedFoodComponents]);
  const keyedComponentsFromSearch = useMemo(() => keyBy(foodComponentsFromSearch, 'id'), [foodComponentsFromSearch!]);

  const { data: childComponentsData, isLoading } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components',
      {
        limit: childFoodCompIDS.length,
        filters: {
          withChildComponents: true,
          ids: childFoodCompIDS,
          country: food.country,
          brand: food.brand,
          kitchen: food.kitchen
        }
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: !!childFoodCompIDS,
      onSuccess: (data) => {
        setKeyedChildComponents(keyBy(data.data, 'id'));
      }
    }
  );

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    isSubmitting,
    isValid,
    dirty,
    setFieldValue,
    setValues,
    resetForm,
    errors
  } = useFoodForm(food, onSubmit);

  const sectionRefs: RefObject<HTMLDivElement>[] = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null)
  ];

  useEffect(() => {
    setAllComponents((prev) => {
      const newComponents = {};
      for (const id in prev) {
        if (values.components?.find((comp) => comp.id === id)) {
          newComponents[id] = prev[id];
        }
      }
      return { ...newComponents, ...keyedSelectedComponents, ...keyedComponentsFromSearch };
    });
  }, [keyedSelectedComponents, keyedComponentsFromSearch]);

  useEffect(() => {
    setChildFoodCompIDS(compact(flatten(values.components?.map((r) => allComponents[r.id]?.childComponents?.map((r) => r.id)))));
  }, [values.components, allComponents]);

  useEffect(() => resetForm, [food]);

  useEffect(() => {
    if (((childComponentsData?.data ?? []).length > 0 || childFoodCompIDS?.length > 0) && values.components?.length !== 0) {
      const childCompIng = compact(
        flatten(
          (values.components ?? []).map((row) =>
            allComponents[row.id]?.childComponents?.map((r) => selectCompIngredientsAttribtues(keyedChildComponents[r.id]))
          )
        )
      );
      const componentsIngredients = compact(
        flatten(values.components?.map((row) => selectCompIngredientsAttribtues(allComponents[row.id])))
      );
      setValues({
        ...values,
        ingredients: compact([...flatten(childCompIng), ...componentsIngredients])
      });
    }
  }, [childComponentsData, childFoodCompIDS, keyedChildComponents]);

  const handleComponentsChange = async (rows: MenuFoodComponent[]) => {
    rows.map((r, index) => isNaN(r.quantity) && rows.splice(index, 1, { id: r.id, quantity: 0 }));
    setChildFoodCompIDS(compact(flatten(rows.map((r) => allComponents[r.id]?.childComponents?.map((r) => r.id)))));
    const componentIngredients = compact(flatten(rows.map((row) => selectCompIngredientsAttribtues(allComponents[row.id]))));
    setValues({
      ...values,
      components: rows,
      macros: findFoodMacros(rows, allComponents, values.macros, !!values.withManualMacros),
      micronutrients: findMicronutrients(rows, allComponents, values.micronutrients, !!values.withManualMacros),
      ingredients: compact(flatten(componentIngredients))
    });
  };

  const getTotalCost = () => {
    const componentCosts = values.components?.map(
      (r) => (selectedFoodComponents.find((k) => k.id === r.id)?.cost || 0) * r.quantity
    );
    const cC = round(
      sumBy(componentCosts, (fk) => fk || 0),
      3
    );
    return (packageCost + cC).toFixed(3);
  };

  const handleAutoCalculate = (value: boolean) => {
    if (value) {
      setValues({
        ...values,
        withManualMacros: value
      });
    } else {
      setValues({
        ...values,
        macros: findAutoCalculatedMacros(values.components ?? [], allComponents),
        micronutrients: findAutoCalculatedMicros(values.components ?? [], allComponents),
        withManualMacros: value
      });
    }
  };

  const closeCommentsPopup = () => {
    commentsPopupRef.current?.close();
  };

  const addCommentSuccessHandler = (data: Food) => {
    setSelectedFood((prev) => ({ ...prev, prototypeActions: data.prototypeActions }) as Food);
    refetch();
  };

  return (
    <Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={2}>
      <StickyHeader
        refetch={refetch}
        dirty={dirty}
        values={values}
        isValid={isValid}
        isDisabled={isDisabled}
        sectionRefs={sectionRefs}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        selectedFood={selectedFood}
        setSelectedFood={setSelectedFood}
        filteredFoodUsed={filteredFoodUsed}
        foodWithAllSizes={foodWithAllSizes}
        setFoodWithAllSizes={setFoodWithAllSizes}
        usedOnMenuButtonColor={usedOnMenuButtonColor}
      />
      <Disclaimer type="meal" sx={{ top: '210px' }} />
      <MealInformation
        values={values}
        errors={errors}
        food={food}
        isDisabled={isDisabled}
        roles={roles}
        showOwner={true}
        setFieldValue={setFieldValue}
        ref={sectionRefs[0]}
      />
      <Components
        values={values}
        isDisabled={isDisabled}
        foodComponents={foodComponentsFromSearch}
        isLoading={isLoading}
        allComponents={allComponents}
        setFieldValue={setFieldValue}
        handleComponentsChange={handleComponentsChange}
        ref={sectionRefs[1]}
        setName={setName}
        name={name}
      />
      <NutritionalInformation
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        microNutrientsAuto={values.withManualMacros || false}
        handleAutoCalculate={handleAutoCalculate}
        displayMessageForMacrosLimits={(macrosProp: 'carbs' | 'cal' | 'protein' | 'fat') =>
          values.macros ? displayMessageForMacrosLimits(allSizesFood, food, values.macros, macrosProp) : ''
        }
        ref={sectionRefs[2]}
      />
      <Information
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
        food={food}
        isDisabled={isDisabled}
        allSizesFood={allSizesFood}
      />
      <Ingredients values={values} roles={roles} isLoading={isLoading} ref={sectionRefs[3]} />
      <CostInformationCard
        values={values}
        setFieldValue={setFieldValue}
        getTotalCost={getTotalCost}
        packagingCost={packageCost}
        allComponents={allComponents}
        foodWithAllSizes={foodWithAllSizes}
        childComponent={keyedChildComponents}
      />
      <PortioningNotes values={values} errors={errors} handleChange={handleChange} isDisabled={isDisabled} ref={sectionRefs[5]} />
      <PackagingInformation
        values={values}
        setFieldValue={setFieldValue}
        setPackageCost={(r) => setPackageCost(r)}
        food={food}
        isDisabled={isDisabled}
        ref={sectionRefs[4]}
        roles={roles}
      />
      <ActivityLogCard
        actionLogs={food.prototypeActions ?? []}
        type="meal"
        addCommentClickHandler={() => commentsPopupRef.current?.open()}
      />
      <FoodCommentsPopup
        selectedFood={food}
        ref={commentsPopupRef}
        successHandler={addCommentSuccessHandler}
        closeCommentsPopup={closeCommentsPopup}
      />
    </Stack>
  );
};

export default FoodForm;
