import { useRef, useState } from 'react';

import { format } from 'date-fns/fp';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Permission, Subscription } from '@calo/dashboard-types';
import { PermissionService, PricingService } from '@calo/services';
import { Brand, Country, DeliveryStatus, Kitchen, SubscriptionStatus } from '@calo/types';
import { Box, Switch, ThemeProvider } from '@mui/material';

import { getRecord } from 'actions';
import mutation from 'actions/mutation';
import { pauseSubscription, removeSuspension, resumeSubscription, updateSubscription } from 'actions/subscription';
import { Button, Dropdown, Icon, Modal, ModalRef } from 'components';
import { theme } from 'lib/componentStyles';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';
import { Delivery, SubscriptionPUValues } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import AddressPanel from './AddressPlanel';
import DeliveriesCard from './DeliveriesCard';
import EditPendingForm from './EditPendingForm';
import FeedBackPanel from './FeedBackPanel';
import LogPanel from './LogPanel';
import MacrosCard from './MacrosCard';
import MacrosDataCard from './MacrosDataCard';
import MacrosStats from './MacrosStats';
import PUForm from './PUForm';
import PhoneNumber from './PhoneNumber';
import PlanCard from './PlanCard';
import SettingsCard from './SettingsCard';
import Stats from './Stats';
import WalletPanel from './WalletPanel';

const between = (x: string, pausedAt: string, unpauseAt: string) => x >= pausedAt && x <= unpauseAt;

const ExactSubscription = () => {
  const { id } = useParams<{ id: string }>();
  const editModalRef = useRef<ModalRef>();
  const [exactOldView, setExactOldView] = useState<boolean>(true);

  const { data } = useQuery(['subscriptions', id], getRecord, {
    suspense: true
  });
  const subscription = data as Subscription & {
    remainingDays: number;
    expectedLastDeliveryDay: string;
    ratings: any[];
    magicPrice?: boolean;
  };

  const { mutateAsync: updateMutation } = useMutation(updateSubscription);
  const { mutateAsync: pauseMutation } = useMutation(pauseSubscription);
  const { mutateAsync: resumeMutation } = useMutation(resumeSubscription);
  const { mutateAsync: removeSuspensionMutation } = useMutation(removeSuspension);

  const roles = useUserRoles();

  const ChangeDeliveryData = (name: string, data?: any) => {
    const allDeliveries = queryClient
      .getQueryData<any>([`subscriptions/${subscription.id}/deliveriesId`])
      .map((d: string) => queryClient.getQueryData(['deliveries', d]));

    if (name.includes(DeliveryStatus.paused)) {
      const pausedDelivery = allDeliveries.filter((delivery: Delivery) => between(delivery.day, data.pausedAt, data.unpauseAt));
      for (const deliveryData of pausedDelivery) {
        mutation(['deliveries', deliveryData.id], { status: DeliveryStatus.paused });
      }
    } else if (name.includes(DeliveryStatus.upcoming)) {
      const resumedDelivery = allDeliveries.filter(
        (delivery: Delivery) =>
          delivery.status.includes(DeliveryStatus.paused) &&
          delivery.day >= format('yyyy-MM-dd')(PermissionService.getMinActionDate(subscription.deliveryDays, Date.now()))
      );
      for (const deliveryData of resumedDelivery) {
        mutation(['deliveries', deliveryData.id], { status: DeliveryStatus.paymentRequired });
      }
    } else if (name.includes(DeliveryStatus.suspended)) {
      const suspensedDelivery = allDeliveries.filter(
        (delivery: Delivery) =>
          delivery.status.includes(DeliveryStatus.suspended) && delivery.day >= format('yyyy-MM-dd')(Date.now())
      );
      for (const deliveryData of suspensedDelivery) {
        mutation(['deliveries', deliveryData.id], { status: DeliveryStatus.paymentRequired });
      }
    }
  };

  const onPause = async ({ startDate, endDate }: SubscriptionPUValues) => {
    await pauseMutation(
      {
        id,
        startDate: format('yyyy-MM-dd')(startDate),
        ...(endDate && {
          endDate: format('yyyy-MM-dd')(endDate)
        })
      },
      {
        onSuccess: (data) => {
          ChangeDeliveryData(DeliveryStatus.paused, data);
        }
      }
    );
  };

  const onResume = async () => {
    await resumeMutation(
      {
        id
      },
      {
        onSuccess: () => {
          ChangeDeliveryData(DeliveryStatus.upcoming);
        }
      }
    );
  };

  const onRemoveSuspension = async () => {
    await removeSuspensionMutation(id);
  };

  const handleEditPending = async (amount: number) => {
    const amountWithVat = {
      ...subscription.pendingAmount,
      [subscription.currency]: PricingService.getGrossAmount(amount, subscription.currency)
    };
    await updateMutation({
      id,
      pendingAmount: amountWithVat
    });
    editModalRef.current?.close();
  };

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  <Link to={Routes.subscriptions}>Subscriptions</Link>
                </li>
                <li>{subscription.name}</li>
              </ul>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <span className="flex flex-row">
                <ThemeProvider theme={theme}>
                  <Box>
                    <Switch
                      onChange={() => {
                        setExactOldView(!exactOldView);
                        history.push(
                          exactOldView
                            ? Routes.subscription2.replace(':id', subscription.id)
                            : Routes.subscription.replace(':id', subscription.id)
                        );
                      }}
                      sx={{ color: 'white' }}
                      checked={exactOldView}
                    />
                  </Box>
                </ThemeProvider>
                {subscription.brand && subscription.brand === Brand.MEALO ? (
                  <Icon name="mealo" size={28} className="w-24 -mb-24" />
                ) : (
                  <Icon name="calo" size={8} className="w-32 -mb-4" />
                )}
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">
                  {subscription.name} ({subscription.phoneNumber}) (
                  {subscription.kitchen
                    ? subscription.kitchen
                    : subscription.country === Country.BH
                      ? Kitchen.BH1
                      : subscription.country === Country.SA
                        ? Kitchen.SA1
                        : ''}
                  ) {subscription.magicPrice ? '(BAH Magic Price Experiment)' : ''}
                  {subscription.status === SubscriptionStatus.paused && (
                    <span data-tooltip={SubscriptionStatus.paused}>
                      <i className="fas fa-pause" />
                    </span>
                  )}
                  {subscription.status === SubscriptionStatus.cancelled && (
                    <span data-tooltip={SubscriptionStatus.cancelled}>
                      <i className="fas fa-ban" />
                    </span>
                  )}
                  {subscription.status === SubscriptionStatus.suspended && (
                    <span data-tooltip={SubscriptionStatus.suspended}>
                      <i className="fas fa-times-circle"></i>
                    </span>
                  )}
                </h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item buttons">
                {subscription.status === SubscriptionStatus.suspended &&
                  roles.includes(Permission.REMOVE_SUBSCRIPTION_SUSPENSION) && (
                    <Button danger onClick={onRemoveSuspension} type="button" content="Remove Suspension" />
                  )}
                {subscription.status === SubscriptionStatus.paused && roles.includes(Permission.RESUME_SUBSCRIPTION) ? (
                  <Button fluid primary onClick={onResume} type="button" content="Resume" />
                ) : (
                  subscription.status === SubscriptionStatus.ongoing &&
                  roles.includes(Permission.PAUSE_SUBSCRIPTION) && (
                    <Dropdown isRight trigger={<Button warning content="Pause subscription" />}>
                      <PUForm
                        onSubmit={onPause}
                        deliveryDays={subscription.deliveryDays}
                        pausedAt={subscription.pausedAt}
                        resumeAt={subscription.unpauseAt}
                      />
                    </Dropdown>
                  )
                )}
              </div>
            </div>
          </div>
          {subscription.status === SubscriptionStatus.ongoing && subscription.pausedAt && (
            <div className="level-right">
              Scheduled for pausing from: <b>{subscription.pausedAt}</b> to: <b>{subscription.unpauseAt}</b>
            </div>
          )}
          {subscription.status === SubscriptionStatus.paused && subscription.unpauseAt && (
            <div className="level-right">
              Resume date: <b>{subscription.unpauseAt}</b>
            </div>
          )}
        </div>
      </section>
      <div>
        <Stats subscription={subscription} onEditPending={() => editModalRef.current?.open()} />

        <PhoneNumber subscription={subscription} />

        {roles.includes(Permission.VIEW_SUBSCRIPTION_DELIVERY_LIST) && <DeliveriesCard subscription={subscription} />}

        <MacrosStats subscription={subscription} />
        <div className="tile is-ancestor">
          <MacrosCard subscription={subscription} />
          <MacrosDataCard subscription={subscription} />
          <SettingsCard subscription={subscription} />
          <PlanCard subscription={subscription} />
        </div>

        <AddressPanel subscription={subscription} />

        {roles.includes(Permission.VIEW_SUBSCRIPTION_WALLET) && <WalletPanel subscription={subscription} />}

        {roles.includes(Permission.VIEW_SUBSCRIPTION_LOGS) && <LogPanel subscription={subscription} />}

        {roles.includes(Permission.VIEW_RATING_LIST) && <FeedBackPanel subscriptionId={subscription.id} />}
      </div>
      <Modal ref={editModalRef} isNarrow>
        <EditPendingForm
          onSubmit={handleEditPending}
          balance={subscription.balance[subscription.currency]}
          currency={subscription.currency}
        />
      </Modal>
    </>
  );
};

export default ExactSubscription;
