import { Dictionary, FoodComponentType } from '@calo/types';
import { FoodComponent } from 'lib/interfaces';

export const getOrderedComponents = (components: any[], allComponents: Dictionary<FoodComponent>) => {
  let availableComponents = [...components];
  const orderedComponents: any[] = [];
  const orderByNames = [
    FoodComponentType.base,
    FoodComponentType.side,
    FoodComponentType.protein,
    FoodComponentType.topping,
    FoodComponentType.sauce
  ];
  for (const foodComponentType of orderByNames) {
    const filtered = availableComponents.filter((comp) =>
      (allComponents[comp.id]?.sections ?? []).map((section) => section.tag).includes(foodComponentType)
    );
    orderedComponents.push(...filtered);
    availableComponents = availableComponents.filter(
      (comp) =>
        !allComponents[comp.id] ||
        !(allComponents[comp.id]?.sections ?? []).map((section) => section.tag).includes(foodComponentType)
    );
  }
  orderedComponents.push(...availableComponents);
  return orderedComponents;
};
