import { useEffect, useState } from 'react';

import { FormikErrors } from 'formik';
import { compact, lowerCase, startCase } from 'lodash-es';
import { useHistory } from 'react-router';

import {
  BaseFoodTags,
  CreateFoodReq,
  FoodTagsKeys,
  NewRatedFoodTags,
  Permission,
  ProteinFoodTags,
  SnackTypeTags,
  TasteFoodTags,
  UpdateFoodReq
} from '@calo/dashboard-types';
import {
  AddonCategory,
  Brand,
  Convenience,
  Cuisine,
  FoodDietType,
  FoodType,
  HeatingInstruction,
  IngredientCategory,
  SpiceLevel,
  Texture
} from '@calo/types';
import { Box, Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { foodInformationFormMultiSelectCustomStyles, foodInformationFormSingleSelectCustomStyles } from 'lib/componentStyles';
import { FoodTagKeys, Routes } from 'lib/enums';
import { getAvailableMealSizes, getFoodTypeColor, getTagColor, resolveHeatingInstruction } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { BaseOmit, Food } from 'lib/interfaces';
import InputMUI from '../InputMUI';
import SelectMUI from '../SelectMUI';

interface FoodInformationFormProps {
  isDisabled: boolean;
  values: Omit<CreateFoodReq, 'id'> | Omit<UpdateFoodReq, BaseOmit>;
  setFieldValue: any;
  allSizesFood?: Food[];
  formType: 'create' | 'update';
  brand?: Brand;
  errors: FormikErrors<Omit<CreateFoodReq | UpdateFoodReq, 'id'>>;
  isPreBuildCustom: boolean;
  isNewFood?: boolean;
}

const isSnack = (food: Omit<CreateFoodReq, 'id'> | Omit<UpdateFoodReq, BaseOmit>) => {
  return (food.type ?? []).includes(FoodType.snack);
};

const FoodInformationForm = ({
  isDisabled,
  errors,
  values,
  setFieldValue,
  allSizesFood,
  formType,
  brand,
  isPreBuildCustom,
  isNewFood
}: FoodInformationFormProps) => {
  const [inputSize, setInputSize] = useState('');
  const [proteinTags, setProteinTags] = useState<ProteinFoodTags[]>([]);
  const [baseTags, setBaseTags] = useState<BaseFoodTags[]>([]);
  const [snackTags, setSnackTags] = useState<SnackTypeTags[]>([]);
  const [ratingTags, setRatingTags] = useState<NewRatedFoodTags | undefined>();
  const [tasteTags, setTasteTags] = useState<TasteFoodTags | undefined>();
  const [addonCategoryTags, setAddonCategoryTags] = useState<AddonCategory | undefined>();
  const [cuisine, setCuisine] = useState<Cuisine | undefined>();
  const [texture, setTexture] = useState<Texture | undefined>();
  const [spicyLevel, setSpicyLevel] = useState<SpiceLevel | undefined>();
  const [convenience, setConvenience] = useState<Convenience | undefined>();

  const history = useHistory();
  const roles = useUserRoles();

  useEffect(() => {
    if (values.foodTags) {
      (values.foodTags as any).map((r) => {
        switch (r.key) {
          case FoodTagsKeys.protein:
            return setProteinTags(r.value);
          case FoodTagsKeys.base:
            return setBaseTags(r.value);
          case FoodTagsKeys.rating:
            return setRatingTags(r.value);
          case FoodTagsKeys.taste:
            return setTasteTags(r.value);
          case FoodTagsKeys.snacktype:
            return setSnackTags(r.value);
          case FoodTagsKeys.addonCategory:
            return setAddonCategoryTags(r.value);
          case FoodTagsKeys.cuisine:
            return setCuisine(r.value);
          case FoodTagsKeys.texture:
            return setTexture(r.value);
          case FoodTagsKeys.spicyLevel:
            return setSpicyLevel(r.value);
          case FoodTagsKeys.convenience:
            return setConvenience(r.value);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!values.type?.includes(FoodType.snack)) {
      setFieldValue(
        'foodTags',
        compact([...((values.foodTags as any) || [])?.filter((k: any) => k.key !== FoodTagsKeys.snacktype), undefined])
      );
      setSnackTags([]);
    }
  }, [values.type]);

  const handleTagsChange = (data: any[]) => {
    const newTags = data.map((row) => row.value);
    if (isNewFood && isPreBuildCustom && !newTags.includes(FoodDietType.preBuiltCustom)) {
      history.replace(Routes.newFood.replace(':type', 'regular'));
    } else if (isNewFood && !isPreBuildCustom && newTags.includes(FoodDietType.preBuiltCustom)) {
      history.replace(Routes.newFood.replace(':type', 'custom'));
    }
    if (!isNewFood && isPreBuildCustom && values.type?.length === 0) {
      setFieldValue('type', FoodType.lunch);
    }
    setFieldValue(
      'tags',
      data.map((row) => row.value)
    );
  };

  const isDairyFree = !(values.ingredients ?? []).flatMap((ing) => ing.category).includes(IngredientCategory.dairy);
  const isGlutenFree = !(values.ingredients ?? []).flatMap((ing) => ing.category).includes(IngredientCategory.gluten);

  const automatedFoodTags: FoodTagKeys[] = [];

  if (isDairyFree) {
    automatedFoodTags.push(FoodTagKeys.dairyFree);
  }

  if (isGlutenFree) {
    automatedFoodTags.push(FoodTagKeys.glutenFree);
  }

  if (isNewFood) {
    automatedFoodTags.push(FoodTagKeys.new);
  }

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      sx={{
        width: '98%',
        m: 2,
        mt: 0
      }}
    >
      <Typography
        sx={{
          color: caloTheme.palette.neutral900,
          textTransform: 'capitalize',
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '23px'
        }}
      >
        Basic Labels
      </Typography>
      <Stack direction="row" justifyContent="space-evenly" alignItems="baseline" spacing={2}>
        <Box sx={{ width: '30%' }}>
          <SelectMUI
            label={formType === 'update' ? 'Size' : 'Size*'}
            value={values.size?.toUpperCase()}
            data-test="exact-meal-size"
            id="foodSizeSelect"
            onInputChange={(d: any) => {
              if (d) {
                setInputSize(d.toUpperCase());
              }
            }}
            onChange={(data: any) => setFieldValue('size', data.value)}
            options={getAvailableMealSizes(['XS', 'S', 'M', 'L', 'XL', inputSize], allSizesFood).map((type) => ({
              value: type,
              label: type
            }))}
            error={!!errors.size}
            isDisabled={isDisabled || brand === Brand.MEALO}
            customStyles={foodInformationFormSingleSelectCustomStyles}
          />
        </Box>
        <Box sx={{ width: '30%' }}>
          <SelectMUI
            name="cuisine"
            label="Cuisine"
            value={cuisine}
            // data-test="exact-meal-foodTags-cuisine"
            onChange={(data: any) => {
              setCuisine(data.value);
              setFieldValue(
                'foodTags',
                compact([
                  ...((values.foodTags as any) || []).filter((k: any) => k.key !== FoodTagsKeys.cuisine),
                  ...(data.value ? [{ key: FoodTagsKeys.cuisine, value: data.value }] : [])
                ])
              );
            }}
            options={[
              {
                label: 'None',
                value: undefined as any
              },
              ...Object.values(Cuisine).map((cuisine) => ({
                value: cuisine,
                label: startCase(cuisine)
              }))
            ]}
            isDisabled={isDisabled}
            customStyles={foodInformationFormSingleSelectCustomStyles}
          />
        </Box>
        <Box sx={{ width: '30%' }}>
          <SelectMUI
            label="Taste (Main Flavor)"
            value={tasteTags}
            data-test="exact-meal-foodTags-taste"
            onChange={(data: any) => {
              setTasteTags(data.value);
              setFieldValue(
                'foodTags',
                compact([
                  ...((values.foodTags as any) || []).filter((k: any) => k.key !== FoodTagsKeys.taste),
                  ...(data.value ? [{ key: FoodTagsKeys.taste, value: data.value }] : [])
                ])
              );
            }}
            options={[
              {
                label: 'None',
                value: undefined as any
              },
              ...Object.values(TasteFoodTags).map((taste) => ({
                value: taste,
                label: startCase(taste)
              }))
            ]}
            isDisabled={isDisabled}
            customStyles={foodInformationFormSingleSelectCustomStyles}
          />
        </Box>
        <Box sx={{ width: '30%' }}>
          <SelectMUI
            label="Addon Category"
            value={addonCategoryTags}
            data-test="exact-meal-foodTags-addon-category"
            onChange={(data: any) => {
              setAddonCategoryTags(data.value);
              setFieldValue(
                'foodTags',
                compact([
                  ...((values.foodTags as any) || []).filter((k: any) => k.key !== FoodTagsKeys.addonCategory),
                  ...(data.value ? [{ key: FoodTagsKeys.addonCategory, value: data.value }] : [])
                ])
              );
            }}
            options={[
              {
                label: 'None',
                value: undefined as any
              },
              ...Object.values(AddonCategory).map((addonCategory) => ({
                value: addonCategory,
                label: startCase(lowerCase(addonCategory))
              }))
            ]}
            isDisabled={isDisabled}
            customStyles={foodInformationFormSingleSelectCustomStyles}
          />
        </Box>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="baseline"
        spacing={2}
        sx={{ pb: 3, borderBottom: '1px solid ' + caloTheme.palette.neutral100 }}
      >
        <Box sx={{ width: '50%' }}>
          <SelectMUI
            label="Type of Meal*"
            value={values.type}
            isMulti
            data-test="exact-meal-type"
            customStyles={foodInformationFormMultiSelectCustomStyles(
              (data: any) => `${getFoodTypeColor(data)}1A`,
              getFoodTypeColor
            )}
            onChange={(data: any) =>
              setFieldValue(
                'type',
                (data as any[]).map((row) => row.value)
              )
            }
            options={[
              FoodType.breakfast,
              FoodType.lunch,
              FoodType.dinner,
              FoodType.snack,
              FoodType.dessert,
              FoodType.caloKids,
              FoodType.coffee,
              FoodType.juice,
              FoodType.salad
            ].map((type) => ({
              value: type,
              label: type
            }))}
            error={!!errors.type}
            isDisabled={isDisabled}
          />
        </Box>
        <Box sx={{ width: '50%' }}>
          <SelectMUI
            label="Dietary Plan*"
            value={values.tags}
            data-test="exact-meal-tags"
            isMulti
            onChange={(data: any) => handleTagsChange(data)}
            customStyles={foodInformationFormMultiSelectCustomStyles(getTagColor, () => 'white')}
            options={Object.values(FoodDietType).map((tag) => ({
              value: tag,
              label: tag === FoodDietType.preBuiltCustom ? 'Pre-Built Custom' : startCase(tag)
            }))}
            error={!!errors.tags}
            isDisabled={isDisabled}
          />
        </Box>
      </Stack>
      <Typography
        sx={{
          color: caloTheme.palette.neutral900,
          textTransform: 'capitalize',
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '23px',
          marginTop: '20px'
        }}
      >
        Meal Specific Labels
      </Typography>
      <Stack direction="row" justifyContent="space-evenly" alignItems="baseline" spacing={2}>
        <Box sx={{ width: '20%' }}>
          <SelectMUI
            isMulti
            label="Protein"
            id="foodInfoProteinSelect"
            value={proteinTags}
            data-test="exact-meal-foodTags.protein"
            onChange={(data: any) => {
              setProteinTags(data.map((r) => r.value));
              setFieldValue(
                'foodTags',
                compact([
                  ...((values.foodTags as any) || []).filter((k: any) => k?.key !== FoodTagsKeys.protein),
                  ...(data.length > 0
                    ? [
                        {
                          key: FoodTagsKeys.protein,
                          value: data.map((d) => d.value)
                        }
                      ]
                    : [])
                ])
              );
            }}
            options={Object.values(ProteinFoodTags).map((protein) => ({
              value: protein,
              label: ProteinFoodTags[protein]
            }))}
            isDisabled={isDisabled}
            customStyles={foodInformationFormMultiSelectCustomStyles()}
          />
        </Box>
        <Box sx={{ width: '20%' }}>
          <SelectMUI
            isMulti
            label="Base"
            id="foodInfoBaseSelect"
            value={baseTags}
            data-test="exact-meal-foodTags-base"
            onChange={(data: any) => {
              setBaseTags(data.map((r) => r.value));
              setFieldValue(
                'foodTags',
                compact([
                  ...((values.foodTags as any) || []).filter((k: any) => k.key !== FoodTagsKeys.base),
                  ...(data.length > 0
                    ? [
                        {
                          key: FoodTagsKeys.base,
                          value: data.map((d) => d.value)
                        }
                      ]
                    : [])
                ])
              );
            }}
            options={Object.values(BaseFoodTags).map((base) => ({
              value: base,
              label: startCase(base)
            }))}
            isDisabled={isDisabled}
            customStyles={foodInformationFormMultiSelectCustomStyles()}
          />
        </Box>
        <Box sx={{ width: '20%' }}>
          <SelectMUI
            label="Convenience"
            value={convenience}
            data-test="exact-meal-foodTags-convenience"
            onChange={(data: any) => {
              setConvenience(data.value);
              setFieldValue(
                'foodTags',
                compact([
                  ...((values.foodTags as any) || []).filter((k: any) => k.key !== FoodTagsKeys.convenience),
                  ...(data.value ? [{ key: FoodTagsKeys.convenience, value: data.value }] : [])
                ])
              );
            }}
            options={[
              {
                label: 'None',
                value: undefined as any
              },
              ...Object.values(Convenience).map((convenience) => ({
                value: convenience,
                label: startCase(lowerCase(convenience))
              }))
            ]}
            isDisabled={isDisabled}
            customStyles={foodInformationFormSingleSelectCustomStyles}
          />
        </Box>
        <Box sx={{ width: '20%' }}>
          <SelectMUI
            label="Texture"
            value={texture}
            data-test="exact-meal-foodTags-texture"
            onChange={(data: any) => {
              setTexture(data.value);
              setFieldValue(
                'foodTags',
                compact([
                  ...((values.foodTags as any) || []).filter((k: any) => k.key !== FoodTagsKeys.texture),
                  ...(data.value ? [{ key: FoodTagsKeys.texture, value: data.value }] : [])
                ])
              );
            }}
            options={[
              {
                label: 'None',
                value: undefined as any
              },
              ...Object.values(Texture).map((texture) => ({
                value: texture,
                label: startCase(lowerCase(texture))
              }))
            ]}
            isDisabled={isDisabled}
            customStyles={foodInformationFormSingleSelectCustomStyles}
          />
        </Box>
        <Box sx={{ width: '20%' }}>
          <SelectMUI
            label="Heating Instruction"
            value={values.heatingInstruction}
            data-test="exact-meal-heating_instruction"
            id="heatingInstructionSelect"
            onChange={(data: any) => setFieldValue('heatingInstruction', data.value)}
            options={Object.values(HeatingInstruction).map((instruction) => ({
              value: instruction,
              label: resolveHeatingInstruction(instruction)
            }))}
            error={!!errors.heatingInstruction}
            isDisabled={isDisabled || brand === Brand.MEALO}
            customStyles={foodInformationFormSingleSelectCustomStyles}
          />
        </Box>
      </Stack>
      <Stack direction="row" justifyContent="space-evenly" alignItems="baseline" spacing={2}>
        <Box sx={{ width: values.type?.includes(FoodType.snack) ? '50%' : '100%' }}>
          <SelectMUI
            label="Spicy Level"
            value={spicyLevel}
            data-test="exact-meal-foodTags-spicyLevel"
            onChange={(data: any) => {
              setSpicyLevel(data.value);
              setFieldValue(
                'foodTags',
                compact([
                  ...((values.foodTags as any) || []).filter((k: any) => k.key !== FoodTagsKeys.spicyLevel),
                  ...(data.value ? [{ key: FoodTagsKeys.spicyLevel, value: data.value }] : [])
                ])
              );
            }}
            options={[
              {
                label: 'None',
                value: undefined as any
              },
              ...Object.values(SpiceLevel).map((spiceLevel) => ({
                value: spiceLevel,
                label: startCase(lowerCase(spiceLevel))
              }))
            ]}
            isDisabled={isDisabled}
            customStyles={foodInformationFormSingleSelectCustomStyles}
          />
        </Box>
        {values.type?.includes(FoodType.snack) && (
          <Box sx={{ width: '50%' }}>
            <SelectMUI
              isMulti
              label="Snack Type"
              value={snackTags}
              data-test="exact-meal-foodTags-snack"
              onChange={(data: any) => {
                setSnackTags(data.map((r) => r.value));
                setFieldValue(
                  'foodTags',
                  compact([
                    ...((values.foodTags as any) || []).filter((k: any) => k.key !== FoodTagsKeys.snacktype),
                    ...(data.length > 0
                      ? [
                          {
                            key: FoodTagsKeys.snacktype,
                            value: data.map((d) => d.value)
                          }
                        ]
                      : [])
                  ])
                );
              }}
              options={Object.values(SnackTypeTags).map((snack) => ({
                value: snack,
                label: startCase(snack)
              }))}
              isDisabled={isDisabled}
              customStyles={foodInformationFormMultiSelectCustomStyles()}
            />
          </Box>
        )}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ pb: 3, borderBottom: '1px solid ' + caloTheme.palette.neutral100 }}
      >
        <Box sx={{ width: '32%' }}>
          <SelectMUI
            label="Heating Instruction Unit"
            value={values.metadata?.heatingInstructionsUnit ?? ''}
            placeholder={isSnack(values) ? 'Seconds' : 'Minutes'}
            data-test="heating_instruction_unit"
            id="heatingInstructionUnit"
            onChange={(data: any) => setFieldValue('metadata.heatingInstructionsUnit', data.value)}
            options={[
              {
                value: 'minutes',
                label: 'Minutes'
              },
              {
                value: 'seconds',
                label: 'Seconds'
              }
            ]}
            error={!!errors.heatingInstruction}
            isDisabled={isDisabled || (formType === 'update' && !roles.includes(Permission.UPDATE_FOOD)) || brand === Brand.MEALO}
            customStyles={foodInformationFormSingleSelectCustomStyles}
          />
        </Box>
        <Box sx={{ width: '32%' }}>
          <InputMUI
            sx={{ marginTop: '18px', width: '100%' }}
            label="Heating Instructions From"
            name="heatingInstructionsFrom "
            type="number"
            placeholder={isSnack(values) ? '45' : '1'}
            value={values.metadata?.heatingInstructionsFrom ?? ''}
            debounce
            InputLabelProps={{
              shrink: true
            }}
            onChange={(data) => setFieldValue('metadata.heatingInstructionsFrom', data.target.value)}
            inputProps={{ style: { borderRadius: 8 }, dataTest: 'foodEnLongDescriptionTextField' }}
          />
        </Box>
        <Box sx={{ width: '32%' }}>
          <InputMUI
            sx={{ marginTop: '18px', width: '100%' }}
            label="Heating Instructions To"
            name="heatingInstructionsTo"
            placeholder={isSnack(values) ? '60' : '2'}
            type="number"
            value={values.metadata?.heatingInstructionsTo ?? ''}
            debounce
            InputLabelProps={{
              shrink: true
            }}
            onChange={(data) => setFieldValue('metadata.heatingInstructionsTo', data.target.value)}
            inputProps={{ style: { borderRadius: 8 }, dataTest: 'foodEnLongDescriptionTextField' }}
          />
        </Box>
      </Stack>
      <Typography
        sx={{
          color: caloTheme.palette.neutral900,
          textTransform: 'capitalize',
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '23px',
          marginTop: '20px'
        }}
      >
        Menu Labeling
      </Typography>
      <Stack direction="row" justifyContent="space-evenly" alignItems="baseline" spacing={2}>
        <Box sx={{ width: '100%' }}>
          <SelectMUI
            label="Labels"
            value={ratingTags}
            data-test="exact-meal-foodTags-rating"
            onChange={(data: any) => {
              setRatingTags(data.value);
              setFieldValue(
                'foodTags',
                compact([
                  ...((values.foodTags as any) || []).filter((k: any) => k.key !== FoodTagsKeys.rating),
                  ...(data.value ? [{ key: FoodTagsKeys.rating, value: data.value }] : [])
                ])
              );
            }}
            options={[
              {
                label: 'None',
                value: undefined as any
              },
              ...Object.values(NewRatedFoodTags).map((rate) => ({
                value: rate,
                label: startCase(NewRatedFoodTags[rate])
              }))
            ]}
            isDisabled={isDisabled}
            customStyles={foodInformationFormSingleSelectCustomStyles}
          />
          <Stack sx={{ flexDirection: 'row', gap: '12px', marginTop: '16px' }}>
            {automatedFoodTags.map((tag) => (
              <Typography
                key={tag}
                sx={{
                  borderRadius: 10,
                  paddingY: '8px',
                  paddingX: '12px',
                  backgroundColor: caloTheme.palette.neutral50,
                  fontWeight: 600,
                  color: caloTheme.palette.neutral700
                }}
              >
                {startCase(tag)}
              </Typography>
            ))}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

export default FoodInformationForm;
