import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { FoodComponent } from 'lib/interfaces';
import { Link } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';

interface FoodComponentRowProps {
  foodComponent: FoodComponent;
  index: number;
}

const FoodComponentRow = ({ foodComponent, index }: FoodComponentRowProps) => {
  const roles = useUserRoles();

  return (
    <tr>
      <td>
        {roles.includes(Permission.VIEW_FOOD_COMPONENTS) ? (
          <Link data-test={`foodComponentRow#${index}`} to={Routes.foodComponent.replace(':id', foodComponent.id)}>
            {foodComponent.name.en}
          </Link>
        ) : (
          foodComponent.name.en
        )}
      </td>
      <td>{foodComponent.weight}</td>
      <td>{foodComponent.macros.cal}</td>
      <td>{foodComponent.macros.protein}</td>
      <td>{foodComponent.macros.carbs}</td>
      <td>{foodComponent.macros.fat}</td>
      <td>{foodComponent.cost}</td>
      <td></td>
    </tr>
  );
};

export default FoodComponentRow;
