import { Kitchen } from '@calo/types';
import DateFnsAdapter from '@date-io/date-fns';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { CalendarPicker, LocalizationProvider, PickersDay, PickersDayProps, pickersDayClasses } from '@mui/x-date-pickers';
import { PickerOnChangeFn } from '@mui/x-date-pickers/internals/hooks/useViews';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components';
import Popup from 'components/Popup';
import { subDays } from 'date-fns';
import { format } from 'date-fns/fp';
import { Food } from 'lib/interfaces';
import React, { useEffect, useRef, useState } from 'react';

interface DuplicateMenuProps {
  kitchen: Kitchen;
  selectedFoods: Food[];
  handleClosePopup: () => void;
  duplicateMenuDate: Date;
  refetchDuplicateMenu: any;
  setDuplicateMenuDate: React.Dispatch<React.SetStateAction<Date>>;
}

const DuplicateMenu = ({
  kitchen,
  duplicateMenuDate,
  refetchDuplicateMenu,
  setDuplicateMenuDate,
  selectedFoods,
  handleClosePopup
}: DuplicateMenuProps) => {
  const duplicateMenuRef = useRef<ModalRef>();
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const openMenuRef = useRef<HTMLButtonElement | null>(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const handleOutsideClick = (e: any) => {
    if (
      showCalendar &&
      calendarRef.current &&
      !calendarRef.current.contains(e.target as Node) &&
      openMenuRef.current !== e.target
    ) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    if (showCalendar) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showCalendar]);

  const handleDaySelected: PickerOnChangeFn<Date> = (day) => {
    setDuplicateMenuDate(day as Date);
    duplicateMenuRef.current?.open();
  };

  const duplicateMenuHandler = () => {
    duplicateMenuRef.current?.close();
    refetchDuplicateMenu();
  };

  const shouldDisableDate = (day: Date, kitchen: Kitchen) => {
    let isDisabled = new Date() < day || day < subDays(new Date(), 30);
    switch (kitchen) {
      case Kitchen.OM1:
        isDisabled = true;
        break;
      default:
        isDisabled = false;
        break;
    }
    return isDisabled;
  };

  const handleRenderDay = (day: Date, DayComponentProps: PickersDayProps<Date>) => {
    const isDisabled = shouldDisableDate(day, kitchen);

    if (isDisabled) {
      return <PickersDay disabled={true} sx={{ backgroundColor: 'none' }} {...DayComponentProps} onClick={() => {}} />;
    } else {
      return (
        <PickersDay
          onClick={() => setDuplicateMenuDate(day)}
          sx={{
            backgroundColor: caloTheme.palette.primary100,
            color: 'black',
            ':hover': { color: 'black', backgroundColor: caloTheme.palette.neutral100 },
            [`&&.${pickersDayClasses.selected}`]: {
              border: 1,
              color: 'black',
              backgroundColor: caloTheme.palette.neutral100,
              borderColor: caloTheme.palette.primary600
            }
          }}
          {...DayComponentProps}
        />
      );
    }
  };

  return (
    <>
      <Stack>
        <Button
          ref={openMenuRef}
          variant="outlined"
          aria-label="new-subscription-cancel"
          sx={{
            height: '51px',
            lineHeight: '17px',
            fontWeight: 600,
            textTransform: 'none',
            mr: 2,
            fontSize: '18px',
            borderRadius: '8px',
            boxShadow: 'none',
            borderColor: caloTheme.palette.primary500,
            color: caloTheme.palette.primary500,
            position: 'relative',
            '&:hover': {
              boxShadow: 'none',
              borderColor: caloTheme.palette.primary600
            }
          }}
          onClick={() => setShowCalendar((prev) => !prev)}
        >
          Duplicate Menu
        </Button>
        {showCalendar && (
          <Box
            ref={calendarRef}
            sx={{
              position: 'absolute',
              top: '180px',
              ml: '-100px',
              zIndex: 100,
              backgroundColor: 'white',
              borderRadius: '10px',
              padding: '10px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'
            }}
          >
            <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Duplicate Menu From:</Typography>
            <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ mb: '4px' }}>
              <CalendarPicker
                renderDay={(day, _value, DayComponentProps) => handleRenderDay(new Date(day), DayComponentProps)}
                onChange={handleDaySelected}
                maxDate={subDays(new Date(), 1)}
                minDate={subDays(new Date(), 30)}
                date={duplicateMenuDate}
                shouldDisableDate={(day: Date) => shouldDisableDate(day, kitchen)}
              />
            </LocalizationProvider>
          </Box>
        )}
      </Stack>
      {duplicateMenuDate && (
        <Popup maxWidth="lg" ref={duplicateMenuRef} onClose={() => handleClosePopup()}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '700px' }}>
            <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography variant="h6" sx={{ fontSize: '28px', fontWeight: 600 }}>
                Duplicating from {format('EEE dd/MM/yyyy', duplicateMenuDate)}
              </Typography>
              <IconButton onClick={() => duplicateMenuRef.current?.close()}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Typography sx={{ fontSize: '16px', marginTop: '10px' }}>
              You are duplicating the menu of {format('EEEE dd/MM/yyyy', duplicateMenuDate)}. Are you sure you want to proceed?
              <br />
              {selectedFoods.length > 0 && 'This will clear your already selected Meals'}
            </Typography>
            <Button
              variant="outlined"
              onClick={duplicateMenuHandler}
              sx={{
                backgroundColor: caloTheme.palette.primary500,
                borderColor: caloTheme.palette.primary500,
                color: caloTheme.palette.white,
                textTransform: 'none',
                alignSelf: 'center',
                marginTop: '30px',
                '&:hover': {
                  boxShadow: 'none',
                  backgroundColor: caloTheme.palette.primary500,
                  borderColor: caloTheme.palette.primary500
                },
                '&:disabled': {
                  backgroundColor: caloTheme.palette.primary300
                }
              }}
            >
              Duplicate
            </Button>
          </Box>
        </Popup>
      )}
    </>
  );
};

export default DuplicateMenu;
