import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components';
import { FormOperation, Routes } from 'lib/enums';
import { formatKitchenText, resolveCountry } from 'lib/helpers';
import history from 'lib/history';
import { FoodComponent, UsedOnMenuReq } from 'lib/interfaces';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Stack, Typography } from '@mui/material';

import { Permission } from '@calo/dashboard-types';
import { deleteFoodComponent } from 'actions';
import { DeleteConfirmationPopup } from 'components';
import { useUserRoles } from 'lib/hooks';
import { useRef } from 'react';
import { useMutation } from 'react-query';
import ComponentNameAndUsage from './ComponentNameAndUsage';
import Container from './Container';

type ComponentHeaderCardProps = {
  isValid: boolean;
  isSubmitting: boolean;
  isEdit: boolean;
  route: Routes;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  isDisabled?: boolean;
} & (
  | {
      operation: FormOperation.update;
      foodComponent: FoodComponent | undefined;
      syncComponentsPopupRef?: React.MutableRefObject<ModalRef | undefined>;
      filteredFoodUsed: UsedOnMenuReq[];
      componentFoodList: any[];
      isFoodLoading: boolean;
    }
  | {
      operation: FormOperation.create;
    }
);

const ComponentHeaderCard = ({
  isValid,
  isSubmitting,
  isEdit,
  handleSubmit,
  isDisabled,
  route,
  ...props
}: ComponentHeaderCardProps) => {
  const roles = useUserRoles();
  const { mutateAsync: deleteMutation } = useMutation(deleteFoodComponent);
  const deletionRef = useRef<ModalRef>(null);

  const handleDeleteComponent = async () => {
    if (props.operation === FormOperation.update && props.foodComponent) {
      await deleteMutation({ id: props.foodComponent.id });
      history.push(Routes.foodComponentList);
    }
  };

  const handleClosePopup = () => {
    deletionRef.current?.close();
  };

  return (
    <>
      <Container sx={{ marginTop: '-35px', paddingBottom: 1 }}>
        <Stack
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                width: '100%'
              }
            }}
          >
            <Stack sx={{ marginLeft: '30px' }}>
              <Link to={route} style={{ color: 'black' }}>
                <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                  <ArrowBackIosIcon
                    style={{
                      fontSize: '14px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 'auto',
                      marginBottom: 'auto'
                    }}
                  />
                  <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>Back</Typography>
                </Stack>
              </Link>
            </Stack>
          </Stack>
          {props.operation === FormOperation.update && (
            <Typography
              variant="h5"
              sx={{
                fontWeight: 400,
                fontSize: '16px',
                border: 'none',
                color: '#000000',
                textTransform: 'none',
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  width: '100%',
                  mt: 1
                }
              }}
            >
              {capitalize(props.foodComponent?.brand)} - {resolveCountry(props.foodComponent?.country)} -{' '}
              {formatKitchenText(props.foodComponent?.kitchen)}
            </Typography>
          )}
        </Stack>
        <Stack
          sx={{
            marginX: 'auto',
            marginTop: '20px',
            marginBottom: '12px',
            width: '100%',
            height: '1px',
            backgroundColor: caloTheme.palette.neutral100
          }}
        />
      </Container>
      <Container sx={{ marginBottom: 2, paddingTop: 0, position: 'sticky', top: 0, zIndex: 500 }}>
        <Stack
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          {props.operation === FormOperation.update && (
            <ComponentNameAndUsage
              foodComponent={props.foodComponent}
              filteredFoodUsed={props.filteredFoodUsed}
              isDisabled={!!isDisabled}
            />
          )}
          {props.operation === FormOperation.create && (
            <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>Create Component</Typography>
          )}
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
            {props.operation === FormOperation.update && roles.includes(Permission.DELETE_FOOD_COMPONENT) && (
              <Button
                variant="outlined"
                disabled={isDisabled || props.isFoodLoading}
                sx={{
                  color: caloTheme.palette.neutral900,
                  fontSize: '16px',
                  fontWeight: 600,
                  textTransform: 'capitalize',
                  borderColor: caloTheme.palette.neutral900,
                  borderRadius: '8px',
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: caloTheme.palette.neutral900,
                    backgroundColor: caloTheme.palette.neutral900,
                    color: caloTheme.palette.white,
                    boxShadow: 'none'
                  }
                }}
                onClick={() => deletionRef.current?.open()}
              >
                {props.isFoodLoading ? 'loading' : 'Delete'}
              </Button>
            )}
            {props.operation === FormOperation.update && props.syncComponentsPopupRef && (
              <Button
                onClick={() => props.syncComponentsPopupRef?.current?.open()}
                disabled={isDisabled}
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  borderRadius: '8px',
                  borderColor: caloTheme.palette.primary500,
                  color: caloTheme.palette.primary500,
                  fontSize: '16px',
                  fontWeight: 600,
                  '&:hover': {
                    borderColor: caloTheme.palette.primary500,
                    color: caloTheme.palette.primary500
                  }
                }}
              >
                Update Components
              </Button>
            )}
            <Button
              data-test="addComponentButton"
              variant="contained"
              sx={{
                textTransform: 'none',
                fontWeight: 600,
                fontSize: '16px',
                borderRadius: '8px',
                backgroundColor: caloTheme.palette.primary500,
                borderColor: caloTheme.palette.primary500,
                boxShadow: 'none',
                color: 'white',
                '&:hover': {
                  boxShadow: 'none',
                  backgroundColor: caloTheme.palette.primary600,
                  borderColor: caloTheme.palette.primary600
                }
              }}
              disabled={isDisabled || !isValid || isSubmitting || isEdit}
              onClick={() => handleSubmit()}
            >
              {props.operation === FormOperation.create ? 'Add Component' : 'Save Changes'}
            </Button>
          </Stack>
        </Stack>
        {props.operation === FormOperation.update && (
          <DeleteConfirmationPopup
            type="component"
            deletionRef={deletionRef}
            typeUsage={
              props.componentFoodList.map((meal) => ({
                name: meal.name.en,
                id: meal.id
              })) || []
            }
            isLoading={props.isFoodLoading}
            onClose={handleClosePopup}
            onDelete={handleDeleteComponent}
          />
        )}
      </Container>
    </>
  );
};

export default ComponentHeaderCard;
