import { useFormik } from 'formik';
import { UpdateSubscriptionReq, Subscription } from '@calo/dashboard-types';

export default (subscription: Subscription, onSubmit: (values: UpdateSubscriptionReq) => any) => {
  const macros = subscription.macros;
  const macrosType = subscription.macrosType;
  const macrosPreferences = subscription.macrosPreferences;
  return useFormik<UpdateSubscriptionReq>({
    enableReinitialize: true,
    initialValues: {
      macros,
      macrosType,
      macrosPreferences
    },

    validate: (_: UpdateSubscriptionReq) => {
      const errors: any = {};

      // if (!values.name) {
      //   errors.name = true;
      // }

      // const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, 'BH');

      // if (!phoneNumber || (phoneNumber && !phoneNumber.isValid())) {
      //   errors.phoneNumber = true;
      // }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
};
