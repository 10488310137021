import { useRef } from 'react';

import { FormikBag, FormikProps, withFormik } from 'formik';

import { PricingService } from '@calo/services';
import { Currency } from '@calo/types';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components';
import Popup from 'components/Popup';

interface EditPendingFormProps {
  onSubmit: (amount: number) => Promise<void>;
  balance: number;
  currency: Currency;
}

const EditPendingForm = ({
  values,
  isValid,
  balance,
  currency,
  handleBlur,
  handleChange,
  handleSubmit
}: EditPendingFormProps & FormikProps<{ amount: number }>) => {
  const confirmModalRef = useRef<ModalRef>();

  return (
    <Box component="form" autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', mt: 2, width: '401px', mx: 'auto' }}>
      <TextField
        type="number"
        name="amount"
        label="Amount"
        sx={{ width: '401px' }}
        onBlur={handleBlur}
        value={values.amount}
        onChange={handleChange}
        placeholder="Enter Amount"
        id="exact-subscription-amount"
        InputProps={{ inputProps: { style: { borderRadius: 8 }, min: 0 }, style: { borderRadius: 8, width: '100%' } }}
      />

      <Stack
        display={'flex'}
        flexDirection={'row'}
        sx={{
          marginX: '4px',
          width: 'full',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: caloTheme.palette.neutral100
        }}
      >
        <Typography
          variant="caption"
          sx={{
            marginLeft: '2px',
            letterSpacing: -0.02,
            fontSize: '12px',
            lineHeight: '14px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 400,
            paddingX: 2,
            paddingY: 4,
            color: caloTheme.palette.neutral900,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto'
            }
          }}
        >
          Amount
        </Typography>
        <Typography
          variant="h1"
          sx={{
            letterSpacing: -0.02,
            fontSize: '14px',
            lineHeight: '17px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600,
            paddingX: 2,
            paddingY: 4,
            color: caloTheme.palette.neutral900,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto'
            }
          }}
        >
          {values.amount} {currency}
        </Typography>
      </Stack>
      <Stack
        display={'flex'}
        flexDirection={'row'}
        sx={{
          marginX: '4px',
          width: 'full',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: caloTheme.palette.neutral100
        }}
      >
        <Typography
          variant="caption"
          sx={{
            marginLeft: '2px',
            letterSpacing: -0.02,
            fontSize: '12px',
            lineHeight: '14px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 400,
            paddingX: 2,
            paddingY: 4,
            color: caloTheme.palette.neutral900,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto'
            }
          }}
        >
          VAT
        </Typography>
        <Typography
          variant="h1"
          sx={{
            letterSpacing: -0.02,
            fontSize: '14px',
            lineHeight: '17px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600,
            paddingX: 2,
            paddingY: 4,
            color: caloTheme.palette.neutral900,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto'
            }
          }}
        >
          {PricingService.getVATForNetAmount(values.amount, currency)} {currency}
        </Typography>
      </Stack>
      <Stack
        display={'flex'}
        flexDirection={'row'}
        sx={{
          marginX: '4px',
          width: 'full',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: caloTheme.palette.neutral100
        }}
      >
        <Typography
          variant="caption"
          sx={{
            marginLeft: '2px',
            letterSpacing: -0.02,
            fontSize: '12px',
            lineHeight: '14px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 400,
            paddingX: 2,
            paddingY: 4,
            color: caloTheme.palette.neutral900,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto'
            }
          }}
        >
          Amount incl. VAT
        </Typography>
        <Typography
          variant="h1"
          sx={{
            letterSpacing: -0.02,
            fontSize: '14px',
            lineHeight: '17px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600,
            paddingX: 2,
            paddingY: 4,
            color: caloTheme.palette.neutral900,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto'
            }
          }}
        >
          {PricingService.roundAmount(
            (values.amount || 0) + PricingService.getVATForNetAmount(values.amount || 0, currency),
            currency
          )}{' '}
          {currency}
        </Typography>
      </Stack>
      <Stack
        display={'flex'}
        flexDirection={'row'}
        sx={{
          marginX: '4px',
          width: 'full',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: caloTheme.palette.neutral100
        }}
      >
        {' '}
        <Typography
          variant="caption"
          sx={{
            marginLeft: '2px',
            letterSpacing: -0.02,
            fontSize: '12px',
            lineHeight: '14px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 400,
            paddingX: 2,
            paddingY: 4,
            color: caloTheme.palette.neutral900,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto'
            }
          }}
        >
          Current Balance
        </Typography>
        <Typography
          variant="h1"
          sx={{
            letterSpacing: -0.02,
            fontSize: '14px',
            lineHeight: '17px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600,
            paddingX: 2,
            paddingY: 4,
            color: caloTheme.palette.neutral900,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto'
            }
          }}
        >
          {balance} {currency}
        </Typography>
      </Stack>
      <Stack
        display={'flex'}
        flexDirection={'row'}
        sx={{
          marginX: '4px',
          width: 'full',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: caloTheme.palette.neutral100
        }}
      >
        <Typography
          variant="caption"
          sx={{
            marginLeft: '2px',
            marginX: '4px',
            letterSpacing: -0.02,
            fontSize: '12px',
            lineHeight: '14px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 400,
            paddingX: 2,
            paddingY: 4,
            color: caloTheme.palette.neutral900,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto'
            }
          }}
        >
          New Balance
        </Typography>
        <Typography
          variant="h1"
          sx={{
            letterSpacing: -0.02,
            fontSize: '14px',
            lineHeight: '17px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600,
            paddingX: 2,
            paddingY: 4,
            color: caloTheme.palette.neutral900,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto'
            }
          }}
        >
          {values.amount > 0 ? PricingService.roundAmount(balance + values.amount, currency) : balance} {currency}
        </Typography>
      </Stack>
      <Stack display={'flex'} flexDirection={'row'} sx={{ width: '100%', justifyContent: 'center' }}>
        <Button
          variant="contained"
          aria-label="addCredit"
          sx={{
            width: '100%',
            height: '51px',
            marginX: '4px',
            marginTop: 2,
            lineHeight: '17px',
            fontWeight: 600,
            fontSize: '14px',
            borderRadius: '8px',
            backgroundColor: caloTheme.palette.primary500,
            borderColor: caloTheme.palette.primary500,
            color: 'white',
            '&:hover': {
              backgroundColor: caloTheme.palette.primary600,
              borderColor: caloTheme.palette.primary600
            }
          }}
          onClick={() => confirmModalRef.current?.open()}
          disabled={!isValid || values.amount < 0}
        >
          Confirm
        </Button>
      </Stack>

      <Popup title="Confirm Details" ref={confirmModalRef} onClose={() => confirmModalRef.current?.close()}>
        <Stack display={'flex'} flexDirection={'column'} sx={{ marginX: '4px', width: 'full' }}>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            sx={{
              marginX: '4px',
              width: 'full',
              justifyContent: 'space-between',
              borderBottom: 1,
              borderColor: caloTheme.palette.neutral100
            }}
          >
            <Typography
              variant="caption"
              sx={{
                marginLeft: '2px',
                letterSpacing: -0.02,
                fontSize: '12px',
                lineHeight: '14px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 400,
                paddingX: 2,
                paddingY: 4,
                color: caloTheme.palette.neutral900,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  width: 'auto'
                }
              }}
            >
              Amount
            </Typography>
            <Typography
              variant="h1"
              sx={{
                marginRight: 2,
                letterSpacing: -0.02,
                fontSize: '14px',
                lineHeight: '17px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                paddingX: 2,
                paddingY: 4,
                color: caloTheme.palette.neutral900,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  width: 'auto'
                }
              }}
            >
              {values.amount} {currency}
            </Typography>
          </Stack>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            sx={{
              marginX: '4px',
              width: 'full',
              justifyContent: 'space-between',
              borderBottom: 1,
              borderColor: caloTheme.palette.neutral100
            }}
          >
            <Typography
              variant="caption"
              sx={{
                marginLeft: '2px',
                letterSpacing: -0.02,
                fontSize: '12px',
                lineHeight: '14px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 400,
                paddingX: 2,
                paddingY: 4,
                color: caloTheme.palette.neutral900,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  width: 'auto'
                }
              }}
            >
              VAT
            </Typography>
            <Typography
              variant="h1"
              sx={{
                marginRight: 2,
                letterSpacing: -0.02,
                fontSize: '14px',
                lineHeight: '17px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                paddingX: 2,
                paddingY: 4,
                color: caloTheme.palette.neutral900,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  width: 'auto'
                }
              }}
            >
              {PricingService.getVATForNetAmount(values.amount, currency)} {currency}
            </Typography>
          </Stack>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            sx={{
              marginX: '4px',
              width: 'full',
              justifyContent: 'space-between',
              borderBottom: 1,
              borderColor: caloTheme.palette.neutral100
            }}
          >
            <Typography
              variant="caption"
              sx={{
                marginLeft: '2px',
                letterSpacing: -0.02,
                fontSize: '12px',
                lineHeight: '14px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 400,
                paddingX: 2,
                paddingY: 4,
                color: caloTheme.palette.neutral900,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  width: 'auto'
                }
              }}
            >
              Current Balance
            </Typography>
            <Typography
              variant="h1"
              sx={{
                marginRight: 2,
                letterSpacing: -0.02,
                fontSize: '14px',
                lineHeight: '17px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                paddingX: 2,
                paddingY: 4,
                color: caloTheme.palette.neutral900,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  width: 'auto'
                }
              }}
            >
              {balance} {currency}
            </Typography>
          </Stack>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            sx={{
              marginX: '4px',
              width: 'full',
              justifyContent: 'space-between',
              borderBottom: 1,
              borderColor: caloTheme.palette.neutral100
            }}
          >
            <Typography
              variant="caption"
              sx={{
                marginLeft: '2px',
                letterSpacing: -0.02,
                fontSize: '12px',
                lineHeight: '14px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 400,
                paddingX: 2,
                paddingY: 4,
                color: caloTheme.palette.neutral900,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  width: 'auto'
                }
              }}
            >
              New Balance
            </Typography>
            <Typography
              variant="h1"
              sx={{
                marginRight: 2,
                letterSpacing: -0.02,
                fontSize: '14px',
                lineHeight: '17px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                paddingX: 2,
                paddingY: 4,
                color: caloTheme.palette.neutral900,
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  width: 'auto'
                }
              }}
            >
              {values.amount > 0 ? PricingService.roundAmount(balance + values.amount, currency) : balance} {currency}
            </Typography>
          </Stack>
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <Button
              variant="text"
              aria-label="addCredit"
              sx={{
                width: '100%',
                height: '51px',
                marginX: '4px',
                marginTop: 2,
                lineHeight: '17px',
                fontWeight: 600,
                fontSize: '14px',
                borderRadius: '8px',
                color: caloTheme.palette.neutral600,
                '&:hover': {
                  backgroundColor: caloTheme.palette.neutral100
                }
              }}
              onClick={() => {
                confirmModalRef.current?.close();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              aria-label="addCredit"
              sx={{
                width: '100%',
                height: '51px',
                marginX: '4px',
                marginTop: 2,
                lineHeight: '17px',
                fontWeight: 600,
                fontSize: '14px',
                borderRadius: '8px',
                backgroundColor: caloTheme.palette.primary500,
                borderColor: caloTheme.palette.primary500,
                color: 'white',
                '&:hover': {
                  backgroundColor: caloTheme.palette.primary600,
                  borderColor: caloTheme.palette.primary600
                }
              }}
              onClick={() => handleSubmit()}
            >
              Add Credits
            </Button>
          </Stack>
        </Stack>
      </Popup>
    </Box>
  );
};

export default withFormik<EditPendingFormProps, { amount: number }>({
  mapPropsToValues: () => ({
    amount: 0
  }),

  validate: (_: { amount: number }) => {
    return {};
  },
  handleSubmit: async (values, { props }: FormikBag<EditPendingFormProps, { amount: number }>) => {
    try {
      await props.onSubmit(values.amount);
    } catch (error) {
      console.log(error);
    }
  }
})(EditPendingForm);
