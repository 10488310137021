import { useFormik } from 'formik';

import { UpdateSubscriptionReq } from '@calo/dashboard-types';

export default (subscription: UpdateSubscriptionReq, onSubmit: (values: UpdateSubscriptionReq) => Promise<void> | void) =>
  useFormik<UpdateSubscriptionReq>({
    enableReinitialize: true,
    initialValues: {
      name: subscription.name,
      autoRenew: subscription.autoRenew,
      macrosType: subscription.macrosType,
      dietType: subscription.dietType,
      email: subscription.email,
      deliveryTime: subscription.deliveryTime,
      paymentMethod: subscription.paymentMethod,
      macrosData: subscription.macrosData,
      withCutlery: subscription.withCutlery,
      withCoolerBag: subscription.withCoolerBag
    },
    validate: (values: UpdateSubscriptionReq) => {
      const errors: Partial<Record<keyof UpdateSubscriptionReq, boolean>> = {};

      if (!values.name) {
        errors.name = true;
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
