import { useState } from 'react';

import cx from 'classnames';
import { useMutation } from 'react-query';

import { Permission, Subscription, UpdateSubscriptionReq } from '@calo/dashboard-types';
import { Currency, DeliveryTime, PaymentMethod } from '@calo/types';

import { updateSubscription } from 'actions';
import { Button, Input, Select } from 'components';
import { useUserRoles } from 'lib/hooks';
import useSettingsForm from './useSettingsForm';

interface SettingsCardProps {
  subscription: Subscription;
}

const SettingsCard = ({ subscription }: SettingsCardProps) => {
  const [editing, setEditing] = useState(false);

  const { mutateAsync: updateMutation } = useMutation(updateSubscription);

  const onSubmit = async (data: UpdateSubscriptionReq) => {
    await updateMutation({
      id: subscription.id,
      ...data
    });
    setEditing(false);
  };

  const { values, isSubmitting, handleChange, handleBlur, isValid, submitForm, setFieldValue } = useSettingsForm(
    subscription,
    onSubmit
  );
  const roles = useUserRoles();

  const toggleOption = (value: boolean, action: string) => (
    <div className="mt-2 flex">
      <label
        className={cx(' cursor-pointer inline-block w-12 p-1 rounded-full', {
          'toggle-left bg-green-500': value && editing,
          'toggle-right bg-red-500': !value && editing,
          'bg-gray-300': !editing
        })}
      >
        <input
          type="checkbox"
          className="hidden"
          disabled={!editing}
          checked={value}
          onChange={() => setFieldValue(`${action}`, value === true ? false : true)}
        />
        <div
          className={cx('h-5 w-5 rounded-full bg-white transition-all right-0', {
            'ml-5': value
          })}
        ></div>
      </label>
      <div className="mt-2 ml-2">
        <p>{value ? 'ON' : 'OFF'}</p>
      </div>
    </div>
  );

  const subscriberPaymentOptions = (currency: Currency) => {
    switch (currency) {
      case Currency.AED:
        return [
          {
            label: PaymentMethod.cash,
            value: PaymentMethod.cash
          },
          {
            label: PaymentMethod.cc,
            value: PaymentMethod.cc
          }
        ];
      case Currency.BHD:
        return [
          {
            label: PaymentMethod.cash,
            value: PaymentMethod.cash
          },
          {
            label: PaymentMethod.cc,
            value: PaymentMethod.cc
          },
          {
            label: PaymentMethod.benefit,
            value: PaymentMethod.benefit
          }
        ];
      case Currency.SAR:
        return [
          {
            label: PaymentMethod.cash,
            value: PaymentMethod.cash
          },
          {
            label: PaymentMethod.cc,
            value: PaymentMethod.cc
          },
          {
            label: PaymentMethod.applePay,
            value: PaymentMethod.applePay
          },
          {
            label: PaymentMethod.mada,
            value: PaymentMethod.mada
          }
        ];
      case Currency.KWD:
        return [
          {
            label: PaymentMethod.cash,
            value: PaymentMethod.cash
          },
          {
            label: PaymentMethod.cc,
            value: PaymentMethod.cc
          },
          {
            label: PaymentMethod.knet,
            value: PaymentMethod.knet
          }
        ];
      case Currency.QAR:
        return [
          {
            label: PaymentMethod.cash,
            value: PaymentMethod.cash
          },
          {
            label: PaymentMethod.cc,
            value: PaymentMethod.cc
          },
          {
            label: PaymentMethod.naps,
            value: PaymentMethod.naps
          }
        ];
      default:
        return [
          {
            label: PaymentMethod.cash,
            value: PaymentMethod.cash
          },
          {
            label: PaymentMethod.cc,
            value: PaymentMethod.cc
          }
        ];
    }
  };

  return (
    <div className="tile is-parent">
      <div className="card">
        <header className="card-header bg-black text-white">
          <p className="card-header-title text-white">Settings</p>
          {roles.includes(Permission.UPDATE_SUBSCRIPTION) && (
            <a href="#" className="card-header-icon" onClick={() => setEditing(!editing)}>
              <span className="icon">
                <i className="fas fa-edit hover: text-white focus:text-white"></i>
              </span>
            </a>
          )}
        </header>
        <div className="card-content">
          <Input disabled={!editing} label="Name" value={values.name} name="name" onChange={handleChange} onBlur={handleBlur} />

          <label className="label text-black text-md mt-2">AutoRenew</label>
          {toggleOption(!!values.autoRenew, 'autoRenew')}

          <label className="label text-black text-md mt-2">Cutlery</label>
          {toggleOption(!!values.withCutlery, 'withCutlery')}

          <label className="label text-black text-md mt-2">Cooler Bag</label>
          {toggleOption(!!values.withCoolerBag, 'withCoolerBag')}

          {editing && (
            <Button
              type="submit"
              className="my-4"
              primary
              fluid
              onClick={submitForm}
              loading={isSubmitting}
              disabled={!isValid || isSubmitting}
              content="Save"
            />
          )}

          <Select
            label="Payment method"
            value={values.paymentMethod}
            isDisabled={!editing || subscription.paymentMethod === PaymentMethod.cash}
            options={subscriberPaymentOptions(subscription.currency)}
            onChange={(data: any) => setFieldValue('paymentMethod', data.value)}
          />

          <Input
            disabled={!editing}
            label="E-mail"
            value={values.email}
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Select
            label="Delivery time"
            value={values.deliveryTime}
            options={[
              { value: DeliveryTime.morning, label: 'Morning' },
              { value: DeliveryTime.evening, label: 'Evening' }
            ]}
            onChange={(data: any) => setFieldValue('deliveryTime', data.value)}
            isDisabled={!editing}
          />
          <Input
            label="Date of Birth"
            className="w-full"
            onChange={(date) => setFieldValue('macrosData.dob', date.target.value)}
            value={values.macrosData?.dob ? values.macrosData.dob : undefined}
            disabled={!editing}
            type="date"
          />
          <div className="field">
            <label className="label">First Day</label>
            <div className="control is-clearfix">
              <input type="text" readOnly={true} className="input is-static" value={subscription.startedAt} />
            </div>
          </div>
          <div className="field">
            <label className="label">Cycle Started On</label>
            <div className="control is-clearfix">
              <input type="text" readOnly={true} className="input is-static" value={subscription.cycleStartedAt} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsCard;
