import { LinkedComponent } from '@calo/dashboard-types';
import { Kitchen } from '@calo/types';
import { getWeightedAverage } from 'lib/helpers';
import { FoodComponent, FoodComponentWithQuantity } from 'lib/interfaces';
import { keyBy, round, sumBy } from 'lodash';

export interface MealSizeWithCost {
  size: string;
  value: number;
}

export const findPopulatedComponents = (components: LinkedComponent[], fullyMappedComps: FoodComponent[]) => {
  const keyedComponents = keyBy(fullyMappedComps, 'id');
  const populatedComps: FoodComponentWithQuantity[] = [];
  for (const comp of components) {
    const component = keyedComponents[comp.id];

    if (!component) {
      continue;
    }

    populatedComps.push({
      ...component,
      quantity: comp.quantity
    });
  }

  return populatedComps;
};

export const calculatePercentageCost = (mealCostsAllSizes: MealSizeWithCost[], kitchen: Kitchen, sellingPrice: number) => {
  const weightedAverageCost =
    mealCostsAllSizes.length < 3
      ? sumBy(mealCostsAllSizes, (mealCost) => mealCost.value) / mealCostsAllSizes.length
      : sumBy(mealCostsAllSizes, (mealCost) => mealCost.value * getWeightedAverage(kitchen, mealCost.size));

  const costPercentage = round((weightedAverageCost * 100) / sellingPrice, 1) || 0;

  return costPercentage;
};
