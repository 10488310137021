import { toast } from 'react-toastify';

import {
  CreateAddonsMenuReq,
  CreateAddonsSubscriptionMenuReq,
  CreateMenuReq,
  DesignMenuReq,
  MenuGenerationQuery,
  UpdateAddonsMenuReq,
  UpdateAddonsSubscriptionMenuReq,
  UpdateMenuReq
} from '@calo/dashboard-types';
import { Brand, Country, Kitchen } from '@calo/types';

import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import { Menu, MenuDraft } from 'lib/interfaces';
import mutation from './mutation';

export const createMenu = async (attr: CreateMenuReq) => {
  const { data, status } = await client.post(`menu`, attr);
  mutation([`${attr.brand}/${attr.kitchen}/menu`, data.id], data);
  ToastSuccessMsgs({ status, action: 'Menu created' });
  return data;
};

export const updateMenu = async (payload: UpdateMenuReq & { id: string; mKitchen: Kitchen; mBrand: Brand; draft: MenuDraft }) => {
  const { id, mKitchen, mBrand, draft, ...rest } = payload;
  const { data, status } = await client.put(`menu/${id}`, {
    day: rest.day,
    food: rest.food,
    tags: rest.tags,
    brand: mBrand,
    kitchen: mKitchen,
    draft: draft
  });
  ToastSuccessMsgs({ status, action: `Menu ${id} updated` });
  mutation([`${mBrand}/${mKitchen}/menu`, id], data);
  return data;
};

export const generateMenu = async (payload: Menu & { id: string; kitchen: Kitchen; brand: Brand }) => {
  const { id, kitchen, brand } = payload;
  const { data, status } = await client.post(
    `menu/${id}/generate`,
    {},
    {
      params: { brand: brand, kitchen: kitchen }
    }
  );
  ToastSuccessMsgs({ status, action: 'Menu generation in progress' });
  mutation([`${kitchen}/menu`, id], { ...payload, generatedAt: data.generatedAt });
  return data;
};

export const createAddons = async (values: CreateAddonsMenuReq) => {
  const { data, status } = await client.post('addons-menu', values);
  if (data) {
    ToastSuccessMsgs({ status, action: 'Addons created' });
  } else {
    toast(`Menu for week ${values.week} already exists`, { type: 'error', autoClose: 2000 });
  }
  mutation(['addons-menu', data.id], data);
  return data;
};

export const updateAddons = async (
  payload: UpdateAddonsMenuReq & { id: string; country: Country; brand: Brand; kitchen: Kitchen }
) => {
  const { id, country: _country, brand, kitchen, ...rest } = payload;
  const { data, status } = await client.put(`addons-menu/${id}?kitchen=${kitchen}`, {
    food: rest.food,
    brand: brand
  });
  ToastSuccessMsgs({ status, action: 'Addons updated' });
  mutation(['addons-menu', id], data);
};

export const createAddonsSubscription = async (values: CreateAddonsSubscriptionMenuReq) => {
  const { data, status } = await client.post('addons-subscription-menu', values);
  if (data) {
    ToastSuccessMsgs({ status, action: 'Addons created' });
  } else {
    toast(`Menu already exists, please refresh the page and try again`, { type: 'error', autoClose: 2000 });
  }
  mutation(['addons-subscription-menu'], data);
  return data;
};

export const updateAddonsSubscription = async (
  payload: UpdateAddonsSubscriptionMenuReq & { country: Country; brand: Brand; kitchen: Kitchen }
) => {
  const { country: _country, brand, kitchen, ...rest } = payload;
  const { data, status } = await client.put(`addons-subscription-menu`, {
    food: rest.food,
    brand: brand,
    kitchen: kitchen
  });
  ToastSuccessMsgs({ status, action: 'Addons updated' });
  mutation(['addons-subscription-menu'], data);
};

export const getGenerationProgress = async (payload: MenuGenerationQuery) => {
  const { filters, limit } = payload;
  const { data } = await client.get('menu-generations', { params: { filters, limit } });
  return data;
};

export const populateMenu = async (attr: DesignMenuReq) => {
  const { data, status } = await client.post(`menu/design`, attr);
  ToastSuccessMsgs({ status, action: 'Populate Menu created' });
  return data;
};

export const getMenu = async (context: any) => {
  const { data } = await client.get(context.queryKey[0], { params: context.queryKey[1] });
  return data;
};
