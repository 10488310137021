import React from 'react';
import { Subscription } from '@calo/dashboard-types';

interface MacrosStatsProps {
  subscription: Subscription;
}

const MacrosStats = ({ subscription }: MacrosStatsProps) => {
  const { macros } = subscription;

  return (
    <div className="level my-4">
      <div className="flex opacity-80 rounded-2xl  w-full mr-8 h-28 border-l-8 border-blue-500">
        <div className="card tile rounded-2xl">
          <div className="card-content -mt-4">
            <div className="is-widget-label">
              <h3 className="subtitle text-black">Cal</h3>
              <p className="text-blue-500 text-3xl -mt-2">{macros.cal}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex opacity-80 rounded-2xl  w-full mr-8 h-28 border-l-8  border-yellow-500">
        <div className="card tile rounded-2xl">
          <div className="card-content -mt-4">
            <div className="is-widget-label">
              <h3 className="subtitle text-black">Carbs</h3>
              <p className="text-yellow-500 text-3xl -mt-2">
                {macros.carbs.min} - {macros.carbs.max}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex opacity-80 rounded-2xl  w-full mr-8 h-28 border-l-8  border-red-500">
        <div className="card tile rounded-2xl">
          <div className="card-content -mt-4">
            <div className="is-widget-label">
              <h3 className="subtitle text-black">Fat</h3>
              <p className="text-red-500 text-3xl -mt-2">
                {macros.fat.min} - {macros.fat.max}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex opacity-80 rounded-2xl  w-full mr-8 h-28 border-l-8  border-green-500">
        <div className="card tile rounded-2xl">
          <div className="card-content -mt-4">
            <div className="is-widget-label">
              <h3 className="subtitle text-black">Protein</h3>
              <p className="text-green-500 text-3xl -mt-2">
                {macros.protein.min} - {macros.protein.max}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MacrosStats;
