import { Permission, Subscription } from '@calo/dashboard-types';
import { SubscriptionFrequency } from '@calo/types';
import { HorizontalRule } from '@mui/icons-material';
import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { caloTheme } from 'assets/images/theme/calo';
import { format, parseISO } from 'date-fns/fp';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Link } from 'react-router-dom';

interface SubscriptionRowProps {
  subscription: Subscription;
}

const SubscriptionRow = ({ subscription }: SubscriptionRowProps) => {
  const roles = useUserRoles();

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      color: caloTheme.palette.neutral900,
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none'
    }
  }));

  return (
    <TableRow key={subscription.id} hover>
      <StyledTableCell
        component="th"
        scope="row"
        sx={{
          textOverflow: 'ellipsis'
        }}
      >
        <Typography
          component="span"
          sx={{
            width: '123px',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'inline-block',
            textOverflow: 'ellipsis',
            fontFamily: caloTheme.typography.fontFamily
          }}
        >
          {roles.includes(Permission.VIEW_SUBSCRIPTION) || roles.includes(Permission.VIEW_DELIVERY_SUBSCRIPTION) ? (
            <Link to={Routes.subscription2.replace(':id', subscription.id)}>{subscription.name}</Link>
          ) : (
            subscription.name
          )}
        </Typography>
      </StyledTableCell>

      <StyledTableCell>{subscription.phoneNumber}</StyledTableCell>

      <StyledTableCell>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            fontFamily: caloTheme.typography.fontFamily
          }}
        >
          {subscription.email}
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography
          sx={{
            fontFamily: caloTheme.typography.fontFamily,
            fontSize: '12px',
            lineHeight: '14px',
            fontWeight: 400
          }}
        >
          {subscription.pendingAmount[subscription.currency]
            ? subscription.balance[subscription.currency]
            : subscription.balance[subscription.currency]}
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography
          sx={{
            fontFamily: caloTheme.typography.fontFamily,
            fontSize: '12px',
            lineHeight: '14px',
            fontWeight: 400
          }}
        >
          {subscription.paymentBalance || <HorizontalRule />}
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography
          sx={{
            fontFamily: caloTheme.typography.fontFamily,
            fontSize: '12px',
            lineHeight: '14px',
            fontWeight: 400
          }}
        >
          {subscription.paymentBalance || <HorizontalRule />}
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography
          sx={{
            fontFamily: caloTheme.typography.fontFamily,
            fontSize: '12px',
            lineHeight: '19px',
            fontWeight: 400
          }}
        >
          {subscription.lastDeliveredDate ? format('dd/MM/yyyy')(parseISO(subscription.lastDeliveredDate)) : '----'}
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        {subscription.plan?.frequency ? (
          (subscription.plan?.frequency === SubscriptionFrequency.quarterly && (
            <Stack
              sx={{
                width: '76px',
                height: '33px',
                minWidth: '76px',
                borderRadius: '37px',
                padding: '8px, 12px, 8px, 12px',
                backgroundColor: caloTheme.palette.secondaryOrange100
              }}
            >
              <Typography
                sx={{
                  ml: '4px',
                  m: 'auto',
                  width: '46px',
                  height: '17px',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '17px',
                  textTransform: 'capitalize',
                  fontFamily: caloTheme.typography.fontFamily,
                  color: caloTheme.palette.secondaryOrange700
                }}
              >
                {subscription.plan.frequency}
              </Typography>
            </Stack>
          ),
          subscription.plan?.frequency === SubscriptionFrequency.monthly && (
            <Stack
              sx={{
                width: '76px',
                height: '33px',
                minWidth: '76px',
                borderRadius: '37px',
                padding: '8px, 12px, 8px, 12px',
                backgroundColor: caloTheme.palette.secondaryBlue100
              }}
            >
              <Typography
                sx={{
                  ml: '4px',
                  m: 'auto',
                  width: '46px',
                  height: '17px',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '17px',
                  textTransform: 'capitalize',
                  fontFamily: caloTheme.typography.fontFamily,
                  color: caloTheme.palette.secondaryBlue700
                }}
              >
                {subscription.plan.frequency}
              </Typography>
            </Stack>
          ),
          subscription.plan?.frequency === SubscriptionFrequency.weekly && (
            <Stack
              sx={{
                width: '76px',
                height: '33px',
                minWidth: '76px',
                borderRadius: '37px',
                padding: '8px, 12px, 8px, 12px',
                backgroundColor: caloTheme.palette.secondaryYellow100
              }}
            >
              <Typography
                sx={{
                  ml: '4px',
                  m: 'auto',
                  width: '46px',
                  height: '17px',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '17px',
                  textTransform: 'capitalize',
                  fontFamily: caloTheme.typography.fontFamily,
                  color: caloTheme.palette.secondaryYellow700
                }}
              >
                {subscription.plan.frequency}
              </Typography>
            </Stack>
          ))
        ) : (
          <Typography sx={{ ml: 3 }}>---</Typography>
        )}
      </StyledTableCell>

      <StyledTableCell>
        <Typography
          sx={{
            fontFamily: caloTheme.typography.fontFamily,
            fontSize: '12px',
            lineHeight: '14px',
            fontWeight: 400,
            color: caloTheme.palette.neutral900
          }}
        >
          {subscription.kitchen || '---'}
        </Typography>
      </StyledTableCell>
    </TableRow>
  );
};
export default SubscriptionRow;
