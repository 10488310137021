import { caloTheme } from 'assets/images/theme/calo';
import InputThemeProvider from 'components/MUI/InputThemeProvider';
import SelectMUI from 'components/MUI/SelectMUI';
import { FormikErrors, FormikTouched } from 'formik';
import { foodInformationFormSingleSelectCustomStyles } from 'lib/componentStyles';
import { getAccessibleCountries, selectCountry } from 'lib/helpers';
import { getKitchenOptions } from 'lib/helpers/kitchenUtils';
import { useUserKitchens } from 'lib/hooks';
import { BaseOmit, FoodComponent } from 'lib/interfaces';

import { Kitchen } from '@calo/types';
import { Card, Stack, TextField, Typography } from '@mui/material';

interface ComponentNameAndImageProps {
  isSubmitting: boolean;
  values: Omit<FoodComponent, BaseOmit>;
  errors: FormikErrors<Omit<FoodComponent, BaseOmit>>;
  touched: FormikTouched<Omit<FoodComponent, BaseOmit>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Omit<FoodComponent, BaseOmit>>> | Promise<any>;
  handleBlur: { (e: React.FocusEvent<any, Element>): void };
  handleChange: { (e: React.ChangeEvent<any>): void };
}

const ComponentInformationCard = ({
  isSubmitting,
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleBlur
}: ComponentNameAndImageProps) => {
  const userKitchens: Kitchen[] = useUserKitchens();

  return (
    <Card
      variant="outlined"
      sx={{
        width: 'full',
        border: 'none',
        px: '12px',
        py: '18px',
        borderRadius: '16px',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          flexDirection: 'column'
        }
      }}
    >
      <Typography sx={{ fontSize: '19px', fontWeight: 600 }}>Component Information</Typography>
      <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Stack sx={{ width: '100%', mx: 2 }}>
          <InputThemeProvider>
            <Stack
              sx={{
                mt: 3,
                mb: 2,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: 2
              }}
            >
              <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2 }}>
                <Stack sx={{ width: '50%' }}>
                  <TextField
                    data-test="foodComponentEnNameTextField"
                    label="Name EN*"
                    name="name.en"
                    id="name.en"
                    placeholder="Enter EN-Name"
                    value={values.name.en}
                    sx={{ width: '100%' }}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name?.en && !!errors.name?.en}
                  />
                </Stack>
                <Stack sx={{ width: '50%' }}>
                  <TextField
                    data-test="foodComponentArNameTextField"
                    label="Name AR*"
                    name="name.ar"
                    id="name.ar"
                    placeholder="Enter AR-Name"
                    value={values.name.ar}
                    sx={{ width: '100%' }}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name?.ar && !!errors.name?.ar}
                    inputProps={{ inputProps: { style: { borderRadius: '16px' } }, style: { borderRadius: '16px' } }}
                  />
                </Stack>
              </Stack>
              <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2 }}>
                <Stack sx={{ width: '50%' }}>
                  <SelectMUI
                    data-test="foodComponentCountrySelect"
                    label="Country*"
                    value={values.country}
                    onChange={(data: any) =>
                      selectCountry({
                        value: data.value,
                        kitchens: userKitchens,
                        setFieldValue
                      })
                    }
                    options={getAccessibleCountries(userKitchens).map((type) => ({
                      value: type,
                      label: type
                    }))}
                    customStyles={foodInformationFormSingleSelectCustomStyles}
                  />
                </Stack>
                <Stack sx={{ width: '50%' }}>
                  <SelectMUI
                    data-test="foodComponentKitchenSelect"
                    label="Kitchen*"
                    value={values.kitchen}
                    onChange={(data: any) => setFieldValue('kitchen', data.value)}
                    options={getKitchenOptions(userKitchens, values.country).filter((option) => option.label !== 'Any')}
                    error={!!errors.kitchen}
                    customStyles={foodInformationFormSingleSelectCustomStyles}
                  />
                </Stack>
              </Stack>
            </Stack>
          </InputThemeProvider>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ComponentInformationCard;
