import { Permission, Subscription, UpdateSubscriptionReq } from '@calo/dashboard-types';
import { MacrosService } from '@calo/services';
import { ActivityLevel, MacrosPreset, MacrosType } from '@calo/types';
import { updateSubscription } from 'actions';
import { Button, Input, Select2 } from 'components';
import { useUserRoles } from 'lib/hooks';
import { useState } from 'react';
import { useMutation } from 'react-query';
import useMacrosForm from './useMacrosForm';

interface DeliveriesPanelProps {
  subscription: Subscription;
}

const MacrosCard = ({ subscription }: DeliveriesPanelProps) => {
  const [editing, setEditing] = useState(false);
  const { mutateAsync: updateMutation } = useMutation(updateSubscription);

  const onSubmit = async (data: UpdateSubscriptionReq) => {
    await updateMutation({
      id: subscription.id,
      ...data
    });
    setEditing(false);
  };

  const { values, isSubmitting, handleChange, handleBlur, isValid, dirty, submitForm, setFieldValue } = useMacrosForm(
    subscription,
    onSubmit
  );
  const roles = useUserRoles();

  const handleMacrosPresetChange = (macrosPreset: MacrosPreset, cal: number) => {
    let macrosData = MacrosService.getMacrosBag(
      cal,
      values.dietType!,
      values.macrosData?.weight ?? 0,
      values.macrosData?.activityLevel || ActivityLevel.level1
    );
    if (!(macrosPreset === MacrosPreset.recommended)) {
      macrosData = MacrosService.getCustomMacrosBag(cal, macrosPreset);
      setFieldValue('macrosType', MacrosType.custom);
    }
    setFieldValue('macros.protein.min', macrosData.protein.min);
    setFieldValue('macros.protein.max', macrosData.protein.max);
    setFieldValue('macros.carbs.min', macrosData.carbs.min);
    setFieldValue('macros.carbs.max', macrosData.carbs.max);
    setFieldValue('macros.fat.min', macrosData.fat.min);
    setFieldValue('macros.fat.max', macrosData.fat.max);
    setFieldValue('macrosPreferences.preset', macrosPreset);
  };

  const handleCalChange = (cal: number) => {
    setFieldValue('macros.cal', cal);
    if (values.macrosPreferences?.preset) {
      handleMacrosPresetChange(values.macrosPreferences.preset, cal);
    }
  };

  const handleMacrosType = (macrosType: MacrosType) => {
    if (macrosType === MacrosType.recommended) {
      const calData = MacrosService.getCal(subscription.macrosData);
      const macrosData = MacrosService.getMacrosBag(
        calData,
        values.dietType!,
        values.macrosData?.weight ?? 0,
        values.macrosData?.activityLevel || ActivityLevel.level1
      );
      setFieldValue('macros.cal', calData);
      setFieldValue('macros.protein.min', macrosData.protein.min);
      setFieldValue('macros.protein.max', macrosData.protein.max);
      setFieldValue('macros.carbs.min', macrosData.carbs.min);
      setFieldValue('macros.carbs.max', macrosData.carbs.max);
      setFieldValue('macros.fat.min', macrosData.fat.min);
      setFieldValue('macros.fat.max', macrosData.fat.max);
      setFieldValue('macrosType', MacrosType.recommended);
      setFieldValue('macrosPreferences.preset', undefined);
    } else {
      setFieldValue('macrosType', MacrosType.custom);
    }
  };

  return (
    <div className="tile is-parent">
      <div className="card">
        <header className="card-header bg-black text-white">
          <p className="card-header-title text-white">Macros</p>
          {roles.includes(Permission.UPDATE_SUBSCRIPTION) && (
            <a href="#" className="card-header-icon" onClick={() => setEditing(!editing)}>
              <span className="icon">
                <i className="fas fa-edit hover: text-white focus:text-white"></i>
              </span>
            </a>
          )}
        </header>
        <div className="card-content">
          <Select2
            label="Macros Source"
            value={values.macrosType}
            options={[
              { value: MacrosType.recommended, label: 'Recommended' },
              { value: MacrosType.custom, label: 'Custom' }
            ]}
            onChange={(data) => handleMacrosType(data.target.value as MacrosType)}
            disabled={!editing}
          />
          {values.macrosType === MacrosType.custom && (
            <Select2
              label="Macros preset"
              value={values.macrosPreferences?.preset}
              options={[
                { value: MacrosPreset.recommended, label: 'Recommended' },
                { value: MacrosPreset.evenSplit, label: 'Even Split' },
                { value: MacrosPreset.highCarbs, label: 'High Carbs' },
                { value: MacrosPreset.highFat, label: 'High Fat' },
                { value: MacrosPreset.highProtein, label: 'Hight Protein' }
              ]}
              onChange={(data) => handleMacrosPresetChange(data.target.value as MacrosPreset, values.macros!.cal)}
              disabled={!editing}
            />
          )}

          <Input
            min={0}
            label="Cal"
            type="number"
            name="macros[cal]"
            onBlur={handleBlur}
            value={values.macros!.cal}
            onChange={(data) => handleCalChange(+data.target.value)}
            disabled={!editing || values.macrosType === MacrosType.recommended}
          />
          <div className="field">
            <div className="field-body">
              <Input
                label="Protein min"
                value={values.macros!.protein.min}
                name="macros.protein.min"
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                min={0}
                disabled
              />
              <Input
                label="Protein max"
                value={values.macros!.protein.max}
                name="macros.protein.max"
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                min={0}
                disabled
              />
            </div>
          </div>
          <div className="field">
            <div className="field-body">
              <Input
                label="Carbs min"
                value={values.macros!.carbs.min}
                name="macros.carbs.min"
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                min={0}
                disabled
              />
              <Input
                label="Carbs max"
                value={values.macros!.carbs.max}
                name="macros.carbs.max"
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                min={0}
                disabled
              />
            </div>
          </div>
          <div className="field">
            <div className="field-body">
              <Input
                label="Fat min"
                value={values.macros!.fat.min}
                name="macros.fat.min"
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                min={0}
                disabled
              />
              <Input
                label="Fat max"
                value={values.macros!.fat.max}
                name="macros.fat.max"
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                min={0}
                disabled
              />
            </div>
          </div>
          {editing && (
            <>
              <Button
                type="submit"
                primary
                fluid
                onClick={submitForm}
                loading={isSubmitting}
                disabled={!dirty || !isValid || isSubmitting}
                content="Save"
              />
              {!subscription.macros && (
                <p className="help">After saving this will take over dynamic macros provided by "Macros Data"</p>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MacrosCard;
