import { Permission, Subscription, UpdateSubscriptionReq } from '@calo/dashboard-types';
import { ActivityLevel, Brand, Gender, Goal, IngredientCategory } from '@calo/types';
import { getList, getListWithParams, updateFoodPreference, updateSubscription } from 'actions';
import { Button, Input, Select } from 'components';
import { handleSearch } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { Ingredient, Options } from 'lib/interfaces';
import { difference, sortBy, unionBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import useMacrosDataForm from './useMacrosDataForm';

interface MacrosDataCardProps {
  subscription: Subscription;
}

const MacrosDataCard = ({ subscription }: MacrosDataCardProps) => {
  const [editing, setEditing] = useState(false);
  const [allergicIngredients, setAllergicIngredients] = useState<Ingredient[]>([]);
  const [allIngredients, setAllIngredients] = useState<Ingredient[]>([]);

  const { mutateAsync: updateMutation } = useMutation(updateSubscription);
  const { mutateAsync: updateAllergicIngMutation } = useMutation(updateFoodPreference);

  const onSubmit = async (data: UpdateSubscriptionReq) => {
    await updateMutation({
      id: subscription.id,
      ...data
    });

    const isOtherAlreadySelected = subscription.macrosData?.allergicFood?.includes(IngredientCategory.other);
    const isOtherSelected = data.macrosData?.allergicFood?.includes(IngredientCategory.other);

    if (isOtherAlreadySelected && !isOtherSelected) {
      await updateAllergicIngMutation({
        subscriptionId: subscription.id,
        allergicIng: [],
        healthConditions: foodPref.healthConditions?.healthConditions,
        notes: foodPref.healthConditions?.notes,
        favorite: foodPref?.favouriteFood || []
      });
      setAllergicIngredients([]);
    } else if (
      isOtherSelected &&
      (difference(foodPref?.ingredientsToAvoid, allergicIngredients).length > 0 ||
        difference(allergicIngredients, foodPref?.ingredientsToAvoid || []).length > 0)
    ) {
      await updateAllergicIngMutation({
        subscriptionId: subscription.id,
        allergicIng: allergicIngredients,
        healthConditions: foodPref.healthConditions?.healthConditions,
        notes: foodPref.healthConditions?.notes,
        favorite: foodPref?.favouriteFood || []
      });
    }
    setEditing(false);
  };

  const { values, isSubmitting, handleChange, handleBlur, isValid, submitForm, setFieldValue } = useMacrosDataForm(
    subscription,
    onSubmit
  );

  const roles = useUserRoles();

  const [filterName, setFilterName] = useState<string>();

  const { data: foodPref } = useQuery<any, Error, any>([`food-preferences/${subscription.id}`], getList, {
    enabled:
      roles.includes(Permission.VIEW_INGREDIENT_LIST) && values.macrosData?.allergicFood?.includes(IngredientCategory.other),
    onSuccess: (data) => {
      setAllergicIngredients(data?.ingredientsToAvoid);
    }
  });

  useQuery<any, Error, { data: Ingredient[] }>(
    [
      'ingredients',
      {
        filters: {
          country: subscription.country,
          brand: subscription.brand || Brand.CALO,
          name: filterName || undefined
        }
      }
    ],
    getListWithParams,
    {
      enabled: roles.includes(Permission.VIEW_INGREDIENT_LIST) && !!filterName,
      onSuccess: (allIngredientList) => {
        setAllIngredients(allIngredientList?.data);
      }
    }
  );

  const options: Options<Ingredient>[] = useMemo(
    () =>
      sortBy(unionBy(allIngredients, allergicIngredients, 'id'), (ingredient) => `${ingredient.name.en}`).map((ingredient) => ({
        value: ingredient.id,
        data: { ...ingredient },
        label: `${ingredient.name.en} (${ingredient.internalName || ''})`
      })),
    [allIngredients, allergicIngredients]
  );

  return (
    <div className="tile is-parent">
      <div className="card">
        <header className="card-header bg-black text-white">
          <p className="card-header-title text-white">Macros Data</p>
          {roles.includes(Permission.UPDATE_SUBSCRIPTION) && (
            <a href="#" className="card-header-icon" onClick={() => setEditing(!editing)}>
              <span className="icon">
                <i className="fas fa-edit hover: text-white focus:text-white"></i>
              </span>
            </a>
          )}
        </header>
        <div className="card-content">
          <Select
            label="Fitness goal"
            value={values.macrosData!.goal}
            isDisabled={!editing}
            options={[
              { value: Goal.loseWeight, label: 'Lose weight' },
              { value: Goal.gainWeight, label: 'Gain weight' },
              { value: Goal.buildMuscle, label: 'Build muscle' },
              { value: Goal.maintainWeight, label: 'Maintain weight' }
            ]}
            onChange={(data: any) => setFieldValue('macrosData[goal]', data.value)}
          />
          {values.macrosData!.goal !== Goal.maintainWeight && (
            <Select
              label="Weight goal"
              value={values.macrosData!.weightGoal}
              isDisabled={!editing}
              options={[
                { value: 0.25, label: '1kg' },
                { value: 0.5, label: '2kg' },
                { value: 1, label: '4kg' }
              ]}
              onChange={(data: any) => setFieldValue('macrosData[weightGoal]', data.value)}
            />
          )}
          <Input
            disabled={!editing}
            label="Weight"
            value={values.macrosData!.weight}
            name="macrosData[weight]"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            step="any"
          />
          <Input
            disabled={!editing}
            label="Height"
            value={values.macrosData!.height}
            name="macrosData[height]"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            step="any"
          />
          <Input
            disabled={!editing}
            label="Target Weight"
            value={values.macrosData!.targetWeight}
            name="macrosData[targetWeight]"
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            step="any"
          />
          <Select
            label="Activity level"
            value={values.macrosData!.activityLevel}
            isDisabled={!editing}
            options={[
              { value: ActivityLevel.level1, label: 'Little or no exercise' },
              { value: ActivityLevel.level2, label: 'Light: 1-3 workouts/week' },
              { value: ActivityLevel.level3, label: 'Moderate: 3-5 workouts/week' },
              { value: ActivityLevel.level4, label: 'Active: 6-7 workouts/week' },
              { value: ActivityLevel.level5, label: 'Very Active: 6-7 intense workouts/week' }
            ]}
            onChange={(data: any) => setFieldValue('macrosData[activityLevel]', data.value)}
          />
          <Select
            label="Gender"
            value={values.macrosData!.gender}
            isDisabled={!editing}
            options={[
              { value: Gender.male, label: 'Male' },
              { value: Gender.female, label: 'Female' }
            ]}
            onChange={(data: any) => setFieldValue('macrosData[gender]', data.value)}
          />
          <Select
            label="Alergic food"
            isMulti
            value={values.macrosData!.allergicFood}
            isDisabled={!editing}
            options={Object.values(IngredientCategory).map((row) => ({
              value: row,
              label: IngredientCategory[row]
            }))}
            onChange={(data: any) =>
              setFieldValue(
                'macrosData[allergicFood]',
                data.map((row) => row.value)
              )
            }
          />
          {values.macrosData!.allergicFood?.includes(IngredientCategory.other) && (
            <Select
              label="Alergic Ingredients"
              isMulti
              value={allergicIngredients && allergicIngredients.map((ingredient) => ingredient.id)}
              isDisabled={!editing}
              options={options}
              onChange={(data: any) => setAllergicIngredients(data.map((ingredient) => ingredient.data))}
              onInputChange={(data: string, action: any) => handleSearch({ text: data, action, name: setFilterName })}
            />
          )}
          {editing && (
            <Button
              type="submit"
              primary
              fluid
              onClick={submitForm}
              loading={isSubmitting}
              disabled={!isValid || isSubmitting}
              content="Save"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MacrosDataCard;
