import { useEffect, useRef, useState } from 'react';

import { endOfWeek, getYear, startOfWeek } from 'date-fns/fp';
import { uniqBy } from 'lodash-es';
import Calendar from 'react-calendar';
import { useMutation, useQuery } from 'react-query';
import { generatePath, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AddonsMenuFoodItem, CreateAddonsMenuReq, Permission, UpdateAddonsMenuReq } from '@calo/dashboard-types';
import { Brand, Country, Kitchen } from '@calo/types';

import { createAddons, getListWithParams, getRecordWithParams, updateAddons } from 'actions';
import { AddonsPicker, Button, Card, Icon, Modal, ModalRef, Select2 } from 'components';
import AddonsTable from 'components/AddonsTable';
import { dateOfWeek } from 'lib';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';
import { Food } from 'lib/interfaces';
import useAddonsForm from './useAddonsForm';

const ExactAddons = () => {
  const { id, country, brand, kitchen } = useParams<{ id: string; country: Country; brand: Brand; kitchen: Kitchen }>();
  const [filterName, setFilterName] = useState<string>();
  const [selectedAddons, setSelectedAddons] = useState<Food[]>([]);

  const { data } = useQuery(['addons-menu', id, { brand: brand || Brand.CALO, kitchen }], getRecordWithParams, {
    suspense: true,
    retry: false
  });
  const addons = data as any;

  const { data: addonsList, isLoading } = useQuery<any, Error, { data: Food[] }>(
    [
      'food',
      {
        filters: {
          name: filterName ? filterName : undefined,
          country,
          brand,
          kitchen: addons.kitchen,
          isDeleted: false
        },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page: 0,
        limit: 20
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: !!filterName
    }
  );

  const { mutateAsync: updateMutation } = useMutation(updateAddons);
  const roles = useUserRoles();
  const [daysRange, setDaysRange] = useState<any>({ selectedDays: [] });

  const onSubmit = async (values: UpdateAddonsMenuReq) => {
    try {
      await updateMutation(
        {
          id,
          food: values.food,
          country: addons.country,
          brand: addons.brand,
          kitchen: addons.kitchen
        },
        {
          onSuccess: () => {
            const path = generatePath(Routes.addonsList, { brand: addons.brand, country: addons.country });
            history.push(path);
          }
        }
      );
    } catch {
      toast('error', { type: 'error', autoClose: 2000 });
    }
  };

  const { values, isSubmitting, isValid, dirty, handleSubmit, setFieldValue, setValues } = useAddonsForm(addons, onSubmit);

  const addonsRef = useRef<ModalRef>();
  const duplicateAddonsRef = useRef<ModalRef>();

  const [addonsItem, setAddonsItem] = useState<AddonsMenuFoodItem>({ id: undefined!, priority: undefined! });

  const [duplicateTime, setDuplicateTime] = useState<{ week: number | undefined; year: number | undefined }>({
    week: undefined,
    year: undefined
  });

  const { mutateAsync: createMutation } = useMutation(createAddons);

  const AddToTable = (value: AddonsMenuFoodItem) => {
    setValues({
      food: [
        ...values.food,
        {
          id: value.id,
          priority: value.priority
        }
      ]
    });
    addonsRef.current?.close();
    setAddonsItem({ id: undefined!, priority: undefined! });
  };

  const editRow = (value: AddonsMenuFoodItem) => {
    setAddonsItem({ id: value.id, priority: value.priority });
    addonsRef.current?.open();
  };

  useEffect(() => {
    if (addonsItem.id !== undefined) {
      setFieldValue(
        'food',
        values.food.filter((e) => e.id !== addonsItem.id)
      );
    }
  }, [addonsItem]);

  const handleWeekClick = (weekNumber: number, days: Date) => {
    const year = getYear(endOfWeek(days));
    setDaysRange({
      selectedDays: [startOfWeek(days), endOfWeek(days)]
    });
    setDuplicateTime({ week: weekNumber, year: year });
  };

  const handleDuplicatedFood = async () => {
    const duplicateAddonsMenu = {
      food: values.food,
      country: addons.country,
      week: duplicateTime.week,
      year: duplicateTime.year,
      brand: addons.brand,
      kitchen: addons.kitchen
    } as CreateAddonsMenuReq;
    try {
      const dAddonsMenu = await createMutation(duplicateAddonsMenu);
      const path = generatePath(Routes.addons, {
        country: dAddonsMenu.country,
        brand: dAddonsMenu.brand,
        id: dAddonsMenu.id,
        kitchen: dAddonsMenu.kitchen
      });
      history.push(path);
    } catch (error: any) {
      if (error.message.includes('409')) {
        toast(`Menu for week ${daysRange.week} already exists`, { type: 'error', autoClose: 2000 });
      } else {
        toast('error', { type: 'error', autoClose: 2000 });
      }
    }
  };

  const handleSelectedAddons = (id: string) => {
    const newAddon = addonsList?.data.find((e) => e.id === id);
    if (newAddon) {
      setSelectedAddons((old) => [...old, newAddon]);
    }
  };

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  <Link to={Routes.addonsList}>Addons</Link>
                </li>
                <li>Week #{addons.week}</li>
              </ul>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              {addons.brand && addons.brand === Brand.MEALO ? (
                <Icon name="mealo" size={28} className="w-24 -mb-24" />
              ) : (
                <Icon name="calo" size={8} className="w-32 -mb-4" />
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">{`${dateOfWeek({ week: addons.week, year: addons.year })} / ${addons.country} `}</h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                {roles.includes(Permission.UPDATE_ADDONS) && (
                  <Button
                    icon="fa fa-clone"
                    onClick={() => duplicateAddonsRef.current?.open()}
                    className="mr-4"
                    data-test="addon-menu-exact-clone-button"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        <div className="mx-4">
          <div className="card w-full">
            <header className="card-header bg-black">
              <p className="card-header-title w-full text-white">
                <span className="icon">
                  <i className="mdi mdi-account-multiple"></i>
                </span>
                Info
              </p>
              <p className="card-header-title flex float-right text-white">
                <h1>{addons.kitchen}</h1>
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit}>
                <AddonsPicker
                  isLoading={isLoading}
                  addonsRef={addonsRef}
                  AddToTable={AddToTable}
                  addonsItem={addonsItem}
                  existAddons={values.food}
                  setFilterName={setFilterName}
                  addonsList={(addonsList?.data || []).filter((f) => !f.deletedAt)}
                  handleSelectedAddons={handleSelectedAddons}
                />
                <Button
                  primary
                  type="submit"
                  content="Save"
                  loading={isSubmitting}
                  onClick={() => handleSubmit()}
                  disabled={!dirty || !isValid || isSubmitting}
                  data-test="addon-menu-exact-save-button"
                />
              </form>
              <Card title={`${addons.year} Week # ${addons.week}`} className="my-2">
                <div className="float-right -mt-8">
                  <Button icon="fas fa-plus" onClick={() => addonsRef.current?.open()} data-test="addon-menu-exact-plus-button" />
                </div>
                <AddonsTable
                  values={values}
                  setValues={setValues}
                  addonsValues={values.food}
                  editRow={(k) => editRow(k)}
                  addonsList={uniqBy(
                    [...(addonsList?.data || []).filter((f) => !f.deletedAt), ...addons.food, ...selectedAddons],
                    'id'
                  ).filter((selectedFood) => values.food.map((r) => r.id).includes(selectedFood.id))}
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Modal ref={duplicateAddonsRef} isNarrow>
        <div className="flex flex-col">
          <span className="label">Week #{duplicateTime.week}</span>
          <div>
            <Calendar
              showWeekNumbers
              calendarType={'US'}
              tileDisabled={() => true}
              value={daysRange.selectedDays.length > 0 ? daysRange.selectedDays : null}
              onClickWeekNumber={(weekNumber, date) => handleWeekClick(weekNumber, date)}
              onClickDay={() => null}
            />
          </div>
          <div className="py-4">
            <Select2
              label="Country"
              value={addons.country}
              name="country"
              disabled
              options={Object.values(Country).map((coun) => ({
                value: coun,
                label: Country[coun]
              }))}
            />
          </div>
          <Select2
            label="Kitchen"
            value={addons.kitchen}
            options={Object.values(Kitchen)
              .filter((r) => r.includes(addons.country || Country.BH))
              .filter((c) => c === addons.kitchen || Kitchen.BH1)
              .map((k) => ({
                label: k,
                value: k
              }))}
            disabled
          />
          <div className="py-4">
            <Select2
              label="Brand"
              value={addons.brand}
              name="brand"
              disabled
              options={Object.values(Brand).map((brand) => ({
                value: brand,
                label: Brand[brand]
              }))}
            />
          </div>
          <Button
            warning
            disabled={duplicateTime.week === undefined}
            content="Confirm"
            onClick={() => {
              handleDuplicatedFood();
              duplicateAddonsRef.current?.close();
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default ExactAddons;
