import { useEffect, useRef, useState } from 'react';

import { flatten, isEqual } from 'lodash-es';
import { useInfiniteQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { AddressService } from '@calo/services';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button, TableCell, TableRow, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components';
import client from 'lib/client';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { SelectedMealData } from '../../../../Accounts/ExactAccount/AccountDeliveriesCard/AccountDeliveriesCard';
import DeliveryOrderModel from '../../../../DeliveryOrderModel';
import { onExport } from '../../../../Export';

interface OrdersListRowProps {
  order: any;
}

const OrdersListRow = ({ order }: OrdersListRowProps) => {
  const roles = useUserRoles();
  const showMealsRef = useRef<ModalRef>();
  const [existingFoodIdList, setExistingFoodIdList] = useState<string[]>([]);
  const [selectedMealsData, setSelectedMealsData] = useState<SelectedMealData[]>([]);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isLoadingMealData, setIsLoadingMealData] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);
  const {
    data: existingFood,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery<{ data: any[]; meta: any }>(
    [
      'food',
      {
        filters: {
          name: undefined,
          country: order.country,
          brand: order.brand,
          kitchen: order.kitchen,
          ids: existingFoodIdList.length > 0 ? existingFoodIdList : undefined
        },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page: 0,
        limit: 100
      }
    ],
    async ({ pageParam = existingFoodIdList.slice(0, 100), queryKey }) => {
      setIsLoading(true);
      const { data } = await client.get(queryKey[0] as string, {
        params: {
          filters: {
            name: undefined,
            country: order.country,
            brand: order.brand,
            kitchen: order.kitchen,
            ids: existingFoodIdList.length > 0 ? pageParam : undefined
          },
          sort: {
            orderBy: 'name',
            orderMode: 'asc'
          },
          page: 0,
          limit: 100
        }
      });
      return data;
    },
    {
      suspense: false,
      enabled: existingFoodIdList.length > 0,
      keepPreviousData: false,
      getNextPageParam: (lastPage, allPages) => {
        const usedIds = allPages.flatMap((ap) => ap.data.map((d) => d.id));
        const nextIds = (existingFoodIdList || []).filter((id) => !usedIds.includes(id));
        return nextIds.length > 0 ? nextIds : undefined;
      }
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    } else {
      setIsLoading(false);
      if (isExporting) {
        onExport(order, existingFood?.pages.flatMap((p) => p.data) ?? []);
        setIsExporting(false);
      }
    }
  }, [existingFood]);

  useEffect(() => {
    if (existingFood && existingFood.pages.flatMap((p) => p.data)) {
      setIsLoadingMealData(true);
      setSelectedMealsData(
        (existingFood.pages.flatMap((p) => p.data) || []).map((food) => ({
          name: food.name.en,
          id: food.id,
          data: food,
          size: food.size,
          numberOfMeals: order.orders[0].items.find((meal: any) => meal.itemId === food.id)?.count
        }))
      );
      setIsLoadingMealData(false);
    }
  }, [existingFood, existingFoodIdList]);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      color: caloTheme.palette.neutral900,
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none'
    }
  }));

  return (
    <TableRow key={order.id} hover>
      <StyledTableCell
        component="th"
        scope="row"
        sx={{
          textOverflow: 'ellipsis'
        }}
      >
        <Typography
          component="span"
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'inline-block',
            textOverflow: 'ellipsis',
            fontFamily: caloTheme.typography.fontFamily
          }}
        >
          {roles.includes(Permission.VIEW_BUSINESS_USER) ? (
            <Link to={Routes.accountUser.replace(':id', order.user.id)}>{order.user.fullName}</Link>
          ) : (
            order.user.fullName
          )}
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Button
          variant="outlined"
          sx={{
            width: '112px',
            height: '42px',
            cursor: 'pointer',
            my: 'auto',
            '&:hover': {
              border: 2,
              fontWeight: 600,
              fontSize: '14px',
              borderRadius: '8px',
              backgroundColor: caloTheme.palette.primary100,
              borderColor: caloTheme.palette.primary600
            },
            border: 2,
            borderRadius: '8px',
            borderColor: caloTheme.palette.primary500,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              justifyItems: 'center',
              margin: 'auto',
              marginTop: 4,
              width: 'auto'
            }
          }}
          onClick={() => {
            setExistingFoodIdList(order.orders[0].items.map((r: any) => r.itemId));
            showMealsRef.current?.open();
          }}
        >
          <Typography
            variant={'button'}
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              color: caloTheme.palette.primary500,
              lineHeight: '19px',
              fontFamily: caloTheme.typography.fontFamily
            }}
          >
            View Meals
          </Typography>
        </Button>
      </StyledTableCell>

      <StyledTableCell>{order.user.phoneNumber}</StyledTableCell>

      <StyledTableCell>
        <Typography
          sx={{
            width: '339px',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            fontFamily: caloTheme.typography.fontFamily
          }}
        >
          {AddressService.display(order.deliveryAddress)}
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '20px',
            fontFamily: caloTheme.typography.fontFamily,
            color: caloTheme.palette.neutral900,
            textTransform: 'capitalize'
          }}
        >
          {order.time}
        </Typography>
      </StyledTableCell>

      <StyledTableCell>
        <Typography
          sx={{
            color:
              order.status === 'confirmed' || order.status === 'deliveried'
                ? caloTheme.palette.primary500
                : caloTheme.palette.red,
            fontFamily: 'Roboto',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '19px',
            textTransform: 'capitalize'
          }}
        >
          {order.status}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <IconButton
          disabled={isLoading || isExporting}
          onClick={() => {
            const newFoodIdList: string[] = flatten(order.orders.map((o: { items: any[] }) => o.items.map((r: any) => r.itemId)));
            if (isEqual(newFoodIdList, existingFoodIdList)) {
              onExport(order, selectedMealsData ? selectedMealsData : []);
            } else {
              setExistingFoodIdList(newFoodIdList);
              setIsExporting(true);
            }
          }}
        >
          <FileDownloadOutlinedIcon />
        </IconButton>
      </StyledTableCell>
      <DeliveryOrderModel
        accountData={order}
        isEditing={false}
        isDuplicate={false}
        updatedData={order}
        isLoading={isLoading || isLoadingMealData}
        setIsDuplicate={() => null}
        existDeliveryDay={[]}
        accountDelivery={order}
        updateDeliveryRef={showMealsRef}
        handleSelectedOption={() => null}
        selectedMealsData={selectedMealsData}
        setAllSelectedMealOptions={() => null}
        handleSelectedMealChanges={() => null}
        setSelectedMealsData={setSelectedMealsData}
        setExistingFoodIdList={setExistingFoodIdList}
        allSelectedMealOptions={existingFood ? existingFood.pages.flatMap((p) => p.data) : []}
        setUpdatedData={() => null}
      />
    </TableRow>
  );
};
export default OrdersListRow;
