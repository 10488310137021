import { DietType, FoodType } from '@calo/types';
import Tag from 'components/Tag';

export const types = (type: FoodType) => {
  switch (type) {
    case FoodType.breakfast:
      return Tag({ yellow: true, children: type });
    case FoodType.coffee:
      return Tag({ style: { backgroundColor: '#7ED320' }, children: type });
    case FoodType.juice:
      return Tag({ style: { backgroundColor: '#7ED320' }, children: type });
    case FoodType.snack:
      return Tag({ red: true, children: type });
    case FoodType.lunch:
      return Tag({ blue: true, children: type });
    case FoodType.dinner:
      return Tag({ blue: true, children: type });
    case FoodType.caloKids:
      return Tag({ lightGreen: true, children: type });
    case FoodType.salad:
      return Tag({ green: true, children: type });
    default:
      return Tag({ children: type });
  }
};

export const tags = (tag: string) => {
  switch (tag) {
    case DietType.balanced:
      return Tag({ green: true, children: 'B' });
    case DietType.lowCarb:
      return Tag({ blue: true, children: 'L' });
    case DietType.highProtein:
      return Tag({ orange: true, children: 'H' });
    case DietType.vegetarian:
      return Tag({ purple: true, children: 'V' });
    default:
      return Tag({ children: tag });
  }
};

export const mapDietTypeToTag = (dietType: string): string => {
  switch (dietType) {
    case 'balanced':
      return 'balanced';
    case 'vegetarian':
      return 'vegetarian';
    case 'lowCarb':
      return 'low-carb';
    case 'highProtein':
      return 'high-protein';
    case 'gourmet':
      return 'gourmet';
    case 'worldCup':
      return 'world-cup';
    case 'newMealClub':
      return 'newMealClub';
    case 'preBuiltCustom':
      return 'preBuiltCustom';
    default:
      return '';
  }
};
