import { getListWithParams } from 'actions';
import { CaloLoader } from 'components';
import { format } from 'date-fns/fp';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { groupedAllMeals, resolveCountryFromKitchen } from 'lib';
import { StyledStatsTableCell } from 'lib/componentStyles';
import history from 'lib/history';
import { useUserKitchens } from 'lib/hooks';
import { FoodComponent } from 'lib/interfaces';
import { flatten, omit, sortBy } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { PortionStatsFilters } from '@calo/dashboard-types';
import { Brand, CustomMealCategory, FoodType, Kitchen, Localized } from '@calo/types';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Box, Card, Stack, Table, TableBody, TableHead, TableRow, Tooltip, Typography } from '@mui/material';

import SharedStatsHeader from '../SharedStatsHeader';
import TableStatsHeaderSection from '../TableStatsHeaderSection';
import { printPDF } from '../utils';
import { getTitle, prepareForExport } from './helpers';
import Settings from './Settings';
import styles from './styles';

export interface FoodStatsComponent {
  key: string;
  quantity: number;
  size: string;
  type?: FoodType[];
  name: Localized;
  components: FoodComponent[];
  specialRequestsQuantity: number;
  modifiedQuantity: number;
  assemblyRequired: boolean;
  category: CustomMealCategory;
}

const KitchenPortionStats = () => {
  const location = useLocation();
  const userKitchen = useUserKitchens();
  const [filtersCheck, setFiltersCheck] = useState({ subscriptions: true, businesses: false, charity: false });
  const [showDetailedView, setShowDetailedView] = useState(false);
  const [selectedCustomSize, setSelectedCustomSize] = useState<string | undefined>(undefined);

  const searchParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState<PortionStatsFilters & { type: FoodType; size: string[]; onlySandwiches: boolean }>({
    day: {
      gte: format('yyyy-MM-dd')(Date.now()),
      lte: format('yyyy-MM-dd')(Date.now())
    },
    size: [],
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    brand: Brand.CALO,
    onlySandwiches: false,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const { data: statsData, isLoading } = useQuery<
    any,
    Error,
    { data: FoodStatsComponent[]; charity: FoodStatsComponent[]; business: FoodStatsComponent[] }
  >(['/stats/kitchen-portion', omit(filters, ['type', 'size', 'onlySandwiches'])], getListWithParams, {
    onSuccess: () => {
      searchParams.set('filters', JSON.stringify(filters));
      history.push({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    }
  });

  const handleMealSize = (row: FoodStatsComponent) => {
    const sizeMappings = {
      [CustomMealCategory.salad]: 'CD',
      [CustomMealCategory.sandwich]: 'CS',
      [CustomMealCategory.rice]: 'CR',
      [CustomMealCategory.pasta]: 'CP'
    };

    if (row.size === 'C') {
      return sizeMappings[row.category] || 'C';
    }
    return row.size;
  };

  const applyFoodAdditionalFilters = (data: FoodStatsComponent[]) => {
    return data.filter((d) => {
      const matchesType = !filters.type || (d.type || []).includes(filters.type);
      const matchesSize = filters.size.length === 0 || filters.size.includes(d.size);
      const matchesAssemblyRequired = filters.onlySandwiches ? d.assemblyRequired : true;

      return matchesType && matchesSize && matchesAssemblyRequired;
    });
  };

  const filteredStatsData: FoodStatsComponent[] = useMemo(() => {
    const data: FoodStatsComponent[][] = [];
    if (filtersCheck.subscriptions) {
      data.push(statsData ? applyFoodAdditionalFilters(statsData.data) : []);
    }
    if (filtersCheck.businesses) {
      data.push(statsData ? applyFoodAdditionalFilters(statsData.business) : []);
    }
    if (filtersCheck.charity) {
      data.push(statsData ? applyFoodAdditionalFilters(statsData.charity) : []);
    }
    const flattenedData = flatten(data);

    if (selectedCustomSize) {
      return flattenedData.filter((item) => handleMealSize(item) === selectedCustomSize);
    }
    return flattenedData;
  }, [filtersCheck, statsData, filters.type, filters.size, filters.onlySandwiches, selectedCustomSize]);
  const summedFoodData = Object.values(groupedAllMeals(filteredStatsData)) as FoodStatsComponent[];

  const onExport = useCallback(async () => {
    if (!summedFoodData) {
      return;
    }
    const workbook = new ExcelJS.Workbook();

    prepareForExport(workbook, summedFoodData, filters, showDetailedView);

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, 'meal-stats' + fileExtension);
  }, [summedFoodData, filtersCheck]);

  const handleOnPrint = () => {
    const records = sortBy(summedFoodData, 'name').map((row) => {
      let results: any = {
        name: row.name.en,
        mealType: (row?.type || []).join(', '),
        size: row.size
      };
      if (showDetailedView) {
        results = {
          ...results,
          quantity: row.quantity - row.specialRequestsQuantity - row.modifiedQuantity,
          modifiedQuantity: row.modifiedQuantity,
          specialRequestsQuantity: row.specialRequestsQuantity
        };
      } else {
        results = {
          ...results,
          totalQuantity: row.quantity
        };
      }
      return results;
    });

    printPDF(getTitle('Meal stats', filters), records, 16);
  };
  const numberOfColumns = showDetailedView ? 6 : 4;

  return (
    <>
      <SharedStatsHeader
        title={'Meal Stats'}
        isLoading={isLoading}
        onExport={onExport}
        isDisabled={summedFoodData.length === 0}
        onPrint={handleOnPrint}
      />
      <Card variant="outlined" sx={styles.card}>
        <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
          <TableStatsHeaderSection
            filtersCheck={filtersCheck}
            setFiltersCheck={setFiltersCheck}
            title="Meals"
            showDetailedViewSwitch={true}
            showDetailedView={showDetailedView}
            setShowDetailedView={setShowDetailedView}
          />
          {isLoading ? (
            <Stack sx={{ width: '100%', justifyContent: 'center' }}>
              <CaloLoader />
            </Stack>
          ) : (
            <Table sx={styles.mainTable}>
              <TableHead style={{ borderRadius: '8px' }} sx={styles.tableHead}>
                <TableRow sx={styles.tableRow}>
                  <StyledStatsTableCell
                    style={{ width: `${100 / numberOfColumns}%`, borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}
                  >
                    Name {String(filters.onlySandwiches)}
                  </StyledStatsTableCell>
                  <StyledStatsTableCell style={{ width: `${100 / numberOfColumns}%` }}>Meal Type</StyledStatsTableCell>
                  <StyledStatsTableCell style={{ width: `${100 / numberOfColumns}%` }}>Size</StyledStatsTableCell>
                  <StyledStatsTableCell style={{ width: `${100 / numberOfColumns}%` }}>
                    {!showDetailedView && (
                      <Tooltip
                        title="Quantity + Modified Quantity + Special Requests Quantity."
                        placement="top-end"
                        sx={{
                          borderRadius: 10,
                          padding: 0.2,
                          ':hover': { cursor: 'pointer' }
                        }}
                      >
                        <InfoOutlined color={'info'} fontSize="medium" />
                      </Tooltip>
                    )}
                    {!showDetailedView && 'Total '}Quantity
                  </StyledStatsTableCell>
                  {showDetailedView && (
                    <>
                      <StyledStatsTableCell style={{ width: `${100 / numberOfColumns}%` }}>
                        Modified Quantity
                      </StyledStatsTableCell>
                      <StyledStatsTableCell
                        style={{
                          width: `${100 / numberOfColumns}%`,
                          borderTopRightRadius: '8px',
                          borderBottomRightRadius: '8px'
                        }}
                      >
                        Special Requests Quantity
                      </StyledStatsTableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              {summedFoodData && summedFoodData.length === 0 ? (
                <StyledStatsTableCell style={{ border: 0 }} colSpan={6}>
                  <Typography sx={styles.noStatsText}>No Stats</Typography>
                </StyledStatsTableCell>
              ) : (
                <TableBody>
                  {sortBy(summedFoodData, 'name')?.map((row) => (
                    <TableRow key={uuid()}>
                      <StyledStatsTableCell style={{ fontWeight: 600 }}>{row.name.en}</StyledStatsTableCell>
                      <StyledStatsTableCell style={{ fontWeight: 400 }}>{(row?.type || []).join(', ')}</StyledStatsTableCell>
                      <StyledStatsTableCell style={{ fontWeight: 400 }}>{handleMealSize(row)}</StyledStatsTableCell>
                      <StyledStatsTableCell style={{ fontWeight: 400 }}>
                        {showDetailedView ? row.quantity - row.specialRequestsQuantity - row.modifiedQuantity : row.quantity}
                      </StyledStatsTableCell>
                      {showDetailedView && (
                        <>
                          <StyledStatsTableCell style={{ fontWeight: 400 }}>{row.modifiedQuantity}</StyledStatsTableCell>
                          <StyledStatsTableCell style={{ fontWeight: 400 }}>{row.specialRequestsQuantity}</StyledStatsTableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          )}
        </Box>
      </Card>
      <Settings
        onFilter={setFilters}
        filters={filters}
        selectedCustomSize={selectedCustomSize}
        setSelectedCustomSize={setSelectedCustomSize}
      />
    </>
  );
};
export default KitchenPortionStats;
