import { Kitchen } from '@calo/types';
import DateFnsAdapter from '@date-io/date-fns';
import { Icon } from '@iconify/react';
import { Autocomplete, Box, Drawer, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { caloTheme } from 'assets/images/theme/calo';
import { format } from 'date-fns/fp';
import { resolveCountryFromKitchen } from 'lib/helpers';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getListWithParams, toggleUISettings } from 'actions';
import { useUserKitchens } from 'lib/hooks';
import { AppState, BusinessOrderFilters, PaginatedBusinessAccountDeliveries } from 'lib/interfaces';
import { sortBy, uniqBy } from 'lodash-es';
import { useQuery } from 'react-query';

interface SettingsProps {
  onFilter: (values: BusinessOrderFilters) => void;
  filters: BusinessOrderFilters;
  page: number;
  setPage: (value: number) => void;
}

const Settings = ({ filters, onFilter, setPage, page }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));
  const [openStartDatePicker, setOpenStartDatePicker] = useState(false);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);

  const handleStartIconButtonClick = (event: any) => {
    event.stopPropagation();
    setOpenStartDatePicker(!openStartDatePicker);
  };

  const handleEndIconButtonClick = (event: any) => {
    event.stopPropagation();
    setOpenEndDatePicker(!openEndDatePicker);
  };

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(toggleUISettings());
  };

  const [filterName, setFilterName] = useState<string>();
  const { data: businessOrders, isLoading } = useQuery<any, Error, PaginatedBusinessAccountDeliveries>(
    [
      'business/deliveries',
      {
        page,
        filters: {
          kitchen: filters.kitchen ? filters.kitchen : undefined,
          date: filters.date
        },
        limit: 200
      }
    ],
    getListWithParams
  );

  useEffect(() => {
    setPage(0);
    if (filterName) {
      setFilterName('');
    }
  }, [filters.kitchen, filters.date]);

  const options = useMemo(() => {
    const sortedOrders = sortBy(businessOrders?.data, (f) => `${f.user.fullName}`);
    const uniqueOrders = uniqBy(sortedOrders, (order) => order.user.id);
    return uniqueOrders.map((order) => ({
      name: order.user.fullName,
      value: order.user.id,
      label: `${order.user.fullName}`
    }));
  }, [businessOrders]);

  return (
    <Drawer anchor="right" open={settingsVisible} onClose={handleClose}>
      <Box sx={{ paddingX: '16px', paddingY: 6, marginRight: '16px' }}>
        <Typography sx={{ lineHeight: '34px', fontSize: '28px', fontWeight: 600, paddingBottom: 4 }}>
          Orders - {resolveCountryFromKitchen(filters.kitchen)}
        </Typography>
        <TextField
          select
          label="Kitchen"
          value={filters.kitchen}
          sx={{ width: '100%', mb: 2 }}
          InputProps={{ style: { borderRadius: '8px', zIndex: 1100 } }}
          onChange={(e: any) => {
            onFilter({ ...filters, kitchen: e.target.value as Kitchen });
          }}
        >
          {Object.values(userKitchens).map((type) => (
            <MenuItem key={type} value={type} onClick={(e) => e.stopPropagation()}>
              {type}
            </MenuItem>
          ))}
        </TextField>

        <Box sx={{ flexDirection: 'column' }}>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '18px',
              fontFamily: 'Roboto',
              color: caloTheme.palette.neutral900
            }}
          >
            Date Filter
          </Typography>
          <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', my: 2 }}>
            <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ width: '100%' }}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                label={'Start Date'}
                open={openStartDatePicker}
                onOpen={() => setOpenStartDatePicker(true)}
                onClose={() => setOpenStartDatePicker(false)}
                value={filters.date.gte || null}
                onChange={(dates: any) => {
                  onFilter({
                    ...filters,
                    date: {
                      ...filters.date,
                      gte: format('yyyy-MM-dd')(dates)
                    }
                  });
                }}
                InputProps={{ style: { borderRadius: '8px' } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{
                      width: '100%',
                      borderRadius: 12,
                      marginRight: 21
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <IconButton size="small" onClick={handleStartIconButtonClick}>
                          <Icon icon="uil:calender" width="22px" height="22px" />
                        </IconButton>
                      ),
                      style: { borderRadius: '8px' }
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ width: '100%' }}>
              <DesktopDatePicker
                inputFormat="dd/MM/yyyy"
                label={'End Date'}
                value={filters.date.lte || null}
                open={openEndDatePicker}
                onOpen={() => setOpenEndDatePicker(true)}
                onClose={() => setOpenEndDatePicker(false)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{
                      width: '100%',
                      borderRadius: 12
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <IconButton size="small" onClick={handleEndIconButtonClick}>
                          <Icon icon="uil:calender" width="22px" height="22px" />
                        </IconButton>
                      ),
                      style: { borderRadius: '8px' }
                    }}
                  />
                )}
                onChange={(dates: any) => {
                  onFilter({
                    ...filters,
                    date: {
                      ...filters.date,
                      lte: format('yyyy-MM-dd')(dates)
                    }
                  });
                }}
              />
            </LocalizationProvider>
          </Stack>
        </Box>
        <Autocomplete
          options={options}
          loading={isLoading}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
          value={options.find((option) => option.value === filters.userId) || null}
          onChange={(event, newValue) => {
            const valueToSend = typeof newValue === 'string' ? newValue : newValue?.value;
            onFilter({ ...filters, userId: valueToSend });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Business"
              placeholder="Search Business"
              sx={{ width: '100%', my: '16px' }}
              InputProps={{ ...params.InputProps, style: { borderRadius: '8px' } }}
            />
          )}
        />
      </Box>
    </Drawer>
  );
};

export default Settings;
