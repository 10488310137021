import { FoodComponentMethodStep, FoodComponentStation } from '@calo/dashboard-types';
import { MenuItem, TextField } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { uploadImage } from 'actions/ui';
import cx from 'classnames';
import { decodeHtmlSymbols } from 'lib/helpers';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { Icon, ModalRef } from '../';
import ComponentMethodImageModal from './ComponentMethodImageModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { ComponentMethodRowProps, MAX_IMAGE_SIZE } from './types';

const ComponentMethodRow = React.memo(
  ({
    uploadLink,
    image,
    values,
    uploadImages,
    disabled,
    step,
    selected,
    setSelected,
    isEdit,
    updateMethodStep,
    setValues,
    setActiveSelected
  }: ComponentMethodRowProps) => {
    const uniqueId = useRef<string>(uuidv4());
    const confirmModalRef = useRef<ModalRef>();
    const [progress, setProgress] = useState(0);
    const componentMethodImageRef = useRef<ModalRef>();
    const [displayImage, setDisplayImage] = useState<string>(image);

    const handleDeleteAction = useCallback(
      (s: FoodComponentMethodStep) => {
        setSelected(s);
        confirmModalRef.current?.open();
      },
      [setSelected]
    );

    const handleRemoveImage = useCallback(
      (stepDesc: FoodComponentMethodStep & { index: number }) => {
        const updatedMethod = [...values.method!];
        updatedMethod[stepDesc.index] = {
          ...updatedMethod[stepDesc.index],
          attachment: ''
        };
        setValues({
          ...values,
          method: updatedMethod
        });
        componentMethodImageRef.current?.close();
      },
      [values, setValues]
    );

    const handleUploadImage = useCallback(
      async (files: File) => {
        if (!files || !files.type.includes('image')) {
          toast('Image only', { type: 'error', autoClose: 2000 });
          return;
        }
        try {
          const { url, fields } = await uploadLink();
          const formData = new FormData();
          for (const [key, value] of Object.entries(fields)) {
            formData.append(key, value);
          }
          formData.append('file', files);
          setDisplayImage(URL.createObjectURL(files));
          const imageUpload = await uploadImage(url, formData, {
            onUploadProgress: (progressEvent) => {
              setProgress(Math.min(100, Math.round((progressEvent.loaded * 100) / progressEvent.total)));
            }
          });
          if (imageUpload) {
            const updatedMethod = values.method?.map((m, i) => (i === selected.index ? { ...m, attachment: displayImage } : m));
            if (updatedMethod) {
              const newState = {
                ...values,
                method: updatedMethod
              };
              setValues(newState);
            }
          }
        } catch (error) {
          toast('Error uploading image', { type: 'error', autoClose: 2000 });
          console.error('Image upload failed:', error);
        }
      },
      [uploadLink, selected.index, values.method, setValues]
    );

    const availableStations = useMemo(() => {
      const currentIndex = (values.method ?? []).indexOf(step);
      if (currentIndex === 0) {
        return (values.cookingStation ?? []).slice(currentIndex, currentIndex + 1);
      }
      const previousStation = (values.method ?? [])
        .slice(0, currentIndex)
        .reverse()
        .find((m) => m.station)?.station;
      const previousStationIndex = (values.cookingStation ?? []).indexOf(previousStation as FoodComponentStation);
      return previousStation && previousStationIndex !== -1
        ? (values.cookingStation ?? []).slice(previousStationIndex, previousStationIndex + 2)
        : (values.cookingStation ?? []).slice(0, 1);
    }, [values.cookingStation, values.method, step]);

    const handleStationChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        updateMethodStep(step.description, step.index, step.attachment, e.target.value);
      },
      [updateMethodStep, step.index, step.attachment, step.description]
    );

    const handleDescriptionChange = useCallback(
      (newDescription: string) => {
        updateMethodStep(newDescription, step.index, step.attachment, step.station);
      },
      [updateMethodStep, step.index, step.attachment, step.station]
    );

    const deleteStep = useCallback(() => {
      const tempData = [...values.method!];
      const updatedMethod = tempData.filter((_, index) => index !== selected.index);
      setValues({
        ...values,
        method: updatedMethod
      });
      setSelected({ description: '', attachment: '', station: '', index: -1 });
    }, [values, selected.index, setValues, setSelected]);

    return (
      <>
        <p className="label mt-2 ml-2">Step {step.index + 1}</p>
        <Draggable key={step.index} draggableId={uniqueId.current} index={step.index}>
          {(provider) => (
            <tr onClick={() => setActiveSelected(step, step.index)} {...provider.draggableProps} ref={provider.innerRef}>
              <td className="w-1" {...provider.dragHandleProps}>
                <Icon name="dragDropIcon" size={8} className="mt-5" />
              </td>
              <td className="w-3/5">
                <Editor
                  tinymceScriptSrc="/tinymce/tinymce.min.js"
                  value={decodeHtmlSymbols(step.description)}
                  onEditorChange={handleDescriptionChange}
                  init={{
                    min_height: 200,
                    height: 200,
                    inline: false,
                    menubar: false,
                    plugins: 'table autoresize',
                    toolbar:
                      'table | tableprops tabledelete | ' +
                      'tableinsertrowbefore tableinsertrowafter tabledeleterow | ' +
                      'tableinsertcolbefore tableinsertcolafter tabledeletecol | ' +
                      'tablecellprops tablemergecells tablesplitcells tablecellvalign ' +
                      'tablecellborderwidth tablecellborderstyle tablecellbackgroundcolor tablecellbordercolor',
                    entity_encoding: 'raw' // This setting may help handle spaces properly
                  }}
                  disabled={disabled || !isEdit}
                />
              </td>
              <td className="w-1/5">
                <TextField
                  select
                  label="Station"
                  value={step.station}
                  onChange={handleStationChange}
                  disabled={disabled || (isEdit && selected.index !== step.index)}
                  fullWidth
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {availableStations.map((station) => (
                    <MenuItem key={station} value={station}>
                      {station}
                    </MenuItem>
                  ))}
                </TextField>
              </td>
              {uploadImages && (
                <td
                  className={cx('h-auto', {
                    'w-1': !uploadImages,
                    'w-1/4': uploadImages
                  })}
                >
                  {uploadImages && step.attachment && (
                    <figure className="w-full">
                      <img
                        alt="placeholder"
                        className={cx('object-cover rounded-md float-right', {
                          'cursor-pointer': !disabled
                        })}
                        onError={(e: any) => (e.target.src = 'https://via.placeholder.com/120')}
                        src={values.method![step.index].attachment}
                        onClick={() => {
                          setSelected({ ...step, index: step.index });
                          componentMethodImageRef.current?.open();
                        }}
                      />
                    </figure>
                  )}
                  {!step.attachment && (
                    <div className="file is-boxed is-fullwidth ">
                      <div className="file-label">
                        <input
                          accept="image/*"
                          type="file"
                          size={2}
                          className="file-input fas fa-paperclip mt-2 cursor-pointer float-right "
                          onClick={() => setSelected({ ...step, index: step.index })}
                          onChange={(e) => {
                            if (e.target.files && e.target.files[0].size > MAX_IMAGE_SIZE) {
                              toast('Image size is too big', { type: 'error', autoClose: 2000 });
                            } else {
                              const file = e.target.files && e.target.files[0];
                              if (file) {
                                handleUploadImage(file);
                              }
                            }
                          }}
                        />
                        <div className="flex-col float-right">
                          <i className="fas fa-paperclip mt-2 cursor-pointer float-right" />
                        </div>
                      </div>
                    </div>
                  )}
                  {progress > 0 && progress < 100 && (
                    <>
                      <br />
                      <progress className="progress" value={progress} max="100">
                        {progress}%
                      </progress>
                    </>
                  )}
                </td>
              )}
              <td className="w-2">
                {!disabled && (
                  <i className="mt-2 fas fa-times cursor-pointer float-right" onClick={() => handleDeleteAction(step)} />
                )}
              </td>
            </tr>
          )}
        </Draggable>
        <ComponentMethodImageModal
          componentMethodImageRef={componentMethodImageRef}
          selected={selected}
          setSelected={setSelected}
          handleUploadImage={handleUploadImage}
          progress={progress}
          index={selected.index}
          handleRemoveImage={handleRemoveImage}
          step={step}
        />
        <ConfirmDeleteModal
          confirmModalRef={confirmModalRef}
          selected={selected}
          setSelected={setSelected}
          handleDelete={deleteStep}
        />
      </>
    );
  }
);

export default ComponentMethodRow;
