import parsePhoneNumberFromString from 'libphonenumber-js';
import { useMutation } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CreateSubscriptionReq, Subscription } from '@calo/dashboard-types';

import { createSubscription } from 'actions';
import { Routes } from 'lib/enums';
import SubscriptionForm from './SubscriptionForm';

const NewSubscription = (subscription: Subscription) => {
  const history = useHistory();
  const { mutateAsync: createMutation } = useMutation(createSubscription);

  const handleCreateSubscription = async (values: CreateSubscriptionReq) => {
    try {
      const phoneNumber = parsePhoneNumberFromString(values.phoneNumber!, values.country);
      const ns = await createMutation({ ...values, phoneNumber: phoneNumber!.number as string });
      history.replace(Routes.subscription.replace(':id', ns.id));
    } catch (error: any) {
      if (error.message.includes('409')) {
        toast('Subscription already exists', { type: 'error', autoClose: 2000 });
      } else {
        toast('error', { type: 'error', autoClose: 2000 });
      }
    }
  };
  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  <Link to={Routes.subscriptions}>Subscriptions</Link>
                </li>
                <li>New</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="card">
          <header className="card-header">
            <p className="card-header-title bg-black text-white">
              <span className="icon">
                <i className="mdi mdi-account-multiple"></i>
              </span>
              Info
            </p>
          </header>

          <div className="relative w-full">
            <SubscriptionForm onSubmit={handleCreateSubscription} subscription={subscription} />
          </div>
        </div>
      </section>
    </>
  );
};
export default NewSubscription;
