import { useEffect, useState } from 'react';

import { format, setDay } from 'date-fns/fp';
import { range, startCase } from 'lodash-es';
import { useMutation } from 'react-query';

import { Permission, Subscription } from '@calo/dashboard-types';
import { DietType, FoodType, PlanType, SubscriptionFrequency, SubscriptionTier } from '@calo/types';

import { updateSubscriptionPlan } from 'actions';
import { Button, Input, Select } from 'components';
import { useUserRoles } from 'lib/hooks';
import { SubscriptionPlanValues } from 'lib/interfaces';
import usePlanForm from './usePlanForm';

const mapKeyedToFoodType = (values: any) =>
  Object.keys(values).reduce((result: FoodType[], ft) => {
    const length = values[ft as FoodType];
    if (length > 0) {
      result = [...result, ...new Array(length).fill(ft)];
    }
    return result;
  }, []);

interface PlanCardProps {
  subscription: Subscription & { remainingDays: number; expectedLastDeliveryDay: string };
}

const PlanCard = ({ subscription }: PlanCardProps) => {
  const [editing, setEditing] = useState(false);
  const [mealsLimitMsg, setMealsLimitMsg] = useState<string>();

  const { mutateAsync: updatePlanMutation } = useMutation(updateSubscriptionPlan);

  const onSubmit = async ({ id, foodType, ...rest }: SubscriptionPlanValues) => {
    await updatePlanMutation({
      id: subscription.id,
      planType: id,
      ...rest,
      ...(id === PlanType.custom && {
        foodType: mapKeyedToFoodType(foodType)
      })
    });
    setEditing(false);
  };

  const { values, isSubmitting, handleChange, handleBlur, isValid, dirty, submitForm, setFieldValue, setValues, errors } =
    usePlanForm(subscription, onSubmit);
  const roles = useUserRoles();

  useEffect(() => {
    if (values.id === PlanType.custom && editing) {
      setValues({
        ...values,
        foodType: {
          breakfast: 1,
          dinner: 1,
          lunch: 1,
          snack: 1,
          dessert: 0,
          caloKids: 0,
          coffee: 0,
          juice: 0,
          salad: 0
        }
      });
    }
  }, [values.id]);

  useEffect(() => {
    if (values.id === PlanType.custom && editing) {
      let total = 0;
      Object.values(FoodType).map((r) => {
        total += values.foodType![r];
      });
      if (total > 30) {
        setMealsLimitMsg(' Maximum number of meals is 30');
      } else if (total === 0) {
        setMealsLimitMsg(' Minimum number of meals is 1');
      } else {
        setMealsLimitMsg(undefined);
      }
    }
  }, [values.id, values.foodType]);

  return (
    <div className="tile is-parent">
      <div className="card">
        <header className="card-header bg-black text-white">
          <p className="card-header-title text-white">Plan</p>
          {roles.includes(Permission.UPDATE_SUBSCRIPTION) && (
            <a href="#" className="card-header-icon" onClick={() => setEditing(!editing)}>
              <span className="icon">
                <i className="fas fa-edit hover: text-white focus:text-white"></i>
              </span>
            </a>
          )}
        </header>
        <div className="card-content">
          <Select
            label="Tier"
            value={values.tier || SubscriptionTier.personalized}
            isDisabled={!editing}
            options={Object.keys(SubscriptionTier).map((p) => ({
              value: p,
              label: startCase(p)
            }))}
            onChange={(data: any) => setFieldValue('tier', data.value)}
          />
          <Select
            label="Plan"
            value={values.id}
            isDisabled={!editing}
            options={Object.keys(PlanType).map((p) => ({
              value: p,
              label: startCase(p)
            }))}
            onChange={(data: any) => setFieldValue('id', data.value)}
          />
          {values.id === PlanType.custom && (
            <div className="mb-2">
              <Input
                disabled={!editing}
                label="Breakfast"
                value={values.foodType![FoodType.breakfast]}
                type="number"
                name={`foodType[${FoodType.breakfast}]`}
                onChange={handleChange}
                onBlur={handleBlur}
                min={0}
                step={1}
                max={30}
                error={!!(errors.foodType! as any)?.breakfast}
              />
              <Input
                disabled={!editing}
                label="Dinner"
                value={values.foodType![FoodType.dinner]}
                type="number"
                name={`foodType[${FoodType.dinner}]`}
                onChange={handleChange}
                onBlur={handleBlur}
                min={0}
                step={1}
                max={30}
                error={!!(errors.foodType! as any)?.dinner}
              />
              <Input
                disabled={!editing}
                label="Lunch"
                value={values.foodType![FoodType.lunch]}
                type="number"
                pattern="^[0-9]"
                name={`foodType[${FoodType.lunch}]`}
                onChange={handleChange}
                onBlur={handleBlur}
                min={0}
                step={1}
                max={30}
                error={!!(errors.foodType! as any)?.lunch}
              />
              <Input
                disabled={!editing}
                label="Snack"
                value={values.foodType![FoodType.snack]}
                type="number"
                name={`foodType[${FoodType.snack}]`}
                onChange={handleChange}
                onBlur={handleBlur}
                min={0}
                step={1}
                max={30}
                error={!!(errors.foodType! as any)?.snack}
              />
              <Input
                disabled={!editing}
                label="Dessert"
                value={values.foodType![FoodType.dessert]}
                type="number"
                name={`foodType[${FoodType.dessert}]`}
                onChange={handleChange}
                onBlur={handleBlur}
                min={0}
                step={1}
                max={30}
                error={!!(errors.foodType! as any)?.dessert}
              />
              <Input
                disabled={!editing}
                label="Calo Kids"
                value={values.foodType![FoodType.caloKids]}
                type="number"
                name={`foodType[${FoodType.caloKids}]`}
                onChange={handleChange}
                onBlur={handleBlur}
                min={0}
                step={1}
                max={30}
                error={!!(errors.foodType! as any)?.caloKids}
              />
              {!!mealsLimitMsg && <p className="text-sm text-red-400">{mealsLimitMsg}</p>}
            </div>
          )}
          <Select
            label="Diet"
            value={values.dietType}
            options={[
              { value: DietType.balanced, label: 'Balanced' },
              { value: DietType.vegetarian, label: 'Vegetarian' },
              { value: DietType.lowCarb, label: 'Low Carb' },
              { value: DietType.highProtein, label: 'High Protein' }
            ]}
            onChange={(data: any) => setFieldValue('dietType', data.value)}
            isDisabled={!editing}
          />
          <Select
            label="Frequency"
            value={values.frequency}
            isDisabled={!editing}
            options={Object.keys(SubscriptionFrequency).map((p) => ({
              value: p,
              label: startCase(p)
            }))}
            onChange={(data: any) => setFieldValue('frequency', data.value)}
          />
          <Select
            label="Delivery Days"
            isMulti
            value={values.deliveryDays}
            isDisabled={!editing}
            options={range(0, 7).map((day) => ({
              value: day,
              label: format('EEE')(setDay(day)(Date.now()))
            }))}
            onChange={(data: any) =>
              setFieldValue(
                'deliveryDays',
                data.map((row) => row.value)
              )
            }
          />
          <div className="field">
            <label className="label">Days Remaining</label>
            <div className="control is-clearfix">
              <input type="text" readOnly={true} className="input is-static" value={subscription.remainingDays} />
            </div>
          </div>

          <div className="field">
            <label className="label">Expected Last Delivery Date</label>
            <div className="control is-clearfix">
              <input type="text" readOnly={true} className="input is-static" value={subscription.expectedLastDeliveryDay} />
            </div>
          </div>

          <div className="field">
            <label className="label">Last Delivered Date</label>
            <div className="control is-clearfix">
              <input type="text" readOnly={true} className="input is-static" value={subscription.lastDeliveredDate} />
            </div>
          </div>

          {editing && (
            <Button
              type="submit"
              primary
              fluid
              onClick={submitForm}
              loading={isSubmitting}
              disabled={!dirty || !isValid || isSubmitting || !!mealsLimitMsg}
              content="Save"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
