import { caloTheme } from 'assets/images/theme/calo';
import cx from 'classnames';
import { Icon } from 'components';
import FoodDietTypeTags from 'components/FoodDietTypeTags';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { CustomComponentsByCategory, Delivery, FoodComponent, FoodWithPosition, Menu } from 'lib/interfaces';
import { capitalize, compact } from 'lodash-es';
import { useEffect, useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { Link } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { CustomMealCategory, FoodActionType, FoodType, MacrosType, Subscription } from '@calo/types';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Button, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import DeliveryMealAction from '../shared/DeliveryMealAction';
import FoodSizeTooltip from './FoodSizeTooltip';

interface DeliveriesMealTableRowProps {
  menu: Menu;
  menuLoading: boolean;
  components: FoodComponent[];
  mealType: string;
  delivery: Delivery;
  meal: FoodWithPosition;
  subscription: Subscription;
  isCustomComponentsLoading: boolean;
  customFoodComponents: FoodComponent[];
  allCustomFoodComponents: CustomComponentsByCategory;
  caloKidsComponents: FoodComponent[];
  setCustomFoodComponents: React.Dispatch<React.SetStateAction<FoodComponent[]>>;
  refetchDelivery: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  setFetchMenu: (value: boolean) => void;
  setFetchAllComponents: (value: boolean) => void;
}

const getTargetMacrosSatisfiedData = (foodMacros, foodMetadata) => {
  if (!foodMetadata)
    return {
      isSatisfied: false,
      message: <span>Target Macros is not satisfied missing food target into !</span>
    };

  if (typeof foodMetadata === 'string') {
    foodMetadata = JSON.parse(foodMetadata);
  }

  const targetMacros = foodMetadata.targetMacros;
  return {
    isSatisfied:
      Math.abs(foodMacros.cal - targetMacros.cal) <= 10 &&
      Math.abs(foodMacros.protein - targetMacros.protein) <= 5 &&
      Math.abs(foodMacros.carbs - targetMacros.carbs) <= 5 &&
      Math.abs(foodMacros.fat - targetMacros.fat) <= 5,
    message: (
      <span>
        Target Macros is not satisfied <br />
        <b>Cal</b>: {foodMacros.cal} - {targetMacros.cal} = {Math.abs(foodMacros.cal - targetMacros.cal)} <br />
        <b>Protein</b>: {foodMacros.protein} - {targetMacros.protein} = {Math.abs(foodMacros.protein - targetMacros.protein)}{' '}
        <br />
        <b>Carbs</b>: {foodMacros.carbs} - {targetMacros.carbs} = {Math.abs(foodMacros.carbs - targetMacros.carbs)} <br />
        <b>Fat</b>: {foodMacros.fat} - {targetMacros.fat} = {Math.abs(foodMacros.fat - targetMacros.fat)} <br />
      </span>
    )
  };
};

const isTargetMacrosSatisfied = (foodMacros, foodMetadata) => {
  return getTargetMacrosSatisfiedData(foodMacros, foodMetadata).isSatisfied;
};

const getTargetMacrosSatisfiedMsg = (foodMacros, foodMetadata) => {
  return getTargetMacrosSatisfiedData(foodMacros, foodMetadata).message;
};

const DeliveriesMealTableRow = ({
  menu,
  menuLoading,
  meal,
  components,
  mealType,
  delivery,
  subscription,
  caloKidsComponents,
  customFoodComponents,
  allCustomFoodComponents,
  isCustomComponentsLoading,
  setCustomFoodComponents,
  refetchDelivery,
  setFetchMenu,
  setFetchAllComponents
}: DeliveriesMealTableRowProps) => {
  const userRoles = useUserRoles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isComponentDetailsOpen, setIsComponentDetailsOpen] = useState<boolean>(false);

  const [mealActionMsg, setMealActionMsg] = useState<string>();
  const [removedComponents, setRemovedComponents] = useState<string[]>([]);

  const roles = useUserRoles();

  useEffect(() => {
    if (meal.actions) {
      const removedAction = meal.actions
        .filter((action) => action.type === FoodActionType.remove)
        .flatMap((component) => component.componentId);
      const swappedAction = meal.actions
        .filter((action) => action.type === FoodActionType.swap)
        .flatMap((component) => component.componentId);

      if (removedAction && removedAction.length > 0 && !swappedAction) {
        setMealActionMsg(`Meal has some components removed from it`);
      } else if (removedAction.length > 0 || swappedAction) {
        setMealActionMsg(`Meal has some components changed from it`);
      } else {
        setMealActionMsg('');
      }
      setRemovedComponents(compact(removedAction));
    }
  }, [meal]);

  return (
    <>
      <TableRow key={meal.id}>
        <TableCell
          sx={{
            fontWeight: 600,
            fontSize: '16px',
            fontHeight: '19px',
            variant: 'h2',
            fontFamily: caloTheme.typography.fontFamily,
            color: caloTheme.palette.neutral900,
            component: 'th',
            scope: 'row',
            display: 'table-cell',
            flexDirection: 'row'
          }}
        >
          {meal.name
            ? mealType === 'giftItem'
              ? '(Gift)'
              : meal.skipped && (
                  <b
                    className={cx('', {
                      'text-cRed': meal.skipped
                    })}
                  >
                    {' '}
                    SKIPPED
                  </b>
                )
            : 'Unkown meal'}{' '}
          <Link
            to={Routes.foodSlug.replace(
              ':slug',
              // @ts-ignore
              meal.slug
            )}
          >
            {meal.name?.en}
            {meal.isSwapped && roles.includes(Permission.VIEW_DEBUG_OPTIONS) && <SwapHorizIcon sx={{ color: 'orange' }} />}
          </Link>
        </TableCell>
        <TableCell>
          {meal.actions && meal.actions.length > 0 && (
            <Tooltip title={mealActionMsg} placement="top" arrow>
              <span>
                <Icon name="removeIcon" size={6} className="w-12" />
              </span>
            </Tooltip>
          )}
          {meal.category === CustomMealCategory.athlete && !isTargetMacrosSatisfied(meal.macros, meal.metadata) && (
            <Tooltip title={getTargetMacrosSatisfiedMsg(meal.macros, meal.metadata)} placement="top" arrow>
              <span>
                <Icon name="infoWarning" size={6} className="w-12" />
              </span>
            </Tooltip>
          )}
        </TableCell>

        <TableCell
          style={{ textAlign: 'center' }}
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            fontHeight: '19px',
            variant: 'h2',
            fontFamily: caloTheme.typography.fontFamily,
            color: caloTheme.palette.neutral900,
            scope: 'row'
          }}
        >
          {mealType === 'meal' && meal.size !== 'C' ? (
            <FoodSizeTooltip meal={meal} delivery={delivery} menu={menu} refetchDelivery={refetchDelivery} />
          ) : (
            meal.size
          )}
        </TableCell>

        <TableCell>
          {meal.type ? (
            <Typography
              variant="h1"
              sx={{
                ml: 2,
                borderRadius: '37px',
                textAlign: 'center',
                px: '6px',
                py: '8px',
                width: '80%',
                fontWeight: 600,
                background:
                  meal.type?.includes(FoodType.dinner) || meal.type?.includes(FoodType.lunch)
                    ? caloTheme.palette.primary100
                    : meal.type?.includes(FoodType.coffee) || meal.type?.includes(FoodType.juice)
                      ? caloTheme.palette.secondaryPurple100
                      : meal.type?.includes(FoodType.breakfast)
                        ? caloTheme.palette.secondaryYellow100
                        : caloTheme.palette.secondaryBlue100,

                color:
                  meal.type?.includes(FoodType.dinner) || meal.type?.includes(FoodType.lunch)
                    ? caloTheme.palette.primary700
                    : meal.type?.includes(FoodType.coffee) || meal.type?.includes(FoodType.juice)
                      ? caloTheme.palette.secondaryPurple800
                      : meal.type?.includes(FoodType.breakfast)
                        ? caloTheme.palette.secondaryYellow800
                        : caloTheme.palette.secondaryBlue700,
                fontSize: '14px',
                lineHeight: '17px',
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              {meal?.addedAs && meal?.addedAs === 'addonSubscription'
                ? 'Add-on Subscription'
                : capitalize(meal.type?.join(' & '))}
            </Typography>
          ) : (
            <Typography
              sx={{
                ml: 2,
                borderRadius: '37px',
                textAlign: 'center',
                px: '6px',
                py: '8px',
                width: '80%',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '17px',
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              ---
            </Typography>
          )}
        </TableCell>

        <TableCell>
          <Button
            variant="text"
            aria-label="show-meal-macros"
            sx={{
              ml: '-16px',
              height: '45px',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '17px',
              borderRadius: '8px',
              textTransform: 'none',
              color: caloTheme.palette.primary500,
              backgroundColor: isOpen ? caloTheme.palette.primary100 : 'white',
              '&:hover': {
                backgroundColor: caloTheme.palette.primary100,
                color: caloTheme.palette.primary600
              },
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                justifyItems: 'center',
                margin: 'auto',
                marginTop: 4,
                width: 'auto'
              }
            }}
            disabled={!meal.macros}
            onClick={() => {
              setIsOpen(!isOpen);
              if (isOpen) setIsComponentDetailsOpen(false);
            }}
          >
            {isOpen ? 'Hide Macros' : 'Show Macros'}
          </Button>
        </TableCell>

        <TableCell>
          <Typography display="flex" flexDirection="row" className="tags capitalize">
            {meal.tags?.map((type, i) => <FoodDietTypeTags key={i} tag={type} blueBuerry />)}
          </Typography>
        </TableCell>

        <TableCell>
          <DeliveryMealAction
            subscription={subscription}
            meal={meal}
            menu={menu}
            menuLoading={menuLoading}
            mealType={mealType}
            delivery={delivery}
            isCustomComponentsLoading={isCustomComponentsLoading}
            customFoodComponents={customFoodComponents}
            allCustomFoodComponents={allCustomFoodComponents}
            caloKidsComponents={caloKidsComponents}
            foodComponents={components}
            setCustomFoodComponents={setCustomFoodComponents}
            refetchDelivery={refetchDelivery}
            setFetchMenu={setFetchMenu}
            setFetchAllComponents={setFetchAllComponents}
          />
        </TableCell>
      </TableRow>
      {isOpen && (
        <TableRow>
          {userRoles.includes(Permission.VIEW_CUSTOM_MACROS_DELIVERY_DETAILS) &&
            subscription.macrosType === MacrosType.custom && (
              <TableCell>
                <Button
                  variant="text"
                  aria-label="show-meal-components-macros"
                  sx={{
                    height: '45px',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '17px',
                    borderRadius: '8px',
                    textTransform: 'none',
                    color: caloTheme.palette.primary500,
                    backgroundColor: isComponentDetailsOpen ? caloTheme.palette.primary100 : 'white',
                    '&:hover': {
                      backgroundColor: caloTheme.palette.primary100,
                      color: caloTheme.palette.primary600
                    },
                    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                      justifyItems: 'center',
                      margin: 'auto',
                      marginTop: 4,
                      width: 'auto'
                    }
                  }}
                  disabled={!meal.macros}
                  onClick={() => setIsComponentDetailsOpen(!isComponentDetailsOpen)}
                >
                  {isComponentDetailsOpen ? 'Hide Components' : 'Show Components'}
                </Button>
              </TableCell>
            )}
          <TableCell
            colSpan={6}
            sx={{
              familyFont: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '19px'
            }}
          >
            <Stack display="flex" flexDirection="row" justifyContent="space-between">
              {Object.entries(meal.macros).map(([mealMacrosKey, mealMacrosValue]) => (
                <Stack>
                  <Typography
                    variant="h6"
                    sx={{
                      color:
                        mealMacrosKey === 'cal'
                          ? caloTheme.palette.primary500
                          : mealMacrosKey === 'fat'
                            ? caloTheme.palette.secondaryYellow500
                            : mealMacrosKey === 'carbs'
                              ? caloTheme.palette.secondaryPurple500
                              : caloTheme.palette.secondaryBlue500,
                      backgroundColor:
                        mealMacrosKey === 'cal'
                          ? caloTheme.palette.primary100
                          : mealMacrosKey === 'fat'
                            ? caloTheme.palette.secondaryYellow100
                            : mealMacrosKey === 'carbs'
                              ? caloTheme.palette.secondaryPurple100
                              : caloTheme.palette.secondaryBlue100,
                      // mr: 4,
                      borderRadius: '8px',
                      left: '12px',
                      top: '8px',
                      fontSize: '16px',
                      lineHeight: '19px',
                      fontFamily: 600,
                      padding: '10px 16px 10px 18px'
                    }}
                  >
                    {`${mealMacrosKey === 'cal' ? 'Calories' : capitalize(mealMacrosKey)}: ${mealMacrosValue} ${mealMacrosKey === 'cal' ? '' : 'g'}`}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </TableCell>
        </TableRow>
      )}
      {userRoles.includes(Permission.VIEW_CUSTOM_MACROS_DELIVERY_DETAILS) &&
        subscription.macrosType === MacrosType.custom &&
        isComponentDetailsOpen &&
        (meal.components || []).map(({ id, name, quantity, macros }) => (
          <TableRow>
            <TableCell>
              <Link to={Routes.foodComponent.replace(':id', id)}>{name?.en}</Link> X {quantity}
              {removedComponents && removedComponents.length > 0 && removedComponents.includes(id) && (
                <Tooltip title="Removed Component" placement="top" arrow>
                  <span>
                    <Icon name="removeIcon" size={6} className="w-12" />
                  </span>
                </Tooltip>
              )}
            </TableCell>
            <TableCell
              colSpan={6}
              sx={{
                familyFont: caloTheme.typography.fontFamily,
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '19px'
              }}
            >
              <Stack display="flex" flexDirection="row" justifyContent="space-between">
                {Object.entries(macros ?? {}).map(([componentMacrosKey, componentMacrosValue]) => (
                  <Stack>
                    <Typography
                      sx={{
                        color:
                          componentMacrosKey === 'cal'
                            ? caloTheme.palette.primary500
                            : componentMacrosKey === 'fat'
                              ? caloTheme.palette.secondaryYellow500
                              : componentMacrosKey === 'carbs'
                                ? caloTheme.palette.secondaryPurple500
                                : caloTheme.palette.secondaryBlue500,
                        backgroundColor:
                          componentMacrosKey === 'cal'
                            ? caloTheme.palette.primary100
                            : componentMacrosKey === 'fat'
                              ? caloTheme.palette.secondaryYellow100
                              : componentMacrosKey === 'carbs'
                                ? caloTheme.palette.secondaryPurple100
                                : caloTheme.palette.secondaryBlue100,
                        borderRadius: '8px',
                        left: '12px',
                        top: '8px',
                        fontSize: '14px',
                        lineHeight: '19px',
                        fontFamily: 600,
                        padding: '10px 16px 10px 18px'
                      }}
                    >
                      {`${componentMacrosKey === 'cal' ? 'Calories' : capitalize(componentMacrosKey)}: ${componentMacrosValue} ${componentMacrosKey === 'cal' ? '' : 'g'}`}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};
export default DeliveriesMealTableRow;
