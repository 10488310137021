import { Subscription, UpdateSubscriptionReq } from '@calo/dashboard-types';
import { Brand, HealthConditionNote, IngredientCategory, Kitchen } from '@calo/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Autocomplete, Box, CircularProgress, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { getListWithParams, updateFoodPreference, updateSubscription } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { HealthConditions } from 'lib/enums';
import { healthConditionsIngredientLists } from 'lib/helpers/healthConditionsList';
import { Ingredient, Options } from 'lib/interfaces';
import { compact, difference, sortBy, startCase, uniq, uniqBy } from 'lodash-es';
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import FoodRestrictionItem from './FoodRestrictionItem';
interface FoodRestrictionsProps {
  foodPref: any;
  selectedCustomer: Subscription;
  refetch: any;
  customerNotes: HealthConditionNote[];
  healthConditionsData: HealthConditions[] | undefined;
  setHealthConditionData: React.Dispatch<React.SetStateAction<HealthConditions[]>>;
  setAllergicIngredients: React.Dispatch<React.SetStateAction<any[]>>;
  allergicIngredients: any[];
  favMealIds: string[];
}

const FoodRestrictions = ({
  refetch,
  selectedCustomer,
  customerNotes,
  favMealIds,
  setAllergicIngredients,
  setHealthConditionData,
  healthConditionsData,
  allergicIngredients,
  foodPref
}: FoodRestrictionsProps) => {
  const [filterName, setFilterName] = useState<string>();
  const [healthConditionIngredient, setHealthConditionIngredient] = useState<string[]>([]);
  const [allIngredients, setAllIngredients] = useState<Ingredient[]>([]);
  const { mutateAsync: updateMutation } = useMutation(updateSubscription);
  const { mutateAsync: updateAllergicIngMutation } = useMutation(updateFoodPreference);

  const [warningIngredients, setWarningIngredients] = useState<IngredientCategory[]>(
    selectedCustomer.macrosData?.allergicFood || []
  );
  const [headerFoodRestrictionOpen, setHederFoodRestrictionOpen] = useState<boolean>(false);

  const handleUpdateAllergicFood = async (data: UpdateSubscriptionReq) => {
    if (
      !data.macrosData?.allergicFood?.includes(IngredientCategory.other) &&
      selectedCustomer.macrosData.allergicFood?.includes(IngredientCategory.other)
    ) {
      await updateAllergicIngMutation({
        subscriptionId: selectedCustomer.id,
        allergicIng: [],
        favorite: favMealIds || [],
        healthConditions: healthConditionsData,
        notes: customerNotes
      }).finally(() => {
        setAllergicIngredients([]);
      });
    }
    await updateMutation({
      id: selectedCustomer.id,
      ...data
    });
  };

  const handleUpdateFoodPrefHealthCondition = async (data: any) => {
    await updateAllergicIngMutation({
      subscriptionId: selectedCustomer.id,
      allergicIng: allergicIngredients,
      healthConditions: data,
      notes: customerNotes,
      favorite: favMealIds
    });
  };

  const handleUpdateFoodPrefIngredientsToAvoid = async (data: any) => {
    const isOtherAlreadySelected = selectedCustomer.macrosData?.allergicFood?.includes(IngredientCategory.other);
    const isOtherSelected = warningIngredients?.includes(IngredientCategory.other);
    if (isOtherAlreadySelected && !isOtherSelected) {
      await updateAllergicIngMutation({
        subscriptionId: selectedCustomer.id,
        allergicIng: [],
        healthConditions: healthConditionsData,
        notes: customerNotes,
        favorite: favMealIds
      });
      setAllergicIngredients([]);
    } else if (
      isOtherSelected &&
      (difference(foodPref?.ingredientsToAvoid, data).length > 0 ||
        difference(data, foodPref?.ingredientsToAvoid || []).length > 0)
    ) {
      await updateAllergicIngMutation({
        subscriptionId: selectedCustomer.id,
        allergicIng: data,
        healthConditions: healthConditionsData,
        notes: customerNotes,
        favorite: favMealIds
      });
      setAllIngredients(data);
    }
    refetch();
  };

  const { isLoading: LoadingWarningIng } = useQuery<any, Error, { data: Ingredient[] }>(
    [
      'ingredients',
      {
        filters: {
          country: selectedCustomer.country,
          brand: selectedCustomer.brand || Brand.CALO,
          name: filterName || undefined
        }
      }
    ],
    getListWithParams,
    {
      keepPreviousData: false,
      suspense: false,
      onSuccess: (allIngredientList) => {
        setAllIngredients(allIngredientList?.data);
      }
    }
  );

  useQuery<any, Error, { data: Ingredient[] }>(
    [
      'ingredients',
      {
        filters: {
          country: selectedCustomer.country,
          brand: selectedCustomer.brand || Brand.CALO,
          ids: healthConditionIngredient || undefined
        }
      }
    ],
    getListWithParams,
    {
      enabled: healthConditionIngredient.length > 0,
      keepPreviousData: false,
      onSuccess: (allIngredientList) => {
        if (allIngredientList.data.length > 0) {
          const ingredientSelectedData = allIngredientList.data?.map(
            (ingData) =>
              ingData && {
                category: ingData.category,
                id: ingData.id,
                name: ingData.name,
                slug: ingData.slug,
                internalName: ingData.internalName
              }
          );
          setAllergicIngredients((old) =>
            old ? uniqBy(compact([...old, ...ingredientSelectedData]), 'id') : uniqBy(compact(ingredientSelectedData), 'id')
          );
          const updatedOtherIngredients = allergicIngredients
            ? uniqBy(compact([...allergicIngredients, ...allIngredientList.data.map((ingredient) => ingredient)]), 'id')
            : uniqBy(compact(allIngredientList.data.map((ingredient) => ingredient)), 'id');
          handleUpdateFoodPrefIngredientsToAvoid(updatedOtherIngredients);
          setHealthConditionIngredient([]);
        }
      }
    }
  );

  const options: Options<Ingredient>[] = useMemo(
    () =>
      sortBy(uniqBy([...allIngredients, ...allergicIngredients], 'id'), (ingredient) => `${ingredient.name.en}`)
        .map((ingredient) => ({
          value: ingredient.id,
          data: { ...ingredient },
          label: `${ingredient.name.en} (${ingredient.internalName || ''})`
        }))
        .filter((ingOption) => !allergicIngredients.find((ing) => ing.id === ingOption.value)),
    [allIngredients, allergicIngredients]
  );

  const handleHealthConditionsSelector = (selectedHC: HealthConditions[]) => {
    const newHealthCondition = selectedHC.find((HC) => !healthConditionsData?.includes(HC));
    // @ts-ignore
    const healthConditionIngredientNameList =
      healthConditionsIngredientLists[newHealthCondition!][selectedCustomer.kitchen || Kitchen.BH1];
    handleUpdateFoodPrefHealthCondition(selectedHC);
    setHealthConditionData(selectedHC);
    if (!warningIngredients?.includes(IngredientCategory.other)) {
      handleUpdateAllergicFood({
        ...selectedCustomer,
        macrosData: {
          ...selectedCustomer.macrosData,
          allergicFood: [...warningIngredients, IngredientCategory.other]
        }
      });
      setWarningIngredients(() => [...warningIngredients, IngredientCategory.other]);
    }
    setHealthConditionIngredient(healthConditionIngredientNameList);
  };

  const handleChangeWarningIng = (data: IngredientCategory[]) => {
    setWarningIngredients(data);
    handleUpdateAllergicFood({
      ...selectedCustomer,
      macrosData: {
        ...selectedCustomer.macrosData,
        allergicFood: data
      }
    });
  };

  return (
    <>
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'space-between'}
        onClick={() => setHederFoodRestrictionOpen(!headerFoodRestrictionOpen)}
      >
        <Typography
          style={{
            fontFamily: 'Roboto',
            fontSize: '19px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '23px',
            letterSpacing: '-0.38px'
          }}
        >
          Food Restrictions
        </Typography>
        {headerFoodRestrictionOpen ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
      </Box>

      {/* Health condition pick up */}
      <Box style={{ marginTop: '4px', display: headerFoodRestrictionOpen ? 'flex' : 'none', flexDirection: 'column' }}>
        <Stack style={{ marginTop: 12, marginBottom: 12 }}>
          <TextField
            select
            label="Health Condition"
            value={healthConditionsData || []}
            placeholder="Select Ingredients..."
            SelectProps={{
              multiple: true,
              value: healthConditionsData || [],
              onChange: (e) => handleHealthConditionsSelector(e.target.value as HealthConditions[]),
              placeholder: 'Select Ingredients...'
            }}
            sx={{ my: 1 }}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {Object.values(HealthConditions).map((HC) => (
              <MenuItem key={HC} value={HC}>
                {startCase(HC.toLowerCase())}
              </MenuItem>
            ))}
          </TextField>
          <Box sx={{ padding: '4px', display: healthConditionsData ? 'flex' : 'none' }}>
            <Stack
              sx={{
                border: 0,
                borderColor: caloTheme.palette.neutral100,
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}
            >
              {healthConditionsData &&
                healthConditionsData.length > 0 &&
                healthConditionsData.map((healthCondition: HealthConditions) => (
                  <FoodRestrictionItem
                    key={healthCondition}
                    keyProp={healthCondition}
                    text={startCase(healthCondition.toLowerCase())}
                    onClick={() => {
                      setHealthConditionData(healthConditionsData.filter((HC) => HC !== healthCondition));
                      handleUpdateFoodPrefHealthCondition(healthConditionsData.filter((HC) => HC !== healthCondition));
                    }}
                  />
                ))}
            </Stack>
          </Box>
        </Stack>
      </Box>

      {/* Warning pick up */}
      <Box style={{ marginTop: '4px', display: headerFoodRestrictionOpen ? 'flex' : 'none', flexDirection: 'column' }}>
        <Stack>
          <TextField
            select
            placeholder={'Search Ingredients'}
            label="Warning Ingredients"
            value={warningIngredients}
            SelectProps={{
              multiple: true,
              value: warningIngredients || [],
              onChange: (data) => handleChangeWarningIng(data.target.value as IngredientCategory[])
            }}
            InputProps={{ inputProps: { style: { borderRadius: 8, width: 'full' } }, style: { borderRadius: 8 } }}
          >
            {Object.values(IngredientCategory)
              .filter((r) => (warningIngredients ? !warningIngredients.includes(r) : r))
              .map((row) => (
                <MenuItem key={row} value={row}>
                  {startCase(row)}
                </MenuItem>
              ))}
          </TextField>
          <Box sx={{ paddingY: 1, paddingX: '4px', display: selectedCustomer.macrosData!.allergicFood ? 'relative' : 'none' }}>
            <Stack
              sx={{
                border: 0,
                borderColor: caloTheme.palette.neutral100,
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}
            >
              {warningIngredients &&
                warningIngredients.length > 0 &&
                warningIngredients.map((allergicIng: any) =>
                  FoodRestrictionItem({
                    keyProp: `${allergicIng.id}-${allergicIng.internalName}-${allergicIng.slug}`,
                    text: `${startCase(allergicIng.toLowerCase())}`,
                    onClick: () => (
                      setWarningIngredients(() => warningIngredients.filter((wi) => wi !== allergicIng)),
                      handleUpdateAllergicFood({
                        ...selectedCustomer,
                        macrosData: {
                          ...selectedCustomer.macrosData,
                          allergicFood: warningIngredients.filter((wi) => wi !== allergicIng) || []
                        }
                      })
                    )
                  })
                )}
            </Stack>
          </Box>
        </Stack>
      </Box>
      {/* Other Ingredients pick up */}
      {warningIngredients?.includes(IngredientCategory.other) && (
        <Box style={{ marginTop: '4px', display: headerFoodRestrictionOpen ? 'flex' : 'none', flexDirection: 'column' }}>
          <Stack sx={{ my: 1, pt: 1 }}>
            <Autocomplete
              style={{ borderRadius: 4 }}
              multiple
              renderTags={(value) =>
                value.map((option, index) => (
                  <div key={index} style={{ display: 'none' }}>
                    {option.label}
                  </div>
                ))
              }
              onChange={(event, newValue, r) => {
                if (r === 'selectOption') {
                  // @ts-ignore
                  setAllergicIngredients((old) =>
                    old
                      ? // @ts-ignore
                        uniq(compact([...old, ...newValue.map((ingredient) => ingredient.data)]))
                      : // @ts-ignore
                        uniq(newValue.map((ing) => ing.data))
                  );
                  // @ts-ignore
                  handleUpdateFoodPrefIngredientsToAvoid(
                    // @ts-ignore
                    uniq(compact([...allergicIngredients, ...newValue.map((ingredient) => ingredient.data)]))
                  );
                }
              }}
              defaultValue={[]}
              options={options}
              loading={LoadingWarningIng}
              clearIcon={null}
              onInputChange={(d, text) => {
                setFilterName(text);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Other Ingredients"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {LoadingWarningIng ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />

            <Box sx={{ padding: '4px' }}>
              <Stack
                sx={{
                  border: 0,
                  borderColor: caloTheme.palette.neutral100,
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}
              >
                {allergicIngredients &&
                  allergicIngredients.length > 0 &&
                  allergicIngredients.map((allergicIng: any) =>
                    FoodRestrictionItem({
                      keyProp: `${allergicIng.id}-${allergicIng.internalName}-${allergicIng.slug}`,
                      text: `${startCase(allergicIng.name.en.toLowerCase())} (${startCase(allergicIng.internalName)})`,
                      onClick: () => (
                        handleUpdateFoodPrefIngredientsToAvoid(allergicIngredients.filter((al) => al.id !== allergicIng.id)),
                        setAllergicIngredients(() => allergicIngredients.filter((al) => al.id !== allergicIng.id))
                      )
                    })
                  )}
              </Stack>
            </Box>
          </Stack>
        </Box>
      )}
    </>
  );
};
export default FoodRestrictions;
