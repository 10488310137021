import { forwardRef } from 'react';

import { Permission, UpdateFoodReq } from '@calo/dashboard-types';
import { Dictionary, FoodDietType } from '@calo/types';
import { Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import NewFoodComponentPicker from 'components/MUI/NewFoodComponentPicker';
import { useUserRoles } from 'lib/hooks';
import { FoodComponent } from 'lib/interfaces';
import { getOrderedComponents } from './helpers';

interface ComponentsProps {
  name: string;
  foodComponents: FoodComponent[];
  isDisabled: boolean;
  values: UpdateFoodReq;
  isLoading: boolean;
  allComponents: Dictionary<FoodComponent>;
  handleComponentsChange: (value: any) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setName: (value: string) => void;
}

const Components = forwardRef<HTMLDivElement, ComponentsProps>(
  (
    {
      values,
      isDisabled,
      foodComponents,
      isLoading,
      allComponents,
      setFieldValue,
      handleComponentsChange,
      setName,
      name
    }: ComponentsProps,
    ref
  ) => {
    const roles = useUserRoles();

    return (
      <>
        {roles.includes(Permission.VIEW_FOOD_COMPONENTS) && (
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            ref={ref}
            sx={{ backgroundColor: caloTheme.palette.white, borderRadius: '16px' }}
          >
            <Typography
              sx={{
                m: 2,
                color: caloTheme.palette.neutral900,
                textTransform: 'capitalize',
                fontSize: '19px',
                fontWeight: 600,
                lineHeight: '23px'
              }}
            >
              Components
            </Typography>
            <NewFoodComponentPicker
              name={name}
              setName={setName}
              isChild={false}
              isLoading={isLoading}
              searchComponentList={foodComponents}
              isDisabled={isDisabled}
              selectedComponents={getOrderedComponents(values.components!, allComponents)}
              allComponents={allComponents}
              setFieldValue={setFieldValue}
              onChange={handleComponentsChange}
              isPreBuildCustom={Boolean(values.tags?.includes(FoodDietType.preBuiltCustom))}
            />
          </Stack>
        )}
      </>
    );
  }
);

export default Components;
