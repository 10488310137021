import '@aws-amplify/ui-react/styles.css';

import { Suspense, useEffect, useState } from 'react';

import { setUISettingsVisible } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import cx from 'classnames';
import { Sidebar, TopBar } from 'components';
import { useDocumentMedia } from 'hooks';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { useUserRoles } from 'lib/hooks';
import queryClient from 'lib/queryClient';
import { persistor, store } from 'lib/store';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import ChefPlayground from 'views/ChefPlayground';

import { Permission } from '@calo/dashboard-types';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Auth } from 'aws-amplify';

import CoolerBags from 'views/CoolerBags/CoolerBags';
import { TopBarContext } from '../context';
import {
  AddressChange,
  Coupons,
  Dashboard,
  Deliveries,
  DeliveryPlanner,
  Drivers,
  Food,
  Gift,
  KDSPermissions,
  KDSUsers,
  Kitchen,
  Menu,
  Payment,
  Plans,
  Refunds,
  Subscriptions,
  SubscriptionsV2,
  SubscriptionUsers,
  Users
} from '../views';
import AffiliateProgram from '../views/AffiliateProgram/AffiliateProgram';
import Business from '../views/Business';
import Complaints from '../views/Complaints';
import DriversMetrics from '../views/DriversMetric';
import EmployeePerks from '../views/EmployeePerks';
import InvitationStats from '../views/InvitationStats';
import Nutritionist from '../views/Nutrition/Nutritionist';
import MealSwap from '../views/OperationsChange/OperationsChange';
import SpecialRequest from '../views/SpecialRequest';
import SupplyChainReports from '../views/SupplyChainReports';
import SupplyChainUsers from '../views/SupplyChainUsers';
import TicketsList from '../views/Tickets/TicketsList';

process.env.REACT_APP_SENTRY_KEY &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5
  });
Auth.configure({
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_AWS_USER_POOL,
  userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT,
  identityPoolId: process.env.REACT_APP_AWS_USER_IDENTITY_POOL
});

const App = () => {
  const { isMobile } = useDocumentMedia();
  const roles = useUserRoles();

  const [isOpen, setIsOpen] = useState(!isMobile);

  useEffect(() => {
    history.listen(() => {
      if (isMobile) {
        setIsOpen(false);
      }
      if (store.getState().ui.settings) {
        store.dispatch(setUISettingsVisible(true));
      }
    });
  }, []);

  return (
    <ThemeProvider theme={caloTheme}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <TopBarContext.Provider value={{}}>
              <Router history={history}>
                <Suspense fallback={<section className="element is-loading" />}>
                  <Sidebar isOpen={isOpen} />
                  <TopBar setIsOpen={setIsOpen} isOpen={isOpen} />
                  <div
                    className={cx('flex flex-1 flex-col mt-16 overflow-x-hidden', { 'ml-56': isOpen })}
                    id="scrollable"
                    style={{ backgroundColor: '#F7F7F7' }}
                  >
                    <Suspense fallback={<section className="element is-loading" />}>
                      <div className="flex flex-col p-8 flex-1">
                        <Switch>
                          <Route exact path={Routes.root} component={Dashboard} />

                          {(roles.includes(Permission.VIEW_ROUTE_PLAN_LIST) ||
                            roles.includes(Permission.VIEW_MAP) ||
                            roles.includes(Permission.UPDATE_KITCHEN)) && (
                            <Route exact component={DeliveryPlanner} path={Routes.deliveryPlanner} />
                          )}

                          <Route
                            path={[
                              ...(roles.includes(Permission.VIEW_DRIVER_METRIC_LIST) ? [Routes.driversMetric] : []),
                              ...(roles.includes(Permission.VIEW_DRIVER_METRIC) ? [Routes.driverMetric] : [])
                            ]}
                            exact
                            component={DriversMetrics}
                          />

                          <Route
                            path={[...(roles.includes(Permission.VIEW_DRIVER_COOLER_BAG_STATS) ? [Routes.coolerBags] : [])]}
                            exact
                            component={CoolerBags}
                          />

                          <Route
                            path={[...(roles.includes(Permission.LIST_APP_COGNITO_USERS) ? [Routes.subscriptionUsers] : [])]}
                            exact
                            component={SubscriptionUsers}
                          />

                          {roles.includes(Permission.HEALTH_CONDITIONS_PLAN_MANAGEMENT) && (
                            <Route path={[Routes.nutritionists]} exact component={Nutritionist} />
                          )}

                          <Route path={[Routes.plansCalculator]} exact component={Plans} />

                          <Route
                            path={[
                              ...(roles.includes(Permission.VIEW_BUSINESS_USER_LIST) ? [Routes.accountUsersList] : []),
                              ...(roles.includes(Permission.CREATE_BUSINESS_USER) ? [Routes.newAccounts] : []),
                              ...(roles.includes(Permission.VIEW_BUSINESS_USER) ? [Routes.accountUser] : []),
                              ...(roles.includes(Permission.VIEW_BUSINESS_DELIVERY_LIST) ? [Routes.orders] : [])
                            ]}
                            exact
                            component={Business}
                          />

                          <Route
                            path={[
                              ...(roles.includes(Permission.VIEW_SUBSCRIPTION_LIST) ? [Routes.subscriptions] : []),
                              ...(roles.includes(Permission.CREATE_SUBSCRIPTION) ? [Routes.newSubscription] : []),
                              ...(roles.includes(Permission.VIEW_SUBSCRIPTION) ||
                              roles.includes(Permission.VIEW_DELIVERY_SUBSCRIPTION)
                                ? [Routes.subscription]
                                : [])
                            ]}
                            exact
                            component={Subscriptions}
                          />

                          <Route
                            path={[...(roles.includes(Permission.LIST_CUSTOMER_COMPLAINT) ? [Routes.complaints] : [])]}
                            exact
                            component={Complaints}
                          />

                          <Route
                            path={[
                              ...(roles.includes(Permission.VIEW_SUBSCRIPTION_LIST) ? [Routes.subscriptions2] : []),
                              ...(roles.includes(Permission.CREATE_SUBSCRIPTION) ? [Routes.newSubscription2] : []),
                              ...(roles.includes(Permission.VIEW_SUBSCRIPTION) ||
                              roles.includes(Permission.VIEW_DELIVERY_SUBSCRIPTION)
                                ? [Routes.subscription2]
                                : [])
                            ]}
                            exact
                            component={SubscriptionsV2}
                          />

                          <Route
                            path={[
                              ...(roles.includes(Permission.VIEW_DELIVERY_LIST) ? [Routes.deliveries] : []),
                              ...(roles.includes(Permission.VIEW_DELIVERY) ? [Routes.delivery] : [])
                            ]}
                            exact
                            component={Deliveries}
                          />

                          <Route
                            path={[...(roles.includes(Permission.LIST_DELIVERY_TICKET) ? [Routes.logisticChange] : [])]}
                            exact
                            component={AddressChange}
                          />

                          <Route
                            path={[...(roles.includes(Permission.LIST_DELIVERY_TICKET) ? [Routes.operationsChanges] : [])]}
                            exact
                            component={MealSwap}
                          />

                          <Route
                            path={[
                              ...(roles.includes(Permission.VIEW_FOOD_LIST) ? [Routes.foodList] : []),
                              ...(roles.includes(Permission.VIEW_FOOD_COMPONENTS_LIST) ? [Routes.foodComponentList] : []),
                              ...(roles.includes(Permission.VIEW_INGREDIENT_LIST) ? [Routes.ingredients] : []),
                              ...(roles.includes(Permission.VIEW_FOOD_LIST_BY_SLUG) ? [Routes.foodSlug] : []),
                              ...(roles.includes(Permission.VIEW_FOOD_LIST_BY_SLUG) ? [Routes.food] : []),
                              ...(roles.includes(Permission.VIEW_FOOD_COMPONENTS) ? [Routes.foodComponent] : []),
                              ...(roles.includes(Permission.CREATE_FOOD) ? [Routes.newFood] : []),
                              ...(roles.includes(Permission.CREATE_FOOD_COMPONENTS) ? [Routes.newFoodComponent] : []),
                              ...(roles.includes(Permission.VIEW_INGREDIENT) ? [Routes.ingredient] : []),
                              ...(roles.includes(Permission.CREATE_INGREDIENT) ? [Routes.newIngredient] : []),
                              ...(roles.includes(Permission.VIEW_RATING_LIST) ? [Routes.feedback, Routes.foodFeedback] : []),
                              ...(roles.includes(Permission.VIEW_KITCHEN_LOGS_LIST) ? [Routes.foodLogs] : [])
                            ]}
                            exact
                            component={Food}
                          />

                          <Route
                            path={[
                              ...(roles.includes(Permission.VIEW_MENU_LIST) ? [Routes.menuList] : []),
                              ...(roles.includes(Permission.VIEW_MENU) ? [Routes.menu] : []),
                              ...(roles.includes(Permission.CREATE_MENU) ? [Routes.newMenu] : []),
                              ...(roles.includes(Permission.VIEW_ADDONS_LIST) ? [Routes.addonsList] : []),
                              ...(roles.includes(Permission.CREATE_ADDONS) ? [Routes.newAddons] : []),
                              ...(roles.includes(Permission.VIEW_ADDONS) ? [Routes.addons] : []),
                              ...(roles.includes(Permission.VIEW_ADDONS_SUBSCRIPTION_LIST)
                                ? [Routes.addonsSubscriptionList]
                                : []),
                              ...(roles.includes(Permission.CREATE_ADDONS_SUBSCRIPTION) ? [Routes.newAddonsSubscription] : []),
                              ...(roles.includes(Permission.UPDATE_ADDONS_SUBSCRIPTION) ? [Routes.addonsSubscription] : [])
                            ]}
                            exact
                            component={Menu}
                          />

                          <Route
                            path={[
                              ...(roles.includes(Permission.VIEW_PROTOTYPE_FOOD_LIST) ? [Routes.playgroundFoodList] : []),
                              ...(roles.includes(Permission.VIEW_PROTOTYPE_COMPONENT_LIST)
                                ? [Routes.playgroundComponentList]
                                : []),
                              ...(roles.includes(Permission.VIEW_PROTOTYPE_INGREDIENT_LIST)
                                ? [Routes.playgroundIngredientList]
                                : []),
                              ...(roles.includes(Permission.VIEW_PROTOTYPE_INGREDIENT) ? [Routes.playgroundIngredient] : []),
                              ...(roles.includes(Permission.CREATE_PROTOTYPE_INGREDIENT) ? [Routes.playgroundNewIngredient] : []),
                              ...(roles.includes(Permission.VIEW_PROTOTYPE_FOOD) ? [Routes.playgroundFood] : []),
                              ...(roles.includes(Permission.VIEW_PROTOTYPE_COMPONENT) ? [Routes.playgroundComponent] : []),
                              ...(roles.includes(Permission.CREATE_PROTOTYPE_FOOD) ? [Routes.playgroundNewFood] : []),
                              ...(roles.includes(Permission.CREATE_PROTOTYPE_COMPONENT) ? [Routes.playgroundNewComponent] : []),
                              ...(roles.includes(Permission.VIEW_PROTOTYPE_FOOD_LIST_BY_SLUG) ? [Routes.playgroundFoodSlug] : [])
                            ]}
                            exact
                            component={ChefPlayground}
                          />

                          <Route
                            path={[
                              ...(roles.includes(Permission.VIEW_KITCHEN_LIST) ? [Routes.kitchenList] : []),
                              ...(roles.includes(Permission.VIEW_KITCHEN_LIST) && roles.includes(Permission.VIEW_KITCHEN)
                                ? [Routes.waitingListMap]
                                : []),
                              ...(roles.includes(Permission.VIEW_KITCHEN) ? [Routes.kitchen] : []),
                              ...(roles.includes(Permission.VIEW_FOOD_STATS) ? [Routes.kitchenFoodStats] : []),
                              ...(roles.includes(Permission.VIEW_PORTION_STATS) ? [Routes.kitchenPortionStats] : []),
                              ...(roles.includes(Permission.VIEW_INGREDIENT_STATS) ? [Routes.kitchenIngredientStats] : []),
                              ...(roles.includes(Permission.VIEW_PACKAGE_ELEMENTS_STATS) ? [Routes.packagingStats] : [])
                            ]}
                            exact
                            component={Kitchen}
                          />

                          <Route
                            path={[
                              ...(roles.includes(Permission.VIEW_FOOD_PACKAGE_LIST) ? [Routes.packagesList] : []),
                              ...(roles.includes(Permission.CREATE_FOOD_PACKAGE) ? [Routes.newPackage] : []),
                              ...(roles.includes(Permission.VIEW_FOOD_PACKAGE) ? [Routes.package] : []),
                              ...(roles.includes(Permission.VIEW_FOOD_PACKAGE_ELEMENT_LIST) ? [Routes.packageElementList] : []),
                              ...(roles.includes(Permission.CREATE_FOOD_PACKAGE_ELEMENT) ? [Routes.newPackageElement] : []),
                              ...(roles.includes(Permission.VIEW_FOOD_PACKAGE_ELEMENT) ? [Routes.packageElement] : [])
                            ]}
                            exact
                            component={Menu}
                          />

                          {roles.includes(Permission.VIEW_DRIVER_LIST) && (
                            <Route path={Routes.drivers} exact component={Drivers} />
                          )}

                          {roles.includes(Permission.VIEW_REFUND_LIST) && (
                            <Route path={Routes.refunds} exact component={Refunds} />
                          )}

                          {roles.includes(Permission.VIEW_WALLET_LIST) && (
                            <Route path={Routes.payment} exact component={Payment} />
                          )}

                          {(roles.includes(Permission.VIEW_INVITATION_CODE_USER_STATS) ||
                            roles.includes(Permission.VIEW_INVITATION_CODE_STATS)) && (
                            <Route path={Routes.invitationStats} exact component={InvitationStats} />
                          )}

                          {roles.includes(Permission.VIEW_TICKET_LIST) && [
                            <Route key={Routes.tickets} path={Routes.tickets} component={TicketsList} />
                          ]}

                          {roles.includes(Permission.LIST_GROUP_GIFT) && [
                            <Route key={Routes.gifts} path={Routes.gifts} component={Gift} />
                          ]}

                          <Route
                            path={[
                              ...(roles.includes(Permission.VIEW_COUPON_LIST) ? [Routes.couponsList] : []),
                              ...(roles.includes(Permission.VIEW_COUPON) ? [Routes.coupon] : []),
                              ...(roles.includes(Permission.CREATE_COUPON) ? [Routes.newCoupon] : [])
                            ]}
                            exact
                            component={Coupons}
                          />

                          <Route
                            path={[
                              ...(roles.includes(Permission.VIEW_USER_LIST) ? [Routes.usersList] : []),
                              ...(roles.includes(Permission.CREATE_USER) ? [Routes.newUser] : []),
                              ...(roles.includes(Permission.VIEW_USER) ? [Routes.user] : [])
                            ]}
                            exact
                            component={Users}
                          />

                          <Route
                            path={[...(roles.includes(Permission.LIST_GIFTS) ? [Routes.specialRequest] : [])]}
                            exact
                            component={SpecialRequest}
                          />

                          <Route
                            path={[
                              ...(roles.includes(Permission.VIEW_KDS_USER_LIST) ? [Routes.kdsUsersList] : []),
                              ...(roles.includes(Permission.CREATE_KDS_USER) ? [Routes.newKdsUser] : []),
                              ...(roles.includes(Permission.VIEW_KDS_USER) ? [Routes.kdsUser] : [])
                            ]}
                            exact
                            component={KDSUsers}
                          />
                          {(roles.includes(Permission.VIEW_KDS_PERMISSIONS_LIST) ||
                            roles.includes(Permission.UPDATE_KDS_PERMISSIONS) ||
                            roles.includes(Permission.DELETE_KDS_PERMISSIONS) ||
                            roles.includes(Permission.CREATE_KDS_PERMISSIONS)) && (
                            <Route path={[Routes.kdsPermissions]} exact component={KDSPermissions} />
                          )}

                          <Route
                            path={[...(roles.includes(Permission.VIEW_EMPLOYEE_PERKS) ? [Routes.employeePerks] : [])]}
                            exact
                            component={EmployeePerks}
                          />

                          {roles.includes(Permission.VIEW_SUPPLY_CHAIN_USER_LIST) && (
                            <Route
                              path={[
                                ...(roles.includes(Permission.VIEW_SUPPLY_CHAIN_USER_LIST) ? [Routes.supplyChainUsersList] : []),
                                ...(roles.includes(Permission.CREATE_SUPPLY_CHAIN_USER) ? [Routes.newSupplyChainUser] : []),
                                ...(roles.includes(Permission.VIEW_SUPPLY_CHAIN_USER) ? [Routes.supplyChainUser] : [])
                              ]}
                              exact
                              component={SupplyChainUsers}
                            />
                          )}
                          {
                            <Route
                              path={[
                                ...(roles.includes(Permission.VIEW_SUPPLY_CHAIN_REPORTS_LIST)
                                  ? [Routes.supplyChainReportsList]
                                  : []),
                                ...(roles.includes(Permission.VIEW_MATERIAL_RESOURCE_PLANING_REPORT)
                                  ? [Routes.supplyChainMRPReport]
                                  : []),
                                ...(roles.includes(Permission.VIEW_MOVING_COST_REPORT)
                                  ? [Routes.supplyChainMovingCostReport]
                                  : [])
                              ]}
                              exact
                              component={SupplyChainReports}
                            />
                          }
                          {(roles.includes(Permission.VIEW_USER_PERMISSIONS_LIST) ||
                            roles.includes(Permission.UPDATE_USER_PERMISSIONS) ||
                            roles.includes(Permission.DELETE_USER_PERMISSIONS) ||
                            roles.includes(Permission.CREATE_USER_PERMISSIONS)) && (
                            <Route path={[Routes.permissions]} exact component={Users} />
                          )}

                          {(roles.includes(Permission.VIEW_AFFILIATE_PROFILE) ||
                            roles.includes(Permission.CREATE_AFFILIATE_PROFILE) ||
                            roles.includes(Permission.PAY_AFFILIATE_COMMISSION)) && (
                            <>
                              <Route path={[Routes.affiliateProfilesList]} exact component={AffiliateProgram} />
                              <Route path={[Routes.affiliateTransactionsList]} exact component={AffiliateProgram} />
                            </>
                          )}

                          <Redirect to={Routes.root} />
                        </Switch>
                      </div>
                    </Suspense>
                  </div>
                </Suspense>
              </Router>
              <ToastContainer position="top-right" />
            </TopBarContext.Provider>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
