import { RefObject, useEffect, useRef, useState } from 'react';

import { Permission, UpdateFoodReq } from '@calo/dashboard-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Stack } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { ConfirmationModal, ModalRef } from 'components';
import { MealTabs } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Food } from 'lib/interfaces';
import Header from '../../Header';
import TabsComponent from './TabsComponent';

interface StickyHeaderProps {
  isSubmitting: boolean;
  dirty: boolean;
  isDisabled: boolean;
  handleSubmit: () => void;
  values: UpdateFoodReq;
  sectionRefs: RefObject<HTMLDivElement>[];
  isValid: boolean;
  selectedFood: Food;
  foodWithAllSizes: Food[];
  setSelectedFood: React.Dispatch<React.SetStateAction<Food | undefined>>;
  setFoodWithAllSizes: (food: Food[]) => void;
  filteredFoodUsed: {
    type: 'mainMenu' | 'addonsMenu';
    date?: string;
    week?: number;
  }[];
  refetch: any;
  usedOnMenuButtonColor: string[];
}

const StickyHeader = ({
  isSubmitting,
  dirty,
  refetch,
  setFoodWithAllSizes,
  usedOnMenuButtonColor,
  isDisabled,
  handleSubmit,
  filteredFoodUsed,
  foodWithAllSizes,
  values,
  sectionRefs,
  isValid,
  selectedFood,
  setSelectedFood
}: StickyHeaderProps) => {
  const roles = useUserRoles();

  const [tab, setTab] = useState(0);
  const isTabClickRef = useRef(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    isTabClickRef.current = true;
    sectionRefs[newValue].current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (isTabClickRef.current) {
          isTabClickRef.current = false;
          return;
        }
        const visibleSection = entries.reduce(
          (max, entry) => (entry.intersectionRatio > max.intersectionRatio ? entry : max),
          entries[0]
        );
        const index = sectionRefs.findIndex((ref) => ref.current === visibleSection.target);
        if (index !== -1) {
          setTab(index);
        }
      },
      {
        rootMargin: '-50% 0px -50% 0px'
      }
    );
    for (const ref of sectionRefs) {
      if (ref.current) {
        observer.observe(ref.current);
      }
    }

    return () => {
      observer.disconnect();
    };
  }, [sectionRefs]);

  const confirmModalRef = useRef<ModalRef>();
  const handleConfirmation = () => {
    if (filteredFoodUsed?.length > 0) {
      confirmModalRef.current?.open();
    } else {
      handleSubmit();
    }
  };

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      spacing={2}
      sx={{
        backgroundColor: caloTheme.palette.white,
        p: 2,
        position: 'sticky',
        top: 0,
        zIndex: 1100,
        margin: 0,
        marginLeft: '-35px !important',
        marginRight: '-35px !important',
        marginTop: '-35px !important'
      }}
    >
      <Header
        refetch={refetch}
        selectedFood={selectedFood}
        foodWithAllSizes={foodWithAllSizes}
        setSelectedFood={setSelectedFood}
        setFoodWithAllSizes={setFoodWithAllSizes}
        filteredFoodUsed={filteredFoodUsed}
        usedOnMenuButtonColor={usedOnMenuButtonColor}
        isDisabled={isDisabled}
      />
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <TabsComponent
          tab={tab}
          handleTabChange={handleTabChange}
          tabLabels={Object.values(MealTabs).map((mealTab) => mealTab)}
        />

        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={2}>
          <LoadingButton
            loadingPosition={'center'}
            id="saveButtonExactMeal"
            type="submit"
            loading={isSubmitting}
            disabled={!dirty || !isValid || isSubmitting || isDisabled || !roles.includes(Permission.BATCH_UPDATE_PROTOTYPE_FOOD)}
            onClick={() => handleConfirmation()}
            sx={{
              textTransform: 'capitalize',
              color: caloTheme.palette.black,
              border: 1,
              borderRadius: '8px',
              borderColor: caloTheme.palette.black,
              py: '10px',
              px: '26px',
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: 600,
              ':hover': {
                borderColor: caloTheme.palette.black,
                backgroundColor: caloTheme.palette.white
              },
              ':disabled': {
                borderColor: caloTheme.palette.grey['400']
              }
            }}
          >
            Save
          </LoadingButton>
        </Stack>

        <ConfirmationModal ref={confirmModalRef} values={values} action={handleSubmit}>
          <div className="mt-4 mb-4 ml-4">
            <span className="flex">
              <label className="label">The change will take effect on the next new generated menu </label>
            </span>
          </div>
        </ConfirmationModal>
      </Stack>
    </Stack>
  );
};

export default StickyHeader;
