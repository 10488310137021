import React, { useState, useRef, useEffect, ReactNode, ReactElement, cloneElement } from 'react';
import cx from 'classnames';

interface DropdownProps {
  trigger: ReactElement;
  children: ReactNode;
  isRight?: boolean;
}

const Dropdown = ({ trigger, children, isRight }: DropdownProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [visible, setVisible] = useState(false);
  const handleToggle = () => {
    setVisible(!visible);
  };

  const handleGlobalClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleGlobalClick);
    return () => {
      document.removeEventListener('mousedown', handleGlobalClick);
    };
  }, [visible]);

  return (
    <div
      className={cx('dropdown', {
        'is-active': visible,
        'is-right': isRight
      })}
      ref={ref}
    >
      <div className="dropdown-trigger">{cloneElement(trigger, { onClick: handleToggle })}</div>
      <div className="dropdown-menu" role="menu">
        <div className="dropdown-content">{children}</div>
      </div>
    </div>
  );
};

export default Dropdown;
