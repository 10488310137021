import { RatedFoodTags } from '@calo/dashboard-types';
import { Brand, Country, Kitchen } from '@calo/types';
import { getListWithParams } from 'actions';
import { Food } from 'lib/interfaces';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

interface useAddingTopRatedMealsProps {
  country: Country;
  kitchen: Kitchen;
  brand: Brand;
}
const DEFAULT_LIMIT = 100;

const useAddingTopRatedMeals = ({ country, kitchen, brand }: useAddingTopRatedMealsProps) => {
  const [page, setPage] = useState<number>(0);
  const [allTopRatedMeals, setAllTopRatedMeals] = useState<Food[]>([]);

  const { data, isLoading } = useQuery<any, Error, { data: Food[]; meta: any }>(
    [
      'food',
      {
        filters: {
          name: undefined,
          country,
          brand,
          kitchen,
          isDeleted: false,
          foodTags: { rating: RatedFoodTags.top }
        },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page,
        limit: DEFAULT_LIMIT
      }
    ],
    getListWithParams,
    {
      keepPreviousData: false
    }
  );

  useEffect(() => {
    if (data?.data) {
      setAllTopRatedMeals([...allTopRatedMeals, ...data.data]);
    }
    console.log(page);
    console.log(Math.ceil(data?.meta.total / DEFAULT_LIMIT) - 1);
    if (data?.meta && page < Math.ceil(data.meta.total / DEFAULT_LIMIT) - 1) {
      setPage(page + 1);
    }
  }, [data, page]);

  useEffect(() => {
    setPage(0);
    setAllTopRatedMeals([]);
  }, [country, kitchen, brand]);

  return {
    isLoadingTopRated: isLoading,
    topRatedMeals: allTopRatedMeals
  };
};

export default useAddingTopRatedMeals;
