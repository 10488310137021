import { Brand, Kitchen } from '@calo/types';
import { resolveCountryFromKitchen } from 'lib';
import { useUserKitchens } from 'lib/hooks';
import { useEffect, useState } from 'react';
import { MenuListFilters } from '../types';

export const useMenuFilters = (searchParams: URLSearchParams): [MenuListFilters, (filters: MenuListFilters) => void] => {
  const userKitchen = useUserKitchens();
  const initialFilters: MenuListFilters = {
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    brand: Brand.CALO,
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  };

  const [filters, setFilters] = useState<MenuListFilters>(initialFilters);

  useEffect(() => {
    searchParams.set('filters', JSON.stringify(filters));
  }, [filters, searchParams]);

  return [filters, setFilters];
};
