import { History } from 'history';
import { isEmpty } from 'lodash';

import { IngredientsFilters, Permission } from '@calo/dashboard-types';
import AddIcon from '@mui/icons-material/Add';
import { Button, Card, Stack, Typography } from '@mui/material';

import { exportIngredient } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import ExportButton from 'components/ExportButton';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Ingredient } from 'lib/interfaces';

interface IngredientListHeaderCardProps {
  filters: IngredientsFilters;
  history: History<unknown>;
  ingredients: Ingredient[] | undefined;
  isDisabled?: boolean;
}

const IngredientListHeaderCard = ({ filters, history, ingredients, isDisabled }: IngredientListHeaderCardProps) => {
  const userRoles = useUserRoles();

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        border: 'none',
        padding: 2,
        paddingX: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '16px'
      }}
    >
      <Typography sx={{ fontSize: '32px', fontWeight: 600 }}>
        Ingredients - {filters.country} / {filters.kitchen}
      </Typography>
      <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          disabled={!userRoles.includes(Permission.CREATE_INGREDIENT) || Boolean(isDisabled)}
          onClick={() => history.push(Routes.newIngredient)}
          sx={{
            borderColor: caloTheme.palette.neutral900,
            borderRadius: '8px',
            color: caloTheme.palette.neutral900,
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: 600,
            px: '16px',
            ':hover': {
              borderColor: caloTheme.palette.neutral900,
              color: caloTheme.palette.neutral900
            }
          }}
        >
          Add
        </Button>
        <ExportButton isDisabled={isEmpty(ingredients)} isLoading={false} onClick={() => exportIngredient(filters)} />
      </Stack>
    </Card>
  );
};

export default IngredientListHeaderCard;
