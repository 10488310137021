import { Permission } from '@calo/dashboard-types';
import { Icon as IconifyIcons } from '@iconify/react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Menu, MenuItem, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { getImageUploadLink, uploadImage } from 'actions';
import { deleteSpecialRequest } from 'actions/specialRequest';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components';
import Popup from 'components/Popup';
import SpecialRequestChipsRenderer from 'components/SpecialRequestChipsRenderer';
import { addDays } from 'date-fns';
import { format, parseISO } from 'date-fns/fp';
import { Routes } from 'lib/enums';
import { getFileExtension } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { SpecialRequest } from 'lib/interfaces';
import { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AttachmentViewPopup } from 'views/Complaints/ComplaintsPopUps';
import EditRequestPopup from '../../EditRequestPopup/EditRequestPopup';
import styles from './styles';

interface SpecialRequestListRowProps {
  request: SpecialRequest;
  refetchList: any;
}

const SpecialRequestListRow = ({ request, refetchList }: SpecialRequestListRowProps) => {
  const roles = useUserRoles();
  const editRequestRef = useRef<ModalRef>();
  const commentRequestRef = useRef<ModalRef>();
  const attachmentRequestRef = useRef<ModalRef>();
  const [deleteRowLoading, setDeleteRowLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingImage, setLoadingImage] = useState(false);
  const { mutateAsync: deleteMutation } = useMutation(deleteSpecialRequest);

  const [selectedRequest, setSelectedRequest] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [displayImage, setDisplayImage] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [progress, setProgress] = useState(0);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    handleUploadImage(files);
  };

  const handleUploadImage = async (files: any) => {
    if (!files && !selectedRequest) {
      return;
    }
    const imageFiles: any[] = [...files];
    for (const file of imageFiles) {
      if (file && !file.type.includes('image')) {
        toast('Image only', { type: 'error', autoClose: 2000 });
        return;
      }
      setLoadingImage(true);
      const imageExtension = getFileExtension(file);
      const { url, fields } = await getImageUploadLink(`gifts/${selectedRequest!.id.split('#')[1]}/.${imageExtension}`, ' ');
      const formData = new FormData();
      for (const key of Object.keys(fields)) {
        formData.append(key, fields[key]);
      }
      formData.append('file', file);

      const imageUpload = await uploadImage(url, formData, {
        onUploadProgress: (progressEvent) =>
          setProgress(Math.min(100, Math.round((progressEvent.loaded * 100) / progressEvent.total)))
      }).finally(() => toast('uploaded', { type: 'success', autoClose: 2000 }));

      if (imageUpload) {
        setDisplayImage((prevImage) => [...prevImage, fields['key'].split('/')[2]]);
        setSelectedRequest({
          ...selectedRequest,
          attachments: displayImage
        });
      } else {
        setSelectedRequest({
          ...selectedRequest,
          attachments: []
        });
      }
      setLoadingImage(false);
    }
  };
  const handleOpenAttachmentsPopup = (request: any) => {
    if (request.attachments) {
      setSelectedRequest(request);
      setDisplayImage(request.attachments || []);
      attachmentRequestRef.current?.open();
    }
  };

  const handleDeleteRow = async (request: any) => {
    try {
      setDeleteRowLoading(true);
      const { data, status } = await deleteMutation(request);
      if (status >= 200 && status <= 299 && data) {
        setAnchorEl(null);
      }
      refetchList();
      setDeleteRowLoading(false);
    } catch (error) {
      setDeleteRowLoading(false);
      console.error(error);
    }
  };
  const deliveryIsEditable = (request: any) => request.date <= format('yyyy-MM-dd')(addDays(new Date(), 1));
  return (
    <>
      <TableRow key={request.id} sx={{ borderBottom: 1, borderBottomColor: caloTheme.palette.neutral100 }}>
        <TableCell style={{ fontWeight: 600, color: caloTheme.palette.secondaryBlue400 }}>
          {roles.includes(Permission.VIEW_SUBSCRIPTION) || roles.includes(Permission.VIEW_DELIVERY_SUBSCRIPTION) ? (
            <Link to={Routes.subscription2.replace(':id', request.userId)}>{request.userName}</Link>
          ) : (
            request.userName
          )}
        </TableCell>
        <TableCell style={{ fontWeight: 400 }}>{request.createdBy.name}</TableCell>
        <TableCell style={{ fontWeight: 400 }}>
          {format('HH:mm a - dd/MM/yyyy')(parseISO(request.updatedAt ?? request.createdAt))}
        </TableCell>
        <TableCell style={{ fontWeight: 400 }}>{format('dd/MM/yyyy')(new Date(request.date))}</TableCell>
        <TableCell style={{ fontWeight: 400, padding: 1 }}>
          {<SpecialRequestChipsRenderer items={request.items} styleOverrides={{ ...styles.chips }} showType={'short'} />}
        </TableCell>
        <TableCell sx={{ justifyContent: 'center' }}>
          <IconButton
            onClick={() => (request.comment ? commentRequestRef.current?.open() : null)}
            disabled={request.comment?.length === 0}
          >
            <IconifyIcons width={21} icon="lucide:message-square" style={{ cursor: 'pointer', margin: 'auto' }} />
          </IconButton>
        </TableCell>
        <TableCell style={{ fontWeight: 400 }}>
          <IconButton onClick={() => handleOpenAttachmentsPopup(request)} disabled={request.attachments?.length === 0}>
            <IconifyIcons width={22} icon="prime:paperclip" style={{ cursor: 'pointer', margin: 'auto' }} />
          </IconButton>
        </TableCell>
        <TableCell style={{ fontWeight: 400 }}>
          <Stack>
            <IconButton key={request.id} disabled={deliveryIsEditable(request)}>
              <IconifyIcons
                width={21}
                icon="pepicons-pop:dots-y"
                style={{ cursor: 'pointer' }}
                onClick={(e: any) => setAnchorEl(e.currentTarget)}
              />
            </IconButton>
            <Menu
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'export-button'
              }}
              id={request.id}
            >
              <>
                <MenuItem
                  onClick={() => {
                    setSelectedRequest(request);
                    editRequestRef.current?.open();
                    setAnchorEl(null);
                  }}
                  sx={{ m: 1 }}
                >
                  <IconifyIcons icon="octicon:pencil-16" style={{ marginRight: 8 }} width={18} />
                  <Typography sx={{ fontWeight: 600, fontSize: '14px', lineHeight: '16px' }}>Edit</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleDeleteRow(request)} sx={{ m: 1 }} disabled={deleteRowLoading}>
                  <IconifyIcons icon="mingcute:delete-2-line" style={{ marginRight: 8 }} width={18} />
                  <Typography sx={{ fontWeight: 600, fontSize: '14px', lineHeight: '16px' }}>Delete</Typography>
                </MenuItem>
              </>
            </Menu>
          </Stack>
        </TableCell>
      </TableRow>

      <Popup fullWidth title="Comment" ref={commentRequestRef} onClose={() => commentRequestRef.current?.close()}>
        <Box flexDirection={'column'} sx={{}}>
          <Stack flexDirection={'row'} sx={{ p: 2, pb: 3 }}>
            <Typography>{request.comment}</Typography>
          </Stack>
          <Stack>
            <Button variant="contained" sx={{ ...styles.confirmButton }} onClick={() => commentRequestRef.current?.close()}>
              Confirm
            </Button>
          </Stack>
        </Box>
      </Popup>

      <AttachmentViewPopup
        urlFile="gifts"
        viewType="view"
        displayImage={displayImage}
        attachmentRef={attachmentRequestRef}
        handleFileChange={handleFileChange}
        selectedComplaint={selectedRequest}
        setSelectedComplaint={setSelectedRequest}
      />

      <Popup
        fullWidth
        maxWidth="laptop"
        title="Edit Request"
        ref={editRequestRef}
        onClose={() => editRequestRef.current?.close()}
        info={
          <>
            <CloseIcon width={24} height={24} onClick={() => editRequestRef.current?.close()} sx={{ cursor: 'pointer' }} />
          </>
        }
      >
        <EditRequestPopup refetchList={refetchList} editRequestRef={editRequestRef} selectedRequest={selectedRequest} />
      </Popup>
    </>
  );
};
export default SpecialRequestListRow;
