import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import { Permission, SubscriptionFilters } from '@calo/dashboard-types';
import { Brand, SubscriptionStatus } from '@calo/types';
import { Box, Switch, ThemeProvider } from '@mui/material';

import { exportSubscriptions, getListWithParams } from 'actions';
import { Button, CaloLoader, Pagination } from 'components';
import { theme } from 'lib/componentStyles';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { PaginatedSubscriptions } from 'lib/interfaces';
import queryClient from 'lib/queryClient';
import Settings from '../Settings';
import SubscriptionRow from '../SubscriptionRow';

type SubscriptionListProps = RouteComponentProps;

const SubscriptionList = ({ history }: SubscriptionListProps) => {
  const [page, setPage] = useState(0);
  const roles = useUserRoles();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [oldView, setOldView] = useState<boolean>(true);
  const [filters, setFilters] = useState<SubscriptionFilters>({
    status: [SubscriptionStatus.ongoing],
    autoRenew: undefined,
    brand: Brand.CALO,
    ...JSON.parse(searchParams.get('filters') || `{}`)
  });

  const { data, isLoading } = useQuery<unknown, Error, PaginatedSubscriptions>(
    ['subscriptions', { page, filters }],
    getListWithParams,
    {
      onSuccess: (data) => {
        searchParams.set('filters', JSON.stringify(filters));
        history.push({
          pathname: location.pathname,
          search: searchParams.toString()
        });
        for (const sub of data?.data || []) {
          queryClient.setQueryData(['subscriptions', sub.id], sub);
        }
      }
    }
  );

  useEffect(() => {
    setPage(0);
  }, [filters]);

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>
                  Subscriptions {filters.country} / {filters.brand} {filters.kitchen && `/ ${filters.kitchen}`}
                </li>
              </ul>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <ThemeProvider theme={theme}>
                <Box sx={{ marginX: 2 }}>
                  <Switch
                    onChange={() => {
                      setOldView(!oldView);
                    }}
                    sx={{ color: 'white' }}
                    checked={oldView}
                  />
                </Box>
              </ThemeProvider>
              {roles.includes(Permission.EXPORT_SUBSCRIPTION_LIST) && (
                <span className="mr-4">
                  <Button onClick={() => exportSubscriptions(filters)} icon="fas fa-file-export" />
                </span>
              )}
              {roles.includes(Permission.CREATE_SUBSCRIPTION) && (
                <Button icon="fas fa-plus" onClick={() => history.push(Routes.newSubscription)} />
              )}
            </div>
          </div>
        </div>
      </section>
      {oldView ? (
        isLoading ? (
          <CaloLoader />
        ) : (
          <div>
            <div className="card has-table has-table-container-upper-radius">
              <div className="card-content">
                <div className="table-container">
                  <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
                    <thead>
                      <tr className="bg-black">
                        <th style={{ color: 'white' }}>Name</th>
                        <th style={{ color: 'white' }}>Phone</th>
                        <th style={{ color: 'white' }}>Email</th>
                        <th style={{ color: 'white' }}>Pending $</th>
                        <th style={{ color: 'white' }}>Balance $</th>
                        <th style={{ color: 'white' }}>First Day</th>
                        <th style={{ color: 'white' }}>Last Delivered Day</th>
                        <th style={{ color: 'white' }}>Frequency</th>
                        <th style={{ color: 'white' }}>Country</th>
                        <th style={{ color: 'white' }}>Kitchen</th>
                        <th style={{ color: 'white' }}>Currency</th>
                        <th></th>
                        <th style={{ color: 'white' }}>ID</th>
                      </tr>
                    </thead>
                    {data && data.data.length === 0 ? (
                      <span className="absolute w-full text-3xl mt-4 text-center font-bold text-gray-400 ">NO SUBSCRIPTION</span>
                    ) : (
                      <tbody>
                        {data &&
                          data.data.map((subscription) => <SubscriptionRow key={subscription.id} subscription={subscription} />)}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        history.push(Routes.subscriptions2)
      )}
      {data && (
        <Pagination
          isHidden={data.data.length === 0}
          limit={data.meta.limit}
          total={data.meta.total}
          page={page}
          onChange={setPage}
        />
      )}
      <Settings onFilter={setFilters} filters={filters} />
    </>
  );
};
export default SubscriptionList;
