import { Icon as Iconify } from '@iconify/react';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Card, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { useEffect, useRef, useState } from 'react';
import { Search, SearchIconWrapper, StyledInputBase, styles } from './styles';

interface WeeklyInfoCardProps {
  isEditable: boolean;
  isDuplicated: boolean;
  weeklyAverageCost: number;
  openAddMealsPopup: () => void;
  setSearchTerm: (value: string) => void;
  setIsEditable: (value: boolean) => void;
  handleUpdateWeeklyMenus: () => Promise<void>;
  setIsWeeklyMenuChanged: (value: boolean) => void;
}

const WeeklyInfoCard = ({
  handleUpdateWeeklyMenus,
  weeklyAverageCost,
  isEditable,
  isDuplicated,
  setIsEditable,
  setSearchTerm,
  openAddMealsPopup
}: WeeklyInfoCardProps) => {
  const [searchValue, setSearchValue] = useState('');
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      setSearchTerm(value);
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isDuplicated) {
      setIsEditable(isDuplicated);
    }
  }, [isDuplicated]);
  return (
    <Card variant="outlined" sx={styles.cardContainer}>
      <Stack sx={{ flexDirection: 'row', width: '50%' }}>
        <Typography sx={styles.text}>Average Weekly Cost</Typography>
        <Typography sx={styles.text}>{weeklyAverageCost.toFixed(2) || 0} %</Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        {isEditable && (
          <>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                value={searchValue}
                sx={styles.searchBar}
                placeholder="Search meals"
                onChange={handleSearchChange}
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </>
        )}
        {isEditable && (
          <Button variant="contained" sx={styles.addMealButton} onClick={openAddMealsPopup} size="small">
            + Add
          </Button>
        )}
        <Button
          variant="outlined"
          sx={styles.editButton}
          disabled={isDuplicated}
          onClick={() => {
            isEditable ? handleUpdateWeeklyMenus() : setIsEditable(true);
          }}
        >
          {isEditable ? (
            <Iconify icon="ic:outline-check" color={caloTheme.palette.neutral900} style={{ width: '24px', height: '24px' }} />
          ) : (
            <Iconify icon="lucide:edit-2" width="19" height="19" color={caloTheme.palette.neutral900} />
          )}
        </Button>
      </Stack>
    </Card>
  );
};

export default WeeklyInfoCard;
