import { ComponentService } from '@calo/services';
import { format } from 'date-fns/fp';
import { flatMap, isEmpty, max, range, sum, sumBy } from 'lodash-es';
import { FoodComponent, FoodComponentWithQuantity, Ingredient } from '../interfaces';

export const handlePurchasingCost = (ingredient: Ingredient) => {
  return (ingredient?.cost || 0) * (ingredient?.quantity || 0) * (ingredient?.wastage || 0);
};

export const calculatePurchasingCost = (
  ingredients: Ingredient[],
  childComponentsWithQuantity: FoodComponentWithQuantity[],
  cookedRawFactor: number
) => {
  const allIngredients = [...ingredients];

  if (isEmpty(allIngredients)) {
    return 0;
  }

  const ingredientsWithPurchasingCost = allIngredients.map((ingredient) => ({
    ...ingredient,
    quantity: (ingredient.quantity || 0) * (ingredient.weight || 1),
    purchasingCost: handlePurchasingCost(ingredient)
  }));
  let totalCostPreCooking = sumBy(ingredientsWithPurchasingCost, 'purchasingCost');
  let ingredientQuantities = sumBy(ingredientsWithPurchasingCost, 'quantity');

  for (const child of childComponentsWithQuantity) {
    const childIngredientsWithPurchasingCost = child.ingredients?.map((ingredient) => ({
      ...ingredient,
      purchasingCost: handlePurchasingCost(ingredient)
    }));

    const childPurchasingCost = sumBy(childIngredientsWithPurchasingCost, 'purchasingCost');
    const childQuantitesTotal = sumBy(childIngredientsWithPurchasingCost, 'quantity');

    const childWeight = ComponentService.calculateComponentWeight(
      child.cups,
      child.measurementUnit,
      child.weight ?? 0,
      child?.quantity ?? 0
    );

    const totalCostPerQuantity = (childPurchasingCost / childQuantitesTotal) * (child.cookedRawFactor ?? 1) * child.quantity;
    totalCostPreCooking += totalCostPerQuantity;
    ingredientQuantities += childWeight;
  }

  const totalCostPreCookingPerGram = +totalCostPreCooking / +ingredientQuantities;

  const purchasingCost = totalCostPreCookingPerGram * cookedRawFactor;
  return purchasingCost || 0;
};

export const calculateFoodPurchasingCost = (components: any[]) => {
  return sum(
    components.map((comp) => {
      const replicatedChildComponents = flatMap(range(comp.quantity), () => comp.childComponents || []);

      return calculatePurchasingCost(comp.ingredients || [], replicatedChildComponents, comp.cookedRawFactor) * comp.quantity;
    })
  );
};

export const getLastUpdatedPurchasingCostDate = (foodComponent: FoodComponent[]) =>
  max(
    foodComponent.map((c) =>
      max(c.ingredients.map((i) => (i.costLastUpdated ? format('dd-MM-yyyy')(new Date(i.costLastUpdated)) : '---')))
    )
  );
