import { AddressService } from 'services';

import { Subscription } from '@calo/dashboard-types';

interface AddressPanelProps {
  subscription: Subscription;
}

const AddressPanel = ({ subscription }: AddressPanelProps) => {
  return (
    <div className="card has-table has-table-container-upper-radius">
      <div className="card">
        <header className="card-header bg-black text-white">
          <p className="card-header-title text-white">Delivery Addresses</p>
        </header>
        <div className="card-content">
          <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
            <thead>
              <tr>
                <th>Address</th>
                <th>Notes</th>
                <th>Driver note</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {subscription.deliveryAddresses.map((deliveryAddress) => (
                <tr key={deliveryAddress.id}>
                  <td>{AddressService.display(deliveryAddress)}</td>
                  <td>{deliveryAddress.notes}</td>
                  <td>{deliveryAddress.driverNote}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AddressPanel;
