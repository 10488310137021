import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Route, Switch } from 'react-router-dom';
import ExactSubscription2 from './ExactSubscription2';
import NewSubscription from './NewSubscription';
import SubscriptionList from './SubscriptionList';

const Subscriptions = () => {
  const roles = useUserRoles();
  return (
    <Switch>
      {roles.includes(Permission.CREATE_SUBSCRIPTION) && [
        <Route key={Routes.newSubscription2} exact path={Routes.newSubscription2} component={NewSubscription} />
      ]}
      {roles.includes(Permission.VIEW_SUBSCRIPTION_LIST) && [
        <Route key={Routes.subscriptions2} exact path={Routes.subscriptions2} component={SubscriptionList} />
      ]}
      {(roles.includes(Permission.VIEW_SUBSCRIPTION) || roles.includes(Permission.VIEW_DELIVERY_SUBSCRIPTION)) && [
        <Route key={Routes.subscription2} path={Routes.subscription2} component={ExactSubscription2} />
      ]}
    </Switch>
  );
};

export default Subscriptions;
