import { Subscription, UpdateSubscriptionReq } from '@calo/dashboard-types';
import { useFormik } from 'formik';

export default (subscription: Subscription, onSubmit: (values: UpdateSubscriptionReq) => Promise<void> | void) =>
  useFormik<UpdateSubscriptionReq>({
    enableReinitialize: true,
    initialValues: {
      macrosData: subscription.macrosData
    },

    validate: (_: UpdateSubscriptionReq) => {
      return {};
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
