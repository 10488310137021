import { Box, Card, Stack } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import BiometricsCard from './BiometricsCard';
import BlockMealCard from './BlockMealCard';
import MacrosCard from './MacrosCard';
import SubscriptionInfo from './SubscriptionInfo';
import WarningIngredients from './WarningIngredients';

interface UserInformationCardProprs {
  subscription: any;
  refetchSubscription: () => void;
}
const UserInformationCard = ({ subscription, refetchSubscription }: UserInformationCardProprs) => (
  <>
    <Card
      variant="outlined"
      sx={{
        border: 'none',
        mb: '14px',
        width: 'full',
        borderRadius: '8px',
        paddingBottom: '4px',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          flexDirection: 'column',
          width: '100%'
        }
      }}
    >
      <SubscriptionInfo key={subscription.id} subscription={subscription} />
    </Card>
    <Box
      display={'flex'}
      sx={{
        mb: 2,
        width: '100%',
        borderRadius: '8px',
        flexDirection: 'row',
        paddingBottom: '4px',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          flexDirection: 'column'
        }
      }}
    >
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          width: '33%',
          mb: 2,
          mr: 1,
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column',
            width: '100%'
          }
        }}
      >
        <BiometricsCard key={subscription.id} subscription={subscription} />
      </Card>
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          width: '33%',
          mb: 2,
          mr: 1,
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column',
            width: '100%'
          }
        }}
      >
        <MacrosCard key={subscription.id} subscription={subscription} />
      </Card>
      <Stack sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <Card
          variant="outlined"
          sx={{
            width: '100%',
            border: 'none',
            height: 'auto',
            mb: 2,
            borderRadius: '8px',
            paddingBottom: '4px',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column',
              width: '100%'
            }
          }}
        >
          <WarningIngredients subscription={subscription} refetchSubscription={refetchSubscription} />
        </Card>
        <Card
          variant="outlined"
          sx={{
            border: 'none',
            width: '100%',
            mb: 2,
            mr: 1,
            borderRadius: '8px',
            paddingBottom: '4px',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              flexDirection: 'column',
              width: '100%'
            }
          }}
        >
          <BlockMealCard subscription={subscription} refetchSubscription={refetchSubscription} />
        </Card>
      </Stack>
    </Box>
  </>
);
export default UserInformationCard;
