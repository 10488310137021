import { ApprovePendingPayoutTransactionReq, CreateAffiliateReq } from '@calo/dashboard-types';

import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';

export const createAffiliateProfile = async (values: CreateAffiliateReq) => {
  const { data, status } = await client.post('/v1/affiliates', values);
  ToastSuccessMsgs({ status, action: 'Affiliate Created successfully!' });
  return data;
};

export const payAffiliate = async (values: ApprovePendingPayoutTransactionReq) => {
  const { data, status } = await client.post(
    `/v1/affiliates/${values.affiliateId}/transactions/${values.transactionId}/approve`,
    values
  );
  ToastSuccessMsgs({ status, action: 'Affiliate Paid successfully' });
  return data;
};
