import { useCallback, useEffect, useRef, useState } from 'react';

import { format, setDay } from 'date-fns/fp';
import { capitalize, debounce, range, startCase } from 'lodash-es';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { CreateSubscriptionReq, Subscription } from '@calo/dashboard-types';
import { AddressService, PermissionService } from '@calo/services';
import {
  ActivityLevel,
  AddressType,
  Brand,
  DeliveryAddress,
  DeliveryTime,
  DietType,
  Gender,
  Goal,
  Kitchen,
  NewDeliveryAddress,
  PaymentMethod,
  PlanType,
  SubscriptionFrequency
} from '@calo/types';
import DateFnsAdapter from '@date-io/date-fns';
import {
  Box,
  Button,
  Card,
  Grid,
  LinearProgress,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { findOnboardingInformation } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { AddressPickerMUI, Icon, ModalRef } from 'components';
import Popup from 'components/Popup';
import { AddressViewMode } from 'lib/enums';
import { getAccessibleCountries, getCenteredCountry, isValidEmail } from 'lib/helpers';
import { getKitchenOptions } from 'lib/helpers/kitchenUtils';
import { useUserKitchens } from 'lib/hooks';
import useSubscriptionForm from './useSubscriptionForm';

interface SubscriptionFormProps {
  subscription: Subscription;
  onSubmit: (value: CreateSubscriptionReq) => any;
}

const SubscriptionForm = ({ onSubmit }: SubscriptionFormProps) => {
  const { values, handleChange, handleBlur, isSubmitting, isValid, dirty, setFieldValue, setValues, resetForm } =
    useSubscriptionForm(onSubmit);
  const addressDialogRef = useRef<ModalRef>();
  const notesPopUpRef = useRef<ModalRef>();

  const [selectedAddress, setSelectedAddress] = useState<Partial<DeliveryAddress> | null>();
  const [deliveryAddressChange, setDeliveryAddressChange] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const userKitchens: string[] = useUserKitchens();

  const { mutateAsync: findOnboardingInformationMutation } = useMutation(findOnboardingInformation);

  const handleFindOnboardingInformation = useCallback(
    debounce(async (phoneNumber: string) => {
      if (phoneNumber && phoneNumber.length > 0) {
        setLoading(true);
        await findOnboardingInformationMutation(phoneNumber)
          .then(
            // @ts-ignore
            (data: CreateSubscriptionReq) => {
              setValues({
                ...values,
                name: data.name || '',
                email: data.email || '',
                phoneNumber: data.phoneNumber || '',
                ...(data.deliveryAddress && {
                  deliveryAddress: data.deliveryAddress
                }),
                country: data.country,
                kitchen: data.kitchen,
                macrosData: {
                  activityLevel: data.macrosData.activityLevel,
                  dob: data.macrosData.dob,
                  gender: data.macrosData.gender,
                  goal: data.macrosData.goal,
                  height: data.macrosData.height,
                  targetWeight: data.macrosData.targetWeight,
                  weight: data.macrosData.weight,
                  weightGoal: data.macrosData.weightGoal
                },
                // @ts-ignore
                planType: data.plan.planType,
                // @ts-ignore
                foodType: data.plan.foodType,
                // @ts-ignore,
                deliveryDays: data.plan.deliveryDays,
                deliveryTime: data.deliveryTime,
                startDate: data.startDate
              }).catch((error) => {
                resetForm();
                console.error(error);
              });
            }
          )
          .catch((error) => {
            console.error(error);
            toast('error', { type: 'error', autoClose: 2000 });
          });
      } else {
        resetForm();
      }
      setLoading(false);
    }, 1000),
    []
  );

  useEffect(() => {
    setValues({ ...values, paymentMethod: PaymentMethod.cc });
  }, []);

  useEffect(() => {
    if (values.phoneNumber) {
      // @ts-ignore
      handleFindOnboardingInformation(values.phoneNumber);
    }
  }, [values.phoneNumber]);

  useEffect(() => {
    if (selectedAddress) {
      addressDialogRef.current?.open();
    } else {
      addressDialogRef.current?.close();
    }
  }, [selectedAddress]);

  const handleNewAddress = (ndeliveryAddress: NewDeliveryAddress) => {
    console.log('handleNewAddress', values.deliveryAddress);
    setValues({
      ...values,
      deliveryAddress: ndeliveryAddress
    });
    setSelectedAddress(null);
  };

  const handlePhoneInputChange = (data: any) => {
    const parsedInput = data.target.value.replace(/[^\d+]/g, ''); // this allows only digits and plus sign to be added
    data.target.value = parsedInput.slice(0, 2) === '00' ? `+${parsedInput.slice(2)}` : parsedInput;
    handleChange(data);
  };

  useEffect(() => {
    if (values.brand === Brand.MEALO) {
      setValues({
        ...values,
        dietType: DietType.balanced,
        macrosData: {
          activityLevel: ActivityLevel.level3,
          dob: values.macrosData.dob,
          gender: Gender.male,
          goal: Goal.loseWeight,
          height: 160,
          targetWeight: 85,
          weight: 80,
          weightGoal: 0.5
        }
      });
    }
  }, [values.brand]);

  useEffect(() => {
    if (values.deliveryAddress.region && values.deliveryAddress.country !== values.country) {
      setDeliveryAddressChange(true);
      toast(`the delivery address that was added is not in ${values.country}`, { type: 'error', autoClose: 2000 });
    } else {
      setDeliveryAddressChange(false);
    }
  }, [values.country, values.deliveryAddress]);

  useEffect(() => {
    if (values.deliveryAddress && values.deliveryAddress.country === values.country) {
      return;
    }

    setFieldValue('deliveryAddress', {
      country: values.country,
      lat: getCenteredCountry(values.country).lat,
      lng: getCenteredCountry(values.country).lng,
      building: '',
      street: '',
      type: AddressType.home,
      apartment: '',
      city: '',
      region: '',
      district: ''
    });
  }, [values.country]);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily,
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    }
  }));

  return (
    <>
      <Box
        component="form"
        sx={{
          width: 'full',
          ml: 3,
          '& .MuiTextField-root': { m: 2, width: '30%', justifyContent: 'space-between' },
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'row',
            width: '96%',
            '& .MuiTextField-root': { my: 2, mx: 1, width: '96%' }
          }
        }}
      >
        <div className="w-full">
          {isLoading && (
            <Box display={'flex'} flexDirection={'row'} my={3} justifyContent={'center'}>
              <Stack>
                <Typography display="flex" flexDirection={'column'} sx={{ width: '120px', height: '48px' }}>
                  <Icon name="calo" size={12} className="w-64 -ml-24 " />
                </Typography>
                <LinearProgress
                  color="inherit"
                  value={80}
                  sx={{ mt: 2 }}
                  style={{ color: caloTheme.palette.primary500, backgroundColor: caloTheme.palette.primary50 }}
                />
              </Stack>
            </Box>
          )}
          <TextField
            data-test="nameTextField"
            required
            id="new-subscription-name"
            label="Name"
            placeholder="Enter Name"
            value={values.name}
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            InputProps={{
              inputProps: { style: { borderRadius: 8 }, 'data-test': 'nameTextFieldInput' },
              style: { borderRadius: 8 }
            }}
          />
          <TextField
            data-test="emailTextField"
            required
            id="new-subscription-email"
            label="Email"
            placeholder="Enter Email"
            value={values.email}
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            type="email"
            error={!isValidEmail(values.email) && values.email.length >= 1}
            InputProps={{
              inputProps: { style: { borderRadius: 8 }, 'data-test': 'emailTextFieldInput' },
              style: { borderRadius: 8 }
            }}
          />
          <TextField
            data-test="phoneNumberTextField"
            required
            id="create-subscription-phoneNumber"
            label="Phone Number"
            placeholder="Enter Phone Number"
            value={values.phoneNumber}
            name="phoneNumber"
            onChange={handlePhoneInputChange}
            onBlur={handleBlur}
            type="text"
            InputProps={{
              inputProps: { style: { borderRadius: 8 }, pattern: '[0-9]*', 'data-test': 'phoneNumberTextFieldInput' },
              style: { borderRadius: 8 }
            }}
          />
          <TextField
            data-test="countrySelect"
            required
            select
            id="new-subscription-country"
            label="Country"
            value={values.country}
            name="Country"
            onChange={(data: any) => {
              setFieldValue('country', data.target.value);
              setFieldValue(
                'kitchen',
                Object.values(Kitchen).find((r) => r.includes(data.target.value) && !r.includes('000'))
              );
            }}
            onBlur={handleBlur}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            SelectProps={{ inputProps: { 'data-test': 'countrySelectInput' } }}
          >
            {getAccessibleCountries(userKitchens).map((country) => (
              <MenuItem value={country} data-test={`${country}MenuItem`}>
                {startCase(country)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            data-test="kitchenSelect"
            required
            select
            id="new-subscription-kitchen"
            label="Kitchen"
            value={values.kitchen}
            name="name"
            onChange={(data: any) => setFieldValue('kitchen', data.target.value)}
            onBlur={handleBlur}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            SelectProps={{ inputProps: { 'data-test': 'kitchenSelectInput' } }}
          >
            {getKitchenOptions(userKitchens, values.country).map((kitchen) => (
              <MenuItem value={kitchen.value} data-test={`${kitchen.value}MenuItem`}>
                {kitchen.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            data-test="paymentMethodSelect"
            required
            select
            id="new-subscription-paymentMethod"
            label="Payment"
            value={values.paymentMethod}
            name="paymentMethod"
            onChange={(data: any) => setFieldValue('paymentMethod', data.target.value)}
            onBlur={handleBlur}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            SelectProps={{ inputProps: { 'data-test': 'paymentMethodSelectInput' } }}
          >
            {Object.values(PaymentMethod).map((p) => (
              <MenuItem value={p} data-test={`${p}MenuItem`}>
                {startCase(p)}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            data-test="subscriptionFrequencySelect"
            required
            select
            id="new-subscription-frequency"
            label="Frequency"
            value={values.frequency}
            name="frequency"
            onChange={(data: any) => setFieldValue('frequency', data.target.value)}
            onBlur={handleBlur}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            SelectProps={{ inputProps: { 'data-test': 'subscriptionFrequencySelectInput' } }}
          >
            {Object.values(SubscriptionFrequency).map((frequency) => (
              <MenuItem value={frequency} data-test={`${frequency}MenuItem`}>
                {startCase(frequency)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            data-test="planTypeSelect"
            required
            select
            id="new-subscription-planType"
            label="Plan"
            value={values.planType}
            name="planType"
            onChange={(data: any) => setFieldValue('planType', data.target.value)}
            onBlur={handleBlur}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            SelectProps={{ inputProps: { 'data-test': 'genderSelectInput' } }}
          >
            {Object.values(PlanType).map((planType) => (
              <MenuItem value={planType} data-test={`${planType}MenuItem`}>
                {startCase(planType)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            data-test="dietTypeSelect"
            required
            select
            id="new-subscription-dietType"
            label="Diet type"
            value={values.dietType}
            name="dietType"
            onChange={(data: any) => setFieldValue('dietType', data.target.value)}
            onBlur={handleBlur}
            hidden={values.brand === Brand.MEALO}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            SelectProps={{ inputProps: { 'data-test': 'dietTypeSelectInput' } }}
          >
            {Object.values(DietType).map((dietType) => (
              <MenuItem value={dietType} data-test={`${dietType}MenuItem`}>
                {capitalize(dietType)}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            data-test="activityLevelSelect"
            required
            select
            id="new-subscription-activityLevel"
            label="Activity level"
            value={values.macrosData.activityLevel}
            name="activityLevel"
            onChange={(data: any) => setFieldValue('macrosData.activityLevel', data.target.value)}
            onBlur={handleBlur}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            SelectProps={{ inputProps: { 'data-test': 'activityLevelSelectInput' } }}
          >
            {Object.values([
              { value: ActivityLevel.level1, label: 'Little or no exercise' },
              { value: ActivityLevel.level2, label: 'Light: 1-3 workouts/week' },
              { value: ActivityLevel.level3, label: 'Moderate: 3-5 workouts/week' },
              { value: ActivityLevel.level4, label: 'Active: 6-7 workouts/week' },
              { value: ActivityLevel.level5, label: 'Very Active: 6-7 intense workouts/week' }
            ]).map((activityLevel) => (
              <MenuItem value={activityLevel.value} data-test={`${activityLevel.value}MenuItem`}>
                {capitalize(activityLevel.label)}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            data-test="goalSelect"
            required
            select
            id="new-subscription-goal"
            label="Goal"
            value={values.macrosData.goal}
            name="macrosData.goal"
            onChange={(data: any) => setFieldValue('macrosData.goal', data.target.value)}
            onBlur={handleBlur}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            SelectProps={{ inputProps: { 'data-test': 'goalSelectInput' } }}
          >
            {Object.values(Goal).map((goal) => (
              <MenuItem value={goal} data-test={`${goal}MenuItem`}>
                {startCase(goal)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            data-test="weightTextField"
            required
            id="new-subscription-weight"
            label="Weight"
            placeholder="Enter Weight"
            hidden={values.brand === Brand.MEALO}
            value={values.macrosData.weight}
            name="macrosData.weight"
            onChange={handleChange}
            onBlur={handleBlur}
            type="numbers"
            InputProps={{
              inputProps: { style: { borderRadius: 8 }, min: 100, max: 300, 'data-test': 'weightTextFieldInput' },
              style: { borderRadius: 8 }
            }}
          />
          <TextField
            data-test="targetWeightTextField"
            required
            id="new-subscription-targetWeight"
            label="Target weight"
            placeholder="Enter Target weight"
            hidden={values.brand === Brand.MEALO}
            value={values.macrosData.targetWeight}
            name="macrosData.targetWeight"
            onChange={handleChange}
            onBlur={handleBlur}
            type="numbers"
            InputProps={{
              inputProps: { style: { borderRadius: 8 }, min: 100, max: 300, 'data-test': 'targetWeightTextFieldInput' },
              style: { borderRadius: 8 }
            }}
          />
          {values.brand === Brand.CALO && (
            <LocalizationProvider dateAdapter={DateFnsAdapter} sx={{ mb: '4px' }}>
              <DesktopDatePicker
                data-test="dateOfBirthDatePicker"
                label="Date of Birth"
                value={values.macrosData.dob}
                maxDate={format('yyyy-MM-dd')(Date.now())}
                inputFormat="dd/MM/yyyy"
                onChange={(endDate) => setFieldValue('macrosData.dob', endDate)}
                renderInput={(params) => <TextField {...params} data-test="dateOfBirthDatePickerInput" />}
              />
            </LocalizationProvider>
          )}
          <TextField
            data-test="heightTextField"
            required
            id="new-subscription-height"
            label="Height"
            placeholder="Enter Height"
            hidden={values.brand === Brand.MEALO}
            value={values.macrosData.height}
            name="macrosData.height"
            onChange={handleChange}
            onBlur={handleBlur}
            type="numbers"
            InputProps={{
              inputProps: { style: { borderRadius: 8 }, min: 100, max: 250, 'data-test': 'heightTextFieldInput' },
              style: { borderRadius: 8 }
            }}
          />

          <TextField
            data-test="genderSelect"
            required
            select
            id="new-subscription-gender"
            label="Gender"
            value={values.macrosData.gender}
            name="macrosData.gender"
            onChange={(data: any) => setFieldValue('macrosData.gender', data.target.value)}
            onBlur={handleBlur}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            SelectProps={{ inputProps: { 'data-test': 'genderSelectInput' } }}
          >
            {Object.values(Gender).map((gender) => (
              <MenuItem value={gender} data-test={`${gender}MenuItem`}>
                {startCase(gender)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            data-test="deliveryTimeSelect"
            required
            select
            id="new-subscription-deliveryTime"
            label="Delivery Time"
            value={values.deliveryTime}
            name="deliveryTime"
            onChange={(data: any) => setFieldValue('deliveryTime', data.target.value)}
            onBlur={handleBlur}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            SelectProps={{ inputProps: { 'data-test': 'deliveryTimeSelectInput' } }}
          >
            {Object.values(DeliveryTime).map((deliveryTime) => (
              <MenuItem value={deliveryTime} data-test={`${deliveryTime}MenuItem`}>
                {startCase(deliveryTime)}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        sx={{
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column',
            width: 'full'
          }
        }}
      >
        <Stack
          sx={{
            width: '32%',
            ml: 3,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: '96%',
              mx: 2
            }
          }}
        >
          <Box
            sx={{
              padding: '8px',
              backgroundColor: 'white',
              display: 'flex',
              direction: 'row',
              width: 'full',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              sx={{
                textAlign: 'left',
                fontSize: '19px',
                lineHeight: '23px',
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600
              }}
            >
              Delivery Days
            </Typography>
          </Box>
          <Stack sx={{ display: 'flex', direction: 'row', width: 'full', justifyContent: 'space-between', padding: '12px' }}>
            <TextField
              select
              label="Days"
              name="deliveryDays"
              value={values.deliveryDays}
              id="new-subscription-deliveryDays"
              placeholder={'Select Delivery Days'}
              SelectProps={{
                multiple: true,
                value: values.deliveryDays,
                onChange: (e) => setFieldValue('deliveryDays', e.target.value)
              }}
              InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            >
              {range(0, 7)
                .filter((r) => values.deliveryDays && !values.deliveryDays.includes(r))
                .map((range) => (
                  <MenuItem value={range}>{format('EEE')(setDay(range)(Date.now()))}</MenuItem>
                ))}
            </TextField>
          </Stack>

          <Box sx={{ padding: '8px' }}>
            <Stack
              sx={{
                border: 1,
                borderColor: caloTheme.palette.neutral100,
                borderRadius: '8px',
                padding: '8px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                margin: '2px'
              }}
            >
              {values.deliveryDays.map((day: any) => (
                <Box
                  sx={{
                    margin: '4px',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    borderRadius: '8px',
                    textAlign: 'center',
                    background: caloTheme.palette.primary100,
                    color: caloTheme.palette.primary700,
                    width: '76px',
                    height: '33px'
                  }}
                >
                  <Grid sx={{ m: 'auto', mt: '4px' }}>
                    <Typography sx={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
                      <p className="mx-2">{format('EEE')(setDay(day)(Date.now()))}</p>
                      <Icon
                        name="removeX"
                        className="m-auto"
                        onClick={() =>
                          setFieldValue(
                            'deliveryDays',
                            values.deliveryDays.filter((row: any) => row !== day)
                          )
                        }
                      />
                    </Typography>
                  </Grid>
                </Box>
              ))}
            </Stack>
          </Box>
        </Stack>
        <Stack
          sx={{
            width: '74%',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: '96%',
              mx: 2
            }
          }}
        >
          <Card
            variant="outlined"
            sx={{
              width: 'full',
              borderRadius: '8px',
              my: 2,
              mx: 2,
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                width: 'full'
              }
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                padding: '4px'
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: '19px',
                  lineHeight: '23px',
                  fontFamily: caloTheme.typography.fontFamily,
                  fontWeight: '600',
                  marginX: '6px',
                  my: 'auto'
                }}
              >
                Delivery Addresses
              </Typography>
              <Button
                data-test="addAddressButton"
                variant="outlined"
                aria-label="addAddressButton"
                sx={{
                  height: '40px',
                  margin: '8px',
                  lineHeight: '17px',
                  fontWeight: 600,
                  fontSize: '14px',
                  borderRadius: '8px',
                  borderColor: caloTheme.palette.primary500,
                  color: caloTheme.palette.primary500,
                  '&:hover': {
                    borderColor: caloTheme.palette.primary600
                  }
                }}
                startIcon={'+'}
                onClick={() => setSelectedAddress({})}
              >
                Add Address
              </Button>
            </Stack>
            <Stack sx={{ width: '100%' }}>
              <TableContainer>
                <Table sx={{ width: '98%', marginX: 'auto', marginY: '4px' }}>
                  <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, color: 'black' }}>
                    <TableRow>
                      <StyledTableCell sx={{ width: '80%' }}>Address</StyledTableCell>
                      <StyledTableCell align="right">Notes</StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ border: 0 }}>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {AddressService.display(values.deliveryAddress)}
                      </TableCell>
                      <TableCell align="right">
                        {values.deliveryAddress.notes ? (
                          <Icon
                            name={'notes'}
                            size={6}
                            className="cursor-pointer"
                            onClick={() => notesPopUpRef.current?.open()}
                          />
                        ) : (
                          '---'
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Icon
                          name={'edit'}
                          size={5}
                          className="cursor-pointer"
                          onClick={() => setSelectedAddress(values.deliveryAddress)}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Card>
          <Stack sx={{ mx: 2, width: 'full' }}>
            <LocalizationProvider dateAdapter={DateFnsAdapter}>
              <DesktopDatePicker
                data-test="startDatePicker"
                inputFormat="dd/MM/yyyy"
                label="Select Starting Date"
                value={values.startDate || null}
                renderInput={(params) => <TextField {...params} data-test="startDatePickerInput" />}
                onChange={(endDate) => setFieldValue('startDate', endDate)}
                minDate={format('yyyy-MM-dd')(PermissionService.getMinActionDate(values.deliveryDays, Date.now()))}
              />
            </LocalizationProvider>
          </Stack>
        </Stack>
      </Box>

      <Box
        display={'flex'}
        flexDirection={'row'}
        sx={{
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: '96%',
            mr: 2
          }
        }}
      >
        <Stack display={'flex'} flexDirection={'row'} justifyContent={'center'} sx={{ my: 2 }} width={'100%'}>
          <Button
            aria-label="new-subscription-cancel"
            sx={{
              width: '262px',
              height: '51px',
              lineHeight: '17px',
              fontWeight: 600,
              fontSize: '14px',
              borderRadius: '8px',
              color: caloTheme.palette.neutral600,
              '&:hover': {
                backgroundColor: caloTheme.palette.neutral100,
                borderColor: caloTheme.palette.neutral100
              }
            }}
            onClick={() => setSelectedAddress(values.deliveryAddress || {})}
          >
            Cancel
          </Button>
          <Button
            data-test="createSubscriptionButton"
            variant="contained"
            aria-label="new-subscription-create"
            disabled={!dirty || !isValid || isSubmitting || !isValidEmail(values.email) || deliveryAddressChange}
            onClick={() => onSubmit(values)}
            sx={{
              width: '262px',
              height: '51px',
              lineHeight: '17px',
              fontWeight: 600,
              fontSize: '14px',
              borderRadius: '8px',
              backgroundColor: caloTheme.palette.primary500,
              borderColor: caloTheme.palette.primary500,
              color: 'white',
              '&:hover': {
                backgroundColor: caloTheme.palette.primary500,
                borderColor: caloTheme.palette.primary600
              }
            }}
          >
            Create
          </Button>
        </Stack>
      </Box>

      <Popup fullWidth ref={addressDialogRef} onClose={() => setSelectedAddress(null)}>
        <AddressPickerMUI
          time={values.deliveryTime}
          country={values.country}
          deliveryAddress={values.deliveryAddress}
          onPick={handleNewAddress}
          label="Create New Address"
          viewMode={AddressViewMode.map}
          onCancel={() => setSelectedAddress(null)}
        />
      </Popup>
      <Popup title="Delivery Note" ref={notesPopUpRef} onClose={() => notesPopUpRef.current?.close()}>
        <Stack display={'flex'} flexDirection={'row'}>
          <Typography
            display={'flex'}
            flexDirection={'row'}
            sx={{
              lineHeight: '17px',
              fontWeight: 600,
              fontSize: '14px',
              color: caloTheme.palette.neutral900
            }}
          >
            {values.deliveryAddress.notes}
          </Typography>
        </Stack>
      </Popup>
    </>
  );
};
export default SubscriptionForm;
