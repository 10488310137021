import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { CreateSubscriptionReq, Subscription } from '@calo/dashboard-types';
import { PermissionService } from '@calo/services';
import {
  ActivityLevel,
  AddressType,
  Brand,
  Country,
  DeliveryAddress,
  DeliveryTime,
  DietType,
  Gender,
  Goal,
  Kitchen,
  NewDeliveryAddress,
  PaymentMethod,
  PlanType,
  SubscriptionFrequency
} from '@calo/types';
import { format, setDay } from 'date-fns/fp';
import { debounce, range } from 'lodash-es';
import { toast } from 'react-toastify';

import { findOnboardingInformation } from 'actions';
import { AddressPicker, Button, Input, Modal, ModalRef, Select, Select2 } from 'components';
import { AddressViewMode } from 'lib/enums';
import { getCenteredCountry, isValidEmail } from 'lib/helpers';
import { useMutation } from 'react-query';
import { AddressService } from 'services';
import useSubscriptionForm from './useSubscriptionForm';

interface SubscriptionFormProps {
  subscription: Subscription;
  onSubmit: (values: CreateSubscriptionReq) => Promise<void>;
}

const SubscriptionForm = ({ onSubmit }: SubscriptionFormProps) => {
  const { values, handleChange, handleBlur, isSubmitting, isValid, dirty, setFieldValue, setValues } =
    useSubscriptionForm(onSubmit);
  const addressModalRef = useRef<ModalRef>();
  const [selectedAddress, setSelectedAddress] = useState<Partial<DeliveryAddress> | null>();
  const [deliveryAddressChange, setDeliveryAddressChange] = useState<boolean>(false);

  const { mutateAsync: findOnboardingInformationMutation } = useMutation(findOnboardingInformation);

  const handleFindOnboardingInformation = useCallback(
    debounce(async (phoneNumber: string) => {
      await findOnboardingInformationMutation(phoneNumber).then(
        // @ts-ignore
        (data: CreateSubscriptionReq) => {
          setValues({
            ...values,
            name: data.name || '',
            email: data.email || '',
            phoneNumber: data.phoneNumber || '',
            deliveryAddress: data.deliveryAddress || undefined,
            country: data.country,
            kitchen: data.kitchen,
            macrosData: {
              activityLevel: data.macrosData.activityLevel,
              dob: data.macrosData.dob,
              gender: data.macrosData.gender,
              goal: data.macrosData.goal,
              height: data.macrosData.height,
              targetWeight: data.macrosData.targetWeight,
              weight: data.macrosData.weight,
              weightGoal: data.macrosData.weightGoal
            },
            // @ts-ignore
            planType: data.plan.planType,
            // @ts-ignore
            foodType: data.plan.foodType,
            // @ts-ignore,
            deliveryDays: data.plan.deliveryDays,
            deliveryTime: data.deliveryTime
          }).catch((error) => {
            console.error(error);
            toast('error', { type: 'error', autoClose: 2000 });
          });
        }
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (values.phoneNumber) {
      // @ts-ignore
      handleFindOnboardingInformation(values.phoneNumber);
    }
  }, [values.phoneNumber]);

  useEffect(() => {
    if (selectedAddress) {
      addressModalRef.current?.open();
    } else {
      addressModalRef.current?.close();
    }
  }, [selectedAddress]);

  const handleNewAddress = (ndeliveryAddress: NewDeliveryAddress) => {
    setValues({
      ...values,
      deliveryAddress: ndeliveryAddress
    });
    setSelectedAddress(null);
  };

  const handlePhoneInputChange = (data: ChangeEvent<HTMLInputElement>) => {
    const input = data.target.value;
    data.target.value = input.slice(0, 2) === '00' ? `+${input.slice(2)}` : input;
    handleChange(data);
  };

  useEffect(() => {
    if (values.brand === Brand.MEALO) {
      setValues({
        ...values,
        dietType: DietType.balanced,
        macrosData: {
          activityLevel: ActivityLevel.level3,
          dob: values.macrosData.dob,
          gender: Gender.male,
          goal: Goal.loseWeight,
          height: 160,
          targetWeight: 85,
          weight: 80,
          weightGoal: 0.5
        }
      });
    }
  }, [values.brand]);

  useEffect(() => {
    if (values.deliveryAddress.region && values.deliveryAddress.country !== values.country) {
      setDeliveryAddressChange(true);
      toast(`the delivery address that was added is not in ${values.country}`, { type: 'error', autoClose: 2000 });
    } else {
      setDeliveryAddressChange(false);
    }
  }, [values.country, values.deliveryAddress]);

  useEffect(() => {
    setFieldValue('deliveryAddress', {
      country: values.country,
      lat: getCenteredCountry(values.country).lat,
      lng: getCenteredCountry(values.country).lng,
      building: '',
      street: '',
      type: AddressType.home,
      apartment: '',
      city: '',
      region: '',
      district: ''
    });
  }, [values.country]);

  return (
    <>
      <div>
        <div className="card p-4 pb-0">
          <header className="card-header mt-4">
            <p className="card-header-title">
              <span className="icon">
                <i></i>
              </span>
              {values.deliveryAddress.street ? <p>Address Info</p> : <p>Add Address</p>}
            </p>
            {!values.deliveryAddress.street && (
              <Button icon="fas fa-plus" className=" m-4" onClick={() => setSelectedAddress({})} />
            )}
          </header>
          <div className="flex m-4 pl-6">
            {values.deliveryAddress.street && (
              <>
                <p>{AddressService.display(values.deliveryAddress)}</p>
                <i className="fas fa-edit mr-4 ml-4 last:mr-0" onClick={() => setSelectedAddress(values.deliveryAddress)} />
                <p>{values.deliveryAddress.notes}</p>
              </>
            )}
          </div>
        </div>
        <div>
          <div className="flex-wrap grid grid-cols-3 grid-flow-row gap-4 w-11/12 ml-6 relative ">
            <Input label="Name" value={values.name} name="name" onChange={handleChange} onBlur={handleBlur} type="text" />
            <span className="relative">
              <Input
                label="Email"
                value={values.email}
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                type="email"
                placeholder="calo@calo.app"
                error={!isValidEmail(values.email) && values.email.length >= 1}
              />
              {!isValidEmail(values.email) && values.email.length > 1 && (
                <p className="text-red-300 text-xs -mt-1"> Email should be in a valid form </p>
              )}
            </span>
            <span className="relative">
              <Input
                label="Phone number"
                value={values.phoneNumber}
                name="phoneNumber"
                pattern="[0-9]*"
                placeholder="+9733XXXXXXXX"
                onChange={handlePhoneInputChange}
                onBlur={handleBlur}
                type="text"
              />
            </span>
            <Select
              label="Country"
              value={values.country}
              onChange={(data: any) => {
                setFieldValue('country', data.value);
                setFieldValue(
                  'kitchen',
                  Object.values(Kitchen).find((r) => r.includes(data.value) && !r.includes('000'))
                );
              }}
              options={Object.values(Country).map((type) => ({
                value: type,
                label: type
              }))}
            />
            <Select2
              label="Kitchen"
              value={values.kitchen}
              onChange={(data: ChangeEvent<HTMLSelectElement>) => setFieldValue('kitchen', data.target.value)}
              options={Object.values(Kitchen)
                .filter((r) => r.includes(values.country) && !r.includes('000'))
                .map((c) => ({
                  value: c,
                  label: c
                }))}
              disabled={Object.values(Kitchen).filter((r) => r.includes(values.country!)).length === 0}
            />
            <Select
              label="Payment"
              value={values.paymentMethod}
              onChange={(data: any) => setFieldValue('paymentMethod', data.value)}
              options={Object.values(PaymentMethod).map((type) => ({
                value: type,
                label: type
              }))}
            />
            <Select
              label="Frequency"
              value={values.frequency}
              onChange={(data: any) => setFieldValue('frequency', data.value)}
              options={Object.values(SubscriptionFrequency).map((type) => ({
                value: type,
                label: type
              }))}
            />
            <Select
              label="Plan"
              value={values.planType}
              onChange={(data: any) => setFieldValue('planType', data.value)}
              options={Object.values(PlanType).map((type) => ({
                value: type,
                label: type
              }))}
            />
            <Select
              label="Diet type"
              value={values.dietType}
              onChange={(data: any) => setFieldValue('dietType', data.value)}
              hidden={values.brand === Brand.MEALO}
              options={Object.values(DietType).map((type) => ({
                value: type,
                label: type
              }))}
            />
            <Select
              label="Activity level"
              value={values.macrosData.activityLevel}
              hidden={values.brand === Brand.MEALO}
              onChange={(data: any) => setFieldValue('macrosData.activityLevel', data.value)}
              options={Object.values(ActivityLevel).map((level) => ({
                value: level,
                label: level
              }))}
            />
            <Select
              label="Goal"
              value={values.macrosData.goal}
              hidden={values.brand === Brand.MEALO}
              onChange={(data: any) => setFieldValue('macrosData.goal', data.value)}
              options={Object.values(Goal).map((goal) => ({
                value: goal,
                label: goal
              }))}
            />
            <Input
              label="Target weight"
              hidden={values.brand === Brand.MEALO}
              value={values.macrosData.targetWeight}
              name="macrosData.targetWeight"
              onChange={handleChange}
              onBlur={handleBlur}
              type="number"
              min={30}
              max={300}
              step="any"
            />
            <Input
              label="Date of birth"
              value={values.macrosData.dob}
              hidden={values.brand === Brand.MEALO}
              name="macrosData.dob"
              onChange={handleChange}
              onBlur={handleBlur}
              type="date"
              max={format('yyyy-MM-dd')(Date.now())}
            />
            <Input
              label="Height"
              value={values.macrosData.height}
              hidden={values.brand === Brand.MEALO}
              name="macrosData.height"
              onChange={handleChange}
              onBlur={handleBlur}
              type="number"
              min={100}
              max={250}
              step="any"
            />
            <Input
              label="Weight goal"
              value={values.macrosData.weightGoal}
              hidden={values.brand === Brand.MEALO}
              name="macrosData.weightGoal"
              onChange={handleChange}
              onBlur={handleBlur}
              type="number"
              min={0}
              step="any"
            />
            <Select
              label="Gender"
              value={values.macrosData.gender}
              onChange={(data: any) => setFieldValue('macrosData.gender', data.value)}
              hidden={values.brand === Brand.MEALO}
              options={Object.values(Gender).map((gender) => ({
                value: gender,
                label: gender
              }))}
            />
            <Input
              label="Weight"
              value={values.macrosData.weight}
              hidden={values.brand === Brand.MEALO}
              name="macrosData.weight"
              onChange={handleChange}
              onBlur={handleBlur}
              type="number"
              min={30}
              max={300}
              step="any"
            />
          </div>
        </div>
        <div className="field">
          <div className="flex-wrap grid grid-cols-2 grid-flow-row gap-4 w-11/12 ml-6 ">
            <Select
              label="Delivery Days"
              isMulti
              value={values.deliveryDays}
              options={range(0, 7).map((day) => ({
                value: day,
                label: format('EEE')(setDay(day)(Date.now()))
              }))}
              onChange={(data: any) =>
                setFieldValue(
                  'deliveryDays',
                  (data || []).map((row) => row.value)
                )
              }
            />
            <Select
              label="Delivery time"
              value={values.deliveryTime}
              onChange={(data: any) => setFieldValue('deliveryTime', data.value)}
              options={Object.values(DeliveryTime).map((time) => ({
                value: time,
                label: time
              }))}
            />
            <Input
              label="Start date"
              value={values.startDate}
              name="startDate"
              onChange={handleChange}
              onBlur={handleBlur}
              type="date"
              min={format('yyyy-MM-dd')(PermissionService.getMinActionDate(values.deliveryDays, Date.now()))}
            />
          </div>
        </div>
        <div className="field">
          <div className="ml-6 mb-4 mt-6">
            <Button
              type="submit"
              primary
              loading={isSubmitting}
              disabled={!dirty || !isValid || isSubmitting || !isValidEmail(values.email) || deliveryAddressChange}
              content="Save"
              onClick={() => onSubmit(values)}
              className="w-1/6"
            />
          </div>
        </div>
      </div>
      <Modal ref={addressModalRef} onClose={() => setSelectedAddress(null)} isNarrow>
        <AddressPicker
          country={values.country}
          onPick={handleNewAddress}
          label="Create New Address"
          time={values.deliveryTime}
          viewMode={AddressViewMode.map}
          kitchen={values.kitchen || Kitchen.BH1}
          onCancel={() => setSelectedAddress(null)}
        />
      </Modal>
    </>
  );
};
export default SubscriptionForm;
