import { MenuFoodComponent } from '@calo/dashboard-types';
import { ComponentService } from '@calo/services';
import { Dictionary, Macros, Micronutrients } from '@calo/types';
import { orderMealsBySize } from 'lib/helpers';
import { Food, FoodComponent } from 'lib/interfaces';
import { round, sumBy, upperFirst } from 'lodash-es';

export const getFoodComponentAmount = (fc: MenuFoodComponent, foodComponents: Dictionary<FoodComponent>) => {
  const component = foodComponents[fc.id];
  return component
    ? ComponentService.calculateComponentWeight(component.cups!, component.measurementUnit, component.weight ?? 0, fc.quantity, 6)
    : 0;
};

export const findFoodMacros = (
  menuComponents: MenuFoodComponent[],
  foodComponents: Dictionary<FoodComponent>,
  macros: Macros | undefined,
  withManualMacros: boolean
) => {
  return {
    cal: withManualMacros
      ? macros?.cal || 0
      : Math.round(sumBy(menuComponents, (fc) => foodComponents[fc.id]?.macros.cal * getFoodComponentAmount(fc, foodComponents))),
    protein: withManualMacros
      ? macros?.protein || 0
      : Math.round(
          sumBy(menuComponents, (fc) => foodComponents[fc.id]?.macros.protein * getFoodComponentAmount(fc, foodComponents))
        ),
    carbs: withManualMacros
      ? macros?.carbs || 0
      : Math.round(
          sumBy(menuComponents, (fc) => foodComponents[fc.id]?.macros.carbs * getFoodComponentAmount(fc, foodComponents))
        ),
    fat: withManualMacros
      ? macros?.fat || 0
      : Math.round(sumBy(menuComponents, (fc) => foodComponents[fc.id]?.macros.fat * getFoodComponentAmount(fc, foodComponents))),
    fiber: withManualMacros
      ? macros?.fiber || 0
      : Math.round(
          sumBy(
            menuComponents,
            (fc) => (foodComponents[fc.id]?.macros.fiber || 0) * getFoodComponentAmount(fc, foodComponents)
          ) || 0
        )
  };
};

export const findMicronutrients = (
  menuComponents: MenuFoodComponent[],
  foodComponents: Dictionary<FoodComponent>,
  micronutrients: Micronutrients | undefined,
  withManualMacros: boolean
) => {
  return {
    addedSugar: withManualMacros
      ? micronutrients?.addedSugar
      : round(
          sumBy(
            menuComponents,
            (fc) => (foodComponents[fc.id]?.micronutrients?.addedSugar || 0) * getFoodComponentAmount(fc, foodComponents)
          ),
          4
        ),
    cholesterol: withManualMacros
      ? micronutrients?.cholesterol
      : round(
          sumBy(
            menuComponents,
            (fc) => (foodComponents[fc.id]?.micronutrients?.cholesterol || 0) * getFoodComponentAmount(fc, foodComponents)
          ),
          4
        ),
    saturatedFats: withManualMacros
      ? micronutrients?.saturatedFats
      : round(
          sumBy(
            menuComponents,
            (fc) => (foodComponents[fc.id]?.micronutrients?.saturatedFats || 0) * getFoodComponentAmount(fc, foodComponents)
          ),
          4
        ),
    sodium: withManualMacros
      ? micronutrients?.sodium
      : round(
          sumBy(
            menuComponents,
            (fc) => (foodComponents[fc.id]?.micronutrients?.sodium || 0) * getFoodComponentAmount(fc, foodComponents)
          ),
          4
        ),
    totalSugar: withManualMacros
      ? micronutrients?.totalSugar
      : round(
          sumBy(
            menuComponents,
            (fc) => (foodComponents[fc.id]?.micronutrients?.totalSugar || 0) * getFoodComponentAmount(fc, foodComponents)
          ),
          4
        ),
    transFats: withManualMacros
      ? micronutrients?.transFats
      : round(
          sumBy(
            menuComponents,
            (fc) => (foodComponents[fc.id]?.micronutrients?.transFats || 0) * getFoodComponentAmount(fc, foodComponents)
          ),
          4
        )
  };
};

export const displayMessageForMacrosLimits = (
  allSizesFood: Food[],
  food: Food,
  macros: Macros,
  macrosProp: 'carbs' | 'cal' | 'protein' | 'fat'
) => {
  const defaultSizes = ['XS', 'S', 'M', 'L', 'XL'];
  const existingSizes = orderMealsBySize(allSizesFood).map((food) => food.size);
  if (defaultSizes.includes(food.size) && food.macros[macrosProp] && macros && macros[macrosProp]) {
    const index = defaultSizes.indexOf(food.size);
    const existingSizeIndex = existingSizes.indexOf(food.size);
    if (index === -1) return '';
    const hightLimit = allSizesFood.find((food) => food.size === existingSizes[existingSizeIndex + 1])?.macros[macrosProp];
    const lowLimit = allSizesFood.find((food) => food.size === existingSizes[existingSizeIndex - 1])?.macros[macrosProp];
    if (lowLimit && lowLimit > macros[macrosProp]) return `${upperFirst(macrosProp)} amount is less than on a smaller meal`;
    if (hightLimit && hightLimit < macros[macrosProp]) return `${upperFirst(macrosProp)} amount is more than on a larger meal`;
    return '';
  }
  return '';
};

export const findAutoCalculatedMacros = (menuComponents: MenuFoodComponent[], foodComponents: Dictionary<FoodComponent>) => {
  return {
    cal: Math.round(
      sumBy(menuComponents, (fc) => foodComponents[fc.id]?.macros.cal * getFoodComponentAmount(fc, foodComponents))
    ),
    protein: Math.round(
      sumBy(menuComponents, (fc) => foodComponents[fc.id]?.macros.protein * getFoodComponentAmount(fc, foodComponents))
    ),
    carbs: Math.round(
      sumBy(menuComponents, (fc) => foodComponents[fc.id]?.macros.carbs * getFoodComponentAmount(fc, foodComponents))
    ),
    fat: Math.round(
      sumBy(menuComponents, (fc) => foodComponents[fc.id]?.macros.fat * getFoodComponentAmount(fc, foodComponents))
    ),
    fiber: Math.round(
      sumBy(menuComponents, (fc) => (foodComponents[fc.id]?.macros.fiber || 0) * getFoodComponentAmount(fc, foodComponents)) || 0
    )
  };
};

export const findAutoCalculatedMicros = (menuComponents: MenuFoodComponent[], foodComponents: Dictionary<FoodComponent>) => {
  return {
    addedSugar: round(
      sumBy(
        menuComponents,
        (fc) => (foodComponents[fc.id]?.micronutrients?.addedSugar || 0) * getFoodComponentAmount(fc, foodComponents)
      ),
      4
    ),
    cholesterol: round(
      sumBy(
        menuComponents,
        (fc) => (foodComponents[fc.id]?.micronutrients?.cholesterol || 0) * getFoodComponentAmount(fc, foodComponents)
      ),
      4
    ),
    saturatedFats: round(
      sumBy(
        menuComponents,
        (fc) => (foodComponents[fc.id]?.micronutrients?.saturatedFats || 0) * getFoodComponentAmount(fc, foodComponents)
      ),
      4
    ),
    sodium: round(
      sumBy(
        menuComponents,
        (fc) => (foodComponents[fc.id]?.micronutrients?.sodium || 0) * getFoodComponentAmount(fc, foodComponents)
      ),
      4
    ),
    totalSugar: round(
      sumBy(
        menuComponents,
        (fc) => (foodComponents[fc.id]?.micronutrients?.totalSugar || 0) * getFoodComponentAmount(fc, foodComponents)
      ),
      4
    ),
    transFats: round(
      sumBy(
        menuComponents,
        (fc) => (foodComponents[fc.id]?.micronutrients?.transFats || 0) * getFoodComponentAmount(fc, foodComponents)
      ),
      4
    )
  };
};

export const selectCompIngredientsAttribtues = (component: FoodComponent | undefined) => {
  return component?.ingredients?.map((r: any) => ({
    id: r.id,
    category: r.category,
    name: r.name,
    slug: r.slug
  }));
};
