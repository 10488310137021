import { Stack, Typography } from '@mui/material';
import { FoodComponent } from 'lib/interfaces';

interface ComponentNameAndUsageProps {
  foodComponent: FoodComponent | undefined;
}

const ComponentNameAndUsage = ({ foodComponent }: ComponentNameAndUsageProps) => {
  return (
    <>
      <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center', justifyContent: 'center' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>{foodComponent?.name.en}</Typography>
      </Stack>
    </>
  );
};

export default ComponentNameAndUsage;
