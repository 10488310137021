import { useEffect, useMemo, useRef, useState } from 'react';

import { flatten, orderBy, startCase } from 'lodash-es';
import { useInfiniteQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { AddToWallet, Permission, Refund, RefundReq, WalletAction } from '@calo/dashboard-types';
import { Country, PaymentMethod } from '@calo/types';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  Card,
  MenuItem,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  styled,
  tableCellClasses
} from '@mui/material';

import { PricingService } from '@calo/services';

import { createWallet, refund, updateAllowanceDays, updateSubscription } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { CaloLoader, Icon, ModalRef } from 'components';
import Popup from 'components/Popup';
import { useDocumentMedia } from 'hooks';
import client from 'lib/client';
import { useUserRoles } from 'lib/hooks';
import { Wallet } from 'lib/interfaces';
import NewWalletForm from './NewWalletForm';
import RefundForm from './RefundForm';
import WalletCardRow from './WalletCardRow';
import WalletCardRowMobile from './WalletCardRowMobile';
import useWalletForm from './useWalletForm';

interface WalletCardProps {
  subscription: any;
}
const WalletCard = ({ subscription }: WalletCardProps) => {
  const roles = useUserRoles();
  const refundModalRef = useRef<ModalRef>();
  const paymentModalRef = useRef<ModalRef>();
  const { isMobile, isTablet } = useDocumentMedia();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<Wallet | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { mutateAsync: createMutation } = useMutation(createWallet);
  const { mutateAsync: updateMutation } = useMutation(updateSubscription);
  const { mutateAsync: updateAllowanceDaysMutation } = useMutation(updateAllowanceDays);

  useEffect(() => {
    if (selected) {
      refundModalRef.current?.open();
    } else {
      refundModalRef.current?.close();
    }
  }, [selected]);

  const onSubmit = async (data: any) => {
    if (values.dayAllowance !== subscription.dayAllowance) {
      await updateAllowanceDaysMutation({ id: subscription.id, days: values.dayAllowance || 0 });
    }
    await updateMutation(
      {
        id: subscription.id,
        ...data
      },
      {
        onSuccess: () => {
          refetch();
        }
      }
    );
  };

  const handleNewCredit = async (data: AddToWallet) => {
    await createMutation(
      { id: subscription.id, ...data },
      {
        onSuccess: () => {
          refetch();
        }
      }
    );
    paymentModalRef.current?.close();
  };

  const { values, setFieldValue, initialValues, setValues } = useWalletForm(subscription, onSubmit);

  const { data, fetchNextPage, hasNextPage, isLoading, refetch } = useInfiniteQuery<{
    data: Wallet[];
    meta: { cursor?: string };
  }>(
    `subscriptions/${subscription.id}/wallets`,
    async ({ queryKey, pageParam }) => {
      const { data } = await client.get(queryKey.join('/'), {
        params: {
          ...(pageParam && {
            cursor: pageParam
          })
        }
      });
      return data;
    },
    {
      getNextPageParam: (data) => data.meta.cursor
    }
  );

  const list = useMemo(
    () =>
      (data?.pages || []).reduce<Wallet[]>((res, r) => {
        res = [...res, ...(r.data || [])];
        return res;
      }, []),
    [data]
  );

  const handleRefund = async (data: Refund & { fullRefund?: boolean }) => {
    if (selected) {
      const values: RefundReq & { fullRefund?: boolean } = {
        amount: data.amount,
        description: data.description,
        note: data.note,
        fullRefund: data.fullRefund
      };
      await refund(selected!.id, subscription.id, values);
      setSelected(null);
      toast('Refund initiated', {
        type: 'success'
      });
    }
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      flexShrink: 0,
      border: 'none',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px',
      variant: 'caption',
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    },
    [`&.${tableCellClasses.body}`]: {
      flexShrink: 0,
      border: 'none',
      fontWeight: 600,
      fontSize: '12px',
      variant: 'caption',
      lineHeight: '14px',
      color: caloTheme.palette.neutral900,
      fontFamily: caloTheme.typography.fontFamily
    }
  }));

  const filteredWalletLogs = useMemo(
    () => orderBy(list, ['createdAt'], ['desc']).filter((walletLogs) => !walletLogs.action.includes(WalletAction.REMOVE)),
    [list]
  );
  const deductedSub = useMemo(
    () => orderBy(list, ['createdAt'], ['desc']).filter((walletLogs) => walletLogs.action.includes(WalletAction.REMOVE)),
    [list]
  );

  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          colorPrimary: {
            '&.Mui-checked': {
              color: caloTheme.palette.white
            }
          },
          track: {
            backgroundColor: caloTheme.palette.neutral400,
            '.Mui-checked.Mui-checked + &': {
              opacity: 0.7,
              backgroundColor: caloTheme.palette.primary500
            }
          }
        }
      }
    }
  });

  const handleEditing = (edit: boolean) => {
    if (!edit) {
      onSubmit(values);
    }
    setIsEditing(edit);
  };

  const handleReset = () => {
    setValues(initialValues);
    setIsEditing(false);
  };

  return (
    <>
      <Card variant="outlined" sx={{ width: '100%', border: 'none', borderRadius: '8px' }}>
        <Box
          sx={{
            margin: 2,
            padding: 2,
            backgroundColor: caloTheme.palette.neutral50,
            borderRadius: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column'
            }
          }}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '19px',
              lineHeight: '23px',
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600
            }}
          >
            Payments
          </Typography>
          {isEditing ? (
            <Stack sx={{ justifyContent: 'end', flexDirection: 'row' }}>
              <CheckIcon sx={{ marginRight: 2 }} style={{ cursor: 'pointer' }} onClick={() => handleEditing(!isEditing)} />
              <ClearIcon style={{ cursor: 'pointer' }} onClick={handleReset} />
            </Stack>
          ) : (
            <Icon
              onClick={() => handleEditing(!isEditing)}
              name="edit2"
              size={6}
              style={{ cursor: 'pointer', width: '26px', height: '26px' }}
            />
          )}
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          width="auto"
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column'
            }
          }}
        >
          <TextField
            select
            name="paymentMethod"
            sx={{ width: '100%', mr: 2, mb: 2 }}
            label="Payment Method"
            value={values.paymentMethod}
            id="exact-subscription-paymentMethod"
            disabled={
              subscription.paymentMethod === PaymentMethod.cash || !roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing
            }
            onChange={(data: any) => {
              setFieldValue('paymentMethod', data.target.value);
            }}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {flatten([
              subscription.paymentMethod === PaymentMethod.cash
                ? [{ value: PaymentMethod.cash, label: 'Cash' }]
                : subscription.country === Country.SA
                  ? [
                      { value: PaymentMethod.cc, label: 'CC' },
                      { value: PaymentMethod.mada, label: 'mada' },
                      { value: PaymentMethod.applePay, label: 'applePay' }
                    ]
                  : [
                      { value: PaymentMethod.cc, label: 'CC' },
                      { value: PaymentMethod.benefit, label: 'Benefit' }
                    ]
            ]).map((p) => (
              <MenuItem key={p.value} value={p.value}>
                {startCase(p.label)}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            name="dayAllowance"
            label="Allowance Days"
            value={values.dayAllowance}
            type="number"
            sx={{ width: '100%', mb: 2 }}
            placeholder="Enter Allowance Days"
            id="exact-subscription-dayAllowance"
            disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
            onChange={(data: any) => setFieldValue('dayAllowance', +data.target.value)}
            inputProps={{
              inputProps: { style: { borderRadius: 8, width: '100%', mr: 2 } },
              style: { borderRadius: 8, width: '100%' }
            }}
          />
          <Stack sx={{ marginX: 2, width: '100%' }}>
            <ThemeProvider theme={theme}>
              <Typography
                sx={{
                  textAlign: 'left',
                  color: caloTheme.palette.neutral900,
                  fontSize: '12px',
                  lineHeight: '14px',
                  width: 'auto',
                  mb: '4px',
                  fontFamily: caloTheme.typography.fontFamily,
                  fontWeight: 600
                }}
              >
                Auto Renew
              </Typography>
              <Switch
                onChange={() => setFieldValue('autoRenew', !values.autoRenew)}
                disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
                checked={values.autoRenew}
              />
            </ThemeProvider>
          </Stack>

          {roles.includes(Permission.CREATE_SUBSCRIPTION_WALLET) && (
            <Stack sx={{ marginX: 2, width: '100%', alignItems: 'end' }}>
              <Button
                variant="outlined"
                aria-label="Add Credits"
                sx={{
                  width: 'auto',
                  height: '45px',
                  lineHeight: '17px',
                  fontWeight: 600,
                  fontSize: '14px',
                  borderRadius: '8px',
                  padding: '14px 20px 14px 20px',
                  color: caloTheme.palette.primary500,
                  borderColor: caloTheme.palette.primary500,
                  '&:hover': {
                    color: caloTheme.palette.primary600,
                    borderColor: caloTheme.palette.primary600
                  },
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    justifyItems: 'center',
                    margin: 'auto',
                    marginTop: 4,
                    width: 'auto'
                  }
                }}
                startIcon={'+'}
                disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
                onClick={() => paymentModalRef.current?.open()}
              >
                Add Credits
              </Button>
            </Stack>
          )}
        </Box>
      </Card>

      <Card variant="outlined" sx={{ width: '100%', border: 'none', borderRadius: '8px', marginTop: '14px' }}>
        <Box
          sx={{
            padding: 2,
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              width: 'auto',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column'
            }
          }}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '19px',
              lineHeight: '23px',
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600
            }}
          >
            Wallet
          </Typography>
        </Box>
        <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
          {isTablet || isMobile ? (
            <Box overflow="auto" width="100%" sx={{ padding: 2 }}>
              {isLoading ? (
                <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                  <CaloLoader />
                </Stack>
              ) : (
                <>
                  {list.map((row) => (
                    <WalletCardRowMobile key={row.id} row={row} onRefund={(wallet) => setSelected(wallet)} />
                  ))}
                </>
              )}
            </Box>
          ) : (
            <>
              {isLoading ? (
                <Stack sx={{ width: '100%', justifyContent: 'center' }}>
                  <CaloLoader />
                </Stack>
              ) : (
                <>
                  {filteredWalletLogs.length === 0 && deductedSub.length === 0 ? (
                    <Typography
                      display={'flex'}
                      flexDirection={'row'}
                      sx={{
                        justifyContent: 'center',
                        color: caloTheme.palette.neutral400,
                        fontSize: '33px',
                        fontWeight: 400,
                        lineHeight: '24px'
                      }}
                    >
                      No Wallet Data
                    </Typography>
                  ) : (
                    <Table
                      sx={{
                        marginY: '4px',
                        minHeight: '120px',
                        tableLayout: 'fixed',
                        overflow: 'auto',
                        width: '100%',
                        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                          flexDirection: 'column'
                        }
                      }}
                    >
                      <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, width: '100%', borderRadius: '8px' }}>
                        <TableRow>
                          <StyledTableCell sx={{ width: '10%' }}>Amount</StyledTableCell>
                          <StyledTableCell sx={{ width: '8%' }}>Currency</StyledTableCell>
                          <StyledTableCell sx={{ width: '8%' }}>Source</StyledTableCell>
                          <StyledTableCell sx={{ width: '8%' }}>Action</StyledTableCell>
                          <StyledTableCell sx={{ width: '12%' }}>Actor</StyledTableCell>
                          <StyledTableCell sx={{ width: '15%' }}>Reason</StyledTableCell>
                          <StyledTableCell sx={{ width: '12%' }}>Note</StyledTableCell>
                          <StyledTableCell sx={{ width: '8%' }}>Delivery</StyledTableCell>
                          <StyledTableCell sx={{ width: '8%' }}>Time</StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredWalletLogs.map((row) => (
                          <>
                            <WalletCardRow row={row} key={row.id} onRefund={(wallet) => setSelected(wallet)} />
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                  {deductedSub.length > 0 && (
                    <Table
                      sx={{
                        marginY: '4px',
                        marginTop: filteredWalletLogs.length === 0 ? '-32px' : '',
                        tableLayout: 'fixed',
                        overflow: 'auto',
                        width: '100%',
                        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                          flexDirection: 'column'
                        }
                      }}
                    >
                      <TableHead
                        sx={{ backgroundColor: caloTheme.palette.secondaryPurple50, width: '100%', borderRadius: '8px' }}
                      >
                        <TableRow onClick={() => setIsOpen(!isOpen)}>
                          <StyledTableCell>Subscription Deductions</StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell>
                            <Typography sx={{ textAlign: 'end' }}>{isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}</Typography>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody sx={{ display: isOpen ? undefined : 'none' }}>
                        {deductedSub.map((row) => (
                          <WalletCardRow row={row} key={row.id} onRefund={(wallet) => setSelected(wallet)} />
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </>
              )}
            </>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
              mb: 2,
              mt: '-4px',
              width: '100%',
              display: 'flex',
              justifyItems: 'center'
            }
          }}
        >
          {hasNextPage && (
            <Button
              variant="text"
              aria-label="Remove Suspension"
              sx={{
                display: 'flex',
                mb: 1,
                mx: 'auto',
                height: '45px',
                fontWeight: 600,
                lineHeight: '17px',
                fontSize: '14px',
                borderRadius: '8px',
                padding: '14px 20px 14px 20px',
                color: caloTheme.palette.primary500,
                '&:hover': {
                  color: caloTheme.palette.primary600
                },
                [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                  display: 'flex',
                  justifyItems: 'center',
                  m: 'auto',
                  mb: 2,
                  mt: '-4px',
                  width: 'auto'
                }
              }}
              onClick={() => fetchNextPage()}
            >
              Show More
            </Button>
          )}
        </Box>
      </Card>

      <Popup ref={refundModalRef} title={'REFUND'} onClose={() => setSelected(null)}>
        {selected && (
          <RefundForm
            wallet={selected}
            onSubmit={(e) => handleRefund(e)}
            max={PricingService.roundAmount(
              +Math.min(subscription.balance[subscription.currency], selected.amount - (selected.vat || 0)),
              subscription.currency
            )}
            currency={selected.currency}
          />
        )}
      </Popup>

      <Popup title={'ADD CREDITS'} ref={paymentModalRef} onClose={() => paymentModalRef.current?.close()}>
        <NewWalletForm
          // @ts-ignore
          onSubmit={handleNewCredit}
          paymentMethod={subscription.paymentMethod}
          userCurrency={subscription.currency}
          userId={subscription.id}
        />
      </Popup>
    </>
  );
};
export default WalletCard;
