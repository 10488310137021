import { MacrosService } from '@calo/services';
import { ActivityLevel, DietType } from '@calo/types';
import { Box, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { Delivery } from 'lib/interfaces';

interface TargetMacrosBoxProps {
  selectedDelivery: Delivery;
}

const TargetMacrosBox = ({ selectedDelivery }: TargetMacrosBoxProps) => {
  const deliveryMacros =
    selectedDelivery.macros ||
    MacrosService.getMacrosBag(
      MacrosService.getCal(selectedDelivery.macrosData!),
      selectedDelivery.plan.dietType || DietType.balanced,
      selectedDelivery.macrosData?.weight ?? 0,
      selectedDelivery.macrosData?.activityLevel || ActivityLevel.level1
    );

  return (
    <Box
      width={'auto'}
      display="flex"
      flexDirection={'row'}
      sx={{
        my: 2,
        mx: 3,
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          flexDirection: 'column'
        }
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontFamily: caloTheme.typography.fontFamily,
          width: '140px',
          fontWeight: 700,
          fontSize: '18px',
          lineHeight: '21.6px',
          mr: 3
        }}
      >
        Target Macros
      </Typography>
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ width: '100%', alignItems: 'center' }}>
        <Stack
          display="flex"
          flexDirection="row"
          sx={{
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 400,
            mr: 3,
            fontSize: '16px',
            lineHeight: '19px'
          }}
        >
          Calories{' '}
          <Typography
            variant="subtitle2"
            sx={{
              mt: '-3px',
              ml: 1,
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '24px'
            }}
          >
            {deliveryMacros.cal} g
          </Typography>
        </Stack>
        <Stack
          display="flex"
          flexDirection="row"
          sx={{
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 400,
            mr: 3,
            fontSize: '16px',
            lineHeight: '19px'
          }}
        >
          Protein{' '}
          <Typography
            variant="subtitle2"
            sx={{
              mt: '-3px',
              ml: 1,
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '24px'
            }}
          >
            {deliveryMacros.protein.min} - {deliveryMacros.protein.max} g
          </Typography>
        </Stack>
        <Stack
          display="flex"
          flexDirection="row"
          sx={{
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 400,
            mr: 3,
            fontSize: '16px',
            lineHeight: '19px'
          }}
        >
          Carbs{' '}
          <Typography
            variant="subtitle2"
            sx={{
              mt: '-3px',
              ml: 1,
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '24px'
            }}
          >
            {deliveryMacros.carbs.min} - {deliveryMacros.carbs.max} g
          </Typography>
        </Stack>
        <Stack
          display="flex"
          flexDirection="row"
          sx={{
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 400,
            mr: 3,
            fontSize: '16px',
            lineHeight: '19px'
          }}
        >
          Fat{' '}
          <Typography
            variant="subtitle2"
            sx={{
              mt: '-3px',
              ml: 1,
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '24px'
            }}
          >
            {deliveryMacros.fat.min} - {deliveryMacros.fat.max}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default TargetMacrosBox;
