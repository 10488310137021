import { useHistory, useParams } from 'react-router-dom';

import { CreateFoodReq } from '@calo/dashboard-types';

import { createFood } from 'actions/food';
import { Routes } from 'lib/enums';
import FoodForm from './FoodForm';

const NewFood = () => {
  const history = useHistory();
  const { type } = useParams<{ type: string }>();

  const handleNewFood = async (values: Omit<CreateFoodReq, 'id'>) => {
    const food = await createFood(values);
    history.replace(Routes.foodSlug.replace(':slug', food.slug));
  };

  return <FoodForm onSubmit={handleNewFood} isPreBuildCustom={type === 'custom'} />;
};

export default NewFood;
