import { FormikBag, FormikProps, withFormik } from 'formik';
import { useRef } from 'react';

import { PricingService } from '@calo/services';
import { Currency } from '@calo/types';
import { Button, ConfirmationModal, Input, ModalRef } from 'components';

interface EditPendingFormProps {
  onSubmit: (amount: number) => Promise<void>;
  balance: number;
  currency: Currency;
}

const EditPendingForm = ({
  values,
  handleSubmit,
  isValid,
  balance,
  currency,
  handleChange,
  handleBlur
}: EditPendingFormProps & FormikProps<{ amount: number }>) => {
  const confirmModalRef = useRef<ModalRef>();

  return (
    <div className="section">
      <div className="field">
        <div className="control">
          <Input
            // className="ml-4"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.amount}
            name="amount"
            label="Amount"
            type="number"
            min={0}
            step={0.1}
          />
        </div>
      </div>
      <table className="table w-full">
        <tbody>
          <tr>
            <td>Amount</td>
            <td>{values.amount}</td>
          </tr>
          <tr>
            <td>VAT</td>
            <td>{PricingService.getVATForNetAmount(values.amount, currency)}</td>
          </tr>
          <tr>
            <td>Amount + VAT</td>
            <td>{PricingService.getGrossAmount(values.amount, currency)}</td>
          </tr>
          <tr>
            <td>Current Balance</td>
            <td>{balance}</td>
          </tr>
          <tr>
            <td>New Balance</td>
            <td>{values.amount > 0 ? PricingService.roundAmount(balance + values.amount, currency) : balance}</td>
          </tr>
        </tbody>
      </table>
      <Button warning fluid content="Confirm" onClick={() => confirmModalRef.current?.open()} disabled={!isValid} />
      <ConfirmationModal ref={confirmModalRef} values={values} action={(values) => handleSubmit(values)}>
        <div className="ml-4">
          <span className="flex">
            <ul>
              <li className="flex">
                <p className="font-bold mr-2">Amount: </p> {values.amount}
              </li>
              <li className="flex">
                <p className="font-bold mr-2">Vat: </p> {PricingService.getVATForNetAmount(values.amount, currency)}
              </li>
              <li className="flex">
                <p className="font-bold mr-2">Amount + VAT: </p> {PricingService.getGrossAmount(values.amount, currency)}
              </li>
              <li className="flex">
                <p className="font-bold mr-2">Current Balance: </p> {balance}
              </li>
              <li className="flex">
                <p className="font-bold mr-2">New Balance: </p>
                {values.amount > 0 ? PricingService.roundAmount(balance + values.amount, currency) : balance} {currency}
              </li>
            </ul>
          </span>
        </div>
      </ConfirmationModal>
    </div>
  );
};

export default withFormik<EditPendingFormProps, { amount: number }>({
  mapPropsToValues: () => ({
    amount: 0
  }),

  validate: (_: { amount: number }) => {
    return {};
  },
  handleSubmit: async (values, { props }: FormikBag<EditPendingFormProps, { amount: number }>) => {
    try {
      await props.onSubmit(values.amount);
    } catch (error) {
      console.log(error);
    }
  }
})(EditPendingForm);
