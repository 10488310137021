import { customizeMeal, replaceAddonSubscriptionFood, replaceFood, skipMeal, unSkipMeal } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef } from 'components';
import Popup from 'components/Popup';
import { useUserRoles } from 'lib/hooks';
import { CustomComponentsByCategory, Delivery, FoodComponent, FoodWithPosition, Menu as MenuFood } from 'lib/interfaces';
import { uniqBy } from 'lodash-es';
import { Fragment, useEffect, useRef, useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation } from 'react-query';

import { Permission } from '@calo/dashboard-types';
import { PermissionService } from '@calo/services';
import {
  CustomMealCategory,
  Food,
  FoodActionType,
  FoodComponentType,
  FoodDietType,
  FoodType,
  MacrosType,
  Subscription
} from '@calo/types';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, CircularProgress, Grid, Menu, MenuItem, Stack } from '@mui/material';

import ConfirmDeleteMealPopup from './ConfirmDeleteMealPopup';
import CustomizeMacrosPopUp from './CustomizeFoodMacrosPopUp';
import CustomizeMealCard from './CustomizeMealCard';
import SwapMealPopUp from './SwapMealPopUp';

interface DeliveryMealProps {
  delivery: Delivery;
  mealType: string;
  meal: FoodWithPosition;
  menu: MenuFood;
  menuLoading: boolean;
  subscription: Subscription;
  isCustomComponentsLoading: boolean;
  customFoodComponents: FoodComponent[];
  allCustomFoodComponents: CustomComponentsByCategory;
  caloKidsComponents: FoodComponent[];
  foodComponents: FoodComponent[];
  setCustomFoodComponents: React.Dispatch<React.SetStateAction<FoodComponent[]>>;
  refetchDelivery: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  setFetchMenu: (value: boolean) => void;
  setFetchAllComponents: (value: boolean) => void;
}
const DeliveryMeal = ({
  meal,
  menu,
  menuLoading,
  mealType,
  delivery,
  subscription,
  customFoodComponents,
  allCustomFoodComponents,
  caloKidsComponents,
  foodComponents,
  isCustomComponentsLoading,
  setCustomFoodComponents,
  refetchDelivery,
  setFetchMenu,
  setFetchAllComponents
}: DeliveryMealProps) => {
  const roles = useUserRoles();
  const foodModalRef = useRef<ModalRef>();

  const confirmModalRef = useRef<ModalRef>();
  const customFoodModalRef = useRef<ModalRef>();
  const removeComponentsRef = useRef<ModalRef>();
  const customizeMacrosModalRef = useRef<ModalRef>();

  const [customFood, setCustomFood] = useState<any | null>();
  const [selected, setSelected] = useState<FoodWithPosition | null>();
  const [selectedDeleteFood, setSelectedDeleteFood] = useState<Food>();
  const [selectedToCustomizeMacros, setSelectedToCustomizeMacros] = useState<FoodWithPosition | null>();
  const [customizeMealLoading, setCustomizeMealLoading] = useState<boolean>(false);

  const [selectedMealToRemoveComponents, setSelectedMealToRemoveComponents] = useState<Food>();
  const [selectedRemovedComponentIdList, setSelectedRemovedComponentIdList] = useState<string[]>([]);
  const [selectedSwappedComponent, setSelectedSwappedComponent] = useState<{ oldId: string; newId: string }[]>([]);
  const [selectedComponent, setSelectedComponent] = useState<FoodComponent | undefined>(undefined);

  const [deliveryActionMenuAnchorEl, setDeliveryActionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isExportMenuOpened = Boolean(deliveryActionMenuAnchorEl);
  const isAddonSubscriptionFood = Boolean(meal?.addedAs && meal?.addedAs === 'addonSubscription');

  const { mutateAsync: replaceMutation } = useMutation(replaceFood);
  const { mutateAsync: skipMealMutation } = useMutation(skipMeal);
  const { mutateAsync: unSkipMealMutation } = useMutation(unSkipMeal);
  const { mutateAsync: customizeMealMutation } = useMutation(customizeMeal);
  const { mutateAsync: replaceAddonSubscriptionFoodMutation } = useMutation(replaceAddonSubscriptionFood);

  const uniqAllComponents: FoodComponent[] = uniqBy(Object.values(allCustomFoodComponents).flat(), 'id');
  useEffect(() => {
    const isPreBuilt = selectedMealToRemoveComponents?.tags.includes(FoodDietType.preBuiltCustom);
    if (isPreBuilt) {
      const modifiedComp = uniqAllComponents.filter((customComp) => {
        const mealSection = (customComp.sections ?? []).find((section) => section.category === CustomMealCategory.meal);
        return (
          mealSection?.tag ===
          (selectedComponent?.sections ?? []).find((section) => section.category === CustomMealCategory.meal)?.tag
        );
      });
      setCustomFoodComponents(modifiedComp || []);
    } else {
      setCustomFoodComponents(
        allCustomFoodComponents.meal.filter(
          (customComp) =>
            (customComp.sections ?? []).find((seciton) => seciton.category === CustomMealCategory.meal)?.tag ===
            FoodComponentType.sauce
        ) || []
      );
    }
  }, [selectedComponent, selectedMealToRemoveComponents]);

  const onSkip = async () => {
    if (meal.skipped) {
      await unSkipMealMutation(
        { deliveryId: delivery.id, foodId: meal.id },
        {
          onSuccess: () => {
            refetchDelivery();
          }
        }
      );
    } else {
      await skipMealMutation(
        { deliveryId: delivery.id, foodId: meal.id },
        {
          onSuccess: () => {
            refetchDelivery();
          }
        }
      );
    }
  };

  useEffect(() => {
    if (selected) {
      setFetchAllComponents(true);
      setFetchMenu(true);
      foodModalRef.current?.open();
    } else {
      foodModalRef.current?.close();
    }
  }, [selected, customFood]);

  useEffect(() => {
    if (selectedToCustomizeMacros) {
      setFetchAllComponents(true);
      customizeMacrosModalRef.current?.open();
    } else {
      customizeMacrosModalRef.current?.close();
    }
  }, [selectedToCustomizeMacros, customFood]);

  const handleEditMeal = (item: FoodWithPosition) => {
    if (item.isCustom) {
      setCustomFood!(item);
      setSelected(item);
    } else {
      setSelected(item);
    }
  };

  const handleCustomizeMealMacros = (item: FoodWithPosition) => {
    if (item.isCustom) {
      setCustomFood!(item);
      setSelectedToCustomizeMacros(item);
    } else {
      setSelectedToCustomizeMacros(item);
    }
  };

  const handleReplace = async (food: any) => {
    if (selected) {
      await replaceMutation(
        {
          id: delivery.id,
          sourceId: selected.id,
          targetId: food.size === 'C' ? (food.id === 'food' ? food.sk : food.id) : food.id || food.foodId,
          positionIndex: selected.positionIndex
        },
        {
          onSuccess: () => {
            foodModalRef.current?.close();
            refetchDelivery();
          }
        }
      );
    }
  };

  const handleReplaceAddonSubscription = async (food: any) => {
    if (selected) {
      await replaceAddonSubscriptionFoodMutation(
        {
          subscriptionId: subscription.id,
          deliveryId: delivery.id,
          sourceId: selected.id,
          targetId: food?.id
        },
        {
          onSuccess: () => {
            foodModalRef.current?.close();
            refetchDelivery();
          }
        }
      );
    }
  };

  const handleCustomizeMeal = async (
    foodId: string,
    delId: string,
    removedIds: string[],
    swappedIds: { oldId: string; newId: string }[]
  ) => {
    const actions = [
      {
        type: FoodActionType.remove,
        componentId: removedIds
      },
      {
        type: FoodActionType.swap,
        swappedComponents: swappedIds,
        componentId: swappedIds.map((swap) => swap.oldId)
      }
    ];
    setCustomizeMealLoading(true);
    await customizeMealMutation(
      {
        foodId: foodId,
        deliveryId: delId,
        actions: actions.filter((action) => action.componentId.length !== 0)
      },
      {
        onSuccess: () => {
          refetchDelivery();
          removeComponentsRef.current?.close();
        },
        onError: () => {
          setCustomizeMealLoading(false);
        }
      }
    );
    setCustomizeMealLoading(false);
  };

  return (
    <>
      <Fragment>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <LoadingButton
            loading={false}
            id="delivery-action-button"
            aria-haspopup="true"
            disableElevation
            onClick={(event) => {
              !deliveryActionMenuAnchorEl && setDeliveryActionMenuAnchorEl(event.currentTarget);
            }}
            variant="outlined"
            aria-label="meal-actions"
            sx={{
              width: '124px',
              height: '45px',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '17px',
              borderRadius: '8px',
              textTransform: 'none',
              color: caloTheme.palette.black,
              borderColor: caloTheme.palette.neutral900,
              '&:hover': {
                borderColor: caloTheme.palette.neutral900,
                backgroundColor: caloTheme.palette.neutral100,
                color: caloTheme.palette.black
              },
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                justifyItems: 'center',
                margin: 'auto',
                marginTop: 1,
                width: 'auto'
              }
            }}
            endIcon={<ArrowDownIcon />}
            disabled={!meal.name || mealType === 'giftItem'}
          >
            Action
          </LoadingButton>
          <Menu
            MenuListProps={{
              'aria-labelledby': 'export-button'
            }}
            anchorEl={deliveryActionMenuAnchorEl}
            open={isExportMenuOpened}
            onClose={() => setDeliveryActionMenuAnchorEl(null)}
          >
            {roles.includes(Permission.REPLACE_FOOD_DELIVERY_MENU) && !meal.skipped && (
              <MenuItem
                sx={{ fontWeight: 600, py: 1, mr: 2 }}
                disabled={isAddonSubscriptionFood ? false : mealType !== 'meal'}
                onClick={() => {
                  handleEditMeal(meal);
                  setDeliveryActionMenuAnchorEl(null);
                }}
              >
                <Box>Replace Meal</Box>
              </MenuItem>
            )}
            {roles.includes(Permission.REPLACE_FOOD_DELIVERY_MENU) &&
              !meal.skipped &&
              subscription.macrosType === MacrosType.athlete && (
                <MenuItem
                  sx={{ fontWeight: 600, py: 1, mr: 2 }}
                  disabled={isAddonSubscriptionFood ? false : mealType !== 'meal'}
                  onClick={() => {
                    handleCustomizeMealMacros(meal);
                    setDeliveryActionMenuAnchorEl(null);
                  }}
                >
                  <Box>Customize Macros</Box>
                </MenuItem>
              )}
            {roles.includes(Permission.DELETE_FOOD_DELIVERY_MENU) && !meal.skipped && (
              <MenuItem
                sx={{ fontWeight: 600, py: 1, mr: 2 }}
                onClick={() => {
                  setSelectedDeleteFood(meal);
                  confirmModalRef.current?.open();
                  setDeliveryActionMenuAnchorEl(null);
                }}
                disabled={
                  isAddonSubscriptionFood ? true : mealType === 'meal' ? !PermissionService.deliveryCanBeEdited(delivery) : false
                }
              >
                <Box>Delete Meal</Box>
              </MenuItem>
            )}
            {roles.includes(Permission.DELETE_FOOD_DELIVERY_MENU) && (
              <MenuItem
                onClick={() => {
                  onSkip();
                  setDeliveryActionMenuAnchorEl(null);
                }}
                sx={{ fontWeight: 600, py: 1, mr: 2 }}
                disabled={
                  isAddonSubscriptionFood || ['meal', 'addon'].includes(meal.addedAs ?? '')
                    ? true
                    : mealType === 'meal'
                      ? !PermissionService.deliveryCanBeEdited(delivery)
                      : false
                }
              >
                <Box>{meal.skipped ? 'Un-Skip Meal' : 'Skip Meal'}</Box>
              </MenuItem>
            )}
            {roles.includes(Permission.REMOVE_COMPONENT_FROM_DELIVERY_FOOD) && !meal.skipped && (
              <MenuItem
                sx={{ fontWeight: 600, py: 1, mr: 2 }}
                onClick={() => {
                  setSelectedMealToRemoveComponents(meal);
                  setSelectedSwappedComponent(
                    meal.actions
                      ?.filter((action) => action.type === FoodActionType.swap)
                      ?.flatMap((action) => action.swappedComponents || []) ?? []
                  );
                  setSelectedRemovedComponentIdList(
                    meal.actions
                      ?.filter((action) => action.type === FoodActionType.remove)
                      ?.flatMap((action) => action.componentId || []) ?? []
                  );
                  setFetchAllComponents(true);
                  setFetchMenu(true);
                  removeComponentsRef.current?.open();
                  setDeliveryActionMenuAnchorEl(null);
                }}
                disabled={
                  mealType === 'meal' &&
                  [FoodType.lunch, FoodType.dinner, FoodType.breakfast, FoodType.caloKids].some((type) =>
                    meal.type?.includes(type)
                  )
                    ? !PermissionService.deliveryCanBeEdited(delivery)
                    : true
                }
              >
                <Box>Edit Meal</Box>
              </MenuItem>
            )}
          </Menu>
        </Grid>
        <ConfirmDeleteMealPopup
          ref={confirmModalRef}
          delivery={delivery}
          selectedDeleteFood={selectedDeleteFood}
          handleClose={() => confirmModalRef.current?.close()}
          setSelectedDeleteFood={setSelectedDeleteFood}
          refetchDelivery={refetchDelivery}
        />
        {selectedToCustomizeMacros && (
          <CustomizeMacrosPopUp
            refetchDelivery={refetchDelivery}
            food={selectedToCustomizeMacros!}
            customizeMacrosModalRef={customizeMacrosModalRef}
            onClose={() => {
              setSelectedToCustomizeMacros(undefined);
              customizeMacrosModalRef.current?.close();
            }}
            deliveryId={delivery.id}
            subscription={subscription}
          />
        )}

        <SwapMealPopUp
          foodModalRef={foodModalRef}
          key={selected?.id}
          delivery={delivery}
          menu={menu}
          menuLoading={menuLoading}
          selectedMeal={selected!}
          setSelectedMeal={setSelected}
          handleClose={() => foodModalRef.current?.close()}
          handleReplace={handleReplace}
          handleReplaceAddonSubscriptionFood={handleReplaceAddonSubscription}
          customFoodComponents={allCustomFoodComponents}
          isCustomComponentsLoading={isCustomComponentsLoading}
          customFoodModalRef={customFoodModalRef}
        />

        <Popup
          fullWidth
          maxWidth="md"
          ref={removeComponentsRef}
          title={'Customize Meal'}
          onClose={() => removeComponentsRef.current?.close()}
        >
          <>
            <Stack sx={{ mt: '-16px' }}>
              <CustomizeMealCard
                key={meal.id}
                components={foodComponents}
                customFoodComponents={customFoodComponents}
                setCustomFoodComponents={setCustomFoodComponents}
                selectedSwappedComponent={selectedSwappedComponent}
                setSelectedSwappedComponent={setSelectedSwappedComponent}
                selectedRemovedComponentIdList={selectedRemovedComponentIdList}
                setSelectedRemovedComponentIdList={setSelectedRemovedComponentIdList}
                isPreBuiltMeal={meal.tags?.includes(FoodDietType.preBuiltCustom)}
                selectedComponent={selectedComponent}
                setSelectedComponent={(comp) => setSelectedComponent(comp)}
                allCustomFoodComponents={allCustomFoodComponents}
                caloKidsComponents={meal.type?.includes(FoodType.caloKids) ? caloKidsComponents : []}
                isLoading={isCustomComponentsLoading}
              />
            </Stack>

            <Stack display={'felx'} flexDirection={'row'} justifyContent={'center'} sx={{ mt: 3 }}>
              <Button
                sx={{
                  width: 'auto',
                  height: '45px',
                  mx: 2,
                  lineHeight: '17px',
                  fontWeight: 600,
                  fontSize: '14px',
                  borderRadius: '8px',
                  color: caloTheme.palette.neutral600,
                  '&:hover': {
                    backgroundColor: caloTheme.palette.neutral100,
                    borderColor: caloTheme.palette.neutral100
                  }
                }}
                onClick={() => {
                  removeComponentsRef.current?.close();
                  setSelectedDeleteFood(undefined);
                }}
                disabled={customizeMealLoading}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                aria-label="confirm-remove-meal"
                sx={{
                  width: 'auto',
                  height: '45px',
                  lineHeight: '17px',
                  fontWeight: 600,
                  fontSize: '14px',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  padding: '14px 20px 14px 20px',
                  backgroundColor: caloTheme.palette.primary500,
                  borderColor: caloTheme.palette.primary500,
                  color: 'white',
                  '&:hover': {
                    color: 'white',
                    backgroundColor: caloTheme.palette.primary600,
                    borderColor: caloTheme.palette.primary600
                  },
                  [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                    marginTop: 2,
                    width: '75%',
                    mx: 1
                  }
                }}
                disabled={customizeMealLoading}
                endIcon={customizeMealLoading ? <CircularProgress size={20} /> : null}
                onClick={() =>
                  handleCustomizeMeal(
                    selectedMealToRemoveComponents!.id,
                    delivery.id,
                    selectedRemovedComponentIdList,
                    selectedSwappedComponent
                  )
                }
              >
                Save
              </Button>
            </Stack>
          </>
        </Popup>
      </Fragment>
    </>
  );
};
export default DeliveryMeal;
