import { Subscription } from '@calo/dashboard-types';
import { PermissionService } from '@calo/services';
import { DeliveryStatus } from '@calo/types';
import { isBefore, parseISO } from 'date-fns/fp';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

import { Button, DeliveryPaymentIcon, Modal, ModalRef } from 'components';
import { Routes } from 'lib/enums';
import { Delivery } from 'lib/interfaces';
import updateDeliveryPayment from 'mutations/updateDeliveryPayment';
import DeliveryPayment from '../../DeliveryPayment';

interface DeliveryRowProps {
  delivery: Delivery;
  subscription: Subscription;
}

const DeliveryRow = ({ delivery, subscription }: DeliveryRowProps) => {
  const paymentDeliveryRef = useRef<ModalRef>();
  const updateMutation = updateDeliveryPayment();

  const handleApplyPayment = async () => {
    await updateMutation(delivery, subscription);
    paymentDeliveryRef.current?.close();
  };

  return (
    <>
      <tr key={delivery.id}>
        <td>
          <Link to={Routes.delivery.replace(':id', delivery.id)}>{delivery.day}</Link>
        </td>
        <td>{delivery.cost}</td>
        <td>{delivery.paidAmount}</td>
        <td>
          <DeliveryPaymentIcon delivery={delivery} />
        </td>
        <td>
          <div>
            {[DeliveryStatus.upcoming, DeliveryStatus.paymentRequired].includes(delivery.status) &&
              delivery.cost !== delivery.paidAmount &&
              subscription.balance[subscription.currency] - delivery.cost >= 0 &&
              isBefore(PermissionService.getMinActionDate(subscription.deliveryDays, Date.now()))(parseISO(delivery.day)) && (
                <div>
                  <Button icon="fas fa-dollar-sign" onClick={() => paymentDeliveryRef.current?.open()} />
                </div>
              )}
          </div>
        </td>
      </tr>
      <Modal ref={paymentDeliveryRef}>
        <DeliveryPayment onApplyPayment={handleApplyPayment} subscription={subscription} delivery={delivery} />
      </Modal>
    </>
  );
};
export default DeliveryRow;
