import { Food } from '@calo/types';
import { keyBy } from 'lodash-es';
import { FoodComponent } from '../interfaces';

export const setComponentsOnFood = (food: Food[], components: FoodComponent[]) => {
  const componentsMap = keyBy(components, 'id');
  const foodWithComponents = food.map((f) => ({
    ...f,
    components: (f.components || []).map((c) => ({
      ...c,
      ...componentsMap[c.id]
    }))
  }));

  return foodWithComponents;
};
