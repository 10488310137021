import { useFormik } from 'formik';
import { BaseOmit, Food, UpdateFoodReqWithIndex } from 'lib/interfaces';
import { isEqual, isNumber, omit } from 'lodash-es';

import { FoodStatus, UpdateFoodReq } from '@calo/dashboard-types';
import { BasicFood, Brand } from '@calo/types';

const useFoodForm = (food: Food, onSubmit: (values: Omit<UpdateFoodReq, BaseOmit>) => any) => {
  const formik = useFormik<UpdateFoodReqWithIndex>({
    initialValues: {
      components: [],
      metadata: {
        excludeFrontLabel: food.metadata?.excludeFrontLabel ?? false,
        excludeBackLabel: food.metadata?.excludeBackLabel ?? false,
        assemblyURL: food.metadata?.assemblyURL ?? ''
      },
      micronutrients: {
        addedSugar: food.micronutrients?.addedSugar || 0,
        cholesterol: food.micronutrients?.cholesterol || 0,
        saturatedFats: food.micronutrients?.saturatedFats || 0,
        sodium: food.micronutrients?.sodium || 0,
        totalSugar: food.micronutrients?.totalSugar || 0,
        transFats: food.micronutrients?.transFats || 0
      },
      ingredientsIds: food.ingredients.map((i) => i.id),
      ...(omit(food, [
        'id',
        'createdAt',
        'updatedAt',
        'lowercaseName',
        'slug',
        'fhk',
        'dataType',
        'lastUsedOnMenu',
        'usedOnMenu'
      ]) as BasicFood)
    },
    enableReinitialize: true,
    validate: (values: Omit<UpdateFoodReq, BaseOmit>) => {
      const errors: any = {};
      if (values.status === FoodStatus.approved) {
        if (
          !values.name?.ar ||
          (values.name?.ar && values.name?.ar.length < 3) ||
          (values.name?.ar && values.name?.ar.length > 50)
        ) {
          errors.name = {
            ...errors.name,
            ar: true
          };
        }
        if (values.type?.length === 0) {
          errors.type = true;
        }
      }

      if (
        !values.name!.en ||
        (values.name?.en && values.name?.en.length < 3) ||
        (values.name?.en && values.name?.en.length > 50)
      ) {
        errors.name = {
          ...errors.name,
          en: true
        };
      }

      if (!values.description!.en) {
        errors.description = {
          ...errors.description,
          en: true
        };
      }

      if (!isNumber(values.macros?.cal)) {
        errors.macros = {
          ...errors.macros,
          cal: true
        };
      }
      if (!isNumber(values.macros?.protein)) {
        errors.macros = {
          ...errors.macros,
          protein: true
        };
      }
      if (!isNumber(values.macros?.carbs)) {
        errors.macros = {
          ...errors.macros,
          carbs: true
        };
      }
      if (!isNumber(values.macros?.fat)) {
        errors.macros = {
          ...errors.macros,
          fat: true
        };
      }
      if (!isNumber(values.macros?.fiber)) {
        errors.macros = {
          ...errors.macros,
          fiber: true
        };
      }

      if (!values.description?.en) {
        errors.description = {
          ...errors.description,
          en: true
        };
      }
      if (values.tags?.length === 0) {
        errors.tags = true;
      }
      if (!values.size && food.brand !== Brand.MEALO) {
        errors.size = true;
      }
      if (values.components?.some((component) => component?.quantity === 0)) {
        errors.components = true;
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      const updatedValues = Object.keys(values).reduce((acc, key) => {
        if (!isEqual(values[key], formik.initialValues[key])) {
          acc[key] = values[key];
        }
        return acc;
      }, {} as UpdateFoodReqWithIndex);
      let updatedValuesWithoutIngredients = omit(updatedValues, ['ingredients']) as UpdateFoodReqWithIndex;
      if (updatedValues.components?.length === 0 || (updatedValues.ingredients && updatedValues.ingredients.length > 0)) {
        const ingredientsIds = updatedValues.ingredients?.map((i) => i.id);
        updatedValuesWithoutIngredients = {
          ...updatedValuesWithoutIngredients,
          ingredientsIds
        };
      }
      try {
        await onSubmit(updatedValuesWithoutIngredients);
      } catch (error) {
        console.log(error);
      }
      setSubmitting(false);
    }
  });

  return formik;
};

export default useFoodForm;
