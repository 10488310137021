import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { UsedOnMenuIndicator } from 'components';
import { FormOperation, Routes } from 'lib/enums';
import { formatKitchenText, resolveCountry } from 'lib/helpers';
import { IngredientOperationValues } from 'lib/interfaces';
import { capitalize } from 'lodash';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import Container from './Container';

type IngredientHeaderCardProps = {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  disabled?: boolean;
  usedOnMenuButtonColor?: string[];
  children?: ReactNode;
  route?: Routes;
} & IngredientOperationValues;

const IngredientHeaderCard = ({
  disabled,
  route,
  handleSubmit,
  usedOnMenuButtonColor,
  children,
  ...props
}: IngredientHeaderCardProps) => {
  return (
    <>
      <Container>
        <Stack sx={styles.headerStack}>
          <Stack sx={styles.backButtonContainer}>
            <Link to={route ?? Routes.ingredients} style={{ color: 'black' }}>
              <Stack sx={styles.backButton}>
                <ArrowBackIosIcon sx={styles.backIcon} />
                <Typography sx={styles.backText}>Back</Typography>
              </Stack>
            </Link>
          </Stack>
          {props.operation === FormOperation.update && (
            <Typography variant="h5" sx={styles.ingredientTitle}>
              {props.operation === FormOperation.update
                ? `${capitalize(props.ingredient.brand)} - ${capitalize(resolveCountry(props.ingredient.country))} - ${formatKitchenText(props.ingredient.kitchen)}`
                : 'New Ingredient'}
            </Typography>
          )}
        </Stack>
        <Stack sx={styles.divider} />
      </Container>
      <Container sx={styles.stickyContainer}>
        <Stack sx={styles.actionStack}>
          <Stack sx={styles.titleStack}>
            <Typography sx={styles.title}>
              {props.operation === FormOperation.update ? props.ingredient.name.en : 'New Ingredient'}
            </Typography>
            {props.operation === FormOperation.update && props.ingredient.usedOnMenu && (
              <UsedOnMenuIndicator
                filteredFoodUsed={props.ingredient.usedOnMenu}
                usedOnMenuButtonColor={usedOnMenuButtonColor}
                type="ingredient"
              />
            )}
          </Stack>
          <Stack sx={styles.buttonStack}>
            {children}
            <Button variant="contained" disabled={disabled} sx={styles.submitButton} onClick={() => handleSubmit()}>
              {props.operation === FormOperation.update ? 'Save Changes' : 'Add Ingredient'}
            </Button>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default IngredientHeaderCard;

const styles = {
  headerStack: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  backButtonContainer: {
    flexDirection: 'row',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column',
      textAlign: 'center',
      width: '100%'
    }
  },
  backButton: {
    marginLeft: '30px',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center'
  },
  backIcon: {
    fontSize: '14px'
  },
  backText: {
    fontSize: '16px',
    fontWeight: 600
  },
  ingredientTitle: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#000000',
    [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
      flexDirection: 'column',
      textAlign: 'center',
      width: '100%',
      marginTop: 1
    }
  },
  divider: {
    marginX: 'auto',
    marginTop: '20px',
    width: '100%',
    height: '1px',
    backgroundColor: caloTheme.palette.neutral100
  },
  stickyContainer: {
    marginTop: '-5px',
    paddingTop: '5px',
    position: 'sticky',
    top: 0,
    zIndex: 500
  },
  actionStack: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  titleStack: {
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center'
  },
  title: {
    fontSize: '20px',
    fontWeight: 600
  },
  buttonStack: {
    flexDirection: 'row',
    gap: '12px'
  },
  submitButton: {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '16px',
    borderRadius: '8px',
    backgroundColor: caloTheme.palette.primary500,
    borderColor: caloTheme.palette.primary500,
    boxShadow: 'none',
    color: 'white',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: caloTheme.palette.primary600,
      borderColor: caloTheme.palette.primary600
    }
  }
};
