import { useEffect, useMemo, useRef, useState } from 'react';

import { parseISO } from 'date-fns';
import { addDays, format } from 'date-fns/fp';
import { capitalize, flatten, isEqual, sortBy, uniqBy } from 'lodash-es';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useInfiniteQuery, useMutation, useQuery } from 'react-query';

import { Permission } from '@calo/dashboard-types';
import { AddressService } from '@calo/services';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, IconButton, Menu, MenuItem, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { createBusinessAccountDelivery, getListWithParams, updateBusinessAccountDelivery } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon, Modal, Button as ModalButton, ModalRef } from 'components';
import { checkCountryCurrency } from 'lib';
import client from 'lib/client';
import { useUserRoles } from 'lib/hooks';
import { BusinessAccount, Food, Options, PaginatedBusinessAccountDeliveries } from 'lib/interfaces';
import DeliveryOrderModel from '../../../../DeliveryOrderModel';
import { onExport } from '../../../../Export';
import { SelectedMealData } from '../AccountDeliveriesCard';

interface AccountDeliverriesRowProps {
  accountDelivery: any;
  account: BusinessAccount;
  existDeliveryDay: string[];
  deleteDelivery: (id: string) => Promise<void>;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<PaginatedBusinessAccountDeliveries, Error>>;
}

const AccountDeliverriesRow = ({
  account,
  accountDelivery,
  refetch,
  existDeliveryDay,
  deleteDelivery
}: AccountDeliverriesRowProps) => {
  const roles = useUserRoles();
  const updateDeliveryRef = useRef<ModalRef>();
  const [updatedData, setUpdatedData] = useState({
    time: accountDelivery.time,
    orders: accountDelivery.orders,
    date: accountDelivery.date,
    addressId: accountDelivery.deliveryAddress.id
  });

  const [searchMealName, setSearchMealName] = useState<string>();
  const [isLoadingMealData, setIsLoadingMealData] = useState<boolean>(false);

  const [selectedMealsData, setSelectedMealsData] = useState<SelectedMealData[]>([]);
  const [allSelectedMealOptions, setAllSelectedMealOptions] = useState<Food[]>([]);
  const { mutateAsync: createMutation } = useMutation(createBusinessAccountDelivery);

  const [deliveryActionMenuAnchorEl, setDeliveryActionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isExportMenuOpened = Boolean(deliveryActionMenuAnchorEl);

  const [existingFoodIdList, setExistingFoodIdList] = useState<string[]>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const { mutateAsync: updateMutation } = useMutation(updateBusinessAccountDelivery);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      color: caloTheme.palette.neutral900,
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none'
    }
  }));

  const { data: foodList, isLoading: foodLoading } = useQuery<any, Error, { data: Food[] }>(
    [
      'food',
      {
        filters: {
          name: searchMealName ? searchMealName : undefined,
          country: accountDelivery.country,
          brand: accountDelivery.brand,
          isDeleted: false,
          kitchen: accountDelivery.kitchen
        },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page: 0,
        limit: 20
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: !!searchMealName,
      keepPreviousData: true
    }
  );

  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: existingFood,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery<{ data: any[]; meta: any }>(
    [
      'food',
      {
        filters: {
          name: undefined,
          country: accountDelivery.country,
          brand: accountDelivery.brand,
          kitchen: accountDelivery.kitchen,
          ids: !!existingFoodIdList && existingFoodIdList.length > 0 ? existingFoodIdList : undefined
        },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page: 0,
        limit: 100
      }
    ],
    async ({ pageParam = (existingFoodIdList ?? []).slice(0, 100), queryKey }) => {
      setIsLoading(true);
      const { data } = await client.get(queryKey[0] as string, {
        params: {
          filters: {
            name: undefined,
            country: accountDelivery.country,
            brand: accountDelivery.brand,
            kitchen: accountDelivery.kitchen,
            ids: !!existingFoodIdList && existingFoodIdList.length > 0 ? pageParam : undefined
          },
          sort: {
            orderBy: 'name',
            orderMode: 'asc'
          },
          page: 0,
          limit: 100
        }
      });
      return data;
    },
    {
      suspense: false,
      enabled: !!existingFoodIdList && existingFoodIdList.length > 0,
      keepPreviousData: false,
      getNextPageParam: (lastPage, allPages) => {
        const usedIds = allPages.flatMap((ap) => ap.data.map((d) => d.id));
        const nextIds = (existingFoodIdList || []).filter((id) => !usedIds.includes(id));
        return nextIds.length > 0 ? nextIds : undefined;
      }
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    } else {
      setIsLoading(false);
      if (isExporting) {
        onExport(accountDelivery, existingFood?.pages.flatMap((p) => p.data) ?? []);
        setIsExporting(false);
      }
    }
  }, [existingFood]);

  useEffect(() => {
    if (existingFood) {
      setIsLoadingMealData(true);
      setAllSelectedMealOptions(uniqBy([...allSelectedMealOptions, ...existingFood.pages.flatMap((p) => p.data)], 'name.en'));
      const allMealDataOption = existingFood.pages
        .flatMap((p) => p.data)
        .map((d: any) => ({
          name: d.name.en,
          id: d.id,
          data: d,
          size: d.size,
          numberOfMeals: accountDelivery.orders[0].items.find((meal: any) => meal.itemId === d.id).count
        }));
      setIsLoadingMealData(false);
      setSelectedMealsData(allMealDataOption);
    }
  }, [existingFood]);

  const sortedFoodList = sortBy(
    (foodList?.data || []).filter((f) => !f.deletedAt),
    (f) => `${f.name.en}`
  );

  const mealOptions = uniqBy(
    useMemo<Options<Food>[]>(
      () =>
        sortedFoodList
          .map((food) => ({
            name: food.name.en,
            value: food.id,
            data: food,
            size: food.size,
            label: `${food.name.en} ${food.size && `(${food.size})`}`
          }))
          .filter((meal) => !selectedMealsData?.find((ex) => ex.id === meal.value)),
      [foodList, selectedMealsData]
    ),
    'name'
  );

  const handleSelectedMealChanges = (selectedMeal: SelectedMealData, value: string, field: string) => {
    if (field === 'size') {
      const newMealId = allSelectedMealOptions.find((meal) => meal.name.en === selectedMeal.name && value === meal.size);
      const updatedMeals = selectedMealsData?.map((meal) =>
        meal.id === selectedMeal.id ? { ...meal, id: newMealId!.id, size: value } : meal
      );
      setSelectedMealsData(updatedMeals);
    } else if (field === 'number') {
      const updatedMeals = selectedMealsData?.map((meal) =>
        meal.id === selectedMeal.id ? { ...meal, numberOfMeals: +value } : meal
      );
      setSelectedMealsData(updatedMeals);
    }
  };

  const handleSelectedOption = (value: Options<Food>) => {
    const allOptions = sortedFoodList.filter((meal) => meal.name.en === value.name);
    if (!allSelectedMealOptions?.find((meal) => meal.name.en === value.name && meal.size === value.size)) {
      setAllSelectedMealOptions(uniqBy([...allSelectedMealOptions, ...allOptions], 'id'));
    }
    setSelectedMealsData([
      ...selectedMealsData!,
      { name: value.name!, id: value.value, data: value.data, size: value.data.size, numberOfMeals: 1 }
    ]);
  };

  const handleUpdateOrder = async () => {
    const itemsValues = selectedMealsData?.map((meal) =>
      accountDelivery.orders[0].items.find((k: any) => k.itemId === meal.id)
        ? {
            id: accountDelivery.orders[0].items.find((f: any) => f.itemId === meal.id).id,
            type: 'food',
            itemId: meal.id,
            count: meal.numberOfMeals,
            isSkipped: false,
            itemCost: 0
          }
        : {
            type: 'food',
            itemId: meal.id,
            count: meal.numberOfMeals,
            isSkipped: false,
            itemCost: 0
          }
    );

    const values = {
      time: updatedData.time,
      orders: [
        {
          withCutlery: false,
          id: accountDelivery.orders[0].id,
          items: itemsValues
        }
      ]
    };
    await updateMutation({ data: values, ddId: accountDelivery.id });
    setExistingFoodIdList([]);
    setSelectedMealsData([]);
    setAllSelectedMealOptions([]);
    updateDeliveryRef.current?.close();
    refetch();
  };

  const handleDuplicateOrder = async () => {
    const itemsValues = selectedMealsData?.map((meal) => ({
      type: 'food',
      itemId: meal.id,
      count: meal.numberOfMeals,
      isSkipped: false,
      itemCost: 0
    }));
    const values = {
      date: updatedData.date,
      country: accountDelivery.country,
      kitchen: accountDelivery.kitchen,
      brand: accountDelivery.brand,
      time: updatedData.time,
      status: 'confirmed',
      currency: checkCountryCurrency(accountDelivery.country),
      userId: accountDelivery.user.id,
      deliveryAddressId: accountDelivery.deliveryAddress.id,
      orders: [
        {
          withCutlery: false,
          items: itemsValues
        }
      ],
      cost: 0,
      totalAmount: 0,
      paidAmount: 0,
      isCharity: false
    };
    await createMutation({
      values
    });
    updateDeliveryRef.current?.close();
    setExistingFoodIdList([]);
    setSelectedMealsData([]);
    setAllSelectedMealOptions([]);
    setIsDuplicate(false);
    refetch();
  };
  const confirmModalRef = useRef<ModalRef>();

  return (
    <>
      <TableRow key={accountDelivery.id} hover>
        <Modal
          ref={confirmModalRef}
          onClose={() => {
            confirmModalRef.current?.close();
          }}
        >
          <section className="section is-title-bar label -my-8 ">
            <p className="label uppercase text-center"> Are you sure you want to delete this delivery</p>
          </section>
          <section>
            <div className="flex justify-center mx-auto">
              <ModalButton
                content="Delete"
                primary
                onClick={async () => {
                  confirmModalRef.current?.close();
                  await deleteDelivery(accountDelivery.id);
                }}
              />
            </div>
          </section>
        </Modal>
        <StyledTableCell
          component="th"
          scope="row"
          sx={{
            textOverflow: 'ellipsis'
          }}
        >
          <Typography
            component="span"
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '19px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'inline-block',
              textOverflow: 'ellipsis',
              fontFamily: caloTheme.typography.fontFamily
            }}
          >
            {format('dd/MM/yyyy')(parseISO(accountDelivery.date))}
          </Typography>
        </StyledTableCell>

        <StyledTableCell style={{ textAlign: 'center' }}>
          <Button
            variant="outlined"
            sx={{
              width: '112px',
              height: '42px',
              cursor: 'pointer',
              my: 'auto',
              '&:hover': {
                border: 2,
                fontWeight: 600,
                fontSize: '14px',
                borderRadius: '8px',
                backgroundColor: caloTheme.palette.primary100,
                borderColor: caloTheme.palette.primary600
              },
              border: 2,
              borderRadius: '8px',
              borderColor: caloTheme.palette.primary500,
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                justifyItems: 'center',
                margin: 'auto',
                marginTop: 4,
                width: 'auto'
              }
            }}
            onClick={() => {
              setExistingFoodIdList(() => accountDelivery.orders[0].items.map((r: any) => r.itemId));
              setIsEditing(false);
              updateDeliveryRef.current?.open();
            }}
          >
            <Typography
              variant={'button'}
              sx={{
                fontSize: '14px',
                fontWeight: 600,
                color: caloTheme.palette.primary500,
                lineHeight: '19px',
                fontFamily: caloTheme.typography.fontFamily
              }}
            >
              View Meals
            </Typography>
          </Button>
        </StyledTableCell>

        <StyledTableCell>
          <Typography
            sx={{
              width: '239px',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '19px',
              fontFamily: caloTheme.typography.fontFamily
            }}
          >
            {accountDelivery.user.email}
          </Typography>
        </StyledTableCell>

        <StyledTableCell>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '19px',
              fontFamily: caloTheme.typography.fontFamily
            }}
          >
            {AddressService.display(accountDelivery.deliveryAddress)}
          </Typography>
        </StyledTableCell>

        <StyledTableCell>
          <Typography
            sx={{
              fontFamily: caloTheme.typography.fontFamily,
              fontSize: '16px',
              lineHeight: '20px',
              fontWeight: 400,
              color: caloTheme.palette.neutral900
            }}
          >
            {capitalize(accountDelivery.time) || '---'}
          </Typography>
        </StyledTableCell>

        <StyledTableCell>
          {accountDelivery.status.toLowerCase() === 'confirmed' ? (
            <Button
              variant="text"
              startIcon={<Icon name={'edit'} size={6} />}
              // disabled = {parseISO(accountDelivery.date) < addDays(1)(Date.now())}
              sx={{
                color: caloTheme.palette.neutral900,
                fontFamily: 'Roboto',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '19px'
              }}
              onClick={() => {
                const newFoodIdList: string[] = flatten(
                  accountDelivery.orders.map((o: { items: any[] }) => o.items.map((r: any) => r.itemId))
                );
                setExistingFoodIdList([]);
                if (isEqual(newFoodIdList, existingFoodIdList)) {
                  setIsEditing(true);
                } else {
                  setExistingFoodIdList(newFoodIdList);
                  setIsEditing(true);
                }
                setDeliveryActionMenuAnchorEl(null);
                updateDeliveryRef.current?.open();
              }}
            >
              Edit
            </Button>
          ) : (
            <Typography
              sx={{
                fontFamily: caloTheme.typography.fontFamily,
                fontSize: '16px',
                lineHeight: '19px',
                fontWeight: 600,
                color:
                  accountDelivery.status.toLowerCase() === 'delivered'
                    ? caloTheme.palette.primary500
                    : caloTheme.palette.secondaryYellow500
              }}
            >
              {accountDelivery.status}
            </Typography>
          )}
        </StyledTableCell>
        <TableCell style={{ border: 0 }}>
          <Stack>
            <IconButton
              onClick={(event) => {
                !deliveryActionMenuAnchorEl && setDeliveryActionMenuAnchorEl(event.currentTarget);
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              MenuListProps={{
                'aria-labelledby': 'export-button'
              }}
              anchorEl={deliveryActionMenuAnchorEl}
              open={isExportMenuOpened}
              onClose={() => setDeliveryActionMenuAnchorEl(null)}
            >
              {[Permission.DELETE_BUSINESS_DELIVERY].some((r) => roles.includes(r)) && (
                <MenuItem
                  sx={{ fontWeight: 600, py: 1 }}
                  disabled={parseISO(accountDelivery.date) < addDays(1)(Date.now())}
                  onClick={() => {
                    confirmModalRef.current?.open();
                    setDeliveryActionMenuAnchorEl(null);
                  }}
                >
                  <Box>Delete</Box>
                </MenuItem>
              )}
              <MenuItem
                sx={{ fontWeight: 600, py: 1 }}
                onClick={() => {
                  setUpdatedData({ ...updatedData, date: null });
                  const newFoodIdList: string[] = flatten(
                    accountDelivery.orders.map((o: { items: any[] }) => o.items.map((r: any) => r.itemId))
                  );
                  if (isEqual(newFoodIdList, existingFoodIdList)) {
                    setIsDuplicate(true);
                  } else {
                    setExistingFoodIdList(newFoodIdList);
                    setIsDuplicate(true);
                  }
                  setDeliveryActionMenuAnchorEl(null);
                  updateDeliveryRef.current?.open();
                }}
              >
                <Box>Duplicate</Box>
              </MenuItem>
              <MenuItem
                disabled={isLoading || isExporting}
                sx={{ fontWeight: 600, py: 1 }}
                onClick={() => {
                  const newFoodIdList: string[] = flatten(
                    accountDelivery.orders.map((o: { items: any[] }) => o.items.map((r: any) => r.itemId))
                  );
                  if (isEqual(newFoodIdList, existingFoodIdList)) {
                    onExport(accountDelivery, selectedMealsData!);
                  } else {
                    setExistingFoodIdList(newFoodIdList);
                    setIsExporting(true);
                  }
                  setDeliveryActionMenuAnchorEl(null);
                }}
              >
                <Box>Download</Box>
              </MenuItem>
            </Menu>
          </Stack>
        </TableCell>

        <DeliveryOrderModel
          isLoading={isLoading || isLoadingMealData}
          accountData={account}
          isEditing={isEditing}
          isDuplicate={isDuplicate}
          mealOptions={mealOptions}
          foodLoading={foodLoading}
          searchMealName={searchMealName}
          setUpdatedData={setUpdatedData}
          setIsDuplicate={setIsDuplicate}
          accountDelivery={accountDelivery}
          existDeliveryDay={existDeliveryDay}
          updateDeliveryRef={updateDeliveryRef}
          selectedMealsData={selectedMealsData}
          setSearchMealName={setSearchMealName}
          handleUpdateOrder={handleUpdateOrder}
          handleDuplicateOrder={handleDuplicateOrder}
          setSelectedMealsData={setSelectedMealsData}
          handleSelectedOption={handleSelectedOption}
          setExistingFoodIdList={setExistingFoodIdList}
          allSelectedMealOptions={allSelectedMealOptions}
          handleSelectedMealChanges={handleSelectedMealChanges}
          setAllSelectedMealOptions={setAllSelectedMealOptions}
          updatedData={{
            time: updatedData.time,
            date: updatedData.date,
            orders: updatedData.orders,
            addressId: updatedData.addressId
          }}
        />
      </TableRow>
    </>
  );
};
export default AccountDeliverriesRow;
