import DOMPurify from 'dompurify';

export const cleanHtml = (str: string): string => {
  const clean = DOMPurify.sanitize(str, {
    ALLOWED_TAGS: ['p', 'table', 'colgroup', 'col', 'tbody', 'tr', 'td'],
    ALLOWED_ATTR: ['border']
  });

  return clean;
};

export const encodeHtmlSymbols = (str: string): string => {
  const cleanedHtml = cleanHtml(str);
  const encodedHtmlSymbols = cleanedHtml
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/\//g, '&#x2F;');

  return encodedHtmlSymbols;
};

export const decodeHtmlSymbols = (str: string): string => {
  const symbols = [
    ['amp', '&'],
    ['apos', "'"],
    ['lt', '<'],
    ['gt', '>'],
    ['quot', '"'],
    ['#39', "'"],
    ['#x2F', '/'],
    ['nbsp', ' ']
  ];

  for (const [symbol, char] of symbols) {
    const regex = new RegExp(`&${symbol};`, 'g');
    str = str.replace(regex, char);
  }

  return str;
};

export const removeHtml = (str: string): string => {
  const clean = DOMPurify.sanitize(str, {
    ALLOWED_TAGS: [],
    ALLOWED_ATTR: []
  });

  return clean;
};

export const removeDecodedHtmlTags = (str: string): string => {
  const decodedHtml = decodeHtmlSymbols(str);
  const cleanedHtml = removeHtml(decodedHtml);
  return cleanedHtml;
};
