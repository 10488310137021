import { PrototypeAction } from '@calo/dashboard-types';
import { toast } from 'react-toastify';

interface ToastSuccessMsgsProps {
  action: string;
  status: number;
}
export const ToastSuccessMsgs = ({ status, action }: ToastSuccessMsgsProps) => {
  if (status >= 200 && status <= 299) {
    toast(action, { type: 'success', autoClose: 2000 });
  }
};

export const getPrototypeActionSuccessMessage = (action: PrototypeAction, type: 'Food' | 'Component' | 'Ingredient') => {
  let message = '';
  if (action === PrototypeAction.comment) {
    message = 'Comment added successfully';
  }
  if (action === PrototypeAction.pend) {
    message = `${type} is now ready for approval`;
  }
  if (action === PrototypeAction.approve) {
    message = `${type} approved successfully`;
  }

  return message;
};
