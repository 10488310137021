import { BatchRemoveFromWaitingListReq, DeleteWaitingListQuery, UpdateKitchenReq } from '@calo/dashboard-types';
import { Kitchen, WaitingListType } from '@calo/types';
import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import mutation from './mutation';

export const resendDayDocs = async (day: string, country: string, kitchen: Kitchen) => {
  const { data, status } = await client.post('/stats/daily-email', { day, country, kitchen });
  ToastSuccessMsgs({ status, action: `Email sent successfully for ${day}/${country}/${kitchen}` });
  return data.data;
};

export const updateKitchen = async (payload: UpdateKitchenReq & { id: Kitchen }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`/kitchens/${id}`, rest);
  mutation(['kitchens', id], data);
  ToastSuccessMsgs({ status, action: 'Kitchen updated succssfully' });
};

export const allowWaiting = async (id: string) => {
  const { data, status } = await client.post(`/waiting-list/${id}/allow`);
  ToastSuccessMsgs({ status, action: `${data.name} added to allowed list` });
  mutation(['/waiting-list', { kitchenId: data.kitchen, displayType: 'list', type: WaitingListType.SUPPLY_CAP }], data);
  return data;
};

export const sendCode = async (payload: { id: string; userName: string }) => {
  const { id, userName } = payload;
  const { data, status } = await client.post(`/waiting-list/${id}/send-invitation-code`);
  ToastSuccessMsgs({ status, action: `Code was sent to ${userName}` });
  mutation(['/waiting-list', { kitchenId: data.kitchen, displayType: 'list', type: WaitingListType.SOFT_LAUNCH }], data);
  return data;
};

export const removeUserFromWaitingList = async (payload: { id: string }) => {
  const { id } = payload;
  const { status } = await client.delete(`/waiting-list/${id}`);
  ToastSuccessMsgs({ status, action: 'removed successfully' });
};

export const bulkInvite = async (payload: BatchRemoveFromWaitingListReq) => {
  const { data, status } = await client.post('/waiting-list/batch-remove-from-pre-launch', payload);
  ToastSuccessMsgs({ status, action: 'Invite sent successfully' });
  return data;
};

export const bulkAllow = async (payload: BatchRemoveFromWaitingListReq) => {
  const { data, status } = await client.post('/waiting-list/batch-allow-from-supply-cap', payload);
  ToastSuccessMsgs({ status, action: 'Users allowed successfully' });
  return data;
};

export const sendInvite = async (payload: { id: string; sendSms: DeleteWaitingListQuery }) => {
  const { id, sendSms } = payload;
  const { status } = await client.delete(`/waiting-list/${id}`, { params: sendSms });
  ToastSuccessMsgs({ status, action: 'Invite sent successfully' });
};
