import { useEffect, useRef, useState } from 'react';

import { endOfWeek, getYear, startOfWeek } from 'date-fns/fp';
import { unionBy } from 'lodash-es';
import Calendar from 'react-calendar';
import { useMutation, useQuery } from 'react-query';
import { RouteComponentProps, generatePath } from 'react-router';

import { AddonsMenuFoodItem, CreateAddonsMenuReq } from '@calo/dashboard-types';
import { Brand, Country, Kitchen } from '@calo/types';

import { createAddons, getListWithParams } from 'actions';
import { AddonsPicker, Button, Card, ModalRef, Select2 } from 'components';
import AddonsTable from 'components/AddonsTable';
import { Routes } from 'lib/enums';
import { getAccessibleCountries, selectCountry } from 'lib/helpers';
import { getKitchenOptions } from 'lib/helpers/kitchenUtils';
import { useUserKitchens } from 'lib/hooks';
import { Food } from 'lib/interfaces';
import useAddonsForm from './useAddonsForm';

type NewAddonsProps = RouteComponentProps;

const NewAddons = ({ history }: NewAddonsProps) => {
  const [country, setCountry] = useState(Country.BH);
  const [brand, setBrand] = useState(Brand.CALO);
  const [kitchen, setKitchen] = useState(Kitchen.BH1);
  const [filterName, setFilterName] = useState<string>();
  const [selectedAddons, setSelectedAddons] = useState<Food[]>([]);

  const userKitchens: Kitchen[] = useUserKitchens();

  const { data: addonsList, isLoading } = useQuery<any, Error, { data: Food[] }>(
    [
      'food',
      {
        filters: { name: filterName ? filterName : undefined, country, brand, kitchen, isDeleted: false },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page: 0,
        limit: 20
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: !!filterName
    }
  );

  const { mutateAsync: createMutation } = useMutation(createAddons);

  const onSubmit = async (values: CreateAddonsMenuReq) => {
    const addons = await createMutation(values);
    if (addons) {
      const path = generatePath(Routes.addons, {
        brand: addons.brand,
        country: addons.country,
        id: addons.id,
        kitchen: addons.kitchen
      });
      history.push(path);
    }
  };

  const { values, isSubmitting, isValid, dirty, setFieldValue, handleSubmit, setValues } = useAddonsForm(onSubmit);

  const addonsRef = useRef<ModalRef>();
  const [addonsItem, setAddonsItem] = useState<AddonsMenuFoodItem>({ id: undefined!, priority: undefined! });
  const [daysRange, setDaysRange] = useState<any>({ selectedDays: [] });

  const AddToTable = (value: AddonsMenuFoodItem) => {
    setValues({
      ...values,
      food: [
        ...values.food,
        {
          id: value.id,
          priority: value.priority
        }
      ]
    });
    addonsRef.current?.close();
    setAddonsItem({ id: undefined!, priority: undefined! });
  };

  const editRow = (value: AddonsMenuFoodItem) => {
    setAddonsItem({ id: value.id, priority: +value.priority });
    addonsRef.current?.open();
  };

  useEffect(() => {
    setCountry(values.country);
    setBrand(values.brand);
    setKitchen(values.kitchen!);
    setValues({
      ...values,
      food: []
    });
  }, [values.brand, values.country, values.kitchen]);

  useEffect(() => {
    if (addonsItem.id !== undefined) {
      setFieldValue(
        'food',
        values.food.filter((e) => e.id !== addonsItem.id)
      );
    }
  }, [addonsItem]);

  const handleWeekClick = (weekNumber: number, days: Date) => {
    const year = getYear(endOfWeek(days));
    setDaysRange({
      selectedDays: [startOfWeek(days), endOfWeek(days)]
    });
    setFieldValue('week', weekNumber);
    setFieldValue('year', year);
  };

  const handleSelectedAddons = (id: string) => {
    const newAddon = addonsList?.data.find((e) => e.id === id);
    if (newAddon) {
      setSelectedAddons((old) => [...old, newAddon]);
    }
  };

  return (
    <div className="card-content">
      <div>
        <AddonsPicker
          isLoading={isLoading}
          addonsList={(addonsList?.data || []).filter((f) => !f.deletedAt)}
          addonsRef={addonsRef}
          addonsItem={addonsItem}
          AddToTable={AddToTable}
          existAddons={values.food}
          setFilterName={setFilterName}
          handleSelectedAddons={handleSelectedAddons}
        />
        <span className="text-black font-bold">Week Number: {values.week}</span>
        <div>
          <Calendar
            showWeekNumbers
            calendarType={'US'}
            tileDisabled={() => true}
            value={daysRange.selectedDays.length > 0 ? daysRange.selectedDays : null}
            onClickWeekNumber={(weekNumber, date) => handleWeekClick(weekNumber, date)}
            onClickDay={() => null}
            data-test="addon-menu-new-calendar-select"
          />
        </div>
        <div className="py-4">
          <div className="flex-wrap grid grid-rows-1 grid-flow-col gap-4 w-11/12 relative ">
            <Select2
              label="Country"
              value={values.country}
              name="country"
              onChange={(data: any) =>
                selectCountry({
                  value: data.target.value,
                  kitchens: userKitchens,
                  setFieldValue
                })
              }
              options={getAccessibleCountries(userKitchens).map((coun) => ({
                value: coun,
                label: Country[coun]
              }))}
              data-test="addon-menu-new-country-select"
            />
            <Select2
              label="Kitchen"
              value={values.kitchen}
              onChange={(data: any) => setFieldValue('kitchen', data.target.value)}
              options={getKitchenOptions(userKitchens, values.country)}
              disabled={userKitchens.length === 0}
              data-test="addon-menu-new-kitchen-select"
            />
          </div>
        </div>
        <Button
          className="flex"
          type="submit"
          primary
          loading={isSubmitting}
          disabled={!dirty || !isValid || isSubmitting}
          content="Save"
          onClick={() => handleSubmit()}
          data-test="addon-menu-new-save-button"
        />
        <Card title={`Addons - week #${values.week}`} className="my-2">
          <div className="float-right -mt-8">
            <Button icon="fas fa-plus" onClick={() => addonsRef.current?.open()} data-test="addon-menu-new-plus-button" />
          </div>
          <AddonsTable
            values={values}
            setValues={setValues}
            addonsValues={values.food}
            editRow={(k) => editRow(k)}
            addonsList={unionBy(
              (addonsList?.data || []).filter((f) => !f.deletedAt),
              selectedAddons,
              'id'
            ).filter((selectedFood) => values.food.map((r) => r.id).includes(selectedFood.id))}
          />
        </Card>
      </div>
    </div>
  );
};

export default NewAddons;
