import { Subscription, UpdateSubscriptionReq } from '@calo/dashboard-types';
import { useFormik } from 'formik';

export default (subscription: Subscription, onSubmit: (values: UpdateSubscriptionReq) => Promise<void> | void) => {
  const macros = subscription.macros;
  const macrosType = subscription.macrosType;
  const macrosPreferences = subscription.macrosPreferences;
  return useFormik<UpdateSubscriptionReq>({
    enableReinitialize: true,
    initialValues: {
      macros,
      macrosType,
      macrosPreferences
    },

    validate: (_: UpdateSubscriptionReq) => {
      return {};
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
};
