import { Subscription } from '@calo/dashboard-types';

import { Button } from 'components';
import { Delivery } from 'lib/interfaces';
interface DeliveryPaymentProps {
  subscription: Subscription;
  delivery: Delivery;
  onApplyPayment: () => Promise<void> | void;
}

const DeliveryPayment = ({ onApplyPayment, subscription, delivery }: DeliveryPaymentProps) => (
  <div>
    <table className="table">
      <tbody>
        <tr>
          <td>Amount</td>
          <td>{delivery.cost - delivery.paidAmount}</td>
        </tr>

        <tr>
          <td>Current Balance</td>
          <td>{subscription.balance[subscription.currency]}</td>
        </tr>
        <tr>
          <td>New Balance</td>
          <td>{(subscription.balance[subscription.currency] - (delivery.cost - delivery.paidAmount)).toFixed(3)}</td>
        </tr>
      </tbody>
    </table>
    <Button type="button" warning fluid content="Confirm" onClick={onApplyPayment} />
  </div>
);
export default DeliveryPayment;
