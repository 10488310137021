import { startCase } from 'lodash-es';

import { Brand, FoodDietType, MenuTagValue } from '@calo/types';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import { Box, Card, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Tooltip, Typography } from '@mui/material';

import { Icon as Iconify } from '@iconify/react';
import { caloTheme } from 'assets/images/theme/calo';
import { getFoodAggregatedRatingsForPeriod, menuTagLabelColor } from 'lib/helpers';
import { LastUsedOnMenuReq, MenuFood } from 'lib/interfaces';
import Icon from '../Icon';
import MenuFoodDietTags from '../MenuFoodDietTags';

interface MenuTableCardProps {
  addTags: {
    name: string;
    tag: MenuTagValue | string;
  };
  brand: Brand;
  food: MenuFood;
  foodLabel: any;
  foodCost: number;
  setReplaceFood: (value: { open: boolean; name: string }) => void;
  isEditable: boolean;
  removeFood: ((ids: string[]) => void) | undefined;
  handleSetFoodTags: (foodName: string, tag: any) => void;
  isMealUsedInWeekRange: (lastUsedDate?: LastUsedOnMenuReq) => boolean;
  handleSelectMealInfo: (food: any) => void;
  setAddTags: React.Dispatch<
    React.SetStateAction<{
      name: string;
      tag: MenuTagValue | string;
    }>
  >;
  handleRemoveAllSizes: (food: MenuFood) => void;
}

const MenuTableCard = ({
  food,
  handleSelectMealInfo,
  isMealUsedInWeekRange,
  foodLabel,
  isEditable,
  removeFood,
  handleSetFoodTags,
  addTags,
  setReplaceFood,
  foodCost,
  setAddTags,
  handleRemoveAllSizes
}: MenuTableCardProps) => {
  const foodLabelIcon = (label: any) => {
    const backgroundColor = Object.keys(menuTagLabelColor).find((key) => label.value.includes(key))
      ? menuTagLabelColor[label.value]
      : caloTheme.palette.white;

    if (label && !label.value.includes('NONE')) {
      return (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent="center"
          sx={{
            minWidth: '45px',
            color: 'white',
            mt: '2px',
            padding: '4px',
            borderRadius: '4px',
            backgroundColor: backgroundColor
          }}
        >
          <Typography
            display={label.value.length > 0 ? '' : 'none'}
            variant="caption"
            sx={{
              fontWeight: 600,
              fontSize: '10px',
              lineHeight: '12px',
              textAlign: 'center'
            }}
          >
            {label.value.includes(MenuTagValue.UPGRADED) ? 'IMPROVED' : startCase(label.value)}
          </Typography>
        </Box>
      );
    }
  };

  const isPrebuiltCustomFood = food.tags.includes(FoodDietType.preBuiltCustom);
  const filteredMenuTags = Object.values(MenuTagValue).filter((t) =>
    isPrebuiltCustomFood ? true : t !== MenuTagValue.CUSTOMIZABLE
  );
  const aggregatedRatings = getFoodAggregatedRatingsForPeriod(food, 90);

  return (
    <Card
      variant="outlined"
      sx={{ padding: 1, marginTop: 1, borderRadius: '8px', width: '100%', zIndex: 0, overflow: 'visible' }}
      key={food.id}
    >
      <Box display={'flex'} flexDirection={'column'}>
        {!!(foodLabel?.find((r: any) => r.foodId === food.id)?.value.length > 0) && (
          <Stack
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'end'}
            sx={{ marginTop: '-16px', mb: '2px', zIndex: 10, overflow: 'visible' }}
          >
            <Typography>{foodLabelIcon(foodLabel?.find((r: any) => r.foodId === food.id))}</Typography>
          </Stack>
        )}
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '19px',
                color: '#000000',
                textTransform: 'capitalize',
                mt: '2px',
                mr: '4px'
              }}
            >
              {`${food.name.en}`}
            </Typography>
          </Stack>
          <Stack display={isEditable ? 'flex' : 'none'} flexDirection={'row'} justifyContent={'end'}>
            <Typography
              sx={{
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '19px',
                color: foodCost > 26 ? caloTheme.palette.red : caloTheme.palette.black,
                mt: '2px'
              }}
              variant={'h6'}
            >
              {foodCost || 0}%
            </Typography>
          </Stack>
        </Box>

        <Box display={'flex'} flexDirection={'row'} sx={{ mt: 2 }} justifyContent={'space-between'}>
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'start'} width={'100%'}>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              sx={{ mt: '1px', mr: 2, padding: '4px', backgroundColor: caloTheme.palette.secondaryYellow100 }}
            >
              <Stack>
                <Icon name="star" size={5} style={{ marginRight: 1 }} />
              </Stack>
              <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '14px', textAlign: 'start', my: 'auto' }}>
                {!!food?.totalRating && !!food?.numberOfRatings ? (food?.totalRating / food?.numberOfRatings).toFixed(2) : 0} (
                {food?.numberOfRatings ? food?.numberOfRatings : 0})
              </Typography>
            </Stack>

            <Stack
              display={'flex'}
              flexDirection={'row'}
              sx={{ mt: '1px', padding: '4px', backgroundColor: caloTheme.palette.secondaryYellow100, mr: '4px' }}
            >
              <Stack>
                <Icon name="star" size={5} style={{ marginRight: 2 }} />
              </Stack>
              <Typography sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '14px', textAlign: 'start', my: 'auto' }}>
                {aggregatedRatings && aggregatedRatings.average && aggregatedRatings.count
                  ? `${aggregatedRatings.average} (${aggregatedRatings.count})`
                  : `0 (0)`}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Box display={'flex'} flexDirection={'row'} sx={{ mt: 2 }} justifyContent={'space-between'}>
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'start'} width={'100%'}>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              sx={{ mt: '1px', mr: 2, padding: '4px', backgroundColor: caloTheme.palette.secondaryRed100 }}
            >
              <Stack>
                <Iconify icon="mdi:heart" style={{ fontSize: 20, color: caloTheme.palette.secondaryRed500 }} />
              </Stack>
              <Typography
                sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '14px', textAlign: 'start', my: 'auto', padding: '4px' }}
              >
                {food?.totalNumberOfFavorites ?? 0}
              </Typography>
            </Stack>
            <Stack
              display={'flex'}
              flexDirection={'row'}
              sx={{ mt: '1px', mr: 2, padding: '4px', backgroundColor: caloTheme.palette.secondaryRed100 }}
            >
              <Stack>
                <Icon name="blockIcon" size={5} className="mt-1" />
              </Stack>
              <Typography
                sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '14px', textAlign: 'start', my: 'auto', padding: '4px' }}
              >
                {food?.totalNumberOfBlocks ?? 0}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ mt: 2, mb: '-4px' }}>
          <Typography display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'} sx={{ mt: '3px' }}>
            {food.tags?.map((r) => <MenuFoodDietTags key={r} tag={r} />)}
          </Typography>

          <Typography>
            {isEditable && isMealUsedInWeekRange(food.lastUsedOnMenu) && (
              <Tooltip
                title={
                  <span>
                    Last Used on: <br />
                    {food.usedOnMenu?.slice(-3)?.map((a, index) => (
                      <span key={index}>
                        {a.date}
                        {index < (food.usedOnMenu?.length ?? 0) - 1 && <br />}
                      </span>
                    ))}
                  </span>
                }
              >
                <Typography
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-evenly'}
                  sx={{
                    borderRadius: '37px',
                    padding: '8px 12px 8px 12px',
                    gap: '8px',
                    fontSize: '14px',
                    fontWeight: 600,
                    color: caloTheme.palette.red,
                    backgroundColor: caloTheme.palette.secondaryPink100
                  }}
                >
                  Used
                </Typography>
              </Tooltip>
            )}
          </Typography>
        </Stack>

        <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ mt: 2 }}>
          <InfoOutlinedIcon sx={{ cursor: 'pointer' }} onClick={() => handleSelectMealInfo(food)} />
          {isEditable && (
            <>
              <ShuffleIcon sx={{ cursor: 'pointer' }} onClick={() => setReplaceFood({ open: true, name: food.name.en })} />
              <LocalOfferOutlinedIcon
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  addTags.name === food.name.en
                    ? setAddTags({ name: '', tag: '' })
                    : setAddTags({
                        name: food.name.en,
                        tag: foodLabel?.find((r: any) => r.foodId === food.id)?.value[0] || ''
                      })
                }
              />
              <CloseOutlinedIcon sx={{ cursor: 'pointer' }} onClick={() => removeFood && handleRemoveAllSizes(food)} />
            </>
          )}
        </Stack>
      </Box>
      {addTags.name === food.name.en && (
        <Box key={food.id} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ mt: 2, width: '100%' }}>
          <Stack display={'flex'} flexDirection={'column'} justifyContent={'space-between'} sx={{ width: '100%' }}>
            {filteredMenuTags.map((tag, index) => (
              <Stack
                key={`${food.id}-${tag}-${index}`}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'start'}
                sx={{
                  borderBottom: index === filteredMenuTags.length - 1 ? 0 : 1,
                  borderColor: caloTheme.palette.neutral400,
                  width: '100%'
                }}
              >
                <FormControl>
                  <RadioGroup>
                    <FormControlLabel
                      label={
                        <Typography
                          onChange={() => handleSetFoodTags(food.name.en, tag)}
                          sx={{
                            fontFamily: caloTheme.typography.fontFamily,
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '14px',
                            color: caloTheme.palette.neutral900
                          }}
                        >
                          {tag === MenuTagValue.UPGRADED ? 'IMPROVED' : startCase(tag)}
                        </Typography>
                      }
                      control={
                        <Radio
                          color="default"
                          onClick={() => handleSetFoodTags(food.name.en, tag)}
                          sx={{
                            cursor: 'pointer',
                            color:
                              addTags.name === food.name.en && addTags.tag === tag
                                ? caloTheme.palette.primary500
                                : caloTheme.palette.neutral500
                          }}
                        />
                      }
                      checked={addTags.name === food.name.en && addTags.tag === tag}
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
            ))}
          </Stack>
        </Box>
      )}
    </Card>
  );
};
export default MenuTableCard;
