import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { GetFoodPreferenceReq, Permission } from '@calo/dashboard-types';
import { Brand, Kitchen, Subscription } from '@calo/types';
import { compact, startCase } from 'lodash-es';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Grid, Stack, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from 'components';

import { getListWithParams, updateBlockMeal } from 'actions';
import { resolveCountryFromKitchen } from 'lib/helpers';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { SearchableDropdown } from './SearchableDropdown';
import useBlockMealForm from './useBlockMealForm';

interface BlockMealCardProps {
  subscription: Subscription;
  refetchSubscription: () => void;
}

function extractName(slug: string) {
  const slugParts = slug.split('-');
  const nameParts = slugParts.slice(0, -2);
  return nameParts.join(' ');
}

const BlockMealCard = ({ subscription, refetchSubscription }: BlockMealCardProps) => {
  const roles = useUserRoles();
  const userKitchen = useUserKitchens();
  const [foodList, setFoodList] = useState<{ slug: string }[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { mutateAsync: updateMutation } = useMutation(updateBlockMeal);
  const filters = {
    country: resolveCountryFromKitchen((userKitchen && userKitchen[0]) || Kitchen.BH1),
    brand: Brand.CALO,
    kitchen: (userKitchen && userKitchen[0]) || Kitchen.BH1
  };
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const onSubmit = async (data: GetFoodPreferenceReq) => {
    await updateMutation({
      id: subscription.id,
      ...data
    });
    refetchSubscription();
  };

  const slugs = compact(subscription.foodPreference?.blockedMeals?.map((food: any) => food.slug)) || [];

  const { values, setFieldValue } = useBlockMealForm(slugs, onSubmit);

  useQuery(
    [
      'food/partial/slug',
      {
        filters: {
          brand: filters.brand,
          country: filters.country,
          kitchen: filters.kitchen,
          partialSlug: searchQuery
        },
        page: 0,
        limit: 50,
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        }
      }
    ],
    getListWithParams,
    {
      suspense: false,
      keepPreviousData: false,
      enabled: !!searchQuery && !!filters.kitchen,
      onSuccess: (data: any) => {
        setFoodList(data.data);
      }
    }
  );

  const handleEditing = async (edit: boolean) => {
    if (!edit) {
      onSubmit({
        ...values
      });
    }
    setIsEditing(edit);
  };

  const handleReset = () => {
    setSearchQuery('');
    setFoodList([]);
    setIsEditing(false);
  };

  return (
    <>
      <Box
        sx={{
          margin: 2,
          padding: 2,
          backgroundColor: caloTheme.palette.neutral50,
          borderRadius: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'auto',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column'
          }
        }}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '19px',
            lineHeight: '23px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Block Meals
        </Typography>
        {isEditing ? (
          <Stack sx={{ justifyContent: 'end', flexDirection: 'row' }}>
            <CheckIcon sx={{ marginRight: 2 }} style={{ cursor: 'pointer' }} onClick={() => handleEditing(!isEditing)} />
            <ClearIcon style={{ cursor: 'pointer' }} onClick={handleReset} />
          </Stack>
        ) : (
          <Icon
            data-test="edit-warning-ingredients-icon"
            onClick={() => handleEditing(!isEditing)}
            name="edit2"
            size={6}
            style={{ cursor: 'pointer', width: '26px', height: '26px' }}
          />
        )}
      </Box>
      <Box
        component="form"
        autoComplete="off"
        sx={{
          overflow: 'visible',
          width: 'full',
          ml: 1,
          mr: 4,
          height: 'full',
          '& .MuiTextField-root': { my: 2, mx: 2, width: 'full' },
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'full',
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTextField-root': { my: 2, mx: 1, justifyContent: 'space-between' }
          }
        }}
      >
        <SearchableDropdown
          options={foodList}
          setFoodList={setFoodList}
          values={values}
          setFieldValue={setFieldValue}
          disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
          setSearchQuery={setSearchQuery}
        />
      </Box>
      {values.blockMealList && values.blockMealList.length > 0 && (
        <Box sx={{ padding: '8px' }}>
          <Stack
            sx={{
              border: 1,
              borderColor: caloTheme.palette.neutral100,
              borderRadius: '8px',
              padding: '8px',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginX: 3,
              marginY: 2
            }}
          >
            {values.blockMealList?.map((blockMeal: any) => (
              <Box
                key={blockMeal}
                sx={{
                  margin: '4px',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  borderRadius: '8px',
                  textAlign: 'center',
                  background: caloTheme.palette.secondaryPink100,
                  color: caloTheme.palette.secondaryPink700
                }}
              >
                <Grid key={blockMeal}>
                  <Typography sx={{ display: 'flex', flexDirection: 'row', mr: '2px' }}>
                    <p className="mx-2">{startCase(extractName(blockMeal))}</p>
                    {isEditing && (
                      <Icon
                        key={blockMeal}
                        name="removeRed"
                        className="m-auto cursor-pointer"
                        onClick={() =>
                          setFieldValue(
                            'blockMealList',
                            values.blockMealList?.filter((row: any) => row !== blockMeal)
                          )
                        }
                      />
                    )}
                  </Typography>
                </Grid>
              </Box>
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
};

export default BlockMealCard;
