import cx from 'classnames';
import { format, parseISO } from 'date-fns/fp';
import { Log } from 'lib/interfaces';
import { useState } from 'react';
import { Res } from './types';
interface MessageRowProps {
  row: Res;
}
const MessageRow = ({ row }: MessageRowProps) => {
  const [isOpen, setIsOpen] = useState<boolean>();

  return (
    <>
      {row.childrens.length > 0 ? (
        <>
          <tr key={row.log.id} onClick={() => setIsOpen(!isOpen)}>
            <td>
              <div className=" py-4 pl-2 text-l inline-flex text-center">
                <i className={cx('fas pr-1 pt-1', { 'fa-angle-up': isOpen, 'fa-angle-down': !isOpen })}> </i>
                Replace Food
              </div>
            </td>
            <td></td>
            <td>
              {' '}
              <div className="mt-3"> {format('yyyy-MM-dd')(parseISO(row.log.createdAt))} </div>
            </td>
          </tr>

          <tr className={cx({ hidden: !isOpen, 'table-row': isOpen })}>
            <td colSpan={3}>
              <table className="w-full">
                <tbody>
                  <tr>
                    <td>
                      <div className="ml-10">{row.log.message}</div>
                    </td>
                    <td>{row.log.actor}</td>
                    <td>{format('hh:mma')(parseISO(row.log.createdAt))}</td>
                  </tr>
                  {row.childrens.map((row: Log) => (
                    <tr key={row.id}>
                      <td>
                        <div className="ml-10">{row.message}</div>
                      </td>
                      <td>{row.actor}</td>
                      <td>{format('hh:mma')(parseISO(row.createdAt))}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        </>
      ) : (
        <tr>
          <td>{row.log.message}</td>
          <td>{row.log.actor}</td>
          <td>{format('Pp')(parseISO(row.log.createdAt))}</td>
        </tr>
      )}
    </>
  );
};
export default MessageRow;
