import { useRef } from 'react';

import { Link } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { Country } from '@calo/types';
import { Icon as Iconify } from '@iconify/react';
import { Button, Divider, Stack, Tooltip, Typography } from '@mui/material';

import { caloTheme } from 'assets/images/theme/calo';
import { ModalRef, UsedOnMenuIndicator } from 'components';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { Routes } from 'lib/enums';
import { resolveCountry } from 'lib/helpers';
import { Food, FoodComponent } from 'lib/interfaces';
import { isEmpty, orderBy } from 'lodash';
import DeleteSizeModal from './DeleteSizeModal';
import MealName from './MealName';
import NewSizeModal from './NewSizeModal';
import Ratings from './Ratings';
import SizesSwitch from './SizesSwitch';
import { createComponentsWorksheet, createFoodWorksheet, createIngredientsWorksheet } from './exportUtils';

interface HeaderProps {
  selectedFood: Food;
  foodWithAllSizes: Food[];
  setSelectedFood: (food: Food) => void;
  roles: any;
  setFoodWithAllSizes: (food: Food[]) => void;
  filteredFoodUsed: {
    type: 'mainMenu' | 'addonsMenu';
    date?: string | undefined;
    week?: number | undefined;
  }[];
  usedOnMenuButtonColor: string[];
  selectedFoodComponents: FoodComponent[];
  childComponents: FoodComponent[];
  isDisabled?: boolean;
}

const Header = ({
  selectedFood,
  foodWithAllSizes,
  setSelectedFood,
  roles,
  setFoodWithAllSizes,
  filteredFoodUsed,
  usedOnMenuButtonColor,
  isDisabled,
  childComponents,
  selectedFoodComponents
}: HeaderProps) => {
  const addMealsModalRef = useRef<ModalRef>();
  const deleteMealModalRef = useRef<ModalRef>();

  const downloadMealHandler = async () => {
    const workbook = new ExcelJS.Workbook();

    const sizes = ['S', 'M', 'L', 'XL'];

    const filteredFoodSizes = foodWithAllSizes.filter((food) => sizes.includes(food.size));
    const foodOrderedBySize = orderBy(filteredFoodSizes, [(item) => sizes.indexOf(item.size)]);

    createIngredientsWorksheet(workbook, selectedFood, selectedFoodComponents);
    createComponentsWorksheet(workbook, selectedFood, selectedFoodComponents, childComponents);

    createFoodWorksheet(workbook, selectedFoodComponents, childComponents, foodOrderedBySize);

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, 'meal-recipe' + fileExtension);
  };
  const isMealUsedInMenu = foodWithAllSizes.some((food) =>
    food.usedOnMenu?.some((menu) => menu.type === 'mainMenu' && new Date(menu.date!) >= new Date())
  );

  const isSizeUsedOnMenu = filteredFoodUsed.some((item) => item.type === 'mainMenu' && new Date(item.date!) >= new Date());
  return (
    <Stack direction="column" justifyContent="space-between" spacing={2} sx={{ backgroundColor: caloTheme.palette.white }}>
      <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ mx: 2, zIndex: 0 }}>
        <Link to={Routes.foodList}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Iconify icon="fluent:chevron-left-24-filled" color={caloTheme.palette.neutral900} style={{ fontSize: '16px' }} />
            <Typography sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '20px', color: caloTheme.palette.black }}>
              Back
            </Typography>
          </Stack>
        </Link>
        <Typography sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '20px', color: caloTheme.palette.black, mr: 1 }}>
          {resolveCountry(selectedFood.country || Country.BH)}
        </Typography>
      </Stack>
      <Divider />
      <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <MealName selectedFood={selectedFood} />
          <Typography variant="h6" className="text-center">
            {selectedFood.kitchen}
          </Typography>
          <Ratings selectedFood={selectedFood} foodWithAllSizes={foodWithAllSizes} />
          <UsedOnMenuIndicator filteredFoodUsed={filteredFoodUsed} usedOnMenuButtonColor={usedOnMenuButtonColor} type="food" />
        </Stack>
        <SizesSwitch selectedFood={selectedFood} foodWithAllSizes={foodWithAllSizes} setSelectedFood={setSelectedFood} />
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Button
            variant="outlined"
            disabled={!selectedFood || isEmpty(foodWithAllSizes) || isEmpty(selectedFoodComponents)}
            startIcon={
              <Iconify icon="octicon:download-16" width="18" height="18" style={{ color: caloTheme.palette.neutral900 }} />
            }
            sx={{
              color: caloTheme.palette.neutral900,
              fontSize: '16px',
              fontWeight: 600,
              textTransform: 'capitalize',
              borderColor: caloTheme.palette.neutral900,
              borderRadius: '8px',
              boxShadow: 'none',
              '&:hover': {
                borderColor: caloTheme.palette.neutral900,
                backgroundColor: caloTheme.palette.white
              }
            }}
            onClick={downloadMealHandler}
          >
            Download
          </Button>
          {roles.includes(Permission.DELETE_FOOD) && (
            <Tooltip arrow placement="top" title={isSizeUsedOnMenu ? 'Meal size is used on menu' : ''}>
              <span>
                <Button
                  variant="outlined"
                  disabled={isDisabled || isSizeUsedOnMenu}
                  sx={{
                    color: caloTheme.palette.neutral900,
                    fontSize: '16px',
                    fontWeight: 600,
                    textTransform: 'capitalize',
                    borderColor: caloTheme.palette.neutral900,
                    borderRadius: '8px',
                    boxShadow: 'none',
                    '&:hover': {
                      borderColor: caloTheme.palette.neutral900,
                      backgroundColor: caloTheme.palette.neutral900,
                      color: caloTheme.palette.white,
                      boxShadow: 'none'
                    }
                  }}
                  onClick={() => deleteMealModalRef.current?.open()}
                >
                  Delete
                </Button>
              </span>
            </Tooltip>
          )}
          {roles.includes(Permission.CREATE_FOOD) && (
            <Button
              variant="outlined"
              disabled={isDisabled}
              sx={{
                color: caloTheme.palette.neutral900,
                fontSize: '16px',
                fontWeight: 600,
                textTransform: 'capitalize',
                borderColor: caloTheme.palette.neutral900,
                borderRadius: '8px',
                boxShadow: 'none',
                '&:hover': {
                  borderColor: caloTheme.palette.neutral900,
                  backgroundColor: caloTheme.palette.neutral900,
                  color: caloTheme.palette.white,
                  boxShadow: 'none'
                }
              }}
              onClick={() => addMealsModalRef.current?.open()}
            >
              Edit Meal Sizes
            </Button>
          )}
        </Stack>
        <NewSizeModal
          selectedFood={selectedFood}
          foodWithAllSizes={foodWithAllSizes}
          setSelectedFood={setSelectedFood}
          setFoodWithAllSizes={setFoodWithAllSizes}
          ref={addMealsModalRef}
          close={() => addMealsModalRef.current?.close()}
        />
        <DeleteSizeModal
          ref={deleteMealModalRef}
          selectedFood={selectedFood}
          setSelectedFood={setSelectedFood}
          isMealUsedInMenu={isMealUsedInMenu}
          foodWithAllSizes={foodWithAllSizes}
          setFoodWithAllSizes={setFoodWithAllSizes}
          close={() => deleteMealModalRef.current?.close()}
        />
      </Stack>
    </Stack>
  );
};

export default Header;
