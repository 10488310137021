import { MacrosService } from '@calo/services';
import { ActivityLevel, DietType } from '@calo/types';
import { Box, Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { Delivery } from 'lib/interfaces';
import { round, sumBy } from 'lodash-es';

interface DeficitMacrosBoxProps {
  selectedDelivery: Delivery;
}

const DeficitMacrosBox = ({ selectedDelivery }: DeficitMacrosBoxProps) => {
  const deliveryMacros =
    selectedDelivery.macros ||
    MacrosService.getMacrosBag(
      MacrosService.getCal(selectedDelivery.macrosData!),
      selectedDelivery.plan.dietType || DietType.balanced,
      selectedDelivery.macrosData?.weight ?? 0,
      selectedDelivery.macrosData?.activityLevel || ActivityLevel.level1
    );

  const totalCalories = round(sumBy(selectedDelivery.food, (food) => food.macros?.cal || 0));
  const totalProtein = round(sumBy(selectedDelivery.food, (food) => food.macros?.protein || 0));
  const totalFat = round(sumBy(selectedDelivery.food, (food) => food.macros?.fat || 0));
  const totalCarbs = round(sumBy(selectedDelivery.food, (food) => food.macros?.carbs || 0));

  const totalCaloriesDeficit = totalCalories - deliveryMacros.cal;
  const totalProteinDeficit = totalProtein - deliveryMacros.protein.min;
  const totalFatDeficit = totalFat - deliveryMacros.fat.min;
  const totalCarbsDeficit = totalCarbs - deliveryMacros.carbs.min;

  return (
    <Box
      width={'auto'}
      display="flex"
      flexDirection={'row'}
      sx={{
        my: 2,
        mx: 3,
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          flexDirection: 'column'
        }
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontFamily: caloTheme.typography.fontFamily,
          width: '140px',
          fontWeight: 700,
          fontSize: '18px',
          lineHeight: '21.6px',
          mr: 3
        }}
      >
        Deficit Macros
      </Typography>
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ width: '100%', alignItems: 'center' }}>
        <Stack
          display="flex"
          flexDirection="row"
          sx={{
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 400,
            mr: 3,
            fontSize: '16px',
            lineHeight: '19px'
          }}
        >
          Calories{' '}
          <Typography
            variant="subtitle2"
            sx={{
              mt: '-3px',
              ml: 1,
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '24px',
              color: totalCaloriesDeficit > 0 ? caloTheme.palette.primary500 : caloTheme.palette.red
            }}
          >
            {totalCaloriesDeficit} g
          </Typography>
        </Stack>
        <Stack
          display="flex"
          flexDirection="row"
          sx={{
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 400,
            mr: 3,
            fontSize: '16px',
            lineHeight: '19px'
          }}
        >
          Protein{' '}
          <Typography
            variant="subtitle2"
            sx={{
              mt: '-3px',
              ml: 1,
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '24px',
              color: totalProteinDeficit > 0 ? caloTheme.palette.primary500 : caloTheme.palette.red
            }}
          >
            {totalProteinDeficit} g
          </Typography>
        </Stack>
        <Stack
          display="flex"
          flexDirection="row"
          sx={{
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 400,
            mr: 3,
            fontSize: '16px',
            lineHeight: '19px'
          }}
        >
          Carbs{' '}
          <Typography
            variant="subtitle2"
            sx={{
              mt: '-3px',
              ml: 1,
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '24px',
              color: totalCarbsDeficit > 0 ? caloTheme.palette.primary600 : caloTheme.palette.red
            }}
          >
            {totalCarbsDeficit} g
          </Typography>
        </Stack>
        <Stack
          display="flex"
          flexDirection="row"
          sx={{
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 400,
            mr: 3,
            fontSize: '16px',
            lineHeight: '19px'
          }}
        >
          Fat{' '}
          <Typography
            variant="subtitle2"
            sx={{
              mt: '-3px',
              ml: 1,
              fontFamily: caloTheme.typography.fontFamily,
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '24px',
              color: totalFatDeficit > 0 ? caloTheme.palette.primary600 : caloTheme.palette.red
            }}
          >
            {totalFatDeficit} g
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default DeficitMacrosBox;
