import { format, parseISO } from 'date-fns/fp';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Permission, Subscription } from '@calo/dashboard-types';
import { Country, Kitchen, PaymentMethod, SubscriptionFrequency, SubscriptionStatus } from '@calo/types';

import { Button } from 'components';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';

interface SubscriptionRowProps {
  subscription: Subscription;
}

const copyToClipBoard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    toast('ID copied!', { type: 'success', autoClose: 2000 });
  } catch {
    toast('Failed to copy!', { type: 'error', autoClose: 2000 });
  }
};

const SubscriptionRow = ({ subscription }: SubscriptionRowProps) => {
  const roles = useUserRoles();

  return (
    <tr>
      <td>
        <div className="flex">
          <span className=" mt-4 flex">
            {roles.includes(Permission.VIEW_SUBSCRIPTION) || roles.includes(Permission.VIEW_DELIVERY_SUBSCRIPTION) ? (
              <Link to={Routes.subscription.replace(':id', subscription.id)}>{subscription.name}</Link>
            ) : (
              subscription.name
            )}
          </span>
        </div>
      </td>
      <td>
        {roles.includes(Permission.VIEW_SUBSCRIPTION) || roles.includes(Permission.VIEW_DELIVERY_SUBSCRIPTION) ? (
          <Link to={Routes.subscription.replace(':id', subscription.id)}>
            <p className="mt-4">{subscription.phoneNumber}</p>
          </Link>
        ) : (
          <p className="mt-4">{subscription.phoneNumber}</p>
        )}
      </td>
      <td>
        <p className="mt-4">{subscription.email}</p>
      </td>
      <td>
        {subscription.pendingAmount[subscription.currency] ? (
          <p className="mt-4">{subscription.pendingAmount[subscription.currency]}</p>
        ) : (
          <p className="mt-4">{subscription.pendingAmount['BHD']}</p>
        )}
      </td>
      <td>
        <p className="mt-4">{subscription.balance[subscription.currency]}</p>
      </td>
      <td>
        <p className="mt-4">
          {subscription.startedAt ? format('MMM dd,yyyy')(parseISO(subscription.startedAt)) : subscription.startedAt}
        </p>
      </td>
      <td>
        <p className="mt-4">
          {subscription.lastDeliveredDate
            ? format('MMM dd,yyyy')(parseISO(subscription.lastDeliveredDate))
            : subscription.lastDeliveredDate}
        </p>
      </td>
      <td>
        {subscription.plan?.frequency === SubscriptionFrequency.quarterly && (
          <p className="my-4 bg-yellow-600 text-white w-24 text-center rounded-xl ">
            {subscription.plan.frequency?.toUpperCase()}
          </p>
        )}
        {subscription.plan?.frequency === SubscriptionFrequency.monthly && (
          <p className="my-4 bg-blue-500 text-white w-24 text-center rounded-xl ">{subscription.plan.frequency?.toUpperCase()}</p>
        )}
        {subscription.plan?.frequency === SubscriptionFrequency.weekly && (
          <p className="my-4 bg-yellow-400 text-white w-24 text-center rounded-xl ">
            {subscription.plan.frequency?.toUpperCase()}
          </p>
        )}
      </td>
      <td>
        <p className="mt-4">{subscription.country}</p>
      </td>
      <td>
        <p className="mt-4">
          {subscription.kitchen
            ? subscription.kitchen
            : subscription.country === Country.BH
              ? Kitchen.BH1
              : subscription.country === Country.SA
                ? Kitchen.SA1
                : ''}
        </p>
      </td>
      <td>
        <p className="mt-4">{subscription.currency}</p>
      </td>
      <td>
        {subscription.status === SubscriptionStatus.paused && <i className="fas fa-pause mt-4 mr-1" title="paused" />}
        {subscription.status === SubscriptionStatus.cancelled && <i className="fas fa-ban mt-4 mr-1" title="cancelled" />}
        {subscription.status === SubscriptionStatus.suspended && (
          <i className="fas fa-times-circle mt-4 mr-1" title="suspended"></i>
        )}
        {subscription.paymentMethod === PaymentMethod.cash && <i className="fas fa-money-bill mt-4 mr-1" title="cash" />}
        {subscription.paymentMethod === PaymentMethod.cc && <i className="fas fa-credit-card mt-4 mr-1" title="credit card" />}
        {subscription.paymentMethod === PaymentMethod.benefit && <i className="far fa-credit-card mt-4 mr-1" title="Benefit" />}
        {subscription.paymentMethod === PaymentMethod.applePay && (
          <i className="fab fa-apple-pay fa-2x mt-4 mr-1" title="applePay" />
        )}
        {subscription.paymentMethod === PaymentMethod.mada && <i className="mt-4 mr-1">mada</i>}
      </td>
      <td>
        <Button icon="far fa-copy" className="mt-2 h-auto w-8" onClick={() => copyToClipBoard(`${subscription.id}`)} />
      </td>
    </tr>
  );
};
export default SubscriptionRow;
