import client from 'lib/client';
import { QueryFunction } from 'react-query';

export * from './addOnSubscription';
export * from './businessAccount';
export * from './calculateFoodCustomMacros';
export * from './complaints';
export * from './coupons';
export * from './customFood';
export * from './delivery';
export * from './employeePerks';
export * from './food';
export * from './foodComponent';
export * from './foodPackages';
export * from './gifts';
export * from './ingredient';
export * from './InvitationCodes';
export * from './kdsUserPermissions';
export * from './kitchen';
export * from './map';
export * from './mealSwap';
export * from './menu';
export * from './plans';
export * from './progress';
export * from './refunds';
export * from './route';
export * from './subscription';
export * from './supplyChainUsers';
export * from './tickets';
export * from './ui';
export * from './user';
export * from './userPermissions';
export * from './users';
export * from './wallet';

export const getListWithParams: QueryFunction<[string, Record<string, any>]> = async (context) => {
  const { data } = await client.get(context.queryKey[0] as string, { params: context.queryKey[1] });
  return data;
};

export const getList: QueryFunction<string> = async (context) => {
  const { data } = await client.get(context.queryKey[0] as string);
  return data;
};

export const getRecord: QueryFunction = async (context) => {
  const { data } = await client.get(context.queryKey.join('/'));

  return data;
};

export const getRecordWithParams: QueryFunction = async (context) => {
  const { data } = await client.get(context.queryKey.slice(0, -1).join('/'), {
    params: context.queryKey[context.queryKey.length - 1]
  });
  return data;
};
