import { useState } from 'react';

import { startCase, toLower } from 'lodash-es';
import { useMutation } from 'react-query';

import { Permission, Subscription, UpdateSubscriptionReq } from '@calo/dashboard-types';
import { MacrosService } from '@calo/services';
import { ActivityLevel, MacrosPreset, MacrosType } from '@calo/types';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material';

import { updateSubscription } from 'actions';
import { caloTheme } from 'assets/images/theme/calo';
import { Icon } from 'components';
import { getPackageMaxMin } from 'lib/helpers/getPackageMaxMin';
import { useUserRoles } from 'lib/hooks';
import useMacrosForm from './useMacrosForm';

interface MacrosCardProps {
  subscription: Subscription;
}

const MacrosCard = ({ subscription }: MacrosCardProps) => {
  const roles = useUserRoles();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { mutateAsync: updateMutation } = useMutation(updateSubscription);

  const onSubmit = async (data: UpdateSubscriptionReq) => {
    await updateMutation({
      id: subscription.id,
      ...data
    });
  };

  const { values, handleBlur, setFieldValue, initialValues, setValues } = useMacrosForm(subscription, onSubmit);

  const [customMacrosError, setCustomMacrosError] = useState<boolean>(false);

  const { packageMin, packageMax } = getPackageMaxMin(subscription.plan.dietType, subscription.plan.foodType);

  const handleMacrosType = (macrosType: MacrosType) => {
    if (macrosType === MacrosType.recommended) {
      setCustomMacrosError(false);
      const calData = MacrosService.getCal(subscription.macrosData);
      const macrosData = MacrosService.getMacrosBag(
        calData,
        values.dietType!,
        values.macrosData?.weight ?? 0,
        values.macrosData?.activityLevel || ActivityLevel.level1
      );
      setFieldValue('macros.cal', calData);
      setFieldValue('macros.protein.min', macrosData.protein.min);
      setFieldValue('macros.protein.max', macrosData.protein.max);
      setFieldValue('macros.carbs.min', macrosData.carbs.min);
      setFieldValue('macros.carbs.max', macrosData.carbs.max);
      setFieldValue('macros.fat.min', macrosData.fat.min);
      setFieldValue('macros.fat.max', macrosData.fat.max);
      setFieldValue('macrosType', MacrosType.recommended);
      setFieldValue('macrosPreferences.preset', MacrosPreset.recommended);
    } else if (macrosType === MacrosType.athlete) {
      setFieldValue('macrosType', MacrosType.athlete);
      setFieldValue('macrosPreferences.preset', MacrosPreset.recommended);
    } else {
      setFieldValue('macrosType', MacrosType.custom);
      setFieldValue('macrosPreferences.preset', MacrosPreset.recommended);
    }
  };

  const handleMacrosPresetChange = (macrosPreset: MacrosPreset, cal: number) => {
    let macrosData = MacrosService.getMacrosBag(
      cal,
      values.dietType!,
      values.macrosData?.weight ?? 0,
      values.macrosData?.activityLevel || ActivityLevel.level1
    );
    if (!(macrosPreset === MacrosPreset.recommended)) {
      macrosData = MacrosService.getCustomMacrosBag(cal, macrosPreset);
      setFieldValue('macrosType', values.macrosType);
    }
    setFieldValue('macros.protein.min', macrosData.protein.min);
    setFieldValue('macros.protein.max', macrosData.protein.max);
    setFieldValue('macros.carbs.min', macrosData.carbs.min);
    setFieldValue('macros.carbs.max', macrosData.carbs.max);
    setFieldValue('macros.fat.min', macrosData.fat.min);
    setFieldValue('macros.fat.max', macrosData.fat.max);
    setFieldValue('macrosPreferences.preset', macrosPreset);
  };

  const handleCalChange = (cal: number) => {
    cal > packageMax || cal < packageMin ? setCustomMacrosError(true) : setCustomMacrosError(false);
    setFieldValue('macros[cal]', cal);
    if (values.macrosPreferences?.preset) {
      handleMacrosPresetChange(values.macrosPreferences.preset, cal);
    }
  };

  const handleMacroEdit = (macrosValue: number, type: string) => {
    setFieldValue(type, macrosValue);
  };

  const handleMacrosDataFields = (name: string, macrosType: { min: number; max: number }) => {
    const macroName = toLower(name);
    return (
      <>
        <Stack display="flex" width="50%" flexDirection={'column'}>
          <Typography
            sx={{
              fontFamily: caloTheme.typography.fontFamily,
              mr: 2,
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '14px'
            }}
            id={`filled-${name}-minimum-helper-text`}
          >
            {name} Minimum
          </Typography>
          {isEditing ? (
            <TextField
              name={`${name}.min`}
              value={macrosType.min}
              placeholder={`Enter ${name}`}
              id="exact-subscription-macrosType-minimum"
              onChange={(data: any) => handleMacroEdit(+data.target.value, `macros.${macroName}.min`)}
              disabled={
                !roles.includes(Permission.UPDATE_SUBSCRIPTION) ||
                values.macrosType === MacrosType.recommended ||
                !isEditing ||
                values.macrosType !== MacrosType.athlete
              }
            />
          ) : (
            <Typography
              variant="h6"
              sx={{
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '19px',
                mt: 1
              }}
              id={`filled-macrosType-minimum-helper-text`}
            >
              {macrosType.min} G
            </Typography>
          )}
        </Stack>
        <Stack display="flex" width="50%" flexDirection={'column'}>
          <Typography
            sx={{
              fontFamily: caloTheme.typography.fontFamily,
              mr: 2,
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '14px'
            }}
            id={`filled-${name}-maximum-helper-text`}
          >
            {name} Maximum
          </Typography>
          {isEditing ? (
            <TextField
              name={`${name}.max`}
              value={macrosType.max}
              placeholder={`Enter ${name}`}
              id="exact-subscription-macrosType-maximum"
              onChange={(data: any) => handleMacroEdit(+data.target.value, `macros.${macroName}.max`)}
              disabled={
                !roles.includes(Permission.UPDATE_SUBSCRIPTION) ||
                values.macrosType === MacrosType.recommended ||
                !isEditing ||
                values.macrosType !== MacrosType.athlete
              }
            />
          ) : (
            <Typography
              variant="h6"
              sx={{
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '19px',
                mt: 1
              }}
              id={`filled-macrosType-maximum-helper-text`}
            >
              {macrosType.max} G
            </Typography>
          )}
        </Stack>
      </>
    );
  };

  const handleEditing = (edit: boolean) => {
    if (!edit) {
      onSubmit({
        ...values
      });
    }
    setIsEditing(edit);
    setCustomMacrosError(false);
  };

  const handleReset = () => {
    setValues(initialValues);
    setIsEditing(false);
    setCustomMacrosError(false);
  };

  return (
    <>
      <Box
        sx={{
          margin: 2,
          padding: 2,
          backgroundColor: caloTheme.palette.neutral50,
          borderRadius: 2,
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'auto',
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column'
          }
        }}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '19px',
            lineHeight: '23px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600
          }}
        >
          Macros
        </Typography>
        {isEditing ? (
          <Stack sx={{ justifyContent: 'end', flexDirection: 'row' }}>
            <CheckIcon sx={{ marginRight: 2 }} style={{ cursor: 'pointer' }} onClick={() => handleEditing(!isEditing)} />
            <ClearIcon style={{ cursor: 'pointer' }} onClick={handleReset} />
          </Stack>
        ) : (
          <Icon
            onClick={() => handleEditing(!isEditing)}
            name="edit2"
            size={6}
            style={{ cursor: 'pointer', width: '26px', height: '26px' }}
          />
        )}
      </Box>

      <Box
        component="form"
        autoComplete="off"
        sx={{
          width: 'full',
          mx: 2,
          '& .MuiTextField-root': { my: 2, mx: 2, width: '95%' },
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            width: 'full',
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTextField-root': { my: 2, mx: 1, width: '100%', justifyContent: 'space-between' }
          }
        }}
      >
        <Stack display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
          <TextField
            select
            type="text"
            onBlur={handleBlur}
            name="macrosSource"
            label="Macros Source"
            value={values.macrosType}
            placeholder="Select Macros Source"
            id="exact-subscription-macrosSource"
            disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
            onChange={(data: any) => handleMacrosType(data.target.value)}
            InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
          >
            {[
              { value: MacrosType.recommended, label: 'Recommended' },
              { value: MacrosType.custom, label: 'Custom' },
              { value: MacrosType.athlete, label: 'Athlete' }
            ].map((macrosType) => (
              <MenuItem key={macrosType.value} value={macrosType.value}>
                {startCase(macrosType.label)}
              </MenuItem>
            ))}
          </TextField>

          {(values.macrosType === MacrosType.custom || values.macrosType === MacrosType.athlete) && (
            <TextField
              select
              type="text"
              onBlur={handleBlur}
              name="macrosPreset"
              label="Macros Preset"
              placeholder="Select Macros Preset"
              id="exact-subscription-macrosPreset"
              value={values.macrosPreferences?.preset}
              disabled={!roles.includes(Permission.UPDATE_SUBSCRIPTION) || !isEditing}
              onChange={(data: any) => handleMacrosPresetChange(data.target.value, values.macros!.cal)}
              InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
            >
              {[
                { value: MacrosPreset.recommended, label: 'Recommended' },
                { value: MacrosPreset.evenSplit, label: 'Even Split' },
                { value: MacrosPreset.highCarbs, label: 'High Carbs' },
                { value: MacrosPreset.highFat, label: 'High Fat' },
                { value: MacrosPreset.highProtein, label: 'Hight Protein' }
              ].map((macrosPreset) => (
                <MenuItem key={macrosPreset.value} value={macrosPreset.value}>
                  {startCase(macrosPreset.label)}
                </MenuItem>
              ))}
            </TextField>
          )}
          <TextField
            label="Calories"
            name="macros[cal]"
            value={values.macros?.cal}
            placeholder="Enter Calories"
            id="exact-subscription-calories"
            sx={
              {
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: customMacrosError && caloTheme.palette.red
                  },
                  '&:hover fieldset': {
                    borderColor: customMacrosError && caloTheme.palette.red
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: customMacrosError && caloTheme.palette.red
                  }
                },
                '& .MuiInputLabel-root': {
                  color: customMacrosError && caloTheme.palette.red,
                  '&.Mui-focused': {
                    color: customMacrosError && caloTheme.palette.red
                  }
                }
              } as any
            }
            onChange={(data: any) => handleCalChange(+data.target.value)}
            disabled={
              !roles.includes(Permission.UPDATE_SUBSCRIPTION) || values.macrosType === MacrosType.recommended || !isEditing
            }
          />
          {customMacrosError && packageMax && packageMin && (
            <Box display={'flex'} flexDirection={'row'} sx={{ mt: 1, marginLeft: 2 }}>
              <Icon name="infoWarning" size={5} />
              <Typography
                sx={{
                  fontFamily: caloTheme.typography.fontFamily,
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '14px'
                }}
                color={caloTheme.palette.red}
              >
                Package serves {packageMin} - {packageMax} calories
              </Typography>
            </Box>
          )}
          <Box
            display={'flex'}
            flexDirection={'row'}
            sx={{ my: 1, mx: 3, width: 'full', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
          >
            {handleMacrosDataFields('Protein', values.macros!.protein)}
          </Box>

          <Box
            display={'flex'}
            flexDirection={'row'}
            sx={{ my: 1, mx: 3, width: 'full', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
          >
            {handleMacrosDataFields('Carbs', values.macros!.carbs)}
          </Box>

          <Box
            display={'flex'}
            flexDirection={'row'}
            sx={{ mb: 2, mt: 1, mx: 3, width: 'full', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
          >
            {handleMacrosDataFields('Fat', values.macros!.fat)}
          </Box>
        </Stack>
      </Box>
    </>
  );
};
export default MacrosCard;
