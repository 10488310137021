import { useEffect, useMemo, useRef, useState } from 'react';

import { format, parseISO } from 'date-fns/fp';
import { flatten } from 'lodash-es';
import { useInfiniteQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { AddToWallet, Permission, Refund, RefundReq, Subscription, Wallet, WalletAction } from '@calo/dashboard-types';
import { Dictionary } from '@calo/types';

import { createWallet, refund } from 'actions';
import { Button, Modal, ModalRef } from 'components';
import Popup from 'components/Popup';
import client from 'lib/client';
import { Routes, SubscriptionPages } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import SubscriptionBalanceSheet from '../../../SubscriptionBalanceSheet';
import NewWalletForm from './NewWalletForm';
import RefundForm from './RefundForm';
import WalletRow from './WalletRow';
import { WalletCollapse } from './types';

interface WalletPanelProps {
  subscription: Subscription;
}

const WalletPanel = ({ subscription }: WalletPanelProps) => {
  const [selected, setSelected] = useState<Wallet | null>(null);
  const paymentModalRef = useRef<ModalRef>();
  const refundModalRef = useRef<ModalRef>();
  const transactionHistoryModalRef = useRef<ModalRef>();

  const { mutateAsync: createMutation } = useMutation(createWallet);
  const roles = useUserRoles();

  useEffect(() => {
    if (selected) {
      refundModalRef.current?.open();
    } else {
      refundModalRef.current?.close();
    }
  }, [selected]);

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery<{ data: Wallet[]; meta: { cursor?: string } }>(
    `subscriptions/${subscription.id}/wallets`,
    async ({ queryKey, pageParam }) => {
      const { data } = await client.get(queryKey.join('/'), {
        params: {
          ...(pageParam && {
            cursor: pageParam
          })
        }
      });
      return data;
    },
    {
      getNextPageParam: (data) => data.meta.cursor
    }
  );

  const list = useMemo(
    () =>
      (data?.pages || []).reduce<Wallet[]>((res, r) => {
        res = [...res, ...(r.data || [])];
        return res;
      }, []),
    [data]
  );

  const handleNewCredit = async (data: AddToWallet) => {
    await createMutation(
      { id: subscription.id, ...data },
      {
        onSuccess: (data) => {
          list.unshift(data);
        }
      }
    );
    paymentModalRef.current?.close();
  };

  const handleRefund = async (data: Refund) => {
    if (selected) {
      const values: RefundReq = {
        amount: data.amount,
        description: data.description,
        note: data.note
      };
      await refund(selected!.id, subscription.id, values);
      setSelected(null);
      toast('Refund initiated', {
        type: 'success'
      });
    }
  };

  const WalletDataCollapsed = list.reduce((res: Dictionary<WalletCollapse[]>, row) => {
    const key = `${format('yyy-MM')(parseISO(row.createdAt))}-${row.action}`;
    if (!res[key]) {
      res[key] = [
        {
          wallet: row,
          childrens: []
        }
      ];
    } else if (row.action === WalletAction.REMOVE) {
      const index = res[key].find((r) => r.wallet.action.includes(WalletAction.REMOVE));
      index?.childrens.push(row);
    } else {
      res[key].push({
        wallet: row,
        childrens: []
      });
    }
    return res;
  }, {});
  const walletData = flatten(Object.values(WalletDataCollapsed));

  return (
    <div className="card has-table has-table-container-upper-radius">
      <header className="card-header bg-black text-white">
        <p className="card-header-title text-white">Wallet</p>
        <div className="inline-flex items-center">
          <Button
            content="Transactions"
            icon="fas"
            className="mr-2"
            onClick={() =>
              window.open(
                `${Routes.subscription2.replace(':id', subscription.id as string)}?tab=${SubscriptionPages.transactions}`,
                '_blank'
              )
            }
          >
            Transactions
          </Button>
        </div>
        <div className="inline-flex items-center">
          <Button content="Balance Sheet" icon="fas" className="mr-2" onClick={() => transactionHistoryModalRef.current?.open()}>
            Balance Sheet
          </Button>
        </div>
        {roles.includes(Permission.CREATE_SUBSCRIPTION_WALLET) && (
          <div className="inline-flex items-center">
            <Button icon="fas fa-plus" className="mr-2" onClick={() => paymentModalRef.current?.open()} />
          </div>
        )}
      </header>
      <div className="card-content">
        <table className="table is-fullwidth is-striped is-hoverable is-sortable is-fullwidth">
          <thead>
            <tr>
              <th>Amount</th>
              <th>Currency</th>
              <th>Action</th>
              <th>Source</th>
              <th>Actor</th>
              <th>Reason</th>
              <th>Note</th>
              <th>Delivery</th>
              <th>Time</th>
              <th></th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>Amount</th>
              <th>Currency</th>
              <th>Action</th>
              <th>Source</th>
              <th>Actor</th>
              <th>Reason</th>
              <th>Note</th>
              <th>Delivery</th>
              <th>Time</th>
              <th></th>
            </tr>
          </tfoot>
          <tbody>
            {walletData.map((row) => (
              <WalletRow key={row.wallet.id} row={row} onRefund={(wallet) => setSelected(wallet)} />
            ))}
            {/* {pages} */}
          </tbody>
        </table>
        {hasNextPage && <Button onClick={() => fetchNextPage()} content="Load More" primary />}
      </div>
      <Modal ref={refundModalRef} onClose={() => setSelected(null)}>
        {selected && (
          <RefundForm
            wallet={selected}
            onSubmit={handleRefund}
            max={+Math.min(subscription.balance[subscription.currency], selected.amount - (selected.vat || 0))}
            currency={selected.currency}
          />
        )}
      </Modal>

      <Popup title={'ADD CREDITS'} ref={paymentModalRef} fullWidth onClose={() => paymentModalRef.current?.close()}>
        <NewWalletForm
          onSubmit={handleNewCredit}
          paymentMethod={subscription.paymentMethod}
          userCurrency={subscription.currency}
        />
      </Popup>

      <Popup
        title={'Balance Sheet'}
        fullWidth={true}
        maxWidth="xl"
        ref={transactionHistoryModalRef}
        onClose={() => transactionHistoryModalRef.current?.close()}
      >
        <SubscriptionBalanceSheet userId={subscription.id} balance={subscription.balance[subscription.currency]} />
      </Popup>
    </div>
  );
};

export default WalletPanel;
