import { Refund, Wallet } from '@calo/dashboard-types';
import { Currency, RefundReason } from '@calo/types';
import { FormikBag, FormikProps, withFormik } from 'formik';
import { useState } from 'react';

import { Button, Input, Select, TextArea } from 'components';
import { refundReasonsHelper } from 'lib/helpers/refundReasonsHelper';
import { SelectOptionString } from 'lib/interfaces';

interface RefundFormProps {
  wallet: Wallet;
  onSubmit: (data: Refund) => Promise<void>;
  max: number;
  currency: Currency;
}

const refundReasons = refundReasonsHelper.getOptions();

const refundSubReasons = refundReasonsHelper.getSubReasonsOptions();

const NewWalletForm = ({
  values,
  isSubmitting,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  dirty,
  isValid,
  submitForm,
  handleSubmit,
  max
}: RefundFormProps & FormikProps<Refund>) => {
  const [isNoteOpen, setNoteIsOpen] = useState<boolean>(false);
  const [isDescriptionOpen, setDescriptionIsOpen] = useState<boolean>(false);

  const setNoteOnChange = (data: SelectOptionString) => {
    if (data.label === 'Other') {
      setNoteIsOpen(true);
    } else {
      setNoteIsOpen(false);
    }
    setFieldValue('note', data.value);
  };

  const setDescriptionOnChange = (data: SelectOptionString) => {
    if (data.label === 'Other') {
      setDescriptionIsOpen(true);
    } else {
      setDescriptionIsOpen(false);
    }
    setFieldValue('description', data.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input
        label="Amount (EXCL. VAT)"
        value={values.amount}
        name="amount"
        onChange={handleChange}
        onBlur={handleBlur}
        type="number"
        max={max}
        step={0.001}
        min={0.001}
      />
      {errors.amount && <div className="text-red-500 mb-2">{errors.amount}</div>}

      <Select label="Internal" value={values.note} options={refundReasons} onChange={(data: any) => setNoteOnChange(data)} />

      {isNoteOpen && <TextArea name="note" onChange={(e) => setFieldValue('description', e.target.value)} onBlur={handleBlur} />}
      {errors.note && <div className="text-red-500 my-1 text-sm">{errors.note}</div>}

      <Select
        label="User reason"
        value={values.description}
        options={refundSubReasons[values.note as RefundReason] || [{ value: '', label: 'Select' }]}
        onChange={(data: any) => setDescriptionOnChange(data)}
      />

      {isDescriptionOpen && (
        <TextArea name="description" onChange={(e) => setFieldValue('description', e.target.value)} onBlur={handleBlur} />
      )}

      {errors.description && <div className="text-red-500 my-1 text-sm">{errors.description}</div>}

      <Button
        type="submit"
        primary
        loading={isSubmitting}
        disabled={!isValid || isSubmitting || !dirty}
        content="Refund"
        onClick={submitForm}
      />
    </form>
  );
};

export default withFormik({
  // mapPropsToValues: () => ({
  // }),
  validate: (values: Refund, props: RefundFormProps) => {
    const errors: any = {};

    if (values.amount > props.max) {
      errors.amount = `Refund amount should not be greater than (${props.max} ${values.currency})`;
    }
    if (!values.amount) {
      errors.amount = 'Amount Feild should not be blank';
    }

    if (!values.description) {
      errors.description = 'User reason Feild should not be blank';
    }
    if (!values.note) {
      errors.note = 'Internal Feild should not be blank';
    }
    return errors;
  },
  handleSubmit: async (values, { setSubmitting, props, resetForm }: FormikBag<RefundFormProps, Refund>) => {
    console.log(values);
    setSubmitting(true);
    try {
      await props.onSubmit(values);
      resetForm();
    } finally {
      setSubmitting(false);
    }
  }
})(NewWalletForm);
