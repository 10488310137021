import { Brand, Kitchen } from '@calo/types';
import { format } from 'date-fns';
import ExcelJS, { Fill } from 'exceljs';
import { maxBy, uniqBy } from 'lodash';

import { MenuCategory, MenuPopulateType } from 'lib/enums';
import { slugify } from 'lib/helpers';
import { Food, Menu } from 'lib/interfaces';
import {
  MenusWithFoodCost,
  filterMenus,
  findDailyAverageCostPerMenu,
  findMenusWithFoodCost,
  resolveFoodTypeFromCategory
} from '../helpers';

export const generateMenuSheets = (
  menuList: Menu[],
  foodList: Food[],
  foodIds: string[],
  kitchen: Kitchen,
  weeklyAverageCost: number,
  workbook: ExcelJS.Workbook,
  isWeekly = false,
  menusWithFoodCost?: MenusWithFoodCost
) => {
  const filteredMenuLists = filterMenus(menuList);
  const worksheet = workbook.addWorksheet(isWeekly ? 'weekly-menu-stats' : `${menuList[0].id}-menu-stats`, {
    pageSetup: { fitToPage: true, orientation: 'portrait' }
  });

  const totalMealsPerMenu = isWeekly
    ? menuList.map((menu) => String(uniqBy(menu.food, 'name.en').length))
    : [String(uniqBy(menuList[0].food, 'name.en').length)];

  addDatesRow(menuList, weeklyAverageCost, totalMealsPerMenu, isWeekly, worksheet); // first row
  addDaysPerWeekRow(menuList, menusWithFoodCost, isWeekly, worksheet); // second row

  setColumnsWidth(worksheet);
  addMealCostHeadersRow(totalMealsPerMenu, worksheet); // third row

  const categoriesAdded: MenuCategory[] = [];
  const foodTypesAdded: string[] = [];

  for (const { category, filteredMenuList } of filteredMenuLists) {
    const rows = addRowsToMaxFoodLength(filteredMenuList, foodTypesAdded, categoriesAdded, category, worksheet);
    fillFoodAndCostCells(menuList, filteredMenuList, foodList, foodIds, rows, categoriesAdded, kitchen);
  }
};

const gradientColors: { [key: string]: Fill } = {
  yellow: {
    type: 'gradient',
    gradient: 'angle',
    degree: 180,
    stops: [
      { color: { argb: 'fffff333' }, position: 0 },
      { color: { argb: 'fffff333' }, position: 1 }
    ]
  },
  red: {
    type: 'gradient',
    gradient: 'angle',
    degree: 180,
    stops: [
      { color: { argb: 'ffff0000' }, position: 0 },
      { color: { argb: 'ffff0000' }, position: 1 }
    ]
  },
  grey: {
    type: 'gradient',
    gradient: 'angle',
    degree: 180,
    stops: [
      { color: { argb: 'ffc9d2d3' }, position: 0 },
      { color: { argb: 'ffc9d2d3' }, position: 1 }
    ]
  },
  blue: {
    type: 'gradient',
    gradient: 'angle',
    degree: 180,
    stops: [
      { color: { argb: 'ff09b8bf' }, position: 0 },
      { color: { argb: 'ff09b8bf' }, position: 1 }
    ]
  },
  green: {
    type: 'gradient',
    gradient: 'angle',
    degree: 180,
    stops: [
      { color: { argb: 'ffd0edd7' }, position: 0 },
      { color: { argb: 'ffd0edd7' }, position: 1 }
    ]
  },
  skinColor: {
    type: 'gradient',
    gradient: 'angle',
    degree: 180,
    stops: [
      { color: { argb: 'fffef4cc' }, position: 0 },
      { color: { argb: 'fffef4cc' }, position: 1 }
    ]
  },
  white: {
    type: 'gradient',
    gradient: 'angle',
    degree: 180,
    stops: [
      { color: { argb: 'ffffffff' }, position: 0 },
      { color: { argb: 'ffffffff' }, position: 1 }
    ]
  }
};

const setColumnsWidth = (worksheet: ExcelJS.Worksheet) => {
  const pivot = 4;
  for (const [index, col] of worksheet.columns.entries()) {
    if (index <= 1) {
      col.width = 20;
    }

    if (index === 2) {
      col.width = 3;
    }

    if (index > 2 && index % pivot === 3) {
      col.width = 28;
    }

    if (index > 2 && index % pivot !== 3) {
      col.width = 15; // cost and meal tag columns
    }
  }
};

const addDatesRow = (
  weeklyMenuList: Menu[],
  weeklyAverageCost: number,
  totalMealsPerMenu: string[],
  isWeekly: boolean,
  worksheet: ExcelJS.Worksheet
) => {
  const daysPerMenu = weeklyMenuList.map((menu) => menu.id);

  const secondRowData = [isWeekly ? 'Weekly Cost' : 'Daily Cost', `${weeklyAverageCost.toFixed(0)} %`, ''];
  for (const _ of daysPerMenu) {
    secondRowData.push('', '', '', '');
  }

  const row = worksheet.addRow(secondRowData);

  let dayIndex = 0;
  for (let i = 4; i < secondRowData.length + 1; i += 4) {
    const mealNameCell = row.getCell(i);
    const mealTagCell = row.getCell(i + 2);

    const startColIndex = worksheet.getColumn(mealNameCell.col).number;
    const endColIndex = worksheet.getColumn(mealTagCell.col).number;

    worksheet.mergeCells(row.number, startColIndex, row.number, endColIndex);
    const dayCell = row.getCell(startColIndex);
    dayCell.value = daysPerMenu[dayIndex];
    dayCell.alignment = { horizontal: 'center' };
    dayCell.font = { bold: true };

    const totalMealsCell = row.getCell(i + 3);
    totalMealsCell.value = totalMealsPerMenu[dayIndex] + ' Meals';
    totalMealsCell.font = { bold: true };

    dayIndex++;
  }
};

const addDaysPerWeekRow = (
  weeklyMenuList: Menu[],
  menusWithFoodCost: MenusWithFoodCost = {},
  isWeekly: boolean,
  worksheet: ExcelJS.Worksheet
) => {
  const daysPerMenu = weeklyMenuList.map((menu) => menu.id);

  const secondRowData = ['', '', ''];
  for (const _ of daysPerMenu) {
    secondRowData.push('', '', '', '');
  }

  const row = worksheet.addRow(secondRowData);

  let dayIndex = 0;
  for (let i = 4; i < secondRowData.length + 1; i += 4) {
    const mealNameCell = row.getCell(i);
    const mealTagCell = row.getCell(i + 2);

    const startColIndex = worksheet.getColumn(mealNameCell.col).number;
    const endColIndex = worksheet.getColumn(mealTagCell.col).number;

    worksheet.mergeCells(row.number, startColIndex, row.number, endColIndex);
    const dateCell = row.getCell(startColIndex);
    dateCell.value = format(new Date(daysPerMenu[dayIndex]), 'eeee');
    dateCell.alignment = { horizontal: 'center' };
    dateCell.font = { bold: true };
    dateCell.fill = gradientColors.yellow;

    const costPerMenuCell = row.getCell(i + 3);
    costPerMenuCell.value = isWeekly
      ? findDailyAverageCostPerMenu(menusWithFoodCost, daysPerMenu[dayIndex]).toFixed(0) + ' %'
      : '';
    costPerMenuCell.font = { bold: true };

    dayIndex++;
  }
};

const addMealCostHeadersRow = (totalMealsPerMenu: string[], worksheet: ExcelJS.Worksheet) => {
  const thirdRowData = ['Meal Type', 'Meal Plan', ''];
  for (const _ of totalMealsPerMenu) {
    thirdRowData.push('Meal', 'Cost', 'Meal Tag', '');
  }

  worksheet.addRow(thirdRowData).eachCell((cell) => {
    cell.font = { bold: true };
  });
};

const addRowsToMaxFoodLength = (
  filteredMenuList: Menu[],
  foodTypesAdded: string[],
  categoriesAdded: MenuCategory[],
  category: MenuCategory,
  worksheet: ExcelJS.Worksheet
) => {
  const rows: ExcelJS.Row[] = [];
  const maxLengthFoodList = maxBy(filteredMenuList, (menu) => menu.food.length)?.food.length || 0;

  for (let i = 0; i < maxLengthFoodList; i++) {
    if (i === 0 && !foodTypesAdded.includes(resolveFoodTypeFromCategory(category)) && foodTypesAdded.length !== 0) {
      const row = worksheet.addRow(['']);
      if (![MenuPopulateType.dessert, MenuPopulateType.salad].includes(resolveFoodTypeFromCategory(category))) {
        row.fill = gradientColors.green;
      }
      if (resolveFoodTypeFromCategory(category) === MenuPopulateType.caloKids) {
        row.fill = gradientColors.yellow;
      }
    }

    const foodType = resolveFoodTypeFromCategory(category);
    const categoryWithoutFoodType = String(category).split(foodType).slice(1).join(' ');

    if (i === 0 && !foodTypesAdded.includes(foodType)) {
      foodTypesAdded.push(foodType);
      categoriesAdded.push(category);
      const row = worksheet.addRow([foodType, categoryWithoutFoodType, i + 1]);
      rows.push(row);
    }

    if (i === 0 && !categoriesAdded.includes(category)) {
      categoriesAdded.push(category);
      worksheet.addRow(['']);
      const row = worksheet.addRow(['', categoryWithoutFoodType, i + 1]);
      rows.push(row);
    }

    if (i !== 0 && foodTypesAdded.includes(resolveFoodTypeFromCategory(category))) {
      const row = worksheet.addRow(['', '', i + 1]);
      rows.push(row);
    }
  }

  return rows;
};

const fillFoodAndCostCells = (
  weeklyMenuList: Menu[],
  filteredMenuList: Menu[],
  foodList: Food[],
  foodIds: string[],
  rows: ExcelJS.Row[],
  categoriesAdded: MenuCategory[],
  kitchen: Kitchen
) => {
  const menusWithFoodCostObject = findMenusWithFoodCost(foodList, foodIds, weeklyMenuList, kitchen);

  let columnIndex = 4;
  for (const menu of filteredMenuList) {
    let rowIndex = 0;

    for (const food of menu.food) {
      const currentRow = rows[rowIndex];
      const mealNameCell = currentRow.getCell(columnIndex);
      mealNameCell.value = {
        text: food.name.en,
        hyperlink: `https://dashboard.calo.app/food/slug/${slugify([food.name.en, menu.kitchen, Brand.CALO])}`
      };

      const costCell = currentRow.getCell(columnIndex + 1);
      const cost = menusWithFoodCostObject[menu.id][food.name.en]?.toFixed(0);
      costCell.value = cost ? cost + '%' : '';
      if (Number(cost) > 25) {
        costCell.fill = gradientColors.red;
        costCell.font = { color: { argb: 'ffffffff' } };
      }
      costCell.alignment = { horizontal: 'right' };

      const foodMenuTags: string[] = menu.tags?.find((foodTags) => foodTags.foodId === food.id)?.value || [];
      const mealTagCell = currentRow.getCell(columnIndex + 2);
      mealTagCell.value = foodMenuTags.join(', ');

      rowIndex++;
    }
    columnIndex += 4;
  }
};
