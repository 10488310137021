import { FoodTagsKeys } from '@calo/dashboard-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button } from '@mui/material';
import { CaloLoader, ModalRef } from 'components';
import AddMealsByFilterPopup from 'components/AddMealsByFilterPopup';
import Popup from 'components/Popup';
import { format } from 'date-fns/fp';
import { useFoodListByFilters } from 'hooks';
import { MenuPresentationType } from 'lib/enums';
import { Food, Menu, MenuFood } from 'lib/interfaces';
import { every, isEmpty } from 'lodash-es';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { filterMenus, findMenusWithFoodCost } from '../helpers';
import { WeekMenuListProps } from '../types';
import MenuListCard from './MenuListCard';
import { styles } from './style';

const WeeklyMenuListCards = ({
  filters,
  foodIds,
  foodList,
  isLoading,
  searchTerm,
  isEditable,
  selectedYear,
  selectedWeek,
  onMenuUpdate,
  weeklyMenuList,
  setWeeklyAverageCost,
  isAddMealsPopupOpen,
  setIsAddMealsPopupOpen
}: WeekMenuListProps) => {
  const addMealsByFiltersRef = useRef<ModalRef>();
  const [filtersOn, setFiltersOn] = useState<boolean>(false);
  const [selectedMenu, setSelectedMenu] = useState<Menu | undefined>();
  const [replaceFood, setReplaceFood] = useState<{ open: boolean; name: string; menuId: string; data?: Food }>({
    open: false,
    name: '',
    menuId: ''
  });
  const [selectedMenuFilters, setSelectedMenuFilters] = useState({
    type: '',
    plan: '',
    protein: '',
    taste: '',
    sandwich: undefined,
    lastUsed: '',
    base: '',
    category: '',
    mealName: ''
  });
  const [searchResultsCount, setSearchResultsCount] = useState(0);
  const searchResultRefs = useRef<HTMLDivElement[]>([]);
  const currentIndexRef = useRef(0);
  const [menuFoodIds, setMenuFoodIds] = useState<string[]>([]);

  useEffect(() => {
    if (replaceFood.open && replaceFood.menuId) {
      const menuDate = new Date(replaceFood.menuId);
      handleDateSelected(menuDate);
    }
  }, [replaceFood.open, replaceFood.menuId]);

  const handleDateSelected = (selectedDate: Date) => {
    const formattedDate = format('yyyy-MM-dd')(selectedDate);
    const menuData = weeklyMenuList?.find((menu) => menu.id === formattedDate);
    if (menuData && menuData.food) {
      const newMenuFoodIds = menuData.food.map((f) => f.id);
      setMenuFoodIds(newMenuFoodIds);
    }
  };

  const filteredMenuLists = filterMenus(weeklyMenuList);
  const { foodListFiltersData, foodFiltersLoading, hasNextPage, fetchNextPage, isLoadingFetchNextPage } = useFoodListByFilters({
    selectedMenuFilters,
    country: filters.country,
    kitchen: filters.kitchen,
    brand: filters.brand,
    filtersOn: true
  });

  const menusWithFoodCostObject = useMemo(() => {
    return findMenusWithFoodCost(foodList, foodIds, weeklyMenuList, filters.kitchen);
  }, [weeklyMenuList?.map((menu) => menu.id).join(','), foodList, foodIds]);

  useEffect(() => {
    if (Object.values(menusWithFoodCostObject).length > 0) {
      const allValues = Object.values(menusWithFoodCostObject).flatMap((menu) => Object.values(menu));
      const sum = allValues.reduce((acc, value) => acc + value, 0);
      const average = sum / allValues.length;
      setWeeklyAverageCost(average);
    }
  }, [Object.keys(menusWithFoodCostObject)]);

  const isFoodEmptyInMenus = (menus: Menu[]) => {
    return every(menus, (menu) => isEmpty(menu.food));
  };

  const handleUpdateMenu = (food: MenuFood, menuId: string) => {
    const updatedMenu = weeklyMenuList?.find((menu) => menu.id === menuId);
    if (!updatedMenu) return;
    const removedIds = updatedMenu!.food?.filter((meal) => meal.name.en === food.name.en).map((meal) => meal.id);
    const updatedFoodList = updatedMenu.food?.filter((meal) => meal.name.en !== food.name.en);
    const tagList = updatedMenu.tags?.filter((r) => r !== null && !removedIds.includes(r.foodId));
    const newUpdatedMenu = { ...updatedMenu, food: updatedFoodList, tags: tagList || undefined };
    onMenuUpdate(newUpdatedMenu);
  };

  const handleClosePopup = () => {
    setSelectedMenuFilters({
      type: '',
      plan: '',
      protein: '',
      taste: '',
      sandwich: undefined,
      lastUsed: '',
      base: '',
      category: '',
      mealName: ''
    });
    setFiltersOn(false);
    setSelectedMenu(undefined);
    setReplaceFood({ open: false, name: '', menuId: '' });
    setIsAddMealsPopupOpen(false);
  };

  const handleSearch = useCallback(
    debounce((query) => {
      if (query) {
        const results = (weeklyMenuList ?? []).flatMap((menu) =>
          menu.food.filter((food) => food.name.en.toLowerCase().includes(query.toLowerCase()))
        );
        setSearchResultsCount(results.length);
        searchResultRefs.current = [];
        currentIndexRef.current = -1;
      } else {
        setSearchResultsCount(0);
        searchResultRefs.current = [];
        currentIndexRef.current = 0;
      }
    }, 300),
    [weeklyMenuList]
  );

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, handleSearch]);

  useEffect(() => {
    if (isAddMealsPopupOpen) {
      addMealsByFiltersRef.current?.open();
    } else {
      setIsAddMealsPopupOpen(false);
      addMealsByFiltersRef.current?.close();
    }
  }, [isAddMealsPopupOpen]);

  const handleReplaceFood = (selectedFood: MenuFood[]) => {
    if (!selectedMenu || !weeklyMenuList) {
      return;
    }
    const updatedMenu = weeklyMenuList.find((menu) => menu.id === selectedMenu.id);
    const replacedFoodSize = updatedMenu!.food.filter((food) => food.name.en !== replaceFood.name);
    const updatedMenuData = {
      ...updatedMenu,
      food: replacedFoodSize ? [...replacedFoodSize, ...selectedFood] : [...selectedFood]
    };
    onMenuUpdate(updatedMenuData as any);
    handleClosePopup();
  };

  const handleAddingMealForSpecificDay = (meals: MenuFood[], day: Date) => {
    const formattedDate = format('yyyy-MM-dd')(day);

    const updatedMenu = weeklyMenuList?.find((menu) => menu.id === formattedDate);

    const updatedMenuData = {
      ...updatedMenu,
      food: [...(updatedMenu?.food ?? []), ...meals]
    };
    onMenuUpdate(updatedMenuData as any);
    handleClosePopup();
  };

  const handleScrollToNextResult = () => {
    if (searchResultsCount > 0 && currentIndexRef.current < searchResultRefs.current.length) {
      searchResultRefs.current[currentIndexRef.current]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setSearchResultsCount((prevCount) => prevCount - 1);
      currentIndexRef.current += 1;
    }
  };

  useEffect(() => {
    if (!weeklyMenuList) {
      return;
    }
    const { data } = replaceFood;
    if (data) {
      const updatedFilters = {
        ...selectedMenuFilters,
        type: replaceFood.data?.type[0] ?? '',
        base: replaceFood.data?.foodTags?.find((tag) => tag.key === FoodTagsKeys.base)?.value?.[0] ?? undefined
      };
      setSelectedMenuFilters(updatedFilters);
      setIsAddMealsPopupOpen(true);
    }
  }, [replaceFood]);

  if (isLoading) {
    return <CaloLoader />;
  }

  return (
    <>
      {filteredMenuLists.map(
        ({ category, filteredMenuList }) =>
          !isFoodEmptyInMenus(filteredMenuList) && (
            <MenuListCard
              key={category}
              searchTerm={searchTerm}
              isEditable={isEditable}
              categoryName={category}
              setReplaceFood={setReplaceFood}
              setSelectedMenu={setSelectedMenu}
              handleUpdateMenu={handleUpdateMenu}
              filteredMenuList={filteredMenuList}
              searchResultRefs={searchResultRefs}
              menusWithFoodCostObject={menusWithFoodCostObject}
            />
          )
      )}

      {isEditable && !!searchTerm && !!searchResultsCount && (
        <Box sx={styles.floatButtonContainer}>
          <Button
            variant="contained"
            sx={styles.floatButtonStyle}
            onClick={handleScrollToNextResult}
            startIcon={<KeyboardArrowDownIcon />}
          >
            {searchResultsCount} MORE
          </Button>
        </Box>
      )}

      <Popup
        fullWidth
        maxWidth="desktop"
        ref={addMealsByFiltersRef}
        onClose={handleClosePopup}
        title={replaceFood.open ? `Swap Meal ${replaceFood.name}` : 'Add Meals'}
      >
        <AddMealsByFilterPopup
          confirmation
          filtersOn={filtersOn}
          foodIds={menuFoodIds ?? foodIds}
          replaceFood={replaceFood}
          selectedWeek={selectedWeek}
          setFiltersOn={setFiltersOn}
          selectedYear={selectedYear}
          fetchNextPage={fetchNextPage}
          isLoading={foodFiltersLoading}
          hasNextPage={hasNextPage || false}
          handleClosePopup={handleClosePopup}
          values={(selectedMenu as any) || []}
          handleReplaceFood={handleReplaceFood}
          selectedMenuFilters={selectedMenuFilters}
          addMealsByFiltersRef={addMealsByFiltersRef}
          isFetchingNextPage={isLoadingFetchNextPage}
          menuPresentation={MenuPresentationType.weekly}
          setSelectedMenuFilters={setSelectedMenuFilters}
          onDateSelected={handleDateSelected}
          foodListFilters={(foodListFiltersData || []).filter((f) => !f.deletedAt)}
          handleFoodChanges={(food, tags, selectedDate) => handleAddingMealForSpecificDay(food, selectedDate!)}
        />
      </Popup>
    </>
  );
};

export default WeeklyMenuListCards;
