import { addDays } from 'date-fns/fp';
import { FormikBag, FormikProps, withFormik } from 'formik';
import DatePicker from 'react-date-picker';

import { PermissionService } from '@calo/services';

import { Button } from 'components';
import { SubscriptionPUValues } from 'lib/interfaces';

interface PUFormProps {
  onSubmit: (values: SubscriptionPUValues) => Promise<void>;
  pausedAt?: string;
  resumeAt?: string;
  deliveryDays: number[];
}

const PUForm = ({
  values,
  submitForm,
  handleSubmit,
  setFieldValue,
  isValid,
  dirty,
  deliveryDays
}: PUFormProps & FormikProps<SubscriptionPUValues>) => (
  <form onSubmit={handleSubmit} className="section">
    <div className="field">
      <div className="control">
        <span className="flex h-8 ml-4">
          <label className="mr-2"> Pause</label>
          <DatePicker
            minDetail="month"
            view="month"
            minDate={PermissionService.getMinActionDate(deliveryDays, Date.now())}
            onChange={(date: Date) => setFieldValue('startDate', date)}
            value={values.startDate}
          />
        </span>
      </div>
    </div>
    <div className="field">
      <div className="control">
        <span className="flex h-8">
          <label className="mr-2"> Resume </label>
          <DatePicker
            minDetail="month"
            view="month"
            minDate={addDays(1)(PermissionService.getMinActionDate(deliveryDays, Date.now()))}
            onChange={(date: Date) => setFieldValue('endDate', date)}
            value={values.endDate}
          />
        </span>
      </div>
    </div>
    <Button type="submit" className="ml-4" warning fluid content="Confirm" onClick={submitForm} disabled={!dirty || !isValid} />
  </form>
);

export default withFormik<PUFormProps, SubscriptionPUValues>({
  validate: (values: SubscriptionPUValues) => {
    const errors: Partial<Record<keyof SubscriptionPUValues, boolean>> = {};
    if (values.endDate && values.endDate <= values.startDate) {
      errors.endDate = true;
    }
    return errors;
  },
  handleSubmit: async (values, { props }: FormikBag<PUFormProps, SubscriptionPUValues>) => {
    try {
      await props.onSubmit(values);
    } catch (error) {
      console.log(error);
    }
  }
})(PUForm);
