import { useEffect, useRef, useState } from 'react';

import { FormikBag, FormikProps, withFormik } from 'formik';

import { AddToWallet, PaymentSource, WalletAction } from '@calo/dashboard-types';
import { Currency, PaymentMethod, PaymentProviderType, PlanType, UserAction, WalletReason } from '@calo/types';

import { Box } from '@mui/material';
import { Button, Input, ModalRef, Select, Select2, TextArea } from 'components';
import Popup from 'components/Popup';
import { walletSubReasonsHelper } from 'lib/helpers/walletReasonHelpers';

interface NewWalletFormProps {
  onSubmit: (data: AddToWallet) => Promise<void>;
  paymentMethod: PaymentMethod;
  userCurrency: Currency;
}

const NewWalletForm = ({
  values,
  isSubmitting,
  handleChange,
  handleBlur,
  setFieldValue,
  isValid,
  handleSubmit,
  userCurrency
}: NewWalletFormProps & FormikProps<AddToWallet>) => {
  const refundReasons = [
    WalletReason.INVAILID_ENTRY,
    WalletReason.DOUBLE_ENTRY,
    WalletReason.REFUNDED_MANUALLY,
    WalletReason.FIXING_BALANCE,
    WalletReason.CREDIT_TRANSFER,
    WalletReason.REMOVING_GIFTS,
    WalletReason.REMOVING_DISCOUNT_CODE_CREDITS,
    WalletReason.OTHER
  ];

  const addReasons = [
    WalletReason.CALO_TEAM_PERKS,
    WalletReason.CASH_OR_BENEFIT_PAYMENT,
    WalletReason.COMPENSATION,
    WalletReason.MANUAL_DISCOUNT,
    WalletReason.CX_GIFT,
    WalletReason.MARKETING_GIFT_AND_EXPERIMENTS,
    WalletReason.CALO_BUSINESS,
    WalletReason.TRANSFERRED_CREDITS,
    WalletReason.CUSTOMERS_INFLUENCERS,
    WalletReason.CUSTOMERS_CALO_EXPERIMENTS,
    WalletReason.COMPLEMENTARY_CARD,
    WalletReason.OTHER
  ];

  const onlinePaymentActions = Object.values(UserAction);

  const subReasonOptions = walletSubReasonsHelper.getOptions();

  const [subReason, setSubReason] = useState<string | undefined>();

  const getSubReasonLabel = (reason: string | undefined, subReason: string | undefined) => {
    const subReasonList = walletSubReasonsHelper.getObject();
    //@ts-ignore
    return subReasonList[reason] && subReasonList[reason][subReason];
  };

  const handleConfirmationModal = async (data: AddToWallet) => {
    //@ts-ignore
    const subReasonLabel = subReasonOptions[data.reason]?.find((item) => item.value === subReason)?.label || subReason;
    if (subReason) {
      data.notes = data.notes ? `${subReasonLabel} - ${data.notes}` : subReasonLabel;
      setFieldValue('notes', data.notes);
    }

    handleSubmit();
    confirmModalRef.current?.close();
  };

  const confirmModalRef = useRef<ModalRef>();

  useEffect(() => {
    setFieldValue('currency', userCurrency);
  }, []);

  return (
    <Box
      sx={{
        minHeight: '550px'
      }}
    >
      <Input label="Amount" value={values.amount} name="amount" onChange={handleChange} onBlur={handleBlur} type="number" />

      <Select2
        label="Action"
        value={values.action}
        options={[
          { value: WalletAction.ADD, label: 'ADD' },
          { value: WalletAction.REFUND, label: 'REFUND' }
        ]}
        onChange={(value: any) => setFieldValue('action', value.target.value)}
      />

      <Select2
        label="Source"
        value={values.source}
        options={
          values.action === WalletAction.ADD
            ? [
                { value: PaymentSource.online, label: 'Payment Link' },
                { value: PaymentSource.offline, label: 'Cash' },
                { value: PaymentSource.credits, label: 'Credits' }
              ]
            : [{ value: PaymentSource.credits, label: 'Credits' }]
        }
        onChange={(value: any) => {
          setFieldValue('source', value.target.value);
          if (value.target.value === PaymentSource.online) {
            setFieldValue('paymentProvider', PaymentProviderType.TAP);
            setFieldValue('paymentMethod', PaymentMethod.cc);
          } else {
            setFieldValue('paymentProvider', undefined);
            setFieldValue('paymentMethod', PaymentMethod.cash);
          }
        }}
      />

      {values.action === WalletAction.ADD && values.source === PaymentSource.online ? (
        <Select2
          label="Payment Provider"
          value={values.paymentProvider}
          options={Object.values(PaymentProviderType).map((type) => ({
            label: type,
            value: type
          }))}
          onChange={(value: any) => setFieldValue('paymentProvider', value.target.value)}
        />
      ) : null}

      {/* <Select2
        label="Payment Method"
        value={values.paymentMethod}
        options={[
          { value: PaymentMethod.cash, label: 'Cash' }
        ]}

        onChange={(value: any) => setFieldValue('paymentMethod', value.target.value)}
      /> */}

      {/* <Select2
        label="Currency"
        value={userCurrency}
        options={[
          ...Object.values(Currency).map(curr => ({
            value: curr,
            label: Currency[curr]
          }))
        ]}
        disabled
        onChange={(value: any) => setFieldValue('currency', userCurrency)}
      /> */}

      <Select
        label="Reason"
        value={values.reason}
        options={(values.action === WalletAction.ADD
          ? [PaymentSource.online, PaymentSource.offline].includes(values.source)
            ? onlinePaymentActions
            : addReasons
          : refundReasons
        ).map((reason) => ({
          label: reason.replaceAll('_', ' '),
          value: reason
        }))}
        onChange={(data: any) => {
          setFieldValue('reason', data.value);
          if ((data.value as unknown as UserAction) === UserAction.CREATE_SUBSCRIPTION) {
            setFieldValue('planType', PlanType.full);
          } else {
            setFieldValue('planType', undefined);
          }
        }}
      />

      {[PaymentSource.online, PaymentSource.offline].includes(values.source) &&
      (values.reason as unknown as UserAction) === UserAction.CREATE_SUBSCRIPTION ? (
        <Select2
          label="Plan Type"
          value={values.planType}
          style={{ textTransform: 'capitalize' }}
          options={Object.values(PlanType).map((type) => ({
            label: type.replace(/([a-z0-9])([A-Z])/g, '$1 $2'),
            value: type
          }))}
          onChange={(value: any) => setFieldValue('planType', value.target.value)}
        />
      ) : null}

      <Select
        label="Sub Reason"
        value={subReason}
        //@ts-ignore
        options={subReasonOptions[values.reason] || [{ value: '', label: 'Select' }]}
        onChange={(data: any) => {
          setSubReason(data.value);
        }}
      />

      <TextArea label="Notes" name="notes" value={values.notes} onChange={handleChange} onBlur={handleBlur} />

      <Button
        primary
        onClick={() => confirmModalRef.current?.open()}
        loading={isSubmitting}
        disabled={!isValid || isSubmitting || !values.reason || !values.source || values.currency !== userCurrency}
        content="Create"
      />

      <Popup title={'Confirm details'} ref={confirmModalRef} fullWidth divider onClose={() => confirmModalRef.current?.close()}>
        <div className="mt-4 mb-4 ml-4">
          <span className="flex">
            <ul>
              <li className="flex">
                <p className="font-bold mr-2">Amount: </p> {values.amount}
              </li>
              <li className="flex">
                <p className="font-bold mr-2">Action: </p> {values.action}
              </li>
              <li className="flex">
                <p className="font-bold mr-2">Source: </p> {values.source}
              </li>
              <li className="flex">
                <p className="font-bold mr-2">Payment Method: </p> {values.paymentMethod}
              </li>
              {values.paymentProvider ? (
                <li className="flex">
                  <p className="font-bold mr-2">Payment Provider: </p> {values.paymentProvider}
                </li>
              ) : null}
              <li className="flex">
                <p className="font-bold mr-2">Currency: </p> {values.currency}
              </li>
              <li className="flex">
                <p className="font-bold mr-2">Reason: </p> {values.reason}
              </li>
              {values.planType ? (
                <li className="flex">
                  <p className="font-bold mr-2">Plan Type: </p> {values.planType}
                </li>
              ) : null}
              {getSubReasonLabel(values.reason, subReason) ? (
                <li className="flex">
                  <p className="font-bold mr-2">Sub Reason:</p>
                  {getSubReasonLabel(values.reason, subReason)}
                </li>
              ) : null}
              {values.notes ? (
                <li className="flex">
                  <p className="font-bold mr-2">Notes: </p>
                  {values.notes}
                </li>
              ) : null}
            </ul>
          </span>
        </div>
        <section>
          <div className="float-right mb-6 mt-6">
            <Button
              content="Cancel"
              className="mr-2"
              onClick={() => {
                confirmModalRef.current?.close();
              }}
            />
            <Button
              content="Confirm"
              warning
              onClick={() => {
                handleConfirmationModal(values);
              }}
            />
          </div>
        </section>
      </Popup>
    </Box>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    amount: 10,
    action: WalletAction.ADD,
    source: PaymentSource.credits,
    paymentMethod: PaymentMethod.cash,
    currency: Currency.BHD,
    userId: ''
  }),

  handleSubmit: async (values, { setSubmitting, props, resetForm }: FormikBag<NewWalletFormProps, AddToWallet>) => {
    try {
      await props.onSubmit(values);
      resetForm();
    } catch {
      setSubmitting(false);
    }
  }
})(NewWalletForm);
