import { Icon as Iconify } from '@iconify/react';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import { Box, Card, IconButton, Stack, Typography } from '@mui/material';
import { toggleUISettings } from 'actions/ui';
import { caloTheme } from 'assets/images/theme/calo';
import ExportButton from 'components/ExportButton';
import { useDispatch } from 'react-redux';
import styles from './styles';

interface SharedStatsHeaderProps {
  title: string;
  isDisabled: boolean;
  isLoading: boolean;
  onExport: () => Promise<void>;
  onPrint?: () => void;
}

const SharedStatsHeader = ({ isLoading, isDisabled, title, onExport, onPrint }: SharedStatsHeaderProps) => {
  const dispatch = useDispatch();

  return (
    <Card variant="outlined" sx={styles.card}>
      <Box sx={styles.cardHeaderBox}>
        <Stack sx={styles.mainStack}>
          <Stack>
            <Typography sx={styles.titleText}>{title}</Typography>
          </Stack>
          <Stack display={'flex'} flexDirection={'row'}>
            <IconButton sx={{ cursor: 'pointer' }} onClick={() => dispatch(toggleUISettings())}>
              <Iconify icon="cil:list-filter" width={24} color={caloTheme.palette.neutral900} />
            </IconButton>
            {!!onPrint && (
              <IconButton sx={{ cursor: 'pointer' }} onClick={onPrint}>
                <PrintOutlinedIcon />
              </IconButton>
            )}

            <ExportButton isDisabled={isDisabled} isLoading={isLoading} onClick={() => onExport()} />
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
};
export default SharedStatsHeader;
