import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import { formatKitchenText, resolveCountry } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { FoodComponent } from 'lib/interfaces';
import { capitalize } from 'lodash';
import { Link, useHistory } from 'react-router-dom';

import { Permission, ProcessingStage, PrototypeAction } from '@calo/dashboard-types';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Stack, Typography } from '@mui/material';

import { addPrototypeComponentAction, approvePrototypeComponent, deletePrototypeComponent } from 'actions/foodComponent';
import { getListWithParams } from 'actions/index';
import DeleteConfirmationPopup from 'components/DeleteConfirmationPopup';
import { ModalRef } from 'components/Modal';
import { useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import StatusBox from 'views/ChefPlayground/Shared/StatusBox';
import ComponentNameAndUsage from './ComponentNameAndUsage';
import Container from './Container';

type ComponentHeaderCardProps = {
  isValid: boolean;
  isSubmitting: boolean;
  isEdit: boolean;
  route: Routes;
  isDisabled?: boolean;
  foodComponent: FoodComponent;
  refetch: () => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
};

const ComponentHeaderCard = ({
  isValid,
  isSubmitting,
  isEdit,
  isDisabled,
  route,
  foodComponent,
  handleSubmit,
  refetch
}: ComponentHeaderCardProps) => {
  const userRoles = useUserRoles();
  const history = useHistory();

  const isApproved = foodComponent.stage === ProcessingStage.approved;
  const isWaitingApproval = foodComponent.stage === ProcessingStage.pending;

  const { mutate: createAction, isLoading: actionLoading } = useMutation(addPrototypeComponentAction, {
    onSuccess: () => {
      refetch();
    }
  });

  const { mutate: approveComponent, isLoading: approveLoading } = useMutation(approvePrototypeComponent, {
    onSuccess: () => {
      refetch();
    }
  });

  const { mutate: deleteComponent, isLoading: deleteLoading } = useMutation(deletePrototypeComponent, {
    onSuccess: () => {
      history.push(route);
    }
  });

  const deletionRef = useRef<ModalRef>(null);

  const { data: foodList, isLoading: loadingFood } = useQuery<any, Error, any>(
    [
      'food/prototype',
      {
        filters: {
          brand: foodComponent.brand,
          country: foodComponent.country,
          kitchen: foodComponent.kitchen,
          componentId: foodComponent.id,
          isDeleted: false
        },
        page: 0,
        limit: 50
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: userRoles.includes(Permission.DELETE_PROTOTYPE_INGREDIENT)
    }
  );

  const handleClosePopup = () => {
    deletionRef.current?.close();
  };

  return (
    <>
      <Container sx={{ marginTop: '-35px', paddingBottom: 1 }}>
        <Stack
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                width: '100%'
              }
            }}
          >
            <Stack sx={{ marginLeft: '30px' }}>
              <Link to={route} style={{ color: 'black' }}>
                <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                  <ArrowBackIosIcon
                    style={{
                      fontSize: '14px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 'auto',
                      marginBottom: 'auto'
                    }}
                  />
                  <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>Back</Typography>
                </Stack>
              </Link>
            </Stack>
          </Stack>

          <Typography
            variant="h5"
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              border: 'none',
              color: '#000000',
              textTransform: 'none',
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                width: '100%',
                mt: 1
              }
            }}
          >
            {capitalize(foodComponent?.brand)} - {resolveCountry(foodComponent?.country)} -{' '}
            {formatKitchenText(foodComponent?.kitchen)}
          </Typography>
        </Stack>
        <Stack
          sx={{
            marginX: 'auto',
            marginTop: '20px',
            marginBottom: '12px',
            width: '100%',
            height: '1px',
            backgroundColor: caloTheme.palette.neutral100
          }}
        />
      </Container>
      <Container sx={{ marginBottom: 2, paddingTop: 0, position: 'sticky', top: 0, zIndex: 500 }}>
        <Stack
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <ComponentNameAndUsage foodComponent={foodComponent} />

          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
            <Button
              variant="outlined"
              disabled={
                !foodComponent ||
                actionLoading ||
                deleteLoading ||
                loadingFood ||
                !userRoles.includes(Permission.DELETE_PROTOTYPE_COMPONENT)
              }
              sx={{
                fontSize: '16px',
                fontWeight: 600,
                textTransform: 'capitalize',
                borderRadius: '8px',
                boxShadow: 'none',
                color: caloTheme.palette.neutral900,
                borderColor: caloTheme.palette.neutral900,
                backgroundColor: 'transparent',
                '&:hover': {
                  borderColor: caloTheme.palette.neutral900,
                  backgroundColor: caloTheme.palette.white
                }
              }}
              onClick={() => deletionRef.current?.open()}
            >
              {loadingFood ? 'loading' : 'Delete'}
            </Button>
            {!isApproved && !isWaitingApproval && (
              <Button
                variant="outlined"
                disabled={
                  !foodComponent ||
                  actionLoading ||
                  isApproved ||
                  !userRoles.includes(Permission.CREATE_ACTION_PROTOTYPE_COMPONENT)
                }
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  textTransform: 'capitalize',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  color: caloTheme.palette.neutral900,
                  borderColor: caloTheme.palette.neutral900,
                  backgroundColor: 'transparent',
                  '&:hover': {
                    borderColor: caloTheme.palette.neutral900,
                    backgroundColor: caloTheme.palette.white
                  }
                }}
                onClick={() => createAction({ action: PrototypeAction.pend, id: foodComponent.id })}
              >
                Send for Approval
              </Button>
            )}
            {isWaitingApproval && !isApproved && (
              <Button
                variant="outlined"
                disabled={
                  !foodComponent ||
                  approveLoading ||
                  actionLoading ||
                  isApproved ||
                  !userRoles.includes(Permission.APPROVE_PROTOTYPE_COMPONENT)
                }
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  textTransform: 'capitalize',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  color: caloTheme.palette.white,
                  borderColor: caloTheme.palette.primary500,
                  backgroundColor: caloTheme.palette.primary500,
                  '&:hover': {
                    borderColor: caloTheme.palette.primary500,
                    backgroundColor: caloTheme.palette.primary700
                  }
                }}
                onClick={() => approveComponent(foodComponent.id)}
              >
                Approve
              </Button>
            )}

            {isApproved && <StatusBox status={ProcessingStage.approved} />}
            <Button
              data-test="addComponentButton"
              variant="contained"
              sx={{
                textTransform: 'none',
                fontWeight: 600,
                fontSize: '16px',
                borderRadius: '8px',
                backgroundColor: caloTheme.palette.primary500,
                borderColor: caloTheme.palette.primary500,
                boxShadow: 'none',
                color: 'white',
                '&:hover': {
                  boxShadow: 'none',
                  backgroundColor: caloTheme.palette.primary600,
                  borderColor: caloTheme.palette.primary600
                }
              }}
              disabled={
                isDisabled || !isValid || isSubmitting || isEdit || !userRoles.includes(Permission.UPDATE_PROTOTYPE_COMPONENT)
              }
              onClick={() => handleSubmit()}
            >
              Save Changes
            </Button>
          </Stack>
        </Stack>
      </Container>
      <DeleteConfirmationPopup
        type="component"
        deletionRef={deletionRef}
        typeUsage={
          foodList?.data?.map((meal) => ({
            name: meal.name.en,
            id: meal.id
          })) || []
        }
        isLoading={loadingFood}
        onClose={handleClosePopup}
        onDelete={() => deleteComponent(foodComponent.id)}
      />
    </>
  );
};

export default ComponentHeaderCard;
