import { CreateDeliveryReq, Subscription } from '@calo/dashboard-types';

import { Button, Input } from 'components';
import { format } from 'date-fns/fp';
import useNewDeliveryForm from './useNewDeliveryForm';

interface DeliveriesPanelProps {
  subscription: Subscription;
  onSubmit: (value: CreateDeliveryReq) => Promise<void>;
}

const NewDeliveryForm = ({ subscription, onSubmit }: DeliveriesPanelProps) => {
  const { handleSubmit, values, handleChange, handleBlur, isSubmitting, isValid } = useNewDeliveryForm(subscription, onSubmit);

  return (
    <form onSubmit={handleSubmit}>
      <Input
        label="Date"
        value={values.date}
        name="date"
        onChange={handleChange}
        onBlur={handleBlur}
        type="date"
        min={format('yyyy-MM-dd')(Date.now())}
      />
      <Button type="submit" primary fluid loading={isSubmitting} disabled={!isValid || isSubmitting} content="Confirm" />
    </form>
  );
};

export default NewDeliveryForm;
