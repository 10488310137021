import { useFormik } from 'formik';

import { Subscription } from '@calo/dashboard-types';
import { DietType, FoodType, SubscriptionTier } from '@calo/types';

import { SubscriptionPlanValues } from 'lib/interfaces';

const mapFoodTypeToKeyed = (foodType: FoodType[]) =>
  foodType.reduce(
    (result, ft: FoodType) => {
      if (!result[ft]) {
        result[ft] = 0;
      }
      result[ft] += 1;
      return result;
    },
    {
      [FoodType.breakfast]: 0,
      [FoodType.dinner]: 0,
      [FoodType.lunch]: 0,
      [FoodType.snack]: 0,
      [FoodType.dessert]: 0,
      [FoodType.caloKids]: 0,
      [FoodType.coffee]: 0,
      [FoodType.juice]: 0,
      [FoodType.salad]: 0
    }
  );

export default (subscription: Subscription, onSubmit: (values: SubscriptionPlanValues) => any) => {
  const { foodType, ...plan } = subscription.plan;
  return useFormik<SubscriptionPlanValues>({
    enableReinitialize: true,
    initialValues: {
      tier: subscription.tier || SubscriptionTier.personalized,
      dietType: DietType.balanced,
      ...plan,
      deliveryDays: subscription.deliveryDays,
      foodType: mapFoodTypeToKeyed(foodType)
    },

    validate: (values: SubscriptionPlanValues) => {
      const errors: any = {};
      if (values.tier === SubscriptionTier.standard && [DietType.highProtein, DietType.lowCarb].includes(values.dietType!)) {
        errors.dietType = {
          ...errors.dietType,
          dietType: true
        };
      }
      if (
        values.foodType?.breakfast === undefined ||
        values.foodType?.breakfast > 30 ||
        values.foodType?.breakfast < 0 ||
        values.foodType?.breakfast % 1 !== 0
      ) {
        errors.foodType = {
          ...errors.foodType,
          breakfast: true
        };
      }

      if (
        values.foodType?.dinner === undefined ||
        values.foodType?.dinner > 30 ||
        values.foodType?.dinner < 0 ||
        values.foodType?.dinner % 1 !== 0
      ) {
        errors.foodType = {
          ...errors.foodType,
          dinner: true
        };
      }
      if (
        values.foodType?.lunch === undefined ||
        values.foodType?.lunch > 30 ||
        values.foodType?.lunch < 0 ||
        values.foodType?.lunch % 1 !== 0
      ) {
        errors.foodType = {
          ...errors.foodType,
          lunch: true
        };
      }
      if (
        values.foodType?.snack === undefined ||
        values.foodType?.snack > 30 ||
        values.foodType?.snack < 0 ||
        values.foodType?.snack % 1 !== 0
      ) {
        errors.foodType = {
          ...errors.foodType,
          snack: true
        };
      }
      if (
        values.foodType?.dessert === undefined ||
        values.foodType?.dessert > 30 ||
        values.foodType?.dessert < 0 ||
        values.foodType?.dessert % 1 !== 0
      ) {
        errors.foodType = {
          ...errors.foodType,
          dessert: true
        };
      }
      if (
        values.foodType?.caloKids === undefined ||
        values.foodType?.caloKids > 30 ||
        values.foodType?.caloKids < 0 ||
        values.foodType?.caloKids % 1 !== 0
      ) {
        errors.foodType = {
          ...errors.foodType,
          caloKids: true
        };
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
};
