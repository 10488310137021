import { useState } from 'react';

import { format } from 'date-fns/fp';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { KitchenLogsFilters, Permission } from '@calo/dashboard-types';
import { Country, FoodComponent, Ingredient, Kitchen } from '@calo/types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { getListWithParams, toggleUISettings } from 'actions';
import { FoodSelect, Select, SideDrawer } from 'components';
import { KitchenLogType } from 'lib/enums';
import { getAccessibleCountries, getKitchenOptions } from 'lib/helpers';
import { useUserKitchens, useUserRoles } from 'lib/hooks';
import { AppState } from 'lib/interfaces';

interface SettingsProps {
  onFilter: (values: KitchenLogsFilters) => void;
  filters: KitchenLogsFilters;
  selectedType: string;
  page: number;
  setPage: (value: number) => void;
}

const Settings = ({ filters, onFilter, selectedType, setPage }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const dispatch = useDispatch();
  const roles = useUserRoles();
  const userKitchens: string[] = useUserKitchens();

  const handleFoodChanges = (foodIds: string[]) => {
    onFilter({ ...filters, targetId: foodIds });
    setPage(0);
  };
  const [filterName, setFilterName] = useState<string>('');

  const { data: foodList, isLoading: foodLoading } = useQuery<any, Error, any>(
    [
      'food',
      {
        filters: {
          name: filterName ? filterName : undefined,
          country: filters.country,
          brand: filters.brand,
          ids: !filterName && filters.targetId ? filters.targetId : undefined
        },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page: 0,
        limit: 50
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: !!(selectedType === KitchenLogType.food) || !!filterName
    }
  );

  const { data: foodComponentList, isLoading: compLoading } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components',
      {
        filters: {
          name: filterName ? filterName : undefined,
          country: filters.country,
          brand: filters.brand,
          kitchen: filters.kitchen,
          ids: !filterName && filters.targetId ? filters.targetId : undefined
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!(selectedType === KitchenLogType.foodComponent) || !!filterName
    }
  );

  const { data: ingredientsData, isLoading: ingLoading } = useQuery<any, Error, { data: Ingredient[] }>(
    [
      'ingredients',
      {
        filters: {
          name: filterName ? filterName : undefined,
          country: filters.country,
          brand: filters.brand,
          kitchen: filters.kitchen,
          ids: !filterName && filters.targetId ? filters.targetId : undefined
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!(selectedType === KitchenLogType.ingredient) || !!filterName
    }
  );

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        {roles.includes(Permission.VIEW_FOOD_LIST) && (
          <FoodSelect
            onChange={(foodIds) => handleFoodChanges(foodIds)}
            value={filters.targetId || []}
            isLoading={foodLoading || compLoading || ingLoading}
            foodList={
              (selectedType === KitchenLogType.food && foodList?.data) ||
              (selectedType === KitchenLogType.foodComponent && foodComponentList?.data) ||
              (selectedType === KitchenLogType.ingredient && ingredientsData?.data) ||
              []
            }
            setFilterName={(v) => setFilterName(v)}
            filterName={filterName || ''}
            label={selectedType}
          />
        )}
        <Select
          label="Country"
          value={filters.country}
          onChange={(e: any) => {
            onFilter({
              ...filters,
              targetId: undefined,
              country: e.value as Country,
              kitchen: userKitchens.find((k) => k.includes(e.value)) as Kitchen
            });
          }}
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select
          label="Kitchen"
          value={filters.kitchen}
          onChange={(e: any) => {
            onFilter({ ...filters, targetId: undefined });
            onFilter({ ...filters, kitchen: e.value as Kitchen });
          }}
          options={getKitchenOptions(userKitchens, filters.country)}
        />
        <div className="field">
          <label className="label">Date range</label>
          <div className="control is-clearfix">
            <DateRangePicker
              onChange={(dates: Date[]) => {
                onFilter({
                  ...filters,
                  date: dates
                    ? {
                        gte: format('yyyy-MM-dd')(dates[0]),
                        lte: format('yyyy-MM-dd')(dates[1])
                      }
                    : undefined
                });
              }}
              value={[filters!.date?.gte, filters!.date?.lte]}
            />
          </div>
        </div>
      </section>
    </SideDrawer>
  );
};

export default Settings;
