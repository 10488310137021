import { useState } from 'react';

import { getListWithParams } from 'actions/index';
import { caloTheme } from 'assets/images/theme/calo';
import { InputValueRestrictionType, Routes } from 'lib/enums';
import { getComponentWeight, isNumberInputValid } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { FoodComponent } from 'lib/interfaces';
import { keyBy, round } from 'lodash-es';
import { useQuery } from 'react-query';
import { getChildCompsWithQuantity, purchasingCostInfo } from 'views/Food/helpers';

import { LinkedComponent, MenuFoodComponent, Permission } from '@calo/dashboard-types';
import { CustomMealCategory, Dictionary, Nutrition } from '@calo/types';
import { Icon as Iconify } from '@iconify/react';
import { IconButton, TableCell, TextField, Typography } from '@mui/material';

import InputThemeProvider from '../InputThemeProvider';
import PrebuiltCustomCells from './PrebuiltCustomCells';

interface FoodComponentPickerProps {
  childComp: boolean;
  isDisabled: boolean;
  selectedComponents: MenuFoodComponent[];
  allComponents: Dictionary<FoodComponent>;
  onChange: (rows: MenuFoodComponent[]) => void;
  component: LinkedComponent;
  isPreBuildCustom?: boolean;
  setTotalCostPerUnit: (cost: number) => void;
  setTotalCost: (cost: number) => void;
  index?: number;
}
const FoodComponentRow = ({
  isDisabled,
  selectedComponents,
  onChange,
  allComponents,
  component,
  isPreBuildCustom,
  setTotalCostPerUnit,
  setTotalCost,
  index
}: FoodComponentPickerProps) => {
  const roles = useUserRoles();

  const isDeleteButtonActive = !isDisabled;
  const mappedComponent = allComponents[component.id];
  const isPrototype = mappedComponent?.prototype;

  const componentWeight = getComponentWeight(allComponents, component);
  const [childComponents, setChildComponents] = useState<Dictionary<FoodComponent>>({});

  const selectedComponentDetails = selectedComponents?.map((component) => allComponents[component.id]);

  useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components',
      {
        filters: {
          ids: selectedComponentDetails?.map((component) => component?.childComponents?.map((child) => child?.id)).flat(),
          country: allComponents[component.id]?.country,
          brand: allComponents[component.id]?.brand,
          kitchen: allComponents[component.id]?.kitchen
        }
      }
    ],
    getListWithParams,
    {
      suspense: false,
      keepPreviousData: true,
      enabled: !!allComponents[component.id]?.childComponents?.length,
      onSuccess: (data) => {
        setChildComponents(keyBy(data.data, 'id'));
      }
    }
  );

  const handleChange = (id: string, quantity: number) => {
    if (!isNumberInputValid(quantity, InputValueRestrictionType.quantity)) {
      return;
    }

    const i = selectedComponents.findIndex((r) => r.id === id);
    onChange([...selectedComponents.slice(0, i), { ...selectedComponents[i], quantity }, ...selectedComponents.slice(i + 1)]);
  };

  const childCompsWithQuantity = (
    allComponents: Dictionary<FoodComponent>,
    childComponents: Dictionary<FoodComponent>,
    component: LinkedComponent
  ) =>
    allComponents[component.id]?.childComponents?.length
      ? getChildCompsWithQuantity(childComponents, allComponents, component)
      : [];

  let totalCost = 0;
  let totalCostPerUnit = 0;

  for (const component of selectedComponents) {
    if (allComponents[component.id]) {
      const { totalPurchasingCost } = purchasingCostInfo(
        allComponents[component.id].ingredients,
        childCompsWithQuantity(allComponents, childComponents, component) ?? [],
        allComponents[component.id].cookedRawFactor ?? 1,
        allComponents[component.id].weight ?? 1
      );
      const componentWeight = getComponentWeight(allComponents, component);
      const costPerGram = totalPurchasingCost;
      totalCost += costPerGram * componentWeight;
      totalCostPerUnit += costPerGram;
    }
  }

  setTotalCost(totalCost);
  setTotalCostPerUnit(totalCostPerUnit);

  const handleComponentCostPerUnit = () => {
    const currentComponent = allComponents[component.id];
    if (currentComponent) {
      const { totalPurchasingCost } = purchasingCostInfo(
        allComponents[component.id].ingredients,
        childCompsWithQuantity(allComponents, childComponents, component) ?? [],
        allComponents[component.id].cookedRawFactor ?? 1,
        allComponents[component.id].weight ?? 1
      );
      return totalPurchasingCost;
    }
    return 0;
  };

  const handleRemoveFoodComponent = (removedId: string) => {
    const newList: MenuFoodComponent[] = [...selectedComponents];
    const food = newList.filter((r) => r.id !== removedId);
    onChange(food);
  };

  const navigateToComponentHandler = () => {
    const url = (isPrototype ? Routes.playgroundComponent : Routes.foodComponent).replace(':id', component.id);
    window.open(url, '_blank');
  };

  const handleMacrosData = (allComponents: Dictionary<FoodComponent>, macrosType: Nutrition, rowId: string, weight: number) => {
    return round((allComponents[rowId]?.macros[macrosType] || 0) * weight, 6);
  };

  return (
    <>
      <TableCell sx={{ p: 0, width: '50px' }}>
        <IconButton
          data-test={`handleRemoveFoodComponent#${index}`}
          disabled={!isDeleteButtonActive}
          onClick={() => handleRemoveFoodComponent(component.id)}
        >
          <Iconify
            color={isDeleteButtonActive ? caloTheme.palette.neutral900 : caloTheme.palette.neutral600}
            width={24}
            icon="uil:trash"
          />
        </IconButton>
      </TableCell>
      <TableCell sx={{ pl: 0, fontSize: '16px' }}>
        <Typography sx={{ position: 'relative', display: 'inline' }}>
          {isPrototype && (
            <Typography sx={{ position: 'absolute', right: 0, top: -15, fontSize: '12px', color: caloTheme.palette.primary500 }}>
              (Draft)
            </Typography>
          )}
          {allComponents[component.id]?.name.en && allComponents[component.id]?.name.en}
        </Typography>
      </TableCell>
      <InputThemeProvider>
        {isPreBuildCustom ? (
          <PrebuiltCustomCells
            component={component}
            quantity={
              allComponents[component.id]?.sections?.find((section) => section.category === CustomMealCategory.meal)?.quantity ??
              1
            }
            handleChange={handleChange}
            isDisabled={isDisabled}
          />
        ) : (
          <TableCell>
            <TextField
              data-test={`componentQuantityInput#${index}`}
              type="number"
              value={component.quantity}
              onChange={(e) => handleChange(component.id, parseFloat(e.target.value))}
              error={!component.quantity}
              disabled={isDisabled}
              inputProps={{ style: { borderRadius: 8 }, datatest: `foodComponentQuantityInput#${index}` }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: caloTheme.palette.primary500
                  }
                }
              }}
            />
          </TableCell>
        )}
      </InputThemeProvider>

      <TableCell sx={{ fontSize: '16px' }}>{allComponents[component.id]?.measurementUnit}</TableCell>
      <TableCell sx={{ fontSize: '16px' }}>{componentWeight}</TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {handleMacrosData(allComponents, 'cal' as any, component.id, componentWeight)}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {handleMacrosData(allComponents, Nutrition.protein, component.id, componentWeight)}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {handleMacrosData(allComponents, Nutrition.carbs, component.id, componentWeight)}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {handleMacrosData(allComponents, Nutrition.fat, component.id, componentWeight)}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {handleMacrosData(allComponents, Nutrition.fiber, component.id, componentWeight)}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>{handleComponentCostPerUnit()}</TableCell>
      <TableCell sx={{ fontSize: '16px' }}>{round(handleComponentCostPerUnit() * componentWeight, 6)}</TableCell>
      <TableCell sx={{ p: 0 }}>
        <IconButton disabled={!roles.includes(Permission.VIEW_FOOD_COMPONENTS)} onClick={navigateToComponentHandler}>
          <Iconify
            color={isDeleteButtonActive ? caloTheme.palette.neutral900 : caloTheme.palette.neutral600}
            width={24}
            icon="ph:arrow-right"
          />
        </IconButton>
      </TableCell>
    </>
  );
};
export default FoodComponentRow;
