import { GiftItemType } from '@calo/dashboard-types';
import { Brand, Kitchen } from '@calo/types';
import { Autocomplete, Box, Button, MenuItem, Stack, TextField } from '@mui/material';
import { getListWithParams } from 'actions';
import SpecialRequestChipsRenderer from 'components/SpecialRequestChipsRenderer';
import { handleSearch, resolveCountryFromKitchen } from 'lib';
import { AddedGiftMealData, Food } from 'lib/interfaces';
import { groupBy, upperFirst } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { v4 as uuid } from 'uuid';
import styles from './styles';

interface AddMealsPopupProps {
  brand: Brand;
  kitchen: Kitchen;
  addMealsSection?: string;
  handleConfirmAddMeals: any;
  handleClosePopup: () => void;
  selectedMeals: AddedGiftMealData[];
  setSelectedMeals: React.Dispatch<React.SetStateAction<AddedGiftMealData[]>>;
}

const AddMealsPopup = ({
  kitchen,
  handleClosePopup,
  addMealsSection = 'create',
  brand,
  selectedMeals,
  setSelectedMeals,
  handleConfirmAddMeals
}: AddMealsPopupProps) => {
  const [filterName, setFilterName] = useState<string>('');
  const [amountLimit, setAmountLimit] = useState<boolean>(false);
  const [foodListData, setFoodListData] = useState<Food[]>([]);
  const [selectedMealData, setSelectedMealData] = useState<AddedGiftMealData>({
    amount: 1,
    type: GiftItemType.meal,
    item: { name: { en: '', ar: '' }, size: '', id: '', slug: '' }
  });

  const { data: food, isLoading: foodLoading } = useQuery<any, Error, { data: Food[] }>(
    [
      'food',
      {
        filters: {
          name: filterName ? filterName : undefined,
          country: resolveCountryFromKitchen(kitchen),
          brand,
          kitchen,
          isDeleted: false
        },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page: 0,
        limit: 25,
        calculatePurchasingCost: false
      }
    ],
    getListWithParams,
    {
      suspense: false,
      enabled: !!filterName
    }
  );

  const filteredFoodList = useMemo(() => food?.data?.filter((food) => !['R', 'R2'].includes(food.size)) || [], [food?.data]);

  const options = useMemo(() => {
    const groupedFoods = groupBy(filteredFoodList || [], 'name.en');
    return Object.values(groupedFoods).map((group) => {
      const food = group[0];
      return {
        name: food.name,
        value: food.id,
        data: food,
        size: food.size,
        groupedByName: group,
        label: `${food.name.en}`
      };
    });
  }, [filteredFoodList, filterName]);

  const handleAddMeals = () => {
    if (addMealsSection === 'create') {
      setSelectedMeals((prev) => [...prev, selectedMealData]);
      setAmountLimit(false);
      setSelectedMealData({
        amount: 1,
        type: GiftItemType.meal,
        item: { name: { en: '', ar: '' }, size: '', id: '', slug: '' }
      });
    } else {
      setSelectedMeals([selectedMealData]);
      setAmountLimit(false);
      setSelectedMealData({
        amount: 1,
        type: GiftItemType.meal,
        item: { name: { en: '', ar: '' }, size: '', id: '', slug: '' }
      });
    }
  };
  const handleAmountChange = (event: any) => {
    setAmountLimit(event.target.value >= 25);
    const value = event.target.value === 0 ? 0 : Math.min(Number(event.target.value), 25);
    setSelectedMealData({
      ...selectedMealData,
      amount: value
    });
  };

  return (
    <Box flexDirection={'column'} sx={{ width: '100%' }}>
      <Stack flexDirection={'row'} sx={{ py: 1 }}>
        <Autocomplete
          loading={foodLoading}
          loadingText="Loading..."
          options={options}
          sx={{ ...styles.autoComplete }}
          value={options.find((option) => option.name.en === selectedMealData.item.name.en) || null}
          onChange={(event, newValue) => {
            if (newValue && newValue instanceof Object) {
              setSelectedMealData({
                type: GiftItemType.meal,
                item: {
                  name: newValue.name,
                  size: newValue.size,
                  id: newValue.value,
                  slug: newValue.data.slug || ''
                },
                amount: selectedMealData.amount
              });
              setFoodListData(newValue.groupedByName);
            } else {
              setFoodListData([]);
              setSelectedMealData({
                amount: 1,
                type: GiftItemType.meal,
                item: { name: { en: '', ar: '' }, size: '', id: '', slug: '' }
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Meal"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                ...params.InputProps,
                style: { borderRadius: 8, height: '48px' },
                placeholder: 'Search'
              }}
              onChange={(data: any) =>
                setFilterName && handleSearch({ text: data.target.value, action: 'input-change', name: setFilterName })
              }
            />
          )}
        />
        <TextField
          select
          name="size"
          sx={{ width: '20%' }}
          label="Size"
          placeholder="Select size"
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            style: { borderRadius: 8, height: '48px' },
            placeholder: 'Search'
          }}
          disabled={selectedMealData.item.id.length === 0}
          value={selectedMealData.item.id}
          onChange={(data: any) =>
            setSelectedMealData({
              ...selectedMealData,
              item: {
                name: selectedMealData.item.name,
                size: foodListData.find((m) => m.id === data.target.value)?.size || '',
                id: data.target.value,
                slug: foodListData.find((m) => m.id === data.target.value)?.slug || ''
              }
            })
          }
        >
          {Object.values(foodListData).map((value) => (
            <MenuItem key={value.id} value={value.id} sx={{ textTransform: 'normal' }}>
              {upperFirst(value.size)}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          name="amount"
          sx={{ width: '20%', mx: 1 }}
          label="Amount"
          disabled={!selectedMealData.item.id}
          value={selectedMealData.amount}
          helperText={amountLimit ? "Can't add more than 25" : ''}
          error={amountLimit}
          InputProps={{
            inputProps: { style: { borderRadius: 8 }, max: 25, type: 'number' },
            style: { borderRadius: 8, height: '48px' }
          }}
          onChange={handleAmountChange}
        ></TextField>
        <Button
          variant="outlined"
          sx={{ ...styles.addButton }}
          disabled={!selectedMealData.item.id}
          onClick={() => handleAddMeals()}
        >
          Add
        </Button>
      </Stack>
      <Box
        display={addMealsSection === 'create' ? 'flex' : 'none'}
        flexDirection={'row'}
        width={'100%'}
        sx={{ py: addMealsSection === 'create' ? 1 : '4px' }}
      >
        <SpecialRequestChipsRenderer
          key={uuid()}
          items={selectedMeals}
          onDelete={(removedMeal) => setSelectedMeals((prev) => prev.filter((meal) => meal.item.id !== removedMeal.item.id))}
        />
      </Box>
      {addMealsSection === 'create' && (
        <Stack flexDirection={'row'} justifyContent={'center'} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            sx={{ ...styles.confirmButton }}
            onClick={handleConfirmAddMeals}
            disabled={selectedMeals.length === 0 || selectedMealData.item.id.length > 0}
          >
            Confirm
          </Button>
          <Button variant="text" sx={{ ...styles.cancelButton }} onClick={handleClosePopup}>
            Cancel
          </Button>
        </Stack>
      )}
    </Box>
  );
};
export default AddMealsPopup;
