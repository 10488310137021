import { ComponentService } from '@calo/services';
import { Micronutrient, Micronutrients, Nutrition } from '@calo/types';
import { Stack, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { FoodComponent } from 'lib/interfaces';
import { round } from 'lodash-es';
import { useEffect, useState } from 'react';

interface ComponentTotalInformationSectionProps {
  meal: any;
  component: FoodComponent;
  allFoodComponentsData: FoodComponent[];
}

export interface MacroMicroData {
  cal: number;
  totalFat: number;
  transFats: number;
  satFats: number;
  pro: number;
  sodium: number;
  cholesterol: number;
  carbs: number;
  fiber: number;
  totalSugar: number;
  addedSugar: number;
}
const ComponentTotalInformationSection = ({ component, meal, allFoodComponentsData }: ComponentTotalInformationSectionProps) => {
  const [mealMacroMicrosData, setMealMacroMicroData] = useState<any>({
    cal: 0,
    totalFat: 0,
    transFats: 0,
    satFats: 0,
    pro: 0,
    sodium: 0,
    cholesterol: 0,
    carbs: 0,
    fiber: 0,
    totalSugar: 0,
    addedSugar: 0
  });

  useEffect(() => {
    if (component) {
      setMealMacroMicroData({
        cal: round(handleCalculateMacros('cal', '', component) || 0, 2),
        totalFat: round(handleCalculateMacros(Nutrition.fat, '', component) || 0, 2),
        transFats: round(handleCalculateMacros('', Micronutrient.transFats, component) || 0, 2),
        satFats: round(handleCalculateMacros('', Micronutrient.saturatedFats, component) || 0, 2),
        pro: round(handleCalculateMacros(Nutrition.protein, '', component) || 0, 2),
        sodium: round(handleCalculateMacros('', Micronutrient.sodium, component) || 0, 2),
        cholesterol: round(handleCalculateMacros('', Micronutrient.cholesterol, component) || 0, 2),
        carbs: round(handleCalculateMacros(Nutrition.carbs, '', component) || 0, 2),
        fiber: round(handleCalculateMacros(Nutrition.fiber, '', component) || 0, 2),
        totalSugar: round(handleCalculateMacros('', Micronutrient.totalSugar, component) || 0, 2),
        addedSugar: round(handleCalculateMacros('', Micronutrient.addedSugar, component) || 0, 2)
      });
    }
  }, [component, meal]);

  const handleInfoStack = (label: string, value: number, unit: string) => (
    <Stack style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <Typography sx={{ fontSize: '12px', fontStyle: 'normal', fontWeight: 400, lineHeight: '12px' }}>{label}</Typography>
      <Typography
        sx={{ fontSize: '12px', fontStyle: 'normal', fontWeight: 600, lineHeight: '12px' }}
      >{`${value} ${unit}`}</Typography>
    </Stack>
  );

  const handleCalculateMacros = (macrosType: Nutrition | 'cal' | '', microType: Micronutrients | string, component: any) => {
    const originalComp = meal?.components?.find((comp: any) => comp?.id === component?.id);
    const comp = allFoodComponentsData.find((com) => com.id === originalComp.id);
    if (originalComp && comp) {
      const componentWeight = ComponentService.calculateComponentWeight(
        comp.cups,
        comp.measurementUnit,
        comp.weight ?? 0,
        originalComp?.quantity || 0,
        2
      );
      return round((originalComp?.macros[macrosType] || 0) * componentWeight, 2);
    } else {
      return round(
        (microType
          ? component?.micronutrients
            ? component.micronutrients[microType as any]
            : 0
          : component?.macros[macrosType]) || 0,
        4
      );
    }
  };

  return (
    <>
      <Stack sx={{ flexDirection: 'row', margin: 1 }}>
        <Typography sx={{ fontSize: '16px', fontStyle: 'normal', fontWeight: 600, lineHeight: '14px' }}>
          {handleCalculateMacros('cal', '', component)}
        </Typography>
        <Typography sx={{ fontSize: '10px', fontStyle: 'normal', fontWeight: 400, lineHeight: '12px', marginLeft: '4px' }}>
          Kcal
        </Typography>
      </Stack>

      <Stack sx={{ justifyContent: 'space-between', flexDirection: 'row', marginRight: 1 }}>
        <Stack
          sx={{
            borderLeftWidth: 2,
            borderLeftColor: caloTheme.palette.neutral100,
            borderRightWidth: 2,
            borderRightColor: caloTheme.palette.neutral100,
            width: '100%',
            paddingX: 1
          }}
        >
          {handleInfoStack('Total Fats', mealMacroMicrosData.totalFat, 'g')}
          {handleInfoStack('Trans Fats', mealMacroMicrosData.transFats, 'g')}
          {handleInfoStack('Sat Fats', mealMacroMicrosData.satFats, 'g')}
        </Stack>

        <Stack sx={{ borderRightWidth: 2, borderRightColor: caloTheme.palette.neutral100, width: '100%', paddingX: 1 }}>
          {handleInfoStack('Pro', handleCalculateMacros(Nutrition.protein, '', component), 'g')}
          {handleInfoStack('Sodium', mealMacroMicrosData.sodium, 'g')}
          {handleInfoStack('Cholesterol', mealMacroMicrosData.cholesterol, 'g')}
        </Stack>

        <Stack sx={{ borderRightWidth: 2, borderRightColor: caloTheme.palette.neutral100, width: '100%', paddingX: 1 }}>
          {handleInfoStack('Carbs', handleCalculateMacros(Nutrition.carbs, '', component), 'g')}
          {handleInfoStack('Fiber', handleCalculateMacros(Nutrition.fiber, '', component), 'g')}
          {handleInfoStack('Total Sugar', mealMacroMicrosData.totalSugar, 'g')}
          {handleInfoStack('Added Sugar', mealMacroMicrosData.addedSugar, 'g')}
        </Stack>
      </Stack>
    </>
  );
};

export default ComponentTotalInformationSection;
