import { Brand, Country, Kitchen } from '@calo/types';
import { toggleUISettings } from 'actions';
import { Select2, SideDrawer } from 'components';
import { getAccessibleCountries, getKitchenOptions } from 'lib';
import { useUserKitchens } from 'lib/hooks';
import { AppState } from 'lib/interfaces';
import { useDispatch, useSelector } from 'react-redux';

interface MenuFilter {
  country: Country;
  brand: Brand;
  kitchen: Kitchen;
}

interface SettingsProps {
  onFilter: (values: MenuFilter) => any;
  filters: MenuFilter;
}

const Settings = ({ filters, onFilter }: SettingsProps) => {
  const { settingsVisible } = useSelector((state: AppState) => ({
    settingsVisible: state.ui.settings
  }));

  const userKitchens: string[] = useUserKitchens();
  const dispatch = useDispatch();

  return (
    <SideDrawer visible={settingsVisible} onClose={() => dispatch(toggleUISettings())}>
      <section className="section">
        <h5 className="title is-5">Filters</h5>
        <Select2
          label="Country"
          value={filters.country}
          onChange={(data) =>
            onFilter({
              ...filters,
              country: data.target.value as Country,
              kitchen: userKitchens.find((k) => k.includes(data.target.value)) as Kitchen
            })
          }
          options={getAccessibleCountries(userKitchens).map((c) => ({
            value: c,
            label: Country[c]
          }))}
        />
        <Select2
          label="Kitchen"
          value={filters.kitchen}
          onChange={(data) => onFilter({ ...filters, kitchen: data.target.value as Kitchen })}
          options={getKitchenOptions(userKitchens, filters.country)}
          disabled={Object.values(Kitchen).filter((r) => r.includes(filters.country!)).length === 0}
        />
      </section>
    </SideDrawer>
  );
};

export default Settings;
