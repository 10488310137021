import { Stack, Typography } from '@mui/material';
import { UsedOnMenuIndicator } from 'components';
import { getUsedOnMenuButtonColor } from 'lib/helpers/usedOnMenu';
import { FoodComponent, UsedOnMenuReq } from 'lib/interfaces';
import { useEffect, useState } from 'react';

interface ComponentNameAndUsageProps {
  foodComponent: FoodComponent | undefined;
  filteredFoodUsed: UsedOnMenuReq[];
  isDisabled: boolean;
}

const ComponentNameAndUsage = ({ foodComponent, filteredFoodUsed }: ComponentNameAndUsageProps) => {
  const [usedOnMenuButtonColor, setUsedOnMenuButtonColor] = useState<string[]>([]);
  useEffect(() => {
    setUsedOnMenuButtonColor([]);
    if (!foodComponent?.usedOnMenu) {
      return;
    }
    for (const data of foodComponent.usedOnMenu) {
      setUsedOnMenuButtonColor((old) => [...old, getUsedOnMenuButtonColor(data)]);
    }
  }, [foodComponent]);

  return (
    <>
      <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center', justifyContent: 'center' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>{foodComponent?.name.en}</Typography>
        {filteredFoodUsed && filteredFoodUsed.length > 0 && (
          <UsedOnMenuIndicator
            filteredFoodUsed={filteredFoodUsed}
            usedOnMenuButtonColor={usedOnMenuButtonColor}
            type="component"
          />
        )}
      </Stack>
    </>
  );
};

export default ComponentNameAndUsage;
