import { Permission, Subscription } from '@calo/dashboard-types';
import { Button } from 'components';
import { useUserRoles } from 'lib/hooks';

interface StatsProps {
  subscription: Subscription;
  onEditPending: () => void;
}

const Stats = ({ subscription, onEditPending }: StatsProps) => {
  const roles = useUserRoles();

  return (
    <div className="tile is-ancestor">
      <div className="tile is-parent">
        <div className="card tile is-child">
          <div className="card-content">
            <div className="level is-mobile">
              <div className="level-left">
                <div className="level-item">
                  <div className="is-widget-label">
                    <h3 className="subtitle is-spaced">Pending amount</h3>
                    {subscription.pendingAmount[subscription.currency] || 0}
                  </div>
                </div>
              </div>
              <div className="level-right">
                {roles.includes(Permission.UPDATE_SUBSCRIPTION) && (
                  <div className="level-item">
                    <Button icon="fas fa-edit" onClick={onEditPending} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tile is-parent">
        <div className="card tile is-child">
          <div className="card-content">
            <div className="level is-mobile">
              <div className="level-item">
                <div className="is-widget-label">
                  <h3 className="subtitle is-spaced">Balance</h3>
                  {subscription.balance[subscription.currency] || 0}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="tile is-parent">
          <div className="card tile is-child">
            <div className="card-content">
              <div className="level is-mobile">
                <div className="level-item">
                  <div className="is-widget-label">
                    <h3 className="subtitle is-spaced">Carbs</h3>
                    {macros.carbs.min} - {macros.carbs.max}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tile is-parent">
          <div className="card tile is-child">
            <div className="card-content">
              <div className="level is-mobile">
                <div className="level-item">
                  <div className="is-widget-label">
                    <h3 className="subtitle is-spaced">Fat</h3>
                    {macros.fat.min} - {macros.fat.max}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tile is-parent">
          <div className="card tile is-child">
            <div className="card-content">
              <div className="level is-mobile">
                <div className="level-item">
                  <div className="is-widget-label">
                    <h3 className="subtitle is-spaced">Protein</h3>
                    {macros.protein.min} - {macros.protein.max}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
    </div>
  );
};

export default Stats;
